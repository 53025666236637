import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService, DialogActionButton, Colors, IFlexDialogConfig, NoWhitespaceValidator } from '@wephone-utils';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { ConfigManager } from '@wephone-core/wephone-core.module';

export interface IQualFormDialogConfig {
  editName: boolean;
  editGroup: boolean;
  editGoal: boolean;
}

export interface IQualFormDialogValues {
  qual: QualificationEntity;
}

@Component({
  selector: 'app-qualification-form-dialog',
  templateUrl: './qualification-form-dialog.component.html',
  styleUrls: ['./qualification-form-dialog.component.scss']
})
export class QualificationFormDialogComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '500px',
    minHeight: '90%',
  };

  dialogTitle = _tk('call_qualification.content.new_qualification');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.onSubmit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];
  // Private member value
  private readonly userGroupRepo = UserGroupRepository.getInstance<UserGroupRepository>();
  private readonly qualRepo = QualificationRepository.getInstance<QualificationRepository>();
  qual: QualificationEntity;

  // Public member value
  formConfig: IQualFormDialogConfig = {
    editName: true,
    editGroup: false,
    editGoal: false
  };

  groups: UserGroupEntity[];
  qualForm: FormGroup;
  disableSubmit = false;

  constructor(
    public dialogRef: MatDialogRef<QualificationFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
  ) {
    super();
    this.groups = this.userGroupRepo.getObjectList();
    this.qual = this.qualRepo.create({
      value: _ti('call_qualification.content.new_qualification')
    }) as QualificationEntity;
  }

  ngOnInit(): void {
    if (this.data.formConfig) {
      this.formConfig = this.data.formConfig;
    }

    if (this.data.formValues && this.data.formValues.qual) {
      this.qual = this.data.formValues.qual;
    }

    this.qualForm = this.fb.group({
      id: this.qual.id,
      parentId: this.qual.parent_id,
      name: [this.qual.value, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]],
      group: this.qual.group,
      isGoal: this.qual.is_goal
    });
  }

  get nameControl(): FormControl {
    return this.qualForm.get('name') as FormControl;
  }

  createNew(): Promise<any> {
    try {
      const newQual = this.qualRepo.create() as QualificationEntity;
      const formData = this.qualForm.value;
      if (this.qualRepo.getExistQualificationName(formData.name, formData.parentId, null)) {
        this.nameControl.setErrors({ duplicated: true });
      }

      if (!this.qualForm.valid) {
        this.qualForm.markAllAsTouched();
        throw new Error('Invalid form');
      }
      newQual.value = formData.name;
      newQual.group = formData.group;
      newQual.parent_id = formData.parentId;
      newQual.is_goal = formData.isGoal ? 1 : 0;

      return this.qualRepo.save(newQual);
    } catch (error) {
      console.error('Create error', error);
      // this.toast.showError(_ti('public.message.create_failure'));
      throw error;
    }
  }

  update(): Promise<any> {
    try {
      const formData = this.qualForm.value;

      const extraData: any = {};

      if (this.formConfig.editName) {
        extraData.value = formData.name;

        if (this.qualRepo.getExistQualificationName(formData.name, formData.parentId, this.qual.id)) {
          this.nameControl.setErrors({ duplicated: true });
        }
      }

      if (this.formConfig.editGroup) {
        extraData.group_id = formData.group ? formData.group.id : null;
      }

      if (this.formConfig.editGoal) {
        extraData.is_goal = formData.isGoal ? 1 : 0;
      }

      if (!this.qualForm.valid) {
        this.qualForm.markAllAsTouched();
        throw new Error('Invalid form');
      }

      return this.qualRepo.update(this.qual, extraData);
    } catch (error) {
      console.error('Create error', error);
      // this.toast.showError(_ti('public.message.update_failure'));
      throw error;
    }
  }

  async onSubmit(): Promise<any> {
    if (this.qualForm.invalid) {
      this.toast.showError(_ti('form.validator.data_invalid'));
      return undefined;
    }

    try {
      this.disableSubmit = true;
      const result = (this.qual.id) ? await this.update() : await this.createNew();
      this.dialogRef.close(result);
    } catch (error) {
      this.disableSubmit = false;
      const msg = error.error && error.error.message ? error.error.message : '';
      this.toast.showError((this.qual.id ? _ti('public.message.update_failure') : _ti('public.message.create_failure')) + ` ${msg}`);
    }
  }

  cancel(result?): void {
    this.dialogRef.close(result);
  }

  hasFeature(feature: string): boolean {
    return ConfigManager.getInstance().hasFeature(feature);
  }
}
