import * as _ from 'lodash';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastService, EditingComponent, parseDateTime } from '@wephone-utils';
import { TranslatePipe } from '@ngx-translate/core';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { OutcallcampaignGeneralComponent } from '@wephone/components/outcallcampaign/outcallcampaign-general/outcallcampaign-general.component';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { StorageService } from '@wephone-core/service/storage.service';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'app-outcallcampaign-multi-edit',
  templateUrl: './outcallcampaign-multi-edit.component.html',
  styleUrls: ['./outcallcampaign-multi-edit.component.scss'],
  providers: [TranslatePipe]
})
export class OutcallcampaignMultiEditComponent extends EditingComponent implements OnInit {
  ALLOW_SCROLL = false;
  MULTI_EDITOR = true;
  @Input() editingList: OutcallCampaignEntity[];
  @ViewChild('generalEditor') generalEditor: OutcallcampaignGeneralComponent;

  tabSelectedIndex: number;

  dummyCampaign: OutcallCampaignEntity;
  dummyQueue: CallQueueEntity;

  constructor(
    private storage: StorageService,
    private em: EntityManager,
    private toast: ToastService
  ) {
    super();
  }

  setEditingData(data: any): void {
    super.setEditingData(data);
    this.resetDummyCampaignValue();
    this.resetForm();
  }

  updateTabSelectedIndex(index: number): void {
    this.tabSelectedIndex = index;
    this.storage.setAppConfig('tab_outcallcampaign.current_tab', this.tabSelectedIndex || 0);
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    console.log('ngOnInit OutcallCampaignEditComponent');
    this.resetDummyCampaignValue();

    this.updateTabSelectedIndex(this.tabSelectedIndex);
  }

  private getComparedCampaignValue(value: any, field: string): any {
    let val = value;
    switch (field) {
      case 'schedule_start_date':
      case 'schedule_end_date':
        val = value && parseDateTime(value).toISODate();
        break;
      default:
        val = value;
        break;
    }
    return val;
  }

  private resetDummyQueueValue(): void {
    const fieldsToEdit = this.getUpdateFieldsQueue();

    for (const k of Object.keys(fieldsToEdit)) {
      if (fieldsToEdit.hasOwnProperty(k)) {
        const keyName = k; // get entity property
        let sameValue = true;
        const editingValue = this.editingList[0]['queue'][keyName];

        for (let i = 1; i < this.editingList.length; i++) {
          const otherEditingValue = this.editingList[i]['queue'][keyName];
          sameValue = otherEditingValue === editingValue;
          console.log('keyName-not', keyName);
          console.log('keyName-cmp', this.editingList[i]['queue'][keyName], this.editingList[0]['queue'][keyName]);

          if (!sameValue) {
            break;
          }
        }
        if (sameValue) {
          console.log('keyName', keyName);
          this.dummyQueue[keyName] = this.editingList[0]['queue'][keyName];
        }
      }
    }
  }

  private resetDummyCampaignValue(): void {
    this.dummyQueue = CallQueueRepository.getInstance<CallQueueRepository>().create() as CallQueueEntity;
    this.dummyCampaign = OutcallCampaignRepository.getInstance<OutcallCampaignRepository>().create() as OutcallCampaignEntity;

    const fieldsToEdit = this.getUpdateFieldsCampaign();
    for (const k of Object.keys(fieldsToEdit)) {
      if (fieldsToEdit.hasOwnProperty(k)) {
        const keyName = k; // get entity property
        let sameValue = true;
        const editingValue = this.getComparedCampaignValue(this.editingList[0][keyName], keyName);

        for (let i = 1; i < this.editingList.length; i++) {
          const otherEditingValue = this.getComparedCampaignValue(this.editingList[i][keyName], keyName);
          sameValue = otherEditingValue === editingValue;

          if (!sameValue) {
            break;
          }
        }
        if (sameValue) {
          this.dummyCampaign[keyName] = this.editingList[0][keyName];
        }
      }
    }

    this.resetDummyQueueValue();
    console.log('this.dummyCampaign', this.dummyCampaign);
    console.log('this.dummyQueue', this.dummyQueue);
  }

  /**
   * Map between entity property & object property
   */
  private getUpdateFieldsCampaign(): object {
    return {
      // name: 'campaign_name',
      schedule_start_date: 'schedule_start_dt',
      schedule_end_date: 'schedule_end_dt',
      hour_start: undefined,
      hour_end: undefined,
      ivr_id: undefined,
      campaign_type: undefined,
      calling_number_id: undefined,
      max_try_count: undefined,
    };
  }

  /**
   * Map between entity property & object property
   */
  private getUpdateFieldsQueue(): object {
    return {
      recording_mode: undefined,
      queue_priority: undefined,
      out_qualification_id: undefined,
      has_qualification: undefined,
      group_id: undefined,
      bo_type: undefined,
      bo_url: undefined,
      alias: undefined,
      aftercall_pause_mode: undefined,
      after_call_time: undefined
    };
  }

  get editors(): any[] {
    return [this.generalEditor];
  }

  formHasChanged(): boolean {
    for (const editor of this.editors) {
      if (editor && editor.formHasChanged()) {
        return true;
      }
    }

    return false;
  }

  resetForm(): void {
    this.resetDummyCampaignValue();
    for (const editor of this.editors) {
      if (editor) {
        editor.resetForm();
      }
    }
    this.onFormValueChange();
  }

  clearEditState(): void {
    for (const editor of this.editors) {
      if (editor) {
        editor.clearEditState();
      }
    }
  }

  private _getSubmitedValues(field: string, changeset_data: any): any {
    let updated_value;
    switch (field) {
      case 'after_call_time':
      case 'am_check_max_duration':
        updated_value = changeset_data[field] || 0;
        break;
      // Get values from Entity
      case 'out_qualification_id':
      case 'opening_hour_calendar_id':
      case 'group_id':
      case 'ivr_id':
      case 'calling_number_id':
        // Should be null instead of undefined to be valid submit key
        updated_value = changeset_data[field] && changeset_data[field].id ? changeset_data[field].id : null;
        break;
      // Get values from Boolean
      case 'recording_mode':
      case 'has_qualification':
      case 'aftercall_pause_mode':
      case 'am_check_enabled':
        updated_value = changeset_data[field] ? 1 : 0;
        break;
      default:
        updated_value = changeset_data[field];
        break;
    }
    return updated_value;
  }

  async submitForm(): Promise<void> {
    this.generalEditor.markAllAsDirty();
    const changeset_data = _.merge(this.generalEditor.getChangeSet());

    // Mapped properties between Entity Object & Object returned from Server (WebService or WebSocket)
    const campaign_fields: any = this.getUpdateFieldsCampaign();

    const queue_fields: any = this.getUpdateFieldsQueue();

    const obj_data_campaign: any = {};
    const obj_data_queue: any = {};
    const update_campaign_fields: string[] = [];
    const update_campaign_extras: any = {};
    const update_queue_fields: string[] = [];

    if (!Object.keys(changeset_data).length) {
      return undefined;
    }

    for (const field of Object.keys(changeset_data)) {
      // Get values from changeset
      const updated_value = this._getSubmitedValues(field, changeset_data);

      if (field in campaign_fields) {
        // Update mapped fields
        const campaign_field = campaign_fields[field] ? campaign_fields[field] : field;
        obj_data_campaign[campaign_field] = updated_value;
        update_campaign_fields.push(field);
      }

      if (field in queue_fields) {
        const queue_field = queue_fields[field] ? queue_fields[field] : field;
        obj_data_queue[queue_field] = updated_value;
        update_queue_fields.push(field);
      }
    }

    const update_fields = update_campaign_fields.concat(update_queue_fields);

    if (!update_fields.length) {
      console.error('No updated fields');

      return;
    }

    if (!this.generalEditor.isValidForm(update_fields)) {
      console.error('General form invalid', this.generalEditor.form);
      this.toast.showError(_ti('public.message.update_failure'));

      return;
    }

    // Set update campaign properties
    let updatedError = false;
    if (update_campaign_fields.length > 0) {
      const retUpdateCampaign = await this._updateCampaign(obj_data_campaign, update_campaign_fields, update_campaign_extras);
      if (!retUpdateCampaign) {
        updatedError = true;
      }
    }

    // Set update queue properties
    if (update_queue_fields.length > 0) {
      const retUpdateQueue = await this._updateQueue(obj_data_queue, update_queue_fields);
      if (!retUpdateQueue) {
        updatedError = true;
      }
    }

    if (!updatedError) {
      this.resetForm();
      this.toast.show(_ti('public.message.update_success'));
    }
  }

  private async _updateQueue(obj_data_queue, update_queue_fields): Promise<any> {
    const queue: CallQueueEntity = this.em.getRepository('CallQueueRepository').create() as CallQueueEntity;

    queue.setObjectData(obj_data_queue, false);

    try {
      const queueRepo: CallQueueRepository = CallQueueRepository.getInstance();
      const ret = await queueRepo.saveAttrsBulk(this.editingQueueIds, queue, update_queue_fields);

      // this.toast.showSuccess(_ti('public.message.update_success'));
      this.clearEditState();
      return ret;
    } catch (e) {
      console.error('Edit Item error', e);
      // this.toast.showError(_ti('public.message.update_failure'));
    }
  }

  get editingIds(): number[] {
    return this.editingList.map(x => x.id);
  }

  get editingQueueIds(): number[] {
    return this.editingList.map(x => x.queue_id);
  }

  private async _updateCampaign(obj_data_campaign, update_campaign_fields, update_campaign_extras): Promise<any> {
    const campaignRepo: OutcallCampaignRepository = this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository');
    const campaign: OutcallCampaignEntity = campaignRepo.create() as OutcallCampaignEntity;

    campaign.setObjectData(obj_data_campaign, false);

    try {
      const ret = await campaignRepo.saveAttrsBulk(this.editingIds, campaign, update_campaign_fields, update_campaign_extras);

      // this.toast.showSuccess(_ti('public.message.update_success'));
      this.clearEditState();
      return ret;
    } catch (e) {
      console.error('Edit Item error', e);
      // this.toast.showError(_ti('public.message.update_failure'));
    }
  }
}
