import { Component, Inject, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileEntryEntity, FileEntryMethod, SsmlVoiceGender } from '@wephone-core/model/entity/fileentry';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { DialogActionButton, Colors, regexSearch, NoWhitespaceValidator } from '@wephone-utils';
import * as _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Text2SpeechDataService, Text2SpeechLangVoice, GoogleCloudVoiceParam, Text2SpeechLangItem, GoogleCloudFileOption } from '../../service/text2speech-data.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { LocalManager } from '@wephone-core/service/local_manager';

enum ChangeSoundMethod {
  TTS = 'text_to_speech',
  UPLOAD = 'upload_file',
}

enum StepChangeSound {
  SELECT_SOUND = 'select_sound',
  ADD_SOUND = 'add_sound',
  CONFIRM_SOUND = 'confirm_sound',
}
interface FileEntryOptions {
  name?: string;
  is_temp?: number;
  creation_method?: number;
  file_entry_id?: number;
}
@Component({
  selector: 'change-sound',
  templateUrl: './change-sound.html',
  styleUrls: ['./change-sound.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeSoundModal extends DialogComponentBase implements OnInit {
  static UnabledPlayedType: string[] = [
    'audio/x-m4a',
    'audio/m4a'
  ];

  @ViewChild('audioPlayer') audioPlayer;
  dialogTitle = _tk('reusable_sound.content.add_sound');
  backButton: DialogActionButton =
    {
      label: _tk('public.navigation.back'),
      action: () => {
        this.goPrevStep();
      },
      visible: () => {
        return this.steps.indexOf(this.step) > 0;
      },
    };
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.navigation.next'),
      action: () => {
        this.goNextStep();
      },
      visible: () => {
        return this.step !== this.steps[this.steps.length - 1] &&
          (this.selectedMethod === ChangeSoundMethod.TTS && this.newFileEntry ||
            this.selectedMethod === ChangeSoundMethod.UPLOAD && this.soundFile
          );
      },
      color: Colors.PRIMARY
    },
    {
      label: _tk('public.finish'),
      action: () => {
        this.save();
      },
      visible: () => {
        return this.step === this.steps[this.steps.length - 1];
      },
      color: Colors.PRIMARY
    }
  ];

  fileEntry: FileEntryEntity; // current file entry

  private fileEntryRepo = EntityManager.getRepository<FileEntryRepository>('FileEntryRepository');
  private selectExisting = true; // Check whether having step select-step or not, default as True

  showBtnSelects: any = [];
  steps: StepChangeSound[] = [
    StepChangeSound.SELECT_SOUND,
    StepChangeSound.ADD_SOUND,
    StepChangeSound.CONFIRM_SOUND,
  ];

  step = StepChangeSound.SELECT_SOUND;
  selectedMethod: string;

  // text2speech
  text2speech_lang_list: Text2SpeechLangItem[] = [];
  text2speech_lang_voices: Text2SpeechLangVoice; // Map between lang & voice

  // file uploaded
  soundFile: any;

  // binding from directive
  showInputName = true; // Check whether showing input name or not, default as True
  markFileAsTemp = true; // Update is_temp file-entry after saving file-entry
  langSearch = '';

  newFileEntry: FileEntryEntity; // new file entry which will be created
  newFileEntryOptions: FileEntryOptions = {};
  searchCtrl = new FormControl();
  fileEntryList: FileEntryEntity[];
  fileEntrySelect = new FormControl();
  newFileEntryList = [];
  // errors: any = {};
  fileDefaultSound: any;
  language: string;
  allowedUploadExtensions = [
    'mp3',
    'wav',
    'm4a',
  ];

  formGenerateVoice: FormGroup;
  formNameSound: FormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<ChangeSoundModal>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private readonly fileEntryService: FileEntryService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly em: EntityManager,
    private readonly text2SpeechDataService: Text2SpeechDataService,
    private readonly localeManager: LocalManager,
    private readonly fb: FormBuilder,
  ) {
    super(cdRef);
    this.fileEntryList = this.fileEntryRepo.getSharedFileEntries();
    if (data.defaultSound) {
      const defaultFileEntry = (this.em.getRepository('FileEntryRepository') as FileEntryRepository).getDefaultFileEntry(data.defaultSound);
      this.fileEntryList.unshift(defaultFileEntry);
    }
    this.newFileEntryList = this.fileEntryList;

    this.fileEntry = data.fileEntry;
    this.markFileAsTemp = data.markFileAsTemp;
    this.showInputName = data.showInputName;
    this.selectExisting = data.selectExisting; // Check whether edit or create new

    // Go to step add-sound if in case of edit sound
    if (!this.selectExisting) {
      this.step = StepChangeSound.ADD_SOUND;
      this.steps = [
        StepChangeSound.ADD_SOUND,
        StepChangeSound.CONFIRM_SOUND,
      ];
    }

    if (this.fileEntry && this.fileEntry.id) {
      this.dialogTitle = _tk('reusable_sound.content.edit_sound');
    }

    this.newFileEntryOptions = {
      name: this.fileEntry && this.fileEntry.name || this.getDefaultSoundName(_ti('reusable_sound.content.name')),
      is_temp: this.markFileAsTemp ? 1 : 0,
    };
    if (this.fileEntry && this.fileEntry.id) {
      this.newFileEntryOptions.file_entry_id = this.fileEntry.id;
    }
  }

  get langList(): Text2SpeechLangItem[] {
    if (this.langSearch) {
      return this.text2speech_lang_list.filter(x => regexSearch(x.label, this.langSearch));
    }
    return this.text2speech_lang_list || [];
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.language = this.localeManager.getLangForDefaultFileEntry();

    try {
      await this.text2SpeechDataService.listGGCloudVoices();
    } catch (error) {
      console.error('Cannot get text2speech voices', error);
      return;
    }

    this.text2speech_lang_list = this.text2SpeechDataService.text2speech_lang_list;
    this.text2speech_lang_voices = this.text2SpeechDataService.text2speech_lang_voices || {};

    let ttsLang = this.text2speech_lang_list[0].value;
    let ttsText: string;
    let ttsVoice: GoogleCloudVoiceParam;

    if (this.fileEntry && this.fileEntry.tts_data) {
      ttsText = this.fileEntry.tts_data.text;
      const lang: string = this.fileEntry.tts_data.lang;
      const voice: string = this.fileEntry.tts_data.voice;

      ttsLang = lang;
      if (!this.text2speech_lang_voices[lang]) {
        console.error(`Not support lang  ${lang} for text2speech voices`);
      } else {
        const v = this.text2speech_lang_voices[lang].find(x => x.gender === voice);
        if (v) {
          ttsVoice = v.value;
        }
      }
    }

    const formGroupsGenerateSound: any = {
      text: [ttsText, [Validators.required, Validators.maxLength(500), NoWhitespaceValidator]],
      lang: [ttsLang, [Validators.required]],
      voice: [ttsVoice, [Validators.required]]
    };

    // const formGroupsConfirm: any = {};

    if (this.showInputName) {
      // formGroupsConfirm.name = [this.newFileEntryOptions.name, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]];
      this.formNameSound = this.fb.group({
        name: [this.newFileEntryOptions.name, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]]
      });
    }

    this.formGenerateVoice = this.fb.group(formGroupsGenerateSound);

    // this.formNameSound = this.fb.group(formGroupsConfirm);

    // Init TTS lang & voice
    if (!ttsVoice) {
      this.selectDefaultVoice();
    }

    this.detectChanges();

    this.addSubscription(
      this.searchCtrl.valueChanges.subscribe(value => {
        const filterValue: string = (value || '').trim();
        this.filterSound(filterValue);
      })
    );

    this.addSubscription(
      this.formGenerateVoice.get('text').valueChanges.subscribe(() => {
        this.clearTTSSound();
      })
    );

  }

  canPlayLocalFile(): boolean {
    return this.soundFile && !_.includes(ChangeSoundModal.UnabledPlayedType, this.soundFile.type);
  }

  getDefaultSoundName(rootName: string): string {
    let name = rootName;
    let count = 0;
    while (true) {
      count += 1;
      if (!this.fileEntryList.find(x => x.name === name)) {
        break;
      }
      name = `${rootName} ${count}`;
    }
    return name;
  }

  filterSound(name: string): void {
    this.newFileEntryList = this.fileEntryList.filter(sound => {
      if (!name) {
        return true;
      }
      // const regex = new RegExp(name, 'ig');
      // return sound.name.match(regex);
      return regexSearch(sound.name, name);
    });
    this.updateDialogLayout();
  }

  private updateDialogTitle(step: StepChangeSound): void {
    if (step === StepChangeSound.ADD_SOUND) {
      this.dialogTitle = _tk('reusable_sound.content.add_sound');
    } else if (step === StepChangeSound.SELECT_SOUND) {
      this.dialogTitle = _tk('reusable_sound.content.edit_sound');
    }
  }

  private setAddSoundData(): void {
    this.newFileEntryOptions = {
      name: this.getDefaultSoundName(_ti('reusable_sound.content.name')),
      is_temp: this.markFileAsTemp ? 1 : 0,
    };
    // this.generateText = undefined;
    this.formGenerateVoice.get('text').setValue('');
  }

  private setSelectSoundData(): void {
    this.newFileEntryOptions = {
      name: this.fileEntry && this.fileEntry.name || this.getDefaultSoundName(_ti('reusable_sound.content.name')),
      is_temp: this.markFileAsTemp ? 1 : 0,
    };
    if (this.fileEntry && this.fileEntry.id) {
      this.newFileEntryOptions.file_entry_id = this.fileEntry.id;
    }
    if (this.fileEntry && this.fileEntry.tts_data) {
      // this.generateText = this.fileEntry.tts_data.text;
      this.formGenerateVoice.get('text').setValue(this.fileEntry.tts_data.text);
    }
  }

  goPrevStep(): void {
    if (this.step === this.steps[0]) {
      return;
    }
    this.step = this.steps[this.steps.indexOf(this.step) - 1];
    if (this.step === StepChangeSound.SELECT_SOUND) {
      this.setSelectSoundData();
    }

    this.updateDialogTitle(this.step);
    this.markAsChanged();
  }

  async goNextStep(): Promise<void> {
    if (this.step === this.steps[this.steps.length - 1]) {
      console.warn('End step has been reached');
      return;
    }
    if (this.step === StepChangeSound.ADD_SOUND && this.newFileEntry) {
      await this.save();
    }
    this.step = this.steps[this.steps.indexOf(this.step) + 1];

    this.markAsChanged();
  }

  addSound(): void {
    this.updateDialogTitle(StepChangeSound.ADD_SOUND);
    this.setAddSoundData();
    this.goNextStep();
  }

  clearTTSSound(): void {
    this.newFileEntry = undefined;
    this.markAsChanged();
  }

  private getSoundNameError(soundName: string): Record<string, string> {
    if (!soundName || !soundName.trim()) {
      return {
        required: 'sound_manager.message.name_is_empty'
      };
    }

    if (this.fileEntryRepo.nameExist(soundName, this.fileEntry && this.fileEntry.id)) {
      return {
        exist: 'public.message.name_exist'
      };
    }
  }

  async recordByText2speech(): Promise<void> {
    if (this.showInputName) {
      const soundNameError: Record<string, string> = this.getSoundNameError(this.formNameSound.get('name').value);

      if (soundNameError) {
        this.formNameSound.get('name').setErrors(soundNameError);
      }
    }

    const formValid = this.formGenerateVoice.valid && (!this.formNameSound || this.formNameSound.valid);

    if (!formValid) {
      this.formGenerateVoice.markAllAsTouched();
      if (this.formNameSound) {
        this.formNameSound.markAllAsTouched();
      }
      this.detectChanges();
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    this.selectedMethod = ChangeSoundMethod.TTS;

    const soundOptions: GoogleCloudFileOption = {
      name: this.newFileEntryOptions.name,
      create_temp_file: this.newFileEntryOptions.is_temp ? 1 : 0
    };

    if (this.newFileEntryOptions.file_entry_id) {
      soundOptions.file_entry_id = this.newFileEntryOptions.file_entry_id;
    }

    try {
      const res = await this.fileEntryService.recordText2SpeechGGCloud(this.formGenerateVoice.get('text').value, soundOptions, this.formGenerateVoice.get('voice').value);
      this.fileEntryRepo.setDataAsReady();
      this.setNewFileEntry(this.fileEntryRepo.getObjectById(res.id));
    } catch (error) {
      console.error('Record text to speech failed', error);
      this.toastService.showErrorMessage(error, _ti('fileentry.error.unknown'));
    }
  }

  private setNewFileEntry(fileentry: FileEntryEntity): void {
    this.newFileEntry = fileentry;
    if (!this.newFileEntry) {
      console.error('No file entry responsed');
      return;
    }
    this.newFileEntry.setRemoteUrl(this.fileEntryService.getFileUrl(this.newFileEntry.public_id, false, fileentry.is_default ? this.language : null));
    this.markAsChanged();
  }

  changeText2speechLang(): void {
    this.clearTTSSound();
    // set default value for voice
    if (!this.formGenerateVoice.get('lang').value) {
      console.error('No lang selected');
      return;
    }
    this.selectDefaultVoice();
    this.markAsChanged();
  }

  private selectDefaultVoice(): void {
    const text2speech_lang = this.formGenerateVoice.get('lang').value;

    if (!text2speech_lang) {
      console.warn('No lang selected');
      return;
    }

    this.formGenerateVoice.get('voice').setValue(this.text2speech_lang_voices[text2speech_lang] &&
      this.text2speech_lang_voices[text2speech_lang][0] &&
      this.text2speech_lang_voices[text2speech_lang][0].value);
  }

  private async uploadLocalSound(): Promise<FileEntryEntity> {
    if (!this.soundFile) {
      console.error('No local sound to upload');
      throw new Error(_ti('sound_manager.message.no_uploaded_file'));
    }
    const newFileEntryOptions: FileEntryOptions = _.cloneDeep(this.newFileEntryOptions);
    newFileEntryOptions.creation_method = FileEntryMethod.UPLOAD;
    newFileEntryOptions.name = this.formNameSound && this.formNameSound.get('name').value || newFileEntryOptions.name;

    const resp: any = await this.fileEntryService.upload(this.soundFile, newFileEntryOptions);
    this.fileEntryRepo.setDataAsReady();
    const file_entry: FileEntryEntity = this.fileEntryRepo.getObjectById(resp.id);
    this.setNewFileEntry(file_entry);

    return this.newFileEntry;
  }

  fileLoadedForBrowser(soundFile: any): void {
    this.selectedMethod = ChangeSoundMethod.UPLOAD;
    this.soundFile = soundFile;
    this.markAsChanged();
    this.goNextStep();
  }

  async save(): Promise<void> {
    // this.errors = {};
    try {
      if (!this.canPublishFileEntry()) {
        console.error('No new file entry to be uploaded or created');
        throw new Error(_ti('sound_manager.message.no_uploaded_file'));
      }

      if (this.formNameSound) {
        const soundNameError: Record<string, string> = this.getSoundNameError(this.formNameSound.get('name').value);

        if (soundNameError) {
          this.formNameSound.get('name').setErrors(soundNameError);
        }

        const formValid = this.formNameSound.valid;

        if (!formValid) {
          this.formNameSound.markAllAsTouched();
          this.detectChanges();
          this.showError(_ti('public.message.data_invalid'));
          return;
        }

      }

      switch (this.selectedMethod) {
        case ChangeSoundMethod.TTS:
          // check the updated name, update it if there is any
          if (!this.newFileEntry) {
            throw new Error(_ti('sound_manager.message.no_uploaded_file'));
          }

          await this.fileEntryService.updateFileEntry(this.newFileEntry.public_id, {
            name: this.formNameSound && this.formNameSound.get('name').value || this.newFileEntry.name,
            is_temp: this.markFileAsTemp ? 1 : 0
          });
          break;

        case ChangeSoundMethod.UPLOAD:
          await this.uploadLocalSound();
          break;

        default:
          throw new Error('No method selected');
          break;
      }

      this.closeDialog();
    } catch (error) {
      this.showErrorMessage(error, _ti('fileentry.error.unknown'));
    } finally {
      this.markAsChanged();
    }
  }

  chooseExisting(fileEntry: FileEntryEntity): void {
    this.dialogRef.close({ file_entry: fileEntry });
  }

  canPublishFileEntry(): boolean {
    return (
      (this.selectedMethod === ChangeSoundMethod.TTS && this.newFileEntry) ||
      (this.selectedMethod === ChangeSoundMethod.UPLOAD && this.soundFile)
    );
  }

  closeDialog = () => {
    if (!this.newFileEntry) {
      console.error('No new file entry created');
      this.toastService.showError(_ti('sound_manager.message.no_uploaded_file'));

      return;
    }
    console.log('new file entry created', this.newFileEntry);
    this.dialogRef.close({ file_entry: this.newFileEntry });
  }

  deleteSound(): void {
    this.stopSound();
    this.soundFile = undefined;
    this.goPrevStep();
  }

  stopSound(): void {
    if (this.audioPlayer) {
      this.audioPlayer.stop();
    }
  }

  markAsChanged(): void {
    this.cdRef.markForCheck();
    this.updateDialogLayout();
  }
}
