import * as _ from 'lodash';

import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors, EmailValidator } from '@wephone-utils';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { _tk, _ti } from '@wephone-translation';

export interface VoicemailOptionInterface {
  voicemail_by_mail_enabled: number;
  voicemail_in_attachement: number;
  voicemail_by_mail_addrs: string[];
}
@Component({
  selector: 'voicemail-option-modal',
  templateUrl: './voicemail-option-modal.component.html',
  styleUrls: ['./voicemail-option-modal.component.scss']
})
export class VoicemailOptionModal extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('call_queue.content.voicemail_notification_config');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        return this.saveData();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<VoicemailOptionModal>,
    @Inject(MAT_DIALOG_DATA) public data: { voicemailOption: VoicemailOptionInterface }
  ) {
    super();

  }

  ngOnInit(): void {
    const voicemailOption = this.data.voicemailOption;
    this.form = this.fb.group({
      voicemail_by_mail_enabled: [!!voicemailOption.voicemail_by_mail_enabled],
      voicemail_in_attachement: [!!voicemailOption.voicemail_in_attachement],
      voicemail_by_mail_addrs: this.fb.array(
        this.getVoicemailByMailAddrsFormControls(_.cloneDeep(voicemailOption.voicemail_by_mail_addrs) || [])
      ),
    });
  }

  private makeMailAddressControl(value?: any): FormControl {
    return new FormControl(value, [Validators.required, EmailValidator]);
  }

  private getVoicemailByMailAddrsFormControls(mailAddrs: string[]): FormControl[] {
    return mailAddrs.map(mailAddr => this.makeMailAddressControl(mailAddr));
  }

  addVoicemailByMailAddr(): void {
    const newFormControl = this.makeMailAddressControl('');
    // newFormControl.markAsTouched();
    // newFormControl.markAsTouched();

    this.voicemailByMailAddrsControl.push(newFormControl);
  }

  removeVoicemailByMailAddr(index: number): void {
    if (this.voicemailByMailAddrsControl.length === 1) {
      this.voicemailByMailEnabledControl.setValue(false);
      this.voicemailByMailEnabledControl.markAsTouched();

      this.voicemailInAttachementControl.setValue(0);
      this.voicemailInAttachementControl.markAsTouched();
    }

    this.voicemailByMailAddrsControl.markAsTouched();
    this.voicemailByMailAddrsControl.removeAt(index);
  }

  get voicemailByMailEnabledControl(): FormControl {
    return this.form.get('voicemail_by_mail_enabled') as FormControl;
  }

  get voicemailInAttachementControl(): FormControl {
    return this.form.get('voicemail_in_attachement') as FormControl;
  }

  get voicemailByMailAddrsControl(): FormArray {
    return this.form.get('voicemail_by_mail_addrs') as FormArray;
  }

  saveData(): void {
    if (this.form.get('voicemail_by_mail_enabled').value && !this.voicemailByMailAddrsControl.length) {
      this.voicemailByMailAddrsControl.setErrors({required: true});
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    const formValue = this.form.value;
    const newValue: VoicemailOptionInterface = {
      voicemail_by_mail_enabled: formValue.voicemail_by_mail_enabled ? 1 : 0,
      voicemail_in_attachement: formValue.voicemail_in_attachement ? 1 : 0,
      voicemail_by_mail_addrs: formValue.voicemail_by_mail_addrs || []
    };
    this.dialogRef.close(newValue);
  }
}
