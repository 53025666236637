import { datafield, Entity } from '@wephone-core/model/model';

export class SipTrunkEntity extends Entity {
  static object_type_id = 'sip_trunk';

  @datafield id: number;
  @datafield enterprise_id: number;
  @datafield sbc_group_id: number;
  @datafield lb_group_id: number;
  @datafield name: string;
  @datafield username: string;
  @datafield password: string;
  @datafield alias: string;
  @datafield prefix: string;
  @datafield proxy_host: string;
  @datafield proxy_port: number;
  @datafield secondary_proxy_host: string;
  @datafield secondary_proxy_port: number;
  @datafield secondary_proxy_mode: number;
  @datafield outgoing_enabled: number;
  @datafield backup_number: string;
  @datafield backup_number_enable: number;
  @datafield sip_ping_enable: number;
  @datafield channel_max: number;
}
