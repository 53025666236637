import { Injectable } from '@angular/core';
import { _ti } from '@wephone-translation';
import { DidEntity } from '@wephone-core/model/entity/did';
import { Deferred } from 'ts-deferred';
import { DialogService } from '@wephone-utils';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { SipphoneConfigSelectComponent } from '@wephone-common/modals';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { ISipPhoneCustomSettings, PhoneModelType } from '@wephone-core/model/entity/sipphone.i';
import { SipPhoneConnectionLogEntity } from '@wephone-core/model/entity/sipphone_connection_log';
import { SipPhoneConnectionLogRepository } from '@wephone-core/model/repository/sipphone_connection_log';
export interface PendingConfigRequest {
  mac_addr: string;
  name?: string;
  last_request_time: DateTime;
}

export interface SipPhoneConnectionLog {
  id: number;
  sipphone_id: number;
  user_agent: string;
  event_dt: DateTime;
  expire_dt: DateTime;
  disconnection: number;
}

export interface ConfiguredPhone {
  mac_addr: string;
  provisioning_token_name: string;
  sipphone_id: number;
  sipphone: SipPhoneEntity;
}

export interface SipphoneCongfigToken {
  mac_addr: string;
  provisioning_token_name: string;
  sipphone_id: number;
  extension: string;
  enterprise_id: number;
  enterprise_name: string;
}

export interface ValidatePhoneConfigResp {
  sipphone_id: number;
  phone_model: string;
  token: string;
  valid_until: DateTime;
}

@Injectable()
export class SipPhoneService {
  static GetPendingConfigRequest = 'sipphone-config/get_pending_config_request';
  static GetSipPhoneConnectionLog = 'sipphone/connection_log/{sipphone_id}?limit={limit}';
  static GetSipPhoneCustomSettings = 'sipphone/sip_phone_custom_settings/{phone_model}';
  static FIND_CONFIGURED_PHONE_PATH = 'sa/sipphone-config/configured';
  static GET_CONFIGURED_PHONE_PATH = 'sipphone-config/configured';
  static CHANGE_CONFIGURED_PHONE_PATH = 'sipphone-config';
  static REMOVE_CONFIGURED_PHONE_PATH = 'sipphone-config';
  static DOWNLOAD_CONFIGURED_PHONE_PATH = 'api/sipphone-config/download-config/{mac_addr}';
  static ValidatePhoneConfigRequest = 'sipphone-config/validate-config-request/{sipphone_id}/{mac_addr}';
  static deletePhoneConfigRequest = 'sipphone-config/delete-config-request/{mac_addr}';
  static RestartSipPhone = 'callcenter/restart_sip_phone';

  constructor(
    private readonly dialogService: DialogService,
    private readonly httpEngine: HttpEngine,
    private readonly em: EntityManager,
  ) { }

  getPhoneConfig(tokenUrl: string): Promise<string> { // for testing
    return this.httpEngine.get(tokenUrl);
  }

  getPhoneCustomSettings(phoneModel: PhoneModelType): Promise<ISipPhoneCustomSettings> {
    const url = SipPhoneService.GetSipPhoneCustomSettings.replace('{phone_model}', phoneModel || 'undefined');
    return this.httpEngine.apiGetV2(url);
  }

  async getPendingConfigRequest(): Promise<PendingConfigRequest[]> {
    try {
      const url: string = SipPhoneService.GetPendingConfigRequest;
      const resp: PendingConfigRequest[] = await this.httpEngine.apiGetV2(url);
      return resp;
    } catch (error) {
      console.error('getPendingConfigRequest failure', error);
    }
  }

  async getSipPhoneConnectionLog(sipphoneId: number, limit: number = 2): Promise<SipPhoneConnectionLogEntity[]> {
    try {
      const repo: SipPhoneConnectionLogRepository = this.em.getRepository('SipPhoneConnectionLogRepository');
      const url: string = SipPhoneService.GetSipPhoneConnectionLog
        .replace('{sipphone_id}', sipphoneId.toString())
        .replace('{limit}', limit.toString())
        ;
      const ret: SipPhoneConnectionLogEntity[] = [];

      const list = await this.httpEngine.apiGetV2(url);
      for (const l of list) {
        ret.push(repo.create(l) as SipPhoneConnectionLogEntity);
      }

      return ret;
    } catch (error) {
      console.error('getSipPhoneConnectionLog failure', error);
      return [];
    }
  }

  async getConfiguredPhones(): Promise<ConfiguredPhone[]> {
    const ret = await this.httpEngine.apiGetV2(SipPhoneService.GET_CONFIGURED_PHONE_PATH);

    return ret;
  }

  async findConfiguredPhones(macAddr: string = ''): Promise<SipphoneCongfigToken[]> {
    const ret = await this.httpEngine.apiGetV2(SipPhoneService.FIND_CONFIGURED_PHONE_PATH, {
      mac_addr: macAddr
    });

    return ret;
  }

  async changeConfiguredPhone(configuredPhone: ConfiguredPhone, sipphone: SipPhoneEntity): Promise<ConfiguredPhone[]> {
    return this.httpEngine.apiPostV2(`${SipPhoneService.CHANGE_CONFIGURED_PHONE_PATH}/${configuredPhone.mac_addr}/sipphone`, { sipphone_id: sipphone.id });
  }

  async removeConfiguredPhone(configuredPhone: ConfiguredPhone): Promise<ConfiguredPhone[]> {
    return this.httpEngine.apiPostV2(`${SipPhoneService.REMOVE_CONFIGURED_PHONE_PATH}/${configuredPhone.mac_addr}/sipphone/remove`);
  }

  validatePhoneConfigRequest(sipphone: SipPhoneEntity, macAddr: string): Promise<ValidatePhoneConfigResp> {
    const url: string = SipPhoneService.ValidatePhoneConfigRequest
      .replace('{sipphone_id}', sipphone.id.toString())
      .replace('{mac_addr}', macAddr);

    return this.httpEngine.apiGetV2(url);
  }

  deletePhoneConfigRequest(macAddr: string): Promise<ValidatePhoneConfigResp> {
    const url: string = SipPhoneService.deletePhoneConfigRequest.replace('{mac_addr}', macAddr);
    return this.httpEngine.apiPostV2(url);
  }

  selectSipPhone(prompt: string, dialogTitle?: string, sipphone?: SipPhoneEntity): Promise<SipPhoneEntity> {
    const data: { prompt: string; dialogTitle?: string, sipphone?: SipPhoneEntity } = { prompt };
    if (dialogTitle) {
      data.dialogTitle = dialogTitle;
    }

    if (sipphone) {
      data.sipphone = sipphone;
    }

    return this.dialogService.openDialog2(SipphoneConfigSelectComponent, { data }).afterClosed().toPromise();
  }

  async getConfirmDeleteSipPhones(items: SipPhoneEntity[]): Promise<boolean> {
    let msg: string = _ti('user.title.delete');
    const msgs: string[] = [];
    const routedDids: DidEntity[] = [];
    const routedCrmRoutings: CrmRoutingRuleEntity[] = [];
    const routedIvrMenus: IvrCustomMenuEntity[] = [];

    for (const item of items) {
      for (const routedDid of item.routedDids) {
        if (!_.includes(routedDids, routedDid)) {
          routedDids.push(routedDid);
        }
      }

      for (const routedCrmRouting of item.routedCrmRoutings) {
        if (!_.includes(routedCrmRoutings, routedCrmRouting)) {
          routedCrmRoutings.push(routedCrmRouting);
        }
      }

      for (const routedIvrMenu of item.routedIvrMenus) {
        if (!_.includes(routedIvrMenus, routedIvrMenu)) {
          routedIvrMenus.push(routedIvrMenu);
        }
      }
    }

    if (routedDids.length) {
      let sipPhoneObjects: SipPhoneEntity[] = items.filter(o => o.routedDids.length);
      sipPhoneObjects = _.uniqBy(sipPhoneObjects, o => o.id);

      msgs.push(_ti('did.message.confirm_delete_with_dependent_did', {
        dependent_numbers: routedDids.map(x => x.display_number).join(', '),
        objects: sipPhoneObjects.map(x => x.extension).join(', '),
      }));
    }

    if (routedCrmRoutings.length) {
      let sipPhoneObjects: SipPhoneEntity[] = items.filter(o => o.routedCrmRoutings.length);
      sipPhoneObjects = _.uniqBy(sipPhoneObjects, o => o.id);

      msgs.push(_ti('crm_routing.message.confirm_delete_with_dependent_items', {
        crm_routing_names: routedCrmRoutings.map(x => x.name).join(', '),
        objects: sipPhoneObjects.map(x => x.extension).join(', '),
      }));
    }

    if (routedIvrMenus.length) {
      let sipPhoneObjects: SipPhoneEntity[] = items.filter(o => o.routedIvrMenus.length);
      sipPhoneObjects = _.uniqBy(sipPhoneObjects, o => o.id);

      msgs.push(_ti('routing_application.message.confirm_delete_with_dependent_ivrmenus', {
        ivrmenu_names: routedIvrMenus.map(x => x.name).join(', '),
        objects: sipPhoneObjects.map(x => x.extension).join(', ')
      }));
    }

    const ret = new Deferred<boolean>();
    const canDelete = !msgs.length;

    if (!canDelete) {
      msgs.push(_ti('routing_application.message.cannot_clear_routing'));
      msg = msgs.join('\n');

      await this.dialogService.showAlert(_ti('dialogs.warning'), msg);
      ret.resolve(canDelete);
      return ret.promise;
    }

    await this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      msg,
      () => {
        ret.resolve(canDelete);
      }
    );
    return ret.promise;
  }

  async restartSipPhone(sipContactId: string): Promise<any> {
    return this.httpEngine.apiPostV2(SipPhoneService.RestartSipPhone, { contact: sipContactId });
  }

  downloadConfigSipPhone(configuredPhone: ConfiguredPhone): void {
    const url = FlexIvrSettings.getInstance()
      .getEnterpriseUrlV2(SipPhoneService.DOWNLOAD_CONFIGURED_PHONE_PATH.replace('{mac_addr}', configuredPhone.mac_addr));
    window.open(url, '_blank');
  }
}
