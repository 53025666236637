import * as _ from 'lodash';
import { Component, OnInit, Inject } from '@angular/core';
import { DidEntity, DidRoutePriority } from '@wephone-core/model/entity/did';
import { DidRepository } from '@wephone-core/model/repository/did';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ToastService, DialogActionButton, Colors } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-edit-did-in-queue-modal',
  templateUrl: './edit-did-in-queue-modal.component.html',
  styleUrls: ['./edit-did-in-queue-modal.component.scss']
})
export class EditDidInQueueModalComponent extends DialogComponentBase implements OnInit {

  dialogTitle = _tk('call_queue.content.didroute.dialog_title');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.updateDid();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  form: FormGroup;
  priorityList: {
    label: string;
    value: number;
  }[];

  did: DidEntity;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditDidInQueueModalComponent>,
    public toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { did: DidEntity; addnew?: boolean }
  ) {
    super();
    this.priorityList = [
      {
        value: DidRoutePriority.VeryHigh,
        label: _ti('call_queue.content.priority_level.very_high')
      },
      {
        value: DidRoutePriority.High,
        label: _ti('call_queue.content.priority_level.high')
      },
      {
        value: DidRoutePriority.Normal,
        label: _ti('call_queue.content.priority_level.normal')
      },
      {
        value: DidRoutePriority.Low,
        label: _ti('call_queue.content.priority_level.low')
      },
      {
        value: DidRoutePriority.VeryLow,
        label: _ti('call_queue.content.priority_level.very_low')
      }
    ];
  }

  get nameControl(): FormControl {
    return this.form.get('name') as FormControl;
  }

  get welcomeMessageControl(): FormControl {
    return this.form.get('welcome_message') as FormControl;
  }

  get routePriorityControl(): FormControl {
    return this.form.get('route_priority') as FormControl;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.did = _.cloneDeep(this.dialogData.did);
    this.form = this.fb.group({
      number: [this.did.number],
      name: [this.did.name],
      welcome_message: [this.did.welcome_message],
      route_priority: [this.did.route_priority]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(values => {
        this.did.number = values.number;
        this.did.name = values.name;
        this.did.welcome_message = values.welcome_message;
        this.did.route_priority = values.route_priority;
      })
    );
  }

  updateDid(): void {
    this.dialogRef.close({ did: this.did });
  }

  close(): void {
    this.dialogRef.close();
  }
}
