import { Component, OnInit, Input } from '@angular/core';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { SubscriptionEntity } from '@wephone-core/model/entity/subscription';
import { SubscriptionPackEntity } from '@wephone-core/model/entity/subscription_pack';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { SubscriptionPacksModal } from '@wephone-common/subscription-packs-modal/subscription-packs-modal.component';
import { DialogService } from '@wephone-utils/services';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'flex-alert-upgrade',
  templateUrl: './flex-alert-upgrade.component.html',
  styleUrls: ['./flex-alert-upgrade.component.scss']
})
export class FlexAlertUpgradeComponent implements OnInit {
  @Input() appPhone: boolean;
  private readonly enterpriseRepo: EnterpriseRepository = EntityManager.getInstance().getRepository('EnterpriseRepository');
  private adminUrl: string;

  leftDays: number;

  constructor(
    private readonly dialogService: DialogService,
    private route: ActivatedRoute,
    flexIvrSettings: FlexIvrSettings,
  ) {
    this.adminUrl = flexIvrSettings.getAbsoluteUrl('v3');
  }

  async ngOnInit(): Promise<any> {
    await this.checkSubscriptionSoonExpired();

    if (this.appPhone !== true && this.route.snapshot && this.route.snapshot.queryParams && this.route.snapshot.queryParams.upgrade) {
      this.openUpgrade();
    }
  }

  showAlertUpgrade(): boolean {
    return this.leftDays !== undefined && this.leftDays <= 30;
  }

  async checkSubscriptionSoonExpired(): Promise<void> {
    this.leftDays = undefined;
    const myEnterprise: EnterpriseEntity = this.enterpriseRepo.getMyEnterprise();
    const subscription: SubscriptionEntity = await myEnterprise.getCurrentSubscription();
    const subscriptionPack: SubscriptionPackEntity = await myEnterprise.getCurrentSubscriptionPack();

    if (subscriptionPack && subscription.expired_dt) {
      const finalDate = subscription.expired_dt.diff(subscription.start_dt, ['days', 'hours']).toObject();
      this.leftDays = finalDate.days;
    }
  }

  openUpgrade(): void {
    if (this.appPhone === true) {
      this.openAdminOnBrowser();
    } else {
      this.dialogService.openDialog2(SubscriptionPacksModal, { hasBackdrop: true, size: 'l' }, async result => {
        if (!result) {
          console.log('Just close dialog, no payment was made');
          return;
        }

        await this.checkSubscriptionSoonExpired();
      });
    }
  }

  private openAdminOnBrowser(): void {
    const domain = AuthenticationService.getInstance().getEnterpriseDomain();
    if (!domain) {
      console.warn('Cannot found enterprise domain');
      return;
    }
    window.open(`${this.adminUrl}/${domain}/dashboard?upgrade=true`, '_blank');
  }

}
