import * as _ from 'lodash';
import { Component, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { IFlexDialogConfig, StringUtils } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IRecordingCall, RCAnalysisState, RCTranscriptionState, TranscriptAnalysisData } from '@wephone/pages/recording-call/recording-call.i';


@Component({
  selector: 'app-recording-call-analysis-result',
  templateUrl: './recording-call-analysis-result.component.html',
  styleUrls: ['./recording-call-analysis-result.component.scss']
})
export class RecordingCallAnalysisResultComponent extends DialogComponentBase {
  static modalConfig: IFlexDialogConfig = {
    size: 'l'
  };

  dialogTitle = _tk('recording_call.content.transcription_detail');

  item: IRecordingCall;
  analysisResult: TranscriptAnalysisData;
  transcriptionText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: { item: IRecordingCall },
    // private dialogService: DialogService,
  ) {
    super();
    this.item = data.item;

    if (this.item.analysis_state === RCAnalysisState.AnalysisDone && this.item.analysis_result) {
      this.analysisResult = typeof this.item.analysis_result === 'string' ? JSON.parse(this.item.analysis_result) : this.item.analysis_result;
    }

    if (this.item.transcription_state === RCTranscriptionState.TranscriptionDone && this.item.transcription) {
      this.transcriptionText = StringUtils.alignTranscriptionText(this.item.transcription, '<br>');
    }
  }

  getKeyAnalysis(): string[] {
    return this.analysisResult && this.analysisResult.json_result ? Object.keys(this.analysisResult.json_result) : [];
  }

  getKeyAnalysisUuid(): string[] {
    return ['analysis_date', 'transcript_analysis_uuid'];
  }

}
