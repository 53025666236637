import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  ToastService, DialogActionButton, Colors, FormService,
} from '@wephone-utils';
import * as _ from 'lodash';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { EnterpriseSipGatewayRepository } from '@wephone-core/model/repository/enterprise_sip_gateway';
import { EnterpriseSipGatewayService } from '@wephone-common/service';
import { IEnterpriseGatewayConfig, IEnterpriseGatewayFirmwareObject, IEnterpriseGatewayOption } from '@wephone-core/model/entity/enterprise_sip_gateway.i';

@Component({
  selector: 'app-enterprise-sip-gateway-create-modal',
  templateUrl: './enterprise-sip-gateway-create-modal.component.html',
  styleUrls: ['./enterprise-sip-gateway-create-modal.component.scss']
})
export class EnterpriseSipGatewayCreateModalComponent extends DialogComponentBase implements OnInit {
  // static modalConfig: IFlexDialogConfig = {
  //   size: 'l',
  //   width: '600px',
  //   minHeight: '90%',
  // };

  dialogTitle = _tk('enterprise_sip_gateway.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  private readonly sipGatewayRepo = EnterpriseSipGatewayRepository.getInstance<EnterpriseSipGatewayRepository>();
  form: FormGroup;
  modelList: IEnterpriseGatewayOption[] = [];
  firmwareObject: IEnterpriseGatewayFirmwareObject = {};

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly formService: FormService,
    private readonly enterpriseSipGatewayService: EnterpriseSipGatewayService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.initFormGroup();
    await this.resovelData();
  }

  private async resovelData(): Promise<any> {
    const sipGatwayConfig: IEnterpriseGatewayConfig = await this.enterpriseSipGatewayService.getEnterpriseGatewayConfig();
    if (sipGatwayConfig) {
      for (const model of Object.keys(sipGatwayConfig)) {
        this.modelList.push({ value: model, label: sipGatwayConfig[model].name });

        const fwVersions = sipGatwayConfig[model].fw_versions;
        const firmwareList: any = [];
        for (const fwVersion of Object.keys(fwVersions)) {
          firmwareList.push({ value: fwVersion, label: fwVersions[fwVersion] });
        }
        this.firmwareObject[model] = firmwareList;
      }
    }
  }

  initFormGroup(): void {
    const macAddressPattern = '^([0-9A-Fa-f:\s-])+$';
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      model: ['', [Validators.required]],
      firmware: ['', [Validators.required]],
      mac: ['', [Validators.required, Validators.maxLength(32), Validators.pattern(macAddressPattern)]],
      port_count: [undefined, [Validators.required, Validators.min(1), Validators.max(99)]],
    });
  }

  private getChangeSet(): any {
    return this.formService.getChangeSet(this.form);
  }

  async submit(): Promise<void> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.toast.showError(_ti('form.validator.data_invalid'));
        return;
      }

      const formDataUpdated = this.getChangeSet();
      const createdEntity = await this.sipGatewayRepo.createAndSave(formDataUpdated);
      this.toast.showSuccess(_ti('public.message.create_success'));
      this.modalRef.returnValue(createdEntity);
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  clearValue(event: MouseEvent, controlName: string): void {
    event.stopPropagation();
    this.form.get(controlName).markAsDirty();
    this.form.get(controlName).setValue(null);
  }
}
