import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CdrService, MissedCallInfo, MissedCallVoicemail } from '@wephone-core/service/cdr_service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PhoneNumberService, DialogActionButton } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { ICallLog } from '@wephone-app-phone/pages/history/calllog.i';
import { CallLogService } from '@wephone-core/service/call_log_service';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { CrmContactService } from '@wephone-core/service/crm-contact.service';
import * as _ from 'lodash';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-missed-call-info-dialog',
  templateUrl: './missed-call-info-dialog.component.html',
  styleUrls: ['./missed-call-info-dialog.component.scss']
})

export class MissedCallInfoDialogComponent extends DialogComponentBase implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  dialogTitle = _tk('stats.missed_call.dialog_missed_call_info.title');
  dialogRightActions: DialogActionButton[] = [];

  clientInfo: any;
  agentTriedList: any[];
  loading = true;
  displayedColumns = [];
  dataSource: any;

  call: ICallLog;
  callNumber: string;
  callInfo;
  voicemailMessages: MissedCallVoicemail[];

  activeCRM: EnterpriseCrmEntity;
  crmConfig: any;
  private readonly _crmContactUrls: any = {};

  constructor(
    private readonly cdrService: CdrService,
    private readonly calllogService: CallLogService,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly phoneNumberService: PhoneNumberService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly crmContactService: CrmContactService,
    cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;
  }

  async ngOnInit(): Promise<void> {
    this.call = this.data.call;
    this.callNumber = this.calllogService.getRemoteNumber(this.call);
    this.getMissedCallInfo();

    if (!this.activeCRM) {
      this.activeCRM = await this.crmContactService.getActiveCRM(false);
      this.crmConfig = this.crmContactService.getConfigCRM(this.activeCRM);
      if (this.activeCRM) {
        this._crmContactUrls[this.activeCRM.type] = {};
      }
      this.cdr.detectChanges();
    }
  }

  async getMissedCallInfo(): Promise<void> {
    this.loading = true;
    try {
      const missedCallInfo: MissedCallInfo = await this.cdrService.get_missed_call_info(this.call.missed_call_id);
      this.callInfo = missedCallInfo.callInfo;

      const callHistory = missedCallInfo.callHistory || [];
      this.voicemailMessages = missedCallInfo.voicemailMessages || [];

      if (!_.isEmpty(callHistory)) {
        this.agentTriedList = callHistory.map(x => {
          const item: ICallLog = this.calllogService.cdrToCallLog(x);
          return item;
        });
        this.cdRef.detectChanges();
        this.displayedColumns = ['agent_name', 'start_time', 'called_number', 'voicemail_public_id'];
        this.dataSource = new MatTableDataSource(this.agentTriedList);
        this.dataSource.sort = this.sort;
      }
    } catch (error) {
      console.error('Error:', error);
      this.showError('An error occured');
    } finally {
      this.updateDialogLayout();
      this.loading = false;
    }

  }

  numberNormalize(phoneNumber: string): string {
    return this.phoneNumberService.getDisplayNumber(phoneNumber);
  }

  makeCall(): void {
    if (this.data.onMakeCall) {
      this.data.onMakeCall(this.callNumber, this.call);
    } else {
      console.error('No onMakeCall params given on data when calling dialog');
    }
  }

  async getCrmContactUrl(phoneNumber: string): Promise<string> {
    if (!this.activeCRM) {
      console.error('No enterprise crm active');
      return undefined;
    }
    let crmContactUrl = this._crmContactUrls[this.activeCRM.type][phoneNumber];
    if (!crmContactUrl) {
      try {
        const contactData = await this.crmContactService.getCrmContactByNumber(this.activeCRM.id, phoneNumber);
        crmContactUrl = contactData.profileUrl;
        this._crmContactUrls[this.activeCRM.type][phoneNumber] = crmContactUrl;
      } catch (e) {
        this.showError(e && e.error && e.error.message || _ti('crm_contact.message.not_found'));
      }
    }
    return crmContactUrl;
  }

  async openContactProfile(phoneNumber: string): Promise<void> {
    const url = await this.getCrmContactUrl(phoneNumber);
    if (url) {
      window.open(url, '_blank');
    }
  }
}
