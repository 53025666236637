import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRepository } from '@wephone-core/model/repository/user';
import { DialogActionButton, Colors, IFlexDialogConfig } from '@wephone-utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { PasswordValidated, MustMatch } from '@wephone/services/form-validator';
import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'change-user-password',
  templateUrl: './change-user-password.html',
  styleUrls: ['./change-user-password.scss']
})
export class ChangeUserPasswordModal extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'xs',
  };

  current_password: string;
  edit_profile: boolean;
  form: FormGroup;
  dialogTitle = _tk('user.title.changepass');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.changePassword();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  hide = true;
  // private user: IUserEntity;
  private userId: number;

  constructor(
    private dialogRef: MatDialogRef<ChangeUserPasswordModal>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private em: EntityManager,
    protected fb: FormBuilder
  ) {
    super();
    const params: any = this.dialog_data;
    this.userId = params.id;
    this.edit_profile = params.edit_profile || false;
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      password: ['', [PasswordValidated()]],
      confirm_password: ['', [Validators.required]]
    }, { validator: MustMatch('password', 'confirm_password') });
  }

  isValid(): boolean {
    return this.form.valid;
  }

  changePassword(): Promise<any> {
    if (!this.isValid()) {
      this.form.markAllAsTouched();
      this.toastService.showError(_ti('public.message.data_invalid'));
      return;
    }

    const data = {
      password: this.form.controls['password'].value,
      confirm_password: this.form.controls['confirm_password'].value
    };

    return this.em.getRepository<UserRepository>('UserRepository')
      .changePassword(this.userId, data)
      .then(
        () => {
          this.toastService.show(_ti('public.message.update_success'));
          this.dismiss({ status: 'success' });
        },
        response => {
          console.error('Change password error', response);
          const msg = response && response.message || _ti('public.message.update_failure');
          this.toastService.showError(msg);
        }
      );
  }

  public dismiss(data = null): void {
    this.dialogRef.close(data);
  }
}
