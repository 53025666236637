import * as _ from 'lodash';

import { Component, Inject, OnInit } from '@angular/core';
import { Colors, DialogActionButton, NoWhitespaceValidator } from '@wephone-utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { TeamGroupEntity } from '@wephone-core/model/entity/teamgroup';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TeamGroupRepository } from '@wephone-core/model/repository/teamgroup';

@Component({
  selector: 'app-team-create-modal',
  templateUrl: './team-create-modal.component.html',
  styleUrls: ['./team-create-modal.component.scss']
})
export class TeamCreateModalComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('team_group.content.create_new');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.createTeam();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  teams: TeamGroupEntity[] = [];
  form: FormGroup;
  private readonly teamRepo: TeamGroupRepository;

  constructor(
    public dialogRef: MatDialogRef<TeamCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly fb: FormBuilder,
  ) {
    super();
    this.teamRepo = TeamGroupRepository.getInstance<TeamGroupRepository>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.teams = this.teamRepo.getObjectList();
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100), NoWhitespaceValidator]],
      parentId: ['']
    });
  }

  private formValid(): boolean {
    const name: string = this.form.get('name').value.trim();
    const teamGroup: TeamGroupEntity = this.teamRepo.getObjectUsingName(name) as TeamGroupEntity;

    if (teamGroup) {
      this.form.get('name').setErrors({ duplicated: true });
    }
    return this.form.valid;
  }

  async createTeam(): Promise<void> {
    try {
      if (!this.formValid()) {
        this.form.markAllAsTouched();
        throw new Error(_ti('public.message.data_invalid'));
      }

      let team: any = {
        name: _.trim(this.form.get('name').value),
      };

      if (this.form.get('parentId').value) {
        team.parent_id = _.trim(this.form.get('parentId').value);
      }

      const response: { object_id: number, message: string } = await this.teamRepo.createTeamAndSave(team);

      console.log('Create Team-group Response', response);
      if (!response) {
        console.error('Saving user error');
        return;
      }

      team = TeamGroupRepository.getInstance().getObjectById(response.object_id);
      this.toastService.show(_ti('public.message.create_success'));
      this.dialogRef.close(team);
    } catch (error) {
      console.error('Create Team error', error);
      let msg = _ti('public.message.create_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.showError(msg);
    }
  }

  removeOption(): void {
    this.form.get('parentId').markAsDirty();
    this.form.get('parentId').setValue(undefined);
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
