import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'; //https://material.angular.io/components/snack-bar/api
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from './message.service';
import { SingletonBase } from '../utils/singleton';
import { FlexSnackBarComponent } from '@wephone-utils/components/flex-snack-bar/flex-snack-bar.component';
import { _ti } from '@wephone-translation';

export enum ToastStyle {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export type ToastActionCallback = () => void;

@Injectable()
export class ToastService extends SingletonBase {
  public static DURATION_SHORT = 10000;
  public static DURATION_LONG = 30000;
  public static POSITION_TOP = 'top';
  public static POSITION_MIDDLE = 'middle';
  public static POSITION_BOTTOM = 'bottom';

  private toastTop = undefined;
  private permanentErrorMap = {};

  constructor(
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    public messageService: MessageService,
  ) {
    super();
    this.messageService.subscribe('toast:open', toast_params => {
      this.show(_ti(toast_params['message']), _ti(toast_params['title']), toast_params['level']);
    });
  }

  public show(text: string, title?: string, style: ToastStyle = ToastStyle.INFO,
    duration = ToastService.DURATION_SHORT,
    actionText?: string,
    onAction?: ToastActionCallback): void {
    if (this.toastTop) {
      this.toastTop.dismiss();
    }

    const config = new MatSnackBarConfig();
    config.panelClass = style;
    config.duration = duration;
    config.data = {
      title,
      message: text,
      action: actionText,
      onAction,
    };
    // this.toastTop = this.snackBar.open(text, actionText, config);
    this.toastTop = this.snackBar.openFromComponent(FlexSnackBarComponent, config);
    if (actionText) {
      this.toastTop.onAction().subscribe(
        () => {
          if (onAction) {
            this.toastTop.dismiss();
            onAction();
          }
        }
      );
    }

    this.toastTop.afterDismissed().subscribe(() => {
      this.toastTop = undefined;
    });
  }

  public showInfo(text: string, duration = ToastService.DURATION_SHORT, actionLabel?: string, onAction?: ToastActionCallback): void {
    this.show(text, undefined, ToastStyle.INFO, duration, actionLabel, onAction);
  }

  public showSuccess(text: string, duration = ToastService.DURATION_SHORT, actionLabel?: string, onAction?: ToastActionCallback): void {
    this.show(text, undefined, ToastStyle.INFO, duration, actionLabel, onAction);
  }

  public showError(text: string, duration = ToastService.DURATION_SHORT, actionLabel?: string, onAction?: ToastActionCallback): void {
    this.show(text, undefined, ToastStyle.ERROR, duration, actionLabel, onAction);
  }

  public showWarning(text: string, duration = ToastService.DURATION_SHORT, actionLabel?: string, onAction?: ToastActionCallback): void {
    this.show(text, undefined, ToastStyle.WARNING, duration, actionLabel, onAction);
  }

  public showErrorMessage(error: any, defaultMessage: string = _ti('public.message.error_occurred'), translated: boolean = false): void {
    let message: string;
    if (error) {
      message = error.error && error.error.message || error.message;
    }

    if (message && translated) {
      message = _ti(message);
    }

    this.showError(message || defaultMessage);
  }

  async showPermanentError(text: string, error_id: string): Promise<void> {
    if (error_id in this.permanentErrorMap) {
      return;
    }
    this.permanentErrorMap[error_id] = true;
    this.translate.get(text).subscribe(async value => {
      const config = new MatSnackBarConfig();
      config.panelClass = ToastStyle.ERROR;

      if (this.permanentErrorMap[error_id]) {
        const t = await this.snackBar.open(value, undefined, config);
        this.permanentErrorMap[error_id] = t;
        t.afterDismissed().subscribe(() => {
          delete this.permanentErrorMap[error_id];
        });
      }
    });
  }

  public hidePermanentError(error_id: string): void {
    const t = this.permanentErrorMap[error_id];
    if (t) {
      if ('dismiss' in t) {
        t.dismiss();
      }
      delete this.permanentErrorMap[error_id];
    }
  }
}
