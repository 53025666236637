import * as _ from 'lodash';
import { _tk, _ti } from '@wephone-translation';
import { Component, ElementRef, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors, ToastService } from '@wephone-utils';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { FormControl } from '@angular/forms';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { FlexTreeInput } from '@wephone-common';
import { Router } from '@angular/router';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { CallQueueAgentLinkEntity } from '@wephone-core/model/entity/callqueue_agent_link';
import { GroupEntity } from '@wephone-core/model/entity/group';

export class TreeNode implements FlexSelectTreeNode {
  children: TreeNode[];
  id: number;
  name: string;
  checked: boolean;
  checkbox: boolean;
  style?: any;
}

@Component({
  selector: 'dialog-agent-change-queue-list',
  templateUrl: 'agentChangeQueueList.html',
  styleUrls: ['agentChangeQueueList.scss'],
})
export class AgentChangeQueueListDialog extends DialogComponentBase implements AfterViewInit {
  dialogTitle = _tk('call_queue.dialog_queue_list.dialog_title');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.save();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  agent: AgentEntity;
  selected_queue_ids: number[];
  tree_group_queue_list: TreeNode[] = [];
  tree_group_campaign_list: TreeNode[] = [];
  flat_inbound_nodes: TreeNode[] = [];
  flat_outbound_nodes: TreeNode[] = [];
  selected_list: any = {
    inbound: [],
    outbound: []
  };

  hasFeatureGroup: boolean;

  inboundControl = new FormControl();
  outboundControl = new FormControl();

  @ViewChild('inboundTree', { static: true }) inboundTree: FlexTreeInput;
  @ViewChild('outboundTree', { static: true }) outboundTree: FlexTreeInput;
  @ViewChild('descriptionTop', { static: true }) descriptionTop: ElementRef;
  @ViewChild('outboundDescriptionTop', { static: true }) outboundDescriptionTop: ElementRef;

  queueRepo = CallQueueRepository.getInstance<CallQueueRepository>();
  isAdmin: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<AgentChangeQueueListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {
      agent: AgentEntity,
      queues: CallQueueEntity[]
    },
    private readonly toaster: ToastService,
    private readonly authService: AuthenticationService,
    private readonly em: EntityManager,
    private readonly router: Router,
    readonly configManager: ConfigManager,
  ) {
    super();
    this.isAdmin = this.authService.isAdmin();
    this.agent = this.data.agent;
    this.selected_queue_ids = this.data.queues && this.data.queues.length && this.data.queues.map(q => q.id) || [];
    this.hasFeatureGroup = configManager.hasFeature('SERVICE_GROUP');

    let groupTree: GroupEntity[];
    if (this.hasFeatureGroup) {
      groupTree = GroupRepository.getInstance<GroupRepository>().getUserGroupTree();

      this.tree_group_queue_list = this.getTreeNodes(groupTree, true, true);
      this.tree_group_campaign_list = this.getTreeNodes(groupTree, true, false);
    } else {
      this.tree_group_queue_list = this.getTreeNodesForEmptyGroup(true);
      this.tree_group_campaign_list = this.getTreeNodesForEmptyGroup(false);
    }

    this.initSelectedNodes();

    this.addSubscription(
      this.inboundControl.valueChanges.subscribe(selectedIds => {
        this.updateInboundSelectedList(selectedIds);
      })
    );

    this.addSubscription(
      this.outboundControl.valueChanges.subscribe(selectedIds => {
        this.updateOutboundSelectedList(selectedIds);
      })
    );
  }

  ngAfterViewInit(): void {
    if (!this.isAdmin) {
      console.error('Not admin');
      return;
    }

    const elFlexLink = this.descriptionTop.nativeElement.querySelector('a.flex-link');
    if (elFlexLink) {
      elFlexLink.addEventListener('click', () => {
        this.dialogRef.close();
        this.gotoEditAgent(this.agent);
      });
    }

    const elOutboundFlexLink = this.outboundDescriptionTop.nativeElement.querySelector('a.flex-link');
    if (elOutboundFlexLink) {
      elOutboundFlexLink.addEventListener('click', () => {
        this.dialogRef.close();
        this.gotoEditAgent(this.agent);
      });
    }

  }

  private gotoEditAgent(agent: AgentEntity): void {
    this.router.navigateByUrl(`manage-users/users/${agent.user_id}`);
  }

  private saveAgentQueue(queueIds: number[]): void {
    this.em.getRepository<CallQueueRepository>('CallQueueRepository')
      .setAgentQueueList(this.agent.id, queueIds)
      .then(
        result => {
          this.toaster.showInfo(_ti('public.message.update_success'));
          this.dialogRef.close(queueIds);
        },
        result => {
          console.error('An unexpected error has occurred:', result);
          this.toaster.showError(result.message || _ti('public.failure'));
        }
      );
  }

  save(): void {
    // Validate exclusive queue (only for inbound queue)
    // Show warning if request more than 1 exclusive queue
    const queueIds: number[] = (this.selected_list.inbound.concat(this.selected_list.outbound))
      .filter(item => item.checkbox && item.checked && item.id)
      .map(i => i.id);

    const requestedQueues: CallQueueEntity[] = this.em.getRepository('CallQueueRepository').getObjectListByIds(queueIds);
    const requestedInbound: CallQueueEntity[] = requestedQueues.filter(x => !x.is_outbound_campaign);
    const requestedExclusives: CallQueueEntity[] = requestedQueues.filter(x => !x.is_outbound_campaign && !!x.is_exclusive);
    if (requestedExclusives.length > 0 && requestedInbound.length > 1) {
      this.showWarning(_ti('call_queue.message.exclusive_confliction_requested', { queue_names: requestedExclusives.map(x => x.queue_name).join(', ') }));
      return;
    }

    // Show warning if exist queue is exclusive but requested not or vice versa
    const agentId: number = this.agent.id;

    const uneligibleAgentLinks: CallQueueAgentLinkEntity[] = [];
    const allQueueAgentLink: CallQueueAgentLinkEntity[] = (this.em.getRepository('CallQueueAgentLinkRepository')
      .getObjectList() as CallQueueAgentLinkEntity[]);

    const inUsedQueueIds: number[] = this.agent.inbound_queue_list.map(x => x.id);
    const exclusiveQueues: CallQueueEntity[] = this.agent.inbound_queue_list.filter(x => x.is_exclusive);

    const exclusiveQueueIds: number[] = exclusiveQueues.map(x => x.id);
    const exclusiveQueueNames: string[] = exclusiveQueues.map(x => x.queue_name);

    for (const qid of queueIds) {
      const queue: CallQueueEntity = this.em.getRepository('CallQueueRepository').getObjectById(qid);
      // Only check new added inbound queue
      if (queue.is_outbound_campaign || _.includes(inUsedQueueIds, qid)) {
        continue;
      }

      if (queue.is_exclusive && !_.includes(exclusiveQueueIds, qid)) {
        exclusiveQueueNames.push(queue.queue_name);
      }

      if (!this.agent.isFreeFromInboundQueue(!!queue.is_exclusive)) {
        let uneligibleAgentLink: CallQueueAgentLinkEntity = allQueueAgentLink
          .find(x => x.operator_keyid === agentId && x.call_queue_keyid === qid);

        if (!uneligibleAgentLink) {
          uneligibleAgentLink = new CallQueueAgentLinkEntity(qid, agentId);
        }

        uneligibleAgentLinks.push(uneligibleAgentLink);
      }
    }

    // if no uneligible agent
    if (uneligibleAgentLinks.length) {
      // Show warning message if there's any conflict between eligible queue and not
      this.showWarning(_ti('call_queue.message.eligible_exist', { queue_names: exclusiveQueueNames.join(', ') }));
      return;
    }

    this.saveAgentQueue(queueIds);
  }

  private setChecked(items: any): void {
    for (const item of items) {
      if (item.checkbox) {
        item.checked = true; // set checked=true manually
      }
    }
  }

  updateInboundSelectedList(selectedIds: number[]): void {
    const selectedItems = this.flat_inbound_nodes.filter(n => selectedIds.findIndex(id => id === n.id) > -1);
    this.setChecked(selectedItems);
    this.selected_list.inbound = selectedItems;
  }

  updateOutboundSelectedList(selectedIds: number[]): void {
    const selectedItems = this.flat_outbound_nodes.filter(n => selectedIds.findIndex(id => id === n.id) > -1);
    this.setChecked(selectedItems);
    this.selected_list.outbound = selectedItems;
  }

  private createTreeNode(id: number, name: string, checkbox: boolean, checked: boolean): TreeNode {
    const node: TreeNode = {
      id,
      name,
      checked: !!checked,
      checkbox: !!checkbox,
      children: []
    };

    return node;
  }

  private getTreeNodesForEmptyGroup(isInBound: boolean): TreeNode[] {
    const n = this.createTreeNode(0, _ti('public.all'), false, false);
    // Get all inbound/outbound queues
    const groupQueues: CallQueueEntity[] = this.em.getRepository<CallQueueRepository>('CallQueueRepository')
      .getObjectList().filter(x => isInBound && !x.is_outbound_campaign || !isInBound && !!x.is_outbound_campaign);

    for (const q of groupQueues) {
      const nq = this.addFlatNode(q, isInBound);
      n.children.push(nq);
    }

    return [n];
  }

  private getTreeNodes(groupList, loadWithoutGroup = true, isInBound = true): TreeNode[] {
    const treeNodes: TreeNode[] = [];

    const agentGroupIds: number[] = this.agent.group_ids || [];
    const queueType = isInBound ? 'inbound' : 'outbound';

    for (const g of groupList) {
      const n = this.createTreeNode(undefined, g.name, false, false);

      if (g.children) {
        n.children = this.getTreeNodes(g.children, false, isInBound);
      }

      const groupId: number = g.id;

      if (agentGroupIds.length === 0 || !groupId || groupId && agentGroupIds.indexOf(groupId) > -1) {
        // Get queues by group
        const groupQueues: CallQueueEntity[] = this.em.getRepository<CallQueueRepository>('CallQueueRepository')
          .get_queues_by_group_id(groupId, false, queueType).filter(x => !x.group_id || _.isEmpty(agentGroupIds) || _.includes(agentGroupIds, x.group_id));

        if (groupQueues.length) {
          for (const q of groupQueues) {
            const nq = this.addFlatNode(q, isInBound);
            n.children.push(nq);
          }
        }
      }

      // Add to tree if group contain queues
      if (n.children.length > 0) {
        treeNodes.push(n);
      }
    }

    // Add some queues that don't belong to any group
    if (loadWithoutGroup) {
      const queuesWithoutGroup: CallQueueEntity[] = this.em.getRepository<CallQueueRepository>('CallQueueRepository')
        .get_queues_by_group_id(null, false, queueType, true);

      for (const q of queuesWithoutGroup) {
        const nq: TreeNode = this.createTreeNode(
          q.id,
          this.getNodeNameByQueue(q, isInBound),
          true,
          this.selected_queue_ids.indexOf(q.id) !== -1
        );

        if (q.is_exclusive) {
          nq.style = 'font-style: italic;text-decoration: underline';
        }

        treeNodes.push(nq);

        if (isInBound) {
          this.flat_inbound_nodes.push(nq);
        } else {
          this.flat_outbound_nodes.push(nq);
        }
      }
    }

    return treeNodes;
  }

  private addFlatNode(q: CallQueueEntity, isInBound: boolean): TreeNode {
    const nq: TreeNode = this.createTreeNode(
      q.id,
      this.getNodeNameByQueue(q, isInBound),
      true,
      this.selected_queue_ids.indexOf(q.id) !== -1
    );

    if (q.is_exclusive) {
      nq.style = 'font-style: italic;text-decoration: underline';
    }

    if (isInBound) {
      this.flat_inbound_nodes.push(nq);
    } else {
      this.flat_outbound_nodes.push(nq);
    }

    return nq;
  }

  private getNodeNameByQueue(q: CallQueueEntity, isInbound: boolean): string {
    let name: string;
    if (isInbound) {
      name = q.queue_name;
    } else {
      const campaign = this.getCampaignOfQueue(q);
      name = campaign && campaign.name;
    }

    return name;
  }

  private getCampaignOfQueue(queue: CallQueueEntity): OutcallCampaignEntity {
    return this.em.getRepository<OutcallCampaignRepository>('OutcallCampaignRepository').getObjectByQueue(queue);
  }

  removeSelectedItem(item: TreeNode, isInBound: boolean): void {
    const flexTree: FlexTreeInput = isInBound && this.inboundTree || this.outboundTree;
    const selectedNode = flexTree.tree.treeModel.getNodeById(item.id);
    if (selectedNode) {
      flexTree.check(selectedNode, false);
    }
  }

  private initSelectedNodes(): void {
    this.selected_list.inbound = this.flat_inbound_nodes.filter(n => this.selected_queue_ids.findIndex(id => id === n.id && n.checkbox) > -1) || [];
    this.selected_list.outbound = this.flat_outbound_nodes.filter(n => this.selected_queue_ids.findIndex(id => id === n.id && n.checkbox) > -1) || [];

    const inboundSelectedIds = this.selected_list.inbound.map(i => i.id);
    const outboundSelectedIds = this.selected_list.inbound.map(i => i.id);

    this.inboundControl.setValue(inboundSelectedIds, { emitEvent: false });
    this.outboundControl.setValue(outboundSelectedIds, { emitEvent: false });
  }
}
