<div fxFill fxLayout="column">
  <div class="wrapper">
    <div class="col-left">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of steps | keyvalue; let i=index;">
            <a (click)="mainArea.scrollToElement(step.key)" class="step {{ step.key }}"
              [ngClass]="{'active': mainArea.activeAnchor === step.key}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ step.value | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
        <div class="inner">
          <div id="general" anchorPart>
            <h1 class="font-weight-bold">{{ 'ivr_evaluation.content.general' | translate }}</h1>
            <div>
              <mat-form-field class="full-width">
                <mat-label>{{'ivr_evaluation.content.name'| translate}}</mat-label>
                <input matInput placeholder="{{ 'ivr_evaluation.content.name'|translate }}" formControlName="name">

                <mat-error [flexFormControlError]="form.get('name')"></mat-error>
              </mat-form-field>
            </div>
            <div>
              <h4 class="font-weight-bold">{{ 'ivr_evaluation.content.welcome_message'|translate }}</h4>
              <sound-upload title="{{'ivr_evaluation.content.play_message'|translate}}" data-ci="welcome_msg"
                [fileEntry]="form.get('welcome_msg').value" [hideRemove]="false"
                (onChange)="updateSoundB4WelcomeMsgFile($event)"></sound-upload>
            </div>

            <mat-card class="mat-card-flat shadow-none py-1 px-0">
              <mat-card-content>
                <div class="question-list" formArrayName="questions">
                  <ng-container *ngFor="let questionControl of questionsControl.controls; let i=index">
                    <div class="question-slot" [attr.data-ci]="'question_' + (i + 1)"
                      [ngClass]="{'question-invalid': questionControl.invalid && questionControl.touched}">
                      <div fxLayoutAlign="space-between center">
                        <h4 class="font-weight-bold mt-0 mb-1">{{ 'ivr_evaluation.content.question'|translate: {n: i+1}
                          }}
                        </h4>
                        <button mat-button type="button" (click)="removeQuestion(i)" *ngIf="i > 0">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                      <div class="question-msg">
                        <sound-upload title="{{'ivr_evaluation.content.play_message'|translate}}"
                          [fileEntry]="questionControl.value && questionControl.value.msg? questionControl.value.msg : undefined"
                          [hideRemove]="true" (onChange)="updateSoundB4QuestionMsgFile($event, i)"></sound-upload>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="space-between center" class="question-keys">
                        <div>{{ 'ivr_evaluation.content.accepted_answers'|translate }}</div>
                        <div>
                          <phonepad-picker [multiple]="true"
                            [options]="{key_length: 1, display_grouped_number: true, position: 'right'}"
                            [keys]="questionControl.value && questionControl.value.accepted_answer ? questionControl.value.accepted_answer : undefined"
                            (valueChange)="phonePadPickerChanged($event, i)">
                          </phonepad-picker>
                        </div>
                      </div>

                      <mat-error *ngIf="questionControl.errors && questionControl.errors.duplicated" [flexFormControlError]="questionControl"
                        [customMessages]="{
                          'duplicated': 'ivr_evaluation.validator.duplicated_keys'|translate:{keys:questionControl.errors.duplicated.keys},
                          'accepted_answer_required': 'ivr_evaluation.validator.accepted_answer_required'|translate
                        }"></mat-error>
                    </div>
                  </ng-container>
                </div>
                <div align="start">
                  <button data-ci="add_question" mat-stroked-button class="add mt-2 mb-2" color="green"
                    (click)="addQuestion()">
                    <mat-icon>add</mat-icon>
                    <span class="ml-1">{{ 'ivr_evaluation.content.add_question' | translate }}</span>
                  </button>
                </div>
              </mat-card-content>
            </mat-card>

            <div>
              <h4 class="font-weight-bold">{{ 'ivr_evaluation.content.thankyou_message'|translate }}</h4>
              <sound-upload title="{{'ivr_evaluation.content.play_message'|translate}}" data-ci="thankyou_msg"
                [fileEntry]="form.get('thankyou_msg').value" [hideRemove]="true"
                (onChange)="updateSoundB4ThankyouMsgFile($event)"></sound-upload>

              <mat-error [flexFormControlError]="form.get('thankyou_msg')"></mat-error>
            </div>

          </div>

          <div class="mt-3">
            <mat-divider [inset]="true" class="section-divider"></mat-divider>
          </div>

          <div id="inused" anchorPart>
            <h1 class="font-weight-bold">{{ 'ivr_evaluation.content.using'|translate }}</h1>

            <ng-container *ngIf="!usedQueues.length; else tplUsedQueues">
              <p>{{ 'ivr_evaluation.content.no_data'|translate }}</p>
            </ng-container>

            <ng-template #tplUsedQueues>
              <div fxLayout="row wrap" fxLayoutAlign="start center" class="pb-1 full-width">
                <div class="inused-item" *ngFor="let item of usedQueues" (click)="gotoPageQueue(item)">
                  <app-queue-mini-block [callQueue]="item"></app-queue-mini-block>
                </div>
                <!-- <ng-container *ngFor="let item of usedQueues">
                  <button mat-stroked-button color="accent" (click)="gotoPageQueue(item)">{{ item.queue_name }}</button>
                </ng-container> -->
              </div>
            </ng-template>

          </div>
        </div>
      </form>

    </div>
  </div>
</div>
