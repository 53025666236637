import { Component, OnInit } from '@angular/core';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { LocalManager } from '@wephone-core/service/local_manager';
import { CallingProfileRepository } from '@wephone-core/model/repository/callingprofile';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { _tk, _ti } from '@wephone-translation';
import { EditingComponent, NoWhitespaceValidator, ToastService } from '@wephone-utils';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UrlValidator } from '@wephone-common/form/inputs/url-input/url-validate';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { EnterpriseTelecomParamsEntity } from '@wephone-core/model/entity/enterprise_telecom_params';
import { EnterpriseTelecomParamsRepository } from '@wephone-core/model/repository/enterprise_telecom_params';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import * as _ from 'lodash';
import { CallingProfileEntity } from '@wephone-core/model/entity/callingprofile';

@Component({
  selector: 'page-enterprise-info',
  templateUrl: 'enterprise-info.html',
  styleUrls: ['enterprise-info.scss']
})
export class EnterpriseInfoPage extends EditingComponent implements OnInit {
  private readonly myEnterpriseRepo = EnterpriseRepository.getInstance<EnterpriseRepository>();

  myEnterprise: EnterpriseEntity;
  enterpriseTelecomParams: EnterpriseTelecomParamsEntity;

  steps = [
    { id: 'info', label: 'enterprise_info.title' },
    { id: 'outbound_limit', label: 'enterprise_info.calling_number' },
    { id: 'default_bo_url', label: 'call_queue.content.open_url' },
    { id: 'phone_settings', label: 'enterprise_info.step.phone_settings' },
  ];

  form: FormGroup;
  // authInfo: AuthenInfo;
  isBusinessHotel: boolean;

  constructor(
    public readonly localManager: LocalManager,
    public readonly toast: ToastService,
    private readonly fb: FormBuilder,
    private readonly authService: AuthenticationService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    
    await this.resolveData();
    // this.authInfo = this.authService.authInfo;
    this.isBusinessHotel = this.authService.isBusinessHotel();

    this.form = this.fb.group({
      name: [this.myEnterprise.name, [Validators.required, NoWhitespaceValidator]],
      domain: [{ value: this.myEnterprise.domain, disabled: true }],
      country: [this.localManager.getCountryByCode2(this.myEnterprise.country), [Validators.required]],
      timezone: [this.localManager.getTimezoneByValue(this.myEnterprise.timezone), [Validators.required]],
      address: [this.myEnterprise.address],
      calling_profile_id: [this.getCallingProfile(), [Validators.required]],
      default_bo_url: [this.myEnterprise.default_bo_url, [UrlValidator.isValidUrl]],
      vlan_phone: [this.enterpriseTelecomParams.vlan_phone, [Validators.min(1), Validators.max(4096)]],
      vlan_pc: [this.enterpriseTelecomParams.vlan_pc, [Validators.min(1), Validators.max(4096)]],
      onhold_music_id: [this.enterpriseTelecomParams.onhold_music],
      default_did_id: [this.myEnterprise.default_did],
    });

    if (this.isBusinessHotel) {
      this.form.addControl('room_calling_profile_id', new FormControl(this.getRoomCallingProfile(), [Validators.required]));
    }

    this.addSubscription(
      this.form.valueChanges.subscribe(() => {
        this.onFormValueChange();
      })
    );
  }

  protected async resolveData(): Promise<any> {
    this.setMyEnterprise();
    await this.setEnterpriseTelecomParams();
  }

  private setMyEnterprise(): void {
    this.myEnterprise = this.myEnterpriseRepo.getMyEnterprise();
  }

  private getCallingProfile(): CallingProfileEntity {
    if (this.myEnterprise && this.myEnterprise.calling_profile_id) {
      return CallingProfileRepository.getInstance<CallingProfileRepository>().getObjectById(this.myEnterprise.calling_profile_id);
    }
  }

  private getRoomCallingProfile(): CallingProfileEntity {
    if (this.myEnterprise && this.myEnterprise.room_calling_profile_id) {
      return CallingProfileRepository.getInstance<CallingProfileRepository>().getObjectById(this.myEnterprise.room_calling_profile_id);
    }
  }

  private async setEnterpriseTelecomParams(): Promise<void> {
    const repo: EnterpriseTelecomParamsRepository = EnterpriseTelecomParamsRepository.getInstance<EnterpriseTelecomParamsRepository>();
    // let enterpriseTelecomParams: EnterpriseTelecomParamsEntity = await repo.findByEnterpriseId(this.myEnterprise.id);
    // if (!enterpriseTelecomParams) {
    //   enterpriseTelecomParams = repo.create({ enterprise_id: this.myEnterprise.id }) as EnterpriseTelecomParamsEntity;
    // }
    // this.enterpriseTelecomParams = enterpriseTelecomParams;
    this.enterpriseTelecomParams = await repo.getCurrentEnterprsieTelecomParams();
  }

  hasControlValue(controlName: string): boolean {
    return controlName && this.form.controls[controlName].value;
  }

  // Form actions
  removeControlValue(controlName: string): void {
    this.form.get(controlName).markAsDirty();
    this.form.get(controlName).setValue(null);
  }

  getChangeSet(): { [key: string]: any } {
    const formData: { [key: string]: any } = super.getChangeSet();
    const attrList = Object.keys(formData);
    for (const attr of attrList) {
      switch (attr) {
        case 'name':
          formData[attr] = _.trim(formData[attr]);
          break;
        case 'country':
          formData['country'] = formData[attr] ? formData[attr].code2 : null;
          break;
        case 'timezone':
          formData['timezone'] = formData[attr] ? formData[attr].value : null;
          break;
        case 'calling_profile_id':
        case 'room_calling_profile_id':
        case 'onhold_music_id':
        case 'default_did_id':
          formData[attr] = formData[attr] ? formData[attr].id : null;
          break;
        default:
          break;
      }
    }
    return formData;
  }

  async update(): Promise<void> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.toast.showError(_ti('public.message.data_invalid'));
        return;
      }
      const formDataUpdated = this.getChangeSet();
      const updatedData = await this.myEnterpriseRepo.updateEnterprise(formDataUpdated);
      delete updatedData.id;
      this.myEnterprise.setObjectData(updatedData, true);
      this.setMyEnterprise();
      await this.setEnterpriseTelecomParams();
      this.toast.showSuccess(_ti('public.message.update_success'));
      this.resetForm();
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.update_failure'));
    }
  }

  resetForm(): void {
    this.form.reset({
      name: this.myEnterprise.name,
      country: this.localManager.getCountryByCode2(this.myEnterprise.country),
      timezone: this.localManager.getTimezoneByValue(this.myEnterprise.timezone),
      address: this.myEnterprise.address,
      domain: this.myEnterprise.domain,
      calling_profile_id: this.getCallingProfile(),
      room_calling_profile_id: this.getRoomCallingProfile(),
      default_bo_url: this.myEnterprise.default_bo_url,
      vlan_phone: this.enterpriseTelecomParams.vlan_phone,
      vlan_pc: this.enterpriseTelecomParams.vlan_pc,
      onhold_music_id: this.enterpriseTelecomParams.onhold_music,
      default_did_id: this.myEnterprise.default_did,
    });

    this.form.markAsPristine();
  }

  updateOnholdMusic(uploadResult: { new_file_entry: FileEntryEntity; remove?: boolean }): void {
    // Empty new_file_entry and remove is true mean remove sound
    const fileEntry: FileEntryEntity = uploadResult.new_file_entry;

    if (!uploadResult.remove && !fileEntry.is_default && (!fileEntry || fileEntry.id === null || fileEntry.id === undefined)) {
      this.toast.showError('No file entry created/updated.');
      return;
    }

    this.form.get('onhold_music_id').markAsDirty();
    this.form.get('onhold_music_id').setValue(fileEntry);
  }

  // isBusinessHotel(): boolean {
  //   return this.authInfo.business_type === BusinessType.HOTEL;
  // }

  clearValue(event: MouseEvent, controlName: string): void {
    event.stopPropagation();
    this.form.get(controlName).setValue(null);
    this.form.get(controlName).markAsDirty();
  }
}
