import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'status-renderer',
  template: `<span>{{ getItemStatus(params) }}</span>`
})
export class StatusRenderer implements ICellRendererAngularComp {
  params: any;

  constructor(
    private translate: TranslateService
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  getItemStatus = (params) => {
    if (params.value) {
      return ['to_try', 'agent_recall', 'client_recall'].indexOf(params.value) > -1 ?this.translate.instant('stats.missed_call.status.' + params.value) : params.value
    }

    return null;
  }

  refresh(): boolean {
    return false;
  }
}
