<div fxFill fxLayout="column" *ngIf="user">
  <div class="wrapper">
    <div class="col-left">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of stepKeys; let i=index;">
            <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
              [ngClass]="{'active': mainArea.activeAnchor === step}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ stepLabel[step] | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div id="_1general" anchorPart>
        <app-user-general #generalUserEditor [hidden]="!user" [user]="user" (formValueChanges)="onFormValueChange()">
        </app-user-general>
      </div>

      <ng-container *ngIf="!user.isWatcher()">

        <mat-divider [inset]="true" class="section-divider"></mat-divider>
        <mat-card class="shadow-none header-text-no-margin" id="_2call_transfer" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon color="primary" class="title-icon">phone_forwarded</mat-icon>
            </div>
            <mat-card-title>
              <h2>{{ 'change_agent_number.title'|translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <div>
              <user-call-reception #userCallReception [agent]="user.agent" (formValueChanges)="onFormValueChange()"
                showAutoPickup="true">
              </user-call-reception>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>
        <mat-card class="shadow-none header-text-no-margin" id="_3work_calendar" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon color="primary" class="title-icon">group</mat-icon>
            </div>
            <mat-card-title>
              <h2>{{ 'opening_hour_calendar.menu.title'|translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <div class="queue-calendar">
              <app-opening-hour-calendar-select #openingHourCalendarSelect [forUser]="true" [agent]="agent"
                (valueChange)="onChangeOpeningCalendar($event)">
              </app-opening-hour-calendar-select>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>
        <mat-card class="shadow-none header-text-no-margin" id="_4agent_numbers" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon color="primary" class="title-icon">format_list_bulleted</mat-icon>
            </div>
            <mat-card-title>
              <h2>{{ 'user.heading.numbers'|translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <div class="mb-1" fxLayout="row" fxLayoutAlign="start center">
              <span class="font-weight-bold mr-2">{{ 'user.content.my_dedicated_number'|translate }}</span>
              <a class="flex-link" (click)="updateUserDedicatedNumber()" *ngIf="!getUserDidDedicatedNumber()">{{
                'user.content.define_dedicated_number'|translate }}</a>
              <a class="flex-link mr-2" (click)="gotoEditNumberPage()" *ngIf="getUserDidDedicatedNumber()">{{
                getUserDidDedicatedNumber().display_number }}</a>
              <a class="flex-link mr-2" (click)="updateUserDedicatedNumber()" *ngIf="getUserDidDedicatedNumber()">{{
                'public.change'|translate }}</a>
              <button mat-icon-button type="button" color="warn"
                *ngIf="hasRemoveDedicatedNumberPermission && getUserDidDedicatedNumber()"
                (click)="removeDedicatedNumber()" matTooltip="{{ 'public.remove'|translate }}">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <ng-container *ngIf="user.can_use_callcenter">
              <div class="font-weight-bold mt-1">{{ 'user.content.other_numbers'|translate }}</div>
              <agent-queues #agentQueues [agent]="user.agent" [add_queue]="true"
                (on_change)="onChangeCallQueues($event)"></agent-queues>
            </ng-container>
          </mat-card-content>
        </mat-card>

        <ng-container *ngIf="isAuthorAdmin && !user.isWatcher()">
          <mat-divider [inset]="true" class="section-divider"></mat-divider>
          <mat-card class="shadow-none header-text-no-margin" id="_5telephone" anchorPart>
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon color="primary" class="title-icon">dialer_sip</mat-icon>
              </div>
              <mat-card-title>
                <h2>{{ 'user.heading.telephone'|translate }}</h2>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="form-group">
              <app-user-sip #sipUserEditor [user]="user" (formValueChanges)="onFormValueChange()">
              </app-user-sip>
            </mat-card-content>
          </mat-card>

          <mat-divider [inset]="true" class="section-divider"></mat-divider>
          <mat-card class="shadow-none header-text-no-margin" id="_6api" anchorPart>
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon color="primary" class="title-icon">more_horiz</mat-icon>
              </div>
              <mat-card-title>
                <h2>{{ 'manage_integration.api'|translate }}</h2>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="form-group">
              <app-user-api #apiUserEditor [hidden]="!user" [user]="user" (formValueChanges)="onFormValueChange()">
              </app-user-api>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isAuthorAdmin && (user.isWatcher() || user.isUser() || user.isAgent())">
        <mat-divider [inset]="true" class="section-divider"></mat-divider>
        <div id="_7access_rights" anchorPart>
          <app-user-access-right #accessRightUserEditor [hidden]="!user" [user]="user"
            (formValueChanges)="onFormValueChange()"></app-user-access-right>
        </div>
      </ng-container>
    </div>
  </div>
</div>
