<ng-container *ngIf="is_ready">
  <ng-template #listFilterTpl>
    <div class="filter-container">
      <!--
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start left" fxLayoutAlign.xs="start" fxLayoutGap="10px"
        fxLayoutGap.xs="0">
        <div fxFlex="100">
          {{ 'did.title.calling_number_hint' | translate }}
          <mat-form-field class="default-did">
            <did-select-form-input allowAnonymous="true" [formControl]="defaultDid"></did-select-form-input>
          </mat-form-field>
        </div>
      </div>
  -->

      <form [formGroup]="filterForm">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutAlign.xs="start center"
          fxLayoutAlign.xs="start" fxLayoutGap="10px" fxLayoutGap.xs="0">
          <!--
          <div>
            <button *ngIf="!didOrder?.is_expired && didOrder?.dids_ordered?.length > 0" mat-icon-button
              matBadge="{{ didOrder?.dids_ordered?.length }}" matBadgePosition="after" matBadgeColor="warn"
              (click)="openDidOrder()">
              <mat-icon>shopping_cart</mat-icon>
            </button>
          </div>
  -->
          <div fxFlex.xs="none">
            <mat-form-field class="full-width">
              <mat-label>{{ 'did.content.filter_object_type' | translate }}</mat-label>
              <mat-select placeholder="{{ 'did.content.filter_object_type' | translate }}" formControlName="objectType">
                <mat-select-trigger *ngIf="filterForm.get('objectType').value">
                  <span fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon *ngIf="filterForm.get('objectType').value.icon" style="margin-right: 10px;">
                      {{ filterForm.get('objectType').value.icon }}</mat-icon>
                    <span>{{ filterForm.get('objectType').value.label | translate }}</span>
                  </span>
                </mat-select-trigger>
                <mat-option>{{ 'public.all' | translate }}</mat-option>
                <mat-option [value]="optionObjectType" *ngFor="let optionObjectType of objectTypeList">
                  <mat-icon *ngIf="optionObjectType.icon">{{ optionObjectType.icon }}</mat-icon>
                  {{ optionObjectType.label | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex.xs="none">
            <mat-form-field class="full-width">
              <mat-label>{{ 'did.content.country' | translate }}</mat-label>
              <mat-select placeholder="{{ 'did.content.filter_country' | translate }}" formControlName="countryCode">
                <mat-select-trigger>
                  <span country-flag [countryCode2]="filterForm.get('countryCode').value"></span>
                  {{ ('country_codes.' + filterForm.get('countryCode').value) | translate }}
                </mat-select-trigger>
                <mat-option>{{ 'public.all' | translate }}</mat-option>
                <mat-option *ngFor="let countryCode of countryCodes" [value]="countryCode">
                  <span country-flag [countryCode2]="countryCode"></span>
                  {{ ('country_codes.' + countryCode) | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex.xs="none">
            <flex-search-input [attr.data-ci]="'filter-number'" placeholder="{{ 'public.search' | translate }}"
              [hintText]="'number_routing.search.hint'|translate" formControlName="filterValue"
              ngClass.xs="search-full-width"></flex-search-input>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <flex-crud-page #flexCrud [pageTitle]="'menu.manage_routing'|translate" [dataSource]="dataSource"
    [tableConfig]="tableConfig" [exportable]="true" exportFileName="exported_numbers"
    [columnTemplates]="{'linked_object_type': tplCallDestination, 'display_number': tplDisplayNumber}"
    [filterTemplate]="listFilterTpl" [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">

    <ng-template #tplCallDestination let-did="row">
      <div fxLayout="row wrap">
        <number-routing-dest [did]="did" [updateLayout]="isUpdatedLayout" [enableTooltip]="true"></number-routing-dest>
        <mat-icon *ngIf="did.routing_error" class="mat-text-warn ml-1" [matTooltip]="did.routing_error|translate">warning</mat-icon>
      </div>
    </ng-template>

    <ng-template #tplDisplayNumber let-did="row">
      <div class="didnumber">
        <div class="number-name" *ngIf="did.name" [matTooltip]="did.name">{{did.name}}</div>
        <div class="relative">
          <span *ngIf="did.country_code" class="country-column" country-flag [countryCode2]="did.country_code">
          </span>
          <span class="number-normalize">{{ did.display_number }}</span>
          <span *ngIf="did.is_new_assignment" class="notification-label">New</span>
        </div>
      </div>
    </ng-template>

  </flex-crud-page>
</ng-container>
