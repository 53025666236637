import { datafield, Entity } from '@wephone-core/model/model';
import { InvoiceEntity } from '@wephone-core/model/entity/invoice';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { InvoiceRepository } from '../repository/invoice';

export class InvoiceItemEntity extends Entity {
  public static object_type_id = 'invoice_item';

  public id: number;
  @datafield
  public invoice_id: number;
  @datafield
  public item_name: string;
  @datafield
  public item_description: string;
  @datafield
  public item_count: number;
  @datafield
  public order: number;
  @datafield
  public price: number;
  @datafield
  public sub_total: number; // = item_count x price but stored in db, not in used

  invoice: InvoiceEntity;

  get currency(): string {
    return this.invoice ? this.invoice.currency : '';
  }

  // Is sub_total but calculated in local
  get item_total(): number {
    const sub_total = this.item_count * this.price;
    return Number(sub_total.toFixed(3));
  }

  dumpObjectData(field_list: string[] = null, use_mapped_name: boolean = false): any {
    const data = super.dumpObjectData(field_list, use_mapped_name);
    data['sub_total'] = undefined;
    return data;
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    if (object_data.invoice_id) {
      this.invoice = EntityManager.getRepository<InvoiceRepository>('InvoiceRepository').getObjectById(object_data.invoice_id);
    }
  }
}
