import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IChangeAwareComponent, EditingComponent, FormService } from '@wephone-utils';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { TranslateService } from '@ngx-translate/core';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'queue-edit-advance',
  templateUrl: './queue-edit-advance.component.html',
  styleUrls: [ './queue-edit-advance.component.scss' ]
})
export class QueueEditAdvanceComponent extends EditingComponent implements OnInit, IChangeAwareComponent {
  @Input() queue: CallQueueEntity;
  @Input() multipleEdit?: boolean;
  @Output() readonly formValueChanges: EventEmitter<boolean>;

  readonly agentDisplayNumberDidNumber: number;
  agentDisplayNumberTypes: {
    value: number;
    label: string;
  }[];

  constructor(public formService: FormService, protected fb: FormBuilder, transService: TranslateService) {
    super();
    this.formValueChanges = new EventEmitter<boolean>();

    this.agentDisplayNumberDidNumber = CallQueueEntity.AGENT_DISPLAY_NUM_DID_NUMBER;
    this.agentDisplayNumberTypes = [
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_CUSTOMER_PHONE,
        label: _ti('call_queue.content.display_number.agent_display.client_phone')
      },
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_CALLER_NUMBER,
        label: _ti('call_queue.content.display_number.agent_display.caller_number')
      },
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_DID_NUMBER,
        label: _ti('call_queue.content.display_number.agent_display.did_number')
      }
    ];
  }

  get aliasControl(): FormControl {
    return this.form.get('alias') as FormControl;
  }

  get xapiAgentSearchControl(): FormControl {
    return this.form.get('xapi_agent_search') as FormControl;
  }

  get agentDisplayNumberTypeControl(): FormControl {
    return this.form.get('agent_display_number_type') as FormControl;
  }

  get agentDisplayNumberControl(): FormControl {
    return this.form.get('agent_display_number') as FormControl;
  }

  get isExclusiveControl(): FormControl {
    return this.form.get('is_exclusive') as FormControl;
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.initFormGroup();
  }

  async submitForm(): Promise<any> {}

  resetForm(): void {
    if (!this.form) {
      return;
    }
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  private getFormResetData(): object {
    return {
      alias: this.queue.alias,
      xapi_agent_search: this.queue.xapi_agent_search,
      agent_display_number_type: this.queue.agent_display_number_type,
      agent_display_number: this.queue.agent_display_number,
      is_exclusive: !!this.queue.is_exclusive,
      analysis_enabled: !!this.queue.analysis_enabled,
      analysis_start_dt: this.queue.analysis_start_dt && this.queue.analysis_start_dt.toJSDate() || null,
    };
  }

  getChangeSet(): object {
    const formData = this.formService.getChangeSet(this.form) || {};
    if (formData) {
      formData.is_exclusive = formData.is_exclusive ? 1 : 0;
    }

    return _.cloneDeep(formData);
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      alias: [ this.queue.alias, [Validators.maxLength(256)] ],
      xapi_agent_search: [ this.queue.xapi_agent_search, [Validators.maxLength(256)] ],
      agent_display_number_type: [ this.queue.agent_display_number_type ],
      agent_display_number: [ this.queue.agent_display_number ],
      is_exclusive: [ !!this.queue.is_exclusive ],
      analysis_enabled: [ !!this.queue.analysis_enabled ],
      analysis_start_dt: [ this.queue.analysis_start_dt ? this.queue.analysis_start_dt.toJSDate() : null ],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe((changes) => {
        this.onFormValueChange();
      })
    );
  }

  isValid(field_list: Array<string> = []): boolean {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return false;
    }

    return true;
  }
}
