import * as _ from 'lodash';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, IFlexDialogConfig, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';

@Component({
  selector: 'app-sipphone-config-select',
  templateUrl: './sipphone-config-select.component.html',
  styleUrls: ['./sipphone-config-select.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SipphoneConfigSelectComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('dialogs.did_setup_wizard.step3.selected_sipphone');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.ok'),
      action: () => {
        return this.submit();
      },
      visible: () => {
        return !!this.sipphone;
      },
      color: Colors.PRIMARY
    }
  ];
  sipphone: SipPhoneEntity;
  error: string;

  constructor(
    private dialogRef: MatDialogRef<SipphoneConfigSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      prompt?: string;
      dialogTitle?: string;
      sipphone?: SipPhoneEntity;
    },
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);

    if (data) {
      if (data.dialogTitle) {
        this.dialogTitle = data.dialogTitle;
      }

      if (data.sipphone) {
        this.sipphone = data.sipphone;
      }
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  submit(): void {
    if (!this.sipphone) {
      this.error = _ti('public.message.data_invalid');
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    this.dialogRef.close(this.sipphone);
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
