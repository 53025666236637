import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FlexTableComponent, IFlexTableConfig, localNow, parseDateTime } from '@wephone-utils';
import * as _ from 'lodash';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { CdrService } from '@wephone-core/service/cdr_service';
import { DateAdapter } from '@angular/material/core';
import { PeriodSelectType } from '@wephone-common/period-select/priod-select-type';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FlexDatepickerMin } from '@wephone/services/form-validator';

@Component({
  selector: 'ivr-menu-statistics',
  templateUrl: './ivr-menu-statistics.component.html',
  styleUrls: ['./ivr-menu-statistics.component.scss']
})
export class IvrMenuStatisticsComponent extends DialogComponentBase {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  dialogTitle = _tk('ivr_menu.content.statistics');

  ivrMenu: IvrCustomMenuEntity;
  dataSource: FlexDataSource;
  tableConfig: IFlexTableConfig;

  totalCalls = 0;
  defaultPeriod = PeriodSelectType.this_week;
  periodList: PeriodSelectType[] = [PeriodSelectType.this_week, PeriodSelectType.last_week, PeriodSelectType.this_month, PeriodSelectType.last_month];
  filterForm: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdrService: CdrService,
    @Inject(MAT_DIALOG_DATA) data,
    datasourceService: DataSourceService,
    dateAdapter: DateAdapter<Date>,
    cdr: ChangeDetectorRef
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;

    this.ivrMenu = data.ivrmenu;
    this.dataSource = datasourceService.createDataSource('ivr_menu_stats');

    this.filterForm = this.fb.group({
      start_dt: [localNow().startOf('weeks').toJSDate(), Validators.required],
      end_dt: [localNow().endOf('weeks').toJSDate(), Validators.required],
    }, { validator: FlexDatepickerMin('end_dt', 'start_dt') });

    this.tableConfig = {
      multiSelect: false,
      enableExportCsv: false,
      columns: [
        { name: 'first_input', label: _tk('ivrmenu.stats.first_input') },
        { name: 'second_input', label: _tk('ivrmenu.stats.second_input') },
        { name: 'total', label: _tk('ivrmenu.stats.total') },
        { name: 'percent', label: _tk('ivrmenu.stats.percent') }
      ]
    };
  }

  async resolveData(): Promise<void> {
    await this.reloadFilter();
  }

  get startDateControl(): FormControl {
    return this.filterForm.get('start_dt') as FormControl;
  }

  get endDateControl(): FormControl {
    return this.filterForm.get('end_dt') as FormControl;
  }

  get groupsControl(): FormControl {
    return this.filterForm.get('groups') as FormControl;
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilter = (d: Date): boolean => {
    const startDate = this.startDateControl.value;
    if (!d || !startDate) {
      return true;
    }
    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  changeFilterDate(periodSelectData: { period?: string, start_dt?: Date, end_dt?: Date }): void {
    if (!periodSelectData) {
      console.error('PeriodSelect data is empty', periodSelectData);
      return;
    }

    if (periodSelectData.start_dt) {
      this.startDateControl.setValue(parseDateTime(periodSelectData.start_dt).toJSDate());
    }

    if (periodSelectData.end_dt) {
      this.endDateControl.setValue(parseDateTime(periodSelectData.end_dt).toJSDate());
    }

    this.reloadFilter();
  }

  async reloadFilter(): Promise<void> {
    if (!this.filterForm.valid) {
      return;
    }

    const filters: any = {
      ivr_id: this.ivrMenu.id,
      start_dt: parseDateTime(this.startDateControl.value).toISODate(),
      end_dt: parseDateTime(this.endDateControl.value).toISODate()
    };

    if (this.flexTable) {
      // this.dataSource.setFilter(filters);
      await this.flexTable.reloadDataSource(filters);
    } else {
      await this.dataSource.load(filters);
    }

    await this.updateTotalIvrCalls();
    this.detectChanges();
  }

  private async updateTotalIvrCalls(): Promise<void> {
    const startDt = parseDateTime(this.startDateControl.value).toJSDate();
    const endDt = parseDateTime(this.endDateControl.value).toJSDate();
    const result = await this.cdrService.getTotalIvrCalls(this.ivrMenu.id, 'ivr', startDt, endDt);
    this.totalCalls = result && result.total || 0;
  }

}
