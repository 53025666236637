import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { PaymentAdyenService } from '@wephone/services/payment-adyen.service';
import { DialogComponentBase } from '@wephone-core-ui';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActionButton, Colors } from '@wephone-utils';
import { StripeService } from 'ngx-stripe';
//, Elements, Element as StripeElement, ElementsOptions, SourceResult
import {
  Source,
  StripeError,
  CreateSourceData,
  StripeElements,
  StripeIbanElement,
  StripeIbanElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { _tk, _ti } from '@wephone-translation';
import { PaymentService } from '@wephone/services/payment.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { SystemParam } from '@wephone-core/system';
import { SubscriptionService } from '@wephone/services/subscription.service';

@Component({
  selector: 'app-add-sepa-debit',
  templateUrl: './add-sepa-debit.component.html',
  styleUrls: ['./add-sepa-debit.component.scss']
})
export class AddSepaDebitComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('add_sepa_debit_modal.title');
  dialogRightActions: Array<DialogActionButton> = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.onSubmit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  elements: StripeElements;

  sourceForm: FormGroup;
  iban: StripeIbanElement;
  error: string;
 
  cardOptions: StripeIbanElementOptions = {
    supportedCountries: ['SEPA'],
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  // optional parameters
  elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  constructor(
    private readonly dialogRef: MatDialogRef<AddSepaDebitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      extend_subscription_pack: string
    },
    private readonly cd: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    public readonly adyenService: PaymentAdyenService,
    private readonly configManager: ConfigManager,
    private readonly stripeService: StripeService,
    private readonly subscriptionService: SubscriptionService,
    private readonly paymentService: PaymentService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    const locale: any = this.paymentService.getLocaleForStripe();
    this.elementsOptions.locale = locale;
    const stripePublishableKey: string = this.configManager.getSystemParam(SystemParam.stripe_publishable_key);
    this.stripeService.setKey(stripePublishableKey);

    this.sourceForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
    });

    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.iban) {
          this.iban = this.elements.create('iban', this.cardOptions);
        }

        setTimeout(() => {
          this.iban.mount('#stripeIban'); // make sure element stripeCard was loaded
        });
      });
  }

  async onSubmit(): Promise<void> {
    try {
      if (!this.sourceForm.valid) {
        for (const name of ['email', 'name']) {
          this.sourceForm.controls[name].markAsTouched();
        }
        console.error('Invalid form');
        throw new Error(_ti('public.message.data_invalid'));
        // return;
      }
  
      const sourceData: CreateSourceData = {
        type: 'sepa_debit',
        currency: 'eur',
        owner: {
          name: this.sourceForm.get('name').value,
          email: this.sourceForm.get('email').value,
        },
        mandate: {
          // Automatically send a mandate notification email to your customer
          // once the source is charged.
          notification_method: 'email',
        }
      };
  
      const result: {
        source?: Source;
        error?: StripeError;
      } = await this.stripeService.createSource(this.iban, sourceData).toPromise();
  
      if (result.error) {
        console.log('Something is wrong:', result.error);
        // this.toastService.showError(result.error.message);
        // return;
        throw new Error(result.error.message);
      }
  
      console.log('Success!', result.source);
      let ret: any;
      if (!this.data || !this.data.extend_subscription_pack) {
        const customer = await this.paymentService.updateSource(result.source.id, 'iban');
        console.log('customer: ', customer);
        ret = customer;
      } else {
        ret = await this.subscriptionService.subscribeSubscriptionPack(this.data.extend_subscription_pack, result.source.id, 'iban');
      }
  
      this.toastService.showInfo(_ti('public.message.update_success'));
      this.dialogRef.close(ret);
    } catch (error) {
      console.error('Error stripe: ', error.message);
      this.error = error.message;
      this.toastService.showError(_ti('public.message.update_failure'));
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
