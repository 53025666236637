import { Component, OnInit, ViewChild } from '@angular/core';
import { FlexBasePage } from '@wephone-core-ui';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { IvrScriptRepository } from '@wephone-core/model/repository/ivrscript';
import { DynamicFilterSource, IFlexTableSidePannelOptions, DialogService, regexSearch } from '@wephone-utils';
import { IvrModuleEditComponent } from '@wephone/components/ivr-module-edit/ivr-module-edit.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IvrModuleCreateModalComponent } from '@wephone/modals/ivr-module/ivr-module-create-modal/ivr-module-create-modal.component';
import { _tk, _ti } from '@wephone-translation';
import { IvrScriptService } from '@wephone/services/ivr-script.service';
import { EntityManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'ivr-module',
  templateUrl: './ivr-module.component.html',
  styleUrls: ['./ivr-module.component.scss']
})
export class IvrModuleComponent extends FlexBasePage implements OnInit {
  filterForm: FormGroup;
  filterValue: string;
  tableConfig: any;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: IvrModuleEditComponent
  };

  private ivrModuleRepo = IvrScriptRepository.getInstance<IvrScriptRepository>();
  @ViewChild('flexCrud', { static: false }) flexCrud;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private ivrScriptService: IvrScriptService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.initDS();
  }

  initDS(): void {
    const ivrModuleSource = this.ivrModuleRepo.dataSource<IvrScriptEntity>();
    this.dataSource = new DynamicFilterSource(ivrModuleSource, this.filterFunc);

    this.tableConfig = {
      enableFilter: false,
      columns: [
        {
          name: 'name',
          label: _tk('ivr_module.content.name'),
          sortable: true,
          sort: 'asc',
          customSortFn: (ivrModule: IvrScriptEntity) => {
            return ivrModule.name.toLowerCase();
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.filterForm = this.fb.group({
      filterValue: undefined
    });

    this.addSubscription(
      this.filterForm.valueChanges.subscribe(this.onFormValueChange)
    );
  }

  filterFunc = (item: IvrScriptEntity): boolean => {
    if (!this.filterValue) {
      return true;
    }

    return regexSearch(item.name, this.filterValue) || regexSearch(item.name, this.filterValue);
  }

  private onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  protected async resolveData(): Promise<any> {
    await this.ivrModuleRepo.findAll();
    await EntityManager.getRepository('CrmRoutingRuleRepository').findAll();
  }

  createNew(): Promise<any> {
    const dialogRef = this.dialogService.openDialog2(IvrModuleCreateModalComponent, {}, (ret: any) => { });
    return dialogRef.afterClosed().toPromise();
  }

  async bulkDelete(items: IvrScriptEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    const confirmed: boolean = await this.ivrScriptService.getConfirmDeleteIvrScripts(items);
    if (confirmed) {
      try {
        await this.ivrModuleRepo.bulkDelete(items);
        this.flexCrud.closeSidePanel();
        this.successToast(_ti('public.message.delete_success'));
      } catch (error) {
        this.showError(_ti('public.message.delete_failure'));
      }
    }
  }
}
