import { isDevMode, Component, OnInit } from '@angular/core';
import { MenuService, MainMenuItem } from '../menu.service';
import { _tk, _ti } from '@wephone-translation';
import { DialogService } from '@wephone-utils';
import { ChatbotDialog } from '@wephone-common/modals';
import { FlexIvrSettings, EntityManager } from '@wephone-core/wephone-core.module';
import { BusinessType } from '@wephone-core/system';

const ADMIN_MENU_ITEMS: MainMenuItem[] = [
  {
    state: 'dashboard',
    name: _tk('site.dashboard'),
    type: 'link',
    icon: 'explore'
  },
  {
    state: 'number-routing',
    name: _tk('menu.manage_routing'),
    type: 'link',
    icon: 'format_list_bulleted'
  },
  {
    state: 'manage-users/users',
    name: _tk('menu.manage_user'),
    type: 'link',
    icon: 'people',
    businessTypes: [BusinessType.HOTEL],
  },
  {
    state: 'manage-users',
    name: _tk('menu.manage_user'),
    type: 'sub',
    icon: 'people',
    businessTypes: [BusinessType.CALLCENTER, BusinessType.ENTERPRISE, BusinessType.RESELLER],
    children: [
      { state: 'users', name: _tk('menu.manage_user') },
      { state: 'teams', name: _tk('menu.manage_team') },
    ]
  },
  {
    state: 'hotelroom',
    name: _tk('menu.hotelroom'),
    type: 'link',
    icon: 'hotel_class',
    businessTypes: [BusinessType.HOTEL],
  },
  {
    state: 'enterprise-pms',
    name: _tk('menu.manage_enterprise_pms'),
    type: 'link',
    icon: 'camera',
    businessTypes: [BusinessType.HOTEL],
  },
  {
    state: 'groups',
    name: _tk('menu.manage_group'),
    type: 'link',
    icon: 'group',
    features: ['SERVICE_GROUP'],
    businessTypes: [BusinessType.CALLCENTER, BusinessType.ENTERPRISE, BusinessType.RESELLER],
  },
  {
    state: 'media',
    name: _tk('menu.manage_media'),
    type: 'sub',
    icon: 'movie',
    businessTypes: [BusinessType.CALLCENTER, BusinessType.ENTERPRISE, BusinessType.RESELLER],
    children: [
      { state: 'reusable-sound', name: _tk('menu.manage_reusablesound') }
//      { state: 'image', name: _tk('menu.manage_image') },
    ]
  },
  {
    state: 'ivr/ivr-menus',
    name: _tk('menu.manage_ivrmenu'),
    type: 'link',
    icon: 'swap_horiz',
    businessTypes: [BusinessType.HOTEL],
  },
  {
    state: 'ivr',
    name: _tk('menu.manage_ivr'),
    type: 'sub',
    icon: 'swap_horiz',
    businessTypes: [BusinessType.CALLCENTER, BusinessType.ENTERPRISE, BusinessType.RESELLER],
    children: [
      { state: 'ivr-menus', name: _tk('menu.manage_ivrmenu') },
      { state: 'ivr-evaluation', name: _tk('menu.manage_ivrevaluation') },
    ]
  },
  {
    state: 'queues',
    name: _tk('menu.manage_queue'),
    type: 'link',
    icon: 'notes'
  },
  {
    state: 'out-call-campaigns',
    name: _tk('menu.manage_outcall_campaign'),
    type: 'link',
    icon: 'calendar_today',
    businessTypes: [BusinessType.CALLCENTER]
  },
  {
    state: 'vocal_messages',
    name: _tk('menu.vocal_messages'),
    type: 'sub',
    icon: 'voicemail',
    children: [
      { state: 'voicemail-queue', name: _tk('menu.voicemail') },
      { state: 'recorded-call', name: _tk('menu.recorded_call') }
    ]
  },
  {
    state: 'statistics',
    name: _tk('menu.statistics'),
    type: 'sub',
    icon: 'equalizer',
    businessTypes: [BusinessType.CALLCENTER, BusinessType.ENTERPRISE, BusinessType.RESELLER],
    children: [
      { state: 'incoming', name: _tk('stats.callcenter.incoming') },
      { state: 'outgoing', name: _tk('stats.callcenter.outgoing') },
      { state: 'missedcall', name: _tk('stats.callcenter.missedcall') },
      { state: 'agent-performance', name: _tk('agent_performance.heading'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'agent-status', name: _tk('stats.agent_status.title'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'user-stats', name: _tk('stats.user_stats.title'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'connection-log', name: _tk('stats.agent_connection_log.title'), businessTypes: [BusinessType.CALLCENTER] },
      // Temporarily hide this menu-item, waiting for query builder's spec
      // { state: 'custom-stats-filter', name: _tk('menu.manage_custom_stats_filter'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'custom-report-config', name: _tk('menu.manage_custom_report_config'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'callcenter-export-data', name: _tk('menu.callcenter_export_data'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'list_debug_message_call', name: _tk('menu.list_debug_message_call'), businessTypes: [BusinessType.CALLCENTER] }
    ]
  },
  {
    state: 'callcenter-params',
    name: _tk('menu.callcenter_params'),
    type: 'sub',
    icon: 'settings_phone',
    children: [
      { state: 'opening-hour', name: _tk('opening_hour_calendar.menu.title') },
      { state: 'pause-reasons', name: _tk('menu.manage_pause_reason'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'qualification', name: _tk('menu.manage_call_qualification'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'shortcut', name: _tk('menu.manage_shortcuts'), businessTypes: [BusinessType.CALLCENTER] },
      { state: 'black-list', name: _tk('menu.manage_blacklist') }
    ]
  },
  {
    state: 'telephony',
    name: _tk('menu.telephony'),
    type: 'sub',
    icon: 'call',
    businessTypes: [BusinessType.CALLCENTER, BusinessType.HOTEL, BusinessType.ENTERPRISE],
    children: [
      { state: 'sip-phones', name: _tk('menu.manage_sipphone') },
      { state: 'enterprise-gateway', name: _tk('menu.manage_sip_gateway') },
      { state: 'sip-trunk', name: _tk('menu.manage_siptrunk'), businessTypes: [BusinessType.CALLCENTER], },
      { state: 'conferences', name: _tk('menu.conference') },
      { state: 'short-dial-code', name: _tk('menu.manage_shortdialcode') },
      { state: 'cdr', name: _tk('menu.manage_cdr') },
    ]
  },
  {
    state: 'crm',
    name: _tk('menu.crm'),
    type: 'sub',
    icon: 'camera',
    businessTypes: [BusinessType.CALLCENTER],
    children: [
      { state: 'crm-routing', name: _tk('crm.title') },
      { state: 'enterprise-crm', name: _tk('menu.manage_enterprise_crm') },
      { state: 'slack-channel', name: _tk('menu.manage_slack_channel') },
      { state: 'tag', name: _tk('menu.manage_tags') }
    ]
  },
  {
    state: 'enterprise',
    name: _tk('menu.enterprise'),
    type: 'sub',
    icon: 'settings_input_component',
    children: [
      { state: 'enterprise-info', name: _tk('enterprise_params.information') },
      { state: 'enterprise-invoices-payment', name: _tk('enterprise_params.invoices_and_payment') },
      { state: 'enterprise-oauth-settings', name: _tk('enterprise_params.oauth_settings') },
      { state: 'enterprise-parameters', name: _tk('menu.manage_enterprise_parameters') },
      { state: 'phone-book', name: _tk('menu.manage_phone_book') },
      { state: 'invoice', name: _tk('menu.manage_invoice') }
    ]
  },
  {
    state: 'sms/sent',
    name: _tk('sms.sms_sent'),
    type: 'link',
    icon: 'email',
    features: ['SMS'],
    businessTypes: [BusinessType.CALLCENTER, BusinessType.HOTEL, BusinessType.ENTERPRISE]
  },
  {
    state: 'sms-template',
    name: _tk('menu.sms_template'),
    type: 'link',
    icon: 'sms',
    features: ['SMS'],
    businessTypes: [BusinessType.CALLCENTER, BusinessType.HOTEL, BusinessType.ENTERPRISE]
  },
  {
    state: 'developpers',
    name: _tk('menu.developers'),
    type: 'sub',
    icon: 'settings',
    businessTypes: [BusinessType.CALLCENTER],
    children: [
      { state: 'manage-api', name: _tk('enterprise_info.tabs.api_integration') },
      { state: 'ivr-application', name: _tk('menu.manage_ivrmodule') },
      { state: 'ivr-remote-apps', name: _tk('menu.manage_ivrremoteapp') },
      { state: 'routing-service', name: _tk('menu.manage_routing_service') },
    ]
  },
  // {
  //   state: 'sip-phone-provisioning-token',
  //   name: _tk('sip_token.manage'),
  //   type: 'link',
  //   icon: 'token',
  // },
];

@Component({
  selector: 'admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
  providers: [MenuService]
})
export class AdminMenuComponent implements OnInit {
  devMode = false;
  adminMenus: MainMenuItem[];

  constructor(
    public menuService: MenuService,
    private readonly dialogService: DialogService,
    private readonly em: EntityManager,
    private readonly settings: FlexIvrSettings,
  ) {
    this.devMode = isDevMode();
    this.adminMenus = ADMIN_MENU_ITEMS;
  }

  ngOnInit(): void {
  }

  // get adminMenus(): Array<MainMenuItem> {
  //   return ADMIN_MENU_ITEMS;
  // }

  openChatbotDialog(): void {
    this.dialogService.openModal(ChatbotDialog, { size: 's', height: '580px' });
  }

  btEnterprise(): boolean {
    return this.settings.btEnterprise();
  }

  btCallCenter(): boolean {
    return this.settings.btCallCenter();
  }
}
