import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import {
  DialogService,
  ToastService,
  IFlexTableSidePannelOptions,
  DynamicFilterSource,
  IFlexTableConfig,
  FlexCRUDPageComponent
} from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FlexBasePage } from '@wephone-core-ui';
import { SlackChannelEntity } from '@wephone-core/model/entity/slack_channel';
import { SlackChannelRepository } from '@wephone-core/model/repository/slack_channel';
import { SlackChannelEditComponent } from '../slack-channel-edit/slack-channel-edit';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'slack-channel-list',
  templateUrl: './slack-channel-list.html',
  styleUrls: ['./slack-channel-list.scss']
})
export class SlackChannelListPage extends FlexBasePage implements OnInit {
  // Constants

  // Private member var with default value
  private entityManager = EntityManager.getInstance() as EntityManager;
  private slackChannelRepo = SlackChannelRepository.getInstance() as SlackChannelRepository;

  // Public member var
  public filterForm: FormGroup;
  filterValue: string;

  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: SlackChannelEditComponent,
  };

  // Public member var with default value
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  // Contructor
  constructor(
    private translate: TranslateService,
    public dialogService: DialogService,
    private fb: FormBuilder,
    public toast: ToastService
  ) {
    super();

    const slackChannelSource = this.slackChannelRepo.dataSource<SlackChannelEntity>();
    this.dataSource = new DynamicFilterSource(slackChannelSource, undefined);

    this.tableConfig = {
      columns: [
        {
          name: 'public_id',
          label: _tk('slack_channel.content.public_id'),
          sortable: true
        },
        {
          name: 'team_name',
          label: _tk('slack_channel.content.team_name'),
          sortable: true
        },
        {
          name: 'incoming_webhook_channel',
          label: _tk('slack_channel.content.channel'),
          sortable: true
        },
        {
          name: 'is_active',
          label: _tk('slack_channel.content.is_active')
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.create();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.entityManager.getRepository('SlackChannelRepository').loadObjectList();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.filterForm = this.fb.group({
      filterValue: ['']
    });

    this.filterForm.valueChanges.subscribe(this.onFormValueChange);
  }

  private onFormValueChange = (formValues: any) => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;

    this.dataSource.onFilterChange();
  }

  async bulkDelete(slackChannels: Array<SlackChannelEntity>): Promise<any> {
    return this.dialogService.confirmDialog(
      this.translate.instant('dialogs.confirmation'),
      this.translate.instant('user.title.delete'),
      async () => {
        if (!slackChannels) {
          return;
        }

        try {
          await this.slackChannelRepo.bulkDelete(slackChannels);
          this.flexCrud.closeSidePanel();
          this.toast.show(_ti('public.message.delete_success'));
          this.slackChannelRepo.reload();
        } catch (e) {
          this.toast.showError(`${this.translate.instant('public.message.delete_failure')} : ${e.error.message}`);
        }
      }
    );
  }

  async create(): Promise<any> {
    try {
      const newSlackChannel = await this.slackChannelRepo.createAndSave();
      this.toast.showSuccess(_ti('public.message.create_success'));
    } catch (e) {
      this.toast.showError(e);
    }
  }
}
