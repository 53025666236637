<div fxFill>
  <form [formGroup]="form" *ngIf="form">
    <mat-radio-group formControlName="phoneMode">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap.xs="5px" class="pb-1">
        <div fxFlex="50">
          <mat-radio-button [value]="0" [disabled]="!isAvailableSipphoneExtension()" data-ci="destination-sipphone">
            {{ 'change_agent_number.destinations.sipphone' | translate}}
          </mat-radio-button>
        </div>
        <div fxFlex="calc(50%-20px)" align="end">
          <span [ngClass]="{'text-yellow': sipphone && !sipphone.is_online}" *ngIf="isAvailableSipphoneExtension()">
            {{ sipphone.extension }}
            <span *ngIf="sipphone && !sipphone.is_online"> {{ 'agent_panel.sipphone_not_connected' | translate }}</span>
          </span>
        </div>
      </div>
      <div [fxLayout]="numberEditable ? 'column' : 'row'" fxLayoutAlign="start" class="pb-1">
        <div fxFlex>
          <mat-radio-button [value]="1" [disabled]="!numberEditable && !form.get('backupPhone').value">
            {{'change_agent_number.destinations.backup_phone' | translate}}
          </mat-radio-button>
        </div>
        <div fxFlex>
          <mat-form-field class="full-width" *ngIf="numberEditable;else DisplayPhoneNumber">
            <mat-label>{{ 'input.phone.label' | translate }}</mat-label>
            <input matInput class="text-right" formControlName="backupPhone">
            
            <mat-error [flexFormControlError]="form.get('backupPhone')"
              [customMessages]="{
                'isForbidden': 'user.message.phone_number_forbidden'|translate
              }"></mat-error>
          </mat-form-field>
          <ng-template #DisplayPhoneNumber>
            <strong class="pull-right">{{ agent?.user?.phone || '' }}</strong>
          </ng-template>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayoutGap.xs="0">
        <div fxFlex="shrink">
          <mat-radio-button [value]="2" [disabled]="!numberEditable && !form.get('backupPhone').value">
            {{'change_agent_number.destinations.both' | translate}}
          </mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
    <section class="pt-1"
      *ngIf="showAutoPickup && (form.get('phoneMode').value == 2 || form.get('phoneMode').value == 0)">
      <mat-slide-toggle formControlName="auto_pickup">
        {{'change_agent_number.auto_pickup' | translate}}
      </mat-slide-toggle>
    </section>
  </form>
</div>
