import { Component, OnInit, ViewChild } from '@angular/core';
import { FlexBasePage } from '@wephone-core-ui';
import { DynamicFilterSource, IFlexTableConfig, DialogService, ToastService, IFlexTableSidePannelOptions, regexSearch } from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EntityManager, ConfigManager } from '@wephone-core/wephone-core.module';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { CreateCrmRoutingModalComponent } from '@wephone/modals/create-crm-routing-modal/create-crm-routing-modal.component';
import { EditCrmRoutingComponent } from '@wephone/components/edit-crm-routing/edit-crm-routing.component';
import { _tk, _ti } from '@wephone-translation';
import { CrmRoutingRuleService } from '@wephone/services/crmroutingrule.service';
import { MatDialogRef } from '@angular/material/dialog';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { DidEntity } from '@wephone-core/model/entity/did';

@Component({
  selector: 'app-crm-routing',
  templateUrl: './crm-routing.component.html',
  styleUrls: ['./crm-routing.component.scss']
})
export class CrmRoutingComponent extends FlexBasePage implements OnInit {
  @ViewChild('flexCrud') flexCrud;

  filterValue: string;
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditCrmRoutingComponent
  };
  crmRoutingForm: FormGroup;
  crms: { [key: number]: EnterpriseCrmEntity; } = {};
  isUpdatedLayout = false;
  dids: { [key: number]: DidEntity; } = {};

  constructor(
    private fb: FormBuilder,
    public dialogService: DialogService,
    public crmRoutingRuleService: CrmRoutingRuleService,
    public toast: ToastService,
    public configManager: ConfigManager
  ) {
    super();
    EntityManager.getRepository('CrmRoutingRuleRepository').findAll();
    const crmRoutingSource = EntityManager.getRepository('CrmRoutingRuleRepository').dataSource<CrmRoutingRuleEntity>();
    this.dataSource = new DynamicFilterSource(crmRoutingSource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('crm_routing.content.name'),
          searchable: true,
          sortable: true,
          sort: 'asc',
          customSortFn: (crmRouting: CrmRoutingRuleEntity) => {
            return crmRouting.name.toLowerCase();
          }
        },
        {
          name: 'crm_id',
          label: _tk('crm_routing.content.crm'),
          searchable: true,
          sortable: true,
          customSortFn: (crmRouting: CrmRoutingRuleEntity) => {
            return this.crms[crmRouting.crm_id].name.toLowerCase();
          },
          // renderCell: (item: any) => {
          //   return this.crms[item.crm_id] ? this.crms[item.crm_id].name : '';
          // }
        },
        {
          name: 'dest_default',
          label: _tk('crm_routing.content.dest_default'),
          searchable: false
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.crmRoutingForm = this.fb.group({
      filterValue: ['']
    });

    this.crmRoutingForm.valueChanges.subscribe(this.onFormValueChange);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected async resolveData(): Promise<any> {
    await EntityManager.getRepository('EnterpriseCrmRepository').findAll();
    await EntityManager.getRepository('IvrRemoteAppRepository').findAll();
    for (const crm of EntityManager.getRepository('EnterpriseCrmRepository').getObjectList()) {
      this.crms[crm.id] = crm;
    }
    this.updateLayout();
  }

  private onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;

    this.dataSource.onFilterChange();
  }

  filterFunc = (item: CrmRoutingRuleEntity): boolean => {
    const filterValue: string = (this.filterValue || '').trim();
    if (!filterValue) {
      return true;
    }

    const crmName = this.crms[item.crm_id] ? this.crms[item.crm_id].name : '';

    return regexSearch(item.name, filterValue) || (crmName && regexSearch(crmName, filterValue));
  }

  // renderApplicationNameCol(item): any {
  //   if (!item.application_name) {
  //     return _ti('did.content.not_config');
  //   }

  //   let app_param = '';
  //   const application_name = _ti(item.application_name);;

  //   const app_param_value = [];
  //   if (item.tooltip) {
  //     for (let i = 0; i < item.tooltip.length; i++) {
  //       app_param += '{0}: {1}'
  //         .replace(/{(0)}/g, _ti(item.tooltip[i].param_name))
  //         .replace(/{(1)}/g, item.tooltip[i].param_value);

  //       app_param_value.push(item.tooltip[i].param_value);
  //     }
  //   }
  //   const tooltip = _ti('did.content.ivr_tooltip_v2', JSON.stringify({ app_name: application_name, params: app_param }));

  //   if (item.routed_fileentry) {
  //     return '{1}'
  //       .replace(/{(0)}/g, tooltip)
  //       .replace(/{(1)}/g, application_name);
  //   }

  //   return '{1}: {2}'
  //     .replace(/{(0)}/g, tooltip)
  //     .replace(/{(1)}/g, application_name)
  //     .replace(/{(2)}/g, app_param_value.join(','));
  // }

  async bulkDelete(items: CrmRoutingRuleEntity[]): Promise<any> {
    if (!items || !items.length) {
      return;
    }

    const confirmed: boolean = await this.crmRoutingRuleService.getConfirmDeleteCrmRoutingRules(items);
    if (confirmed) {
      try {
        await (EntityManager.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository') as CrmRoutingRuleRepository).bulkDelete(items);
        EntityManager.getRepository('CrmRoutingRuleRepository').reload();
        this.flexCrud.closeSidePanel();
        this.toast.show(_ti('public.message.delete_success'));
      } catch (e) {
        console.error('Delete error', e);
        this.toast.showError(e.message || _ti('public.message.delete_failure'));
      }
    }
  }

  createNew(): MatDialogRef<any> {
    const newCrmRouting = EntityManager.getRepository('CrmRoutingRuleRepository').create() as CrmRoutingRuleEntity;
    return this.dialogService.openDialog2(CreateCrmRoutingModalComponent, {
      data: {
        crmRouting: newCrmRouting
      }
    });
  }

  getVirtualDid(crmRouting: CrmRoutingRuleEntity): DidEntity {
    const did: DidEntity = this.dids[crmRouting.id] || new DidEntity();
    did.action_office_hour = crmRouting.dest_default;

    this.dids[crmRouting.id] = did;
    return did;
  }

  private updateLayout(): void {
    // Manual update layout of number-routing-dest elements
    this.isUpdatedLayout = !this.isUpdatedLayout;
  }

}
