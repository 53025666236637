import * as _ from 'lodash';

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { DialogActionButton, Colors, IFlexDialogConfig, NoWhitespaceValidator } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrls: ['./create-group-modal.component.scss']
})
export class CreateGroupModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('group.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  private readonly groupRepo = UserGroupRepository.getInstance<UserGroupRepository>();

  groups = [];
  group: UserGroupEntity;
  form: FormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<CreateGroupModalComponent>,
    public configManager: ConfigManager,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly fb: FormBuilder
  ) {
    super();
    this.groups = this.groupRepo.getObjectList();
  }

  ngOnInit(): void {
    this.group = _.cloneDeep(this.data.group);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.group.name, [Validators.required, Validators.maxLength(100), NoWhitespaceValidator]],
      group: [this.group.parent_id]
    });

    this.form.valueChanges.subscribe(changes => {
      this.group.name = _.trim(changes.name);
      this.group.parent_id = changes.group;
    });
  }

  cancel(result?): void {
    this.dialogRef.close(result);
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      this.formatData();
      const createdResp: { object_id: number } = await this.groupRepo.save(this.group);
      this.showInfo(_ti('public.message.create_success'));
      this.dialogRef.close(this.groupRepo.getObjectById(createdResp.object_id));
    } catch (error) {
      let msg = _ti('public.message.create_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.showError(msg);
    }
  }

  private readonly formatData = () => {
    if (this.group.parent) {
      this.group.parent_id = this.group.parent.getId();
    }
  }
}
