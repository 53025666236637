import { datafield, Entity } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { ContactFormatEntity } from '@wephone-core/model/entity/contact_format';
import { DidCountryEntity } from '@wephone-core/model/entity/did_country';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { SubscriptionRepository } from '@wephone-core/model/repository/subscription';
import { SubscriptionEntity } from '@wephone-core/model/entity/subscription';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { DateTime } from 'luxon';
import { DidCountryRepository } from '@wephone-core/model/repository/did_country';
import { parseDateTime } from '@wephone-utils/utils/time-util';
import { SubscriptionPackEntity } from './subscription_pack';
import { BusinessType } from '@wephone-core/system';
import { DidEntity } from './did';
import { DidRepository } from '../repository/did';

export enum CrmType {
  HUBSPOT = 'hubspot',
  SALESFORCE = 'salesforce',
  ZENDESK = 'zendesk',
  FRESHDESK = 'freshdesk',
  ZOHO = 'zoho',
  VTIGER = 'vtiger',
  PIPEDRIVE = 'pipedrive',
  SHOPIFY = 'shopify',
  CUSTOMCRM = 'customcrm',
  DYNAMICS = 'dynamics',
  HELPSCOUT = 'helpscout',
  ORACLEHOSPITALITY = 'oraclehospitality',
}

export enum ValidationState {
  VALIDATED = 0,
  WAIT_EMAIL_USER = 1,
  WAIT_SUPERADMIN = 2
}
export class EnterpriseEntity extends Entity {
  static object_type_id = 'enterprise';

  @datafield id: number;
  @datafield calling_profile_id: number;
  @datafield default_did_id: number;
  @datafield room_calling_profile_id: number;
  @datafield name;
  @datafield max_user: number;
  @datafield cc_license: number;
  @datafield last_max_user;
  @datafield last_cc_license;
  @datafield enabled;
  @datafield accounting_active;
  @datafield description;
  @datafield domain;
  @datafield callcenter_common_pw;
  @datafield apikey;
  @datafield parent_id: number;
  @datafield logo_id: number;
  @datafield validation_state: ValidationState;
  @datafield enterprise_params: string;
  @datafield system_params: string;
  @datafield address: string;
  @datafield billing_contact_email: string;
  @datafield country: string;
  @datafield currency: string;
  @datafield remaining_credit: number;
  @datafield api_webcallback: any;
  @datafield vat_number: string;
  @datafield subscription_id: number;
  @datafield subscription_pack_id: number; // set from ws
  @datafield subscription_expired_date: any; // set from ws
  @datafield opening_calendar_id: number;
  @datafield dialplan_outbound_rule_id: number;
  @datafield dialplan_room_outbound_rule_id: number;
  @datafield reseller_id: number;
  @datafield trial: number;
  @datafield timezone: string;
  @datafield has_default_credit_card: number;
  @datafield business_type = BusinessType.CALLCENTER;
  @datafield invoice_day;
  @datafield invoice_auto: number;
  @datafield cc_free_license: number;
  @datafield free_user: number;
  @datafield sms_callback_url: string;
  @datafield creation_dt: DateTime;
  @datafield public_proxy_id: number;
  @datafield default_bo_url: string;
  @datafield insee_code: string;

  contact_formats: ContactFormatEntity[];
  contact_format: ContactFormatEntity;

  did_country: DidCountryEntity;
  subscription: SubscriptionEntity;

  isValidationStateWaitUser(): boolean {
    return this.validation_state === ValidationState.WAIT_EMAIL_USER;
  }

  isValidationStateWaitSA(): boolean {
    return this.validation_state === ValidationState.WAIT_SUPERADMIN;
  }

  get default_did(): DidEntity {
    if (!this.default_did_id) {
      return;
    }
    return EntityManager.getRepository<DidRepository>('DidRepository').getObjectById(this.default_did_id);
  }

  get default_opening_calendar(): OpeningHourCalendarEntity {
    if (!this.opening_calendar_id) {
      return;
    }
    return EntityManager.getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository').getObjectById(
      this.opening_calendar_id
    );
  }

  set default_opening_calendar(opening_calendar: OpeningHourCalendarEntity) {
    this.opening_calendar_id = opening_calendar ? opening_calendar.getId() : undefined;
  }

  canCreateInvoice(): boolean {
    if (!this.accounting_active || !this.subscription_id) {
      return false;
    }
    return true;
  }

  async canBuyNumber(): Promise<boolean> {
    const isTrial = await this.isTrialSubscriptionPack();
    if (isTrial) {
      return false;
    }
    return true;
  }

  private async isTrialSubscriptionPack(): Promise<boolean> {
    const subscriptionPack = await this.getCurrentSubscriptionPack();
    return !subscriptionPack || subscriptionPack.isTrial();
  }

  async getCurrentSubscription(): Promise<SubscriptionEntity> {
    const subscriptions: SubscriptionEntity[] = await SubscriptionRepository.getInstance().findAll();
    if (subscriptions) {
      subscriptions.sort((a: SubscriptionEntity, b: SubscriptionEntity) => {
        return a.start_dt < b.start_dt ? 1 : -1;
      });
      return subscriptions[0];
    }
  }

  async getCurrentSubscriptionPack(): Promise<SubscriptionPackEntity> {
    const subscription = await this.getCurrentSubscription();
    if (!subscription || !subscription.subscription_pack) {
      return;
    }
    return subscription.subscription_pack;
  }

  async setObjectData(object_data: any, fetch_related_data: boolean): Promise<void> {
    super.setObjectData(object_data, fetch_related_data);

    // if (object_data.subscription_id) {
    //   console.log('object_data.subscription_id', object_data.subscription_id);
    //   const subscription = SubscriptionRepository.getInstance().getObjectById(object_data.subscription_id);
    //   if (!subscription) {
    //     console.error('No subscription');
    //   }
    //   console.log('this.subscription', this.subscription);
    // }

    // if (object_data.subscription && object_data.subscription.id) {
    //   this.subscription = SubscriptionRepository.getInstance().create(object_data.subscription) as SubscriptionEntity;
    // }

    // if (this.country) {
    //   this.country = this.country.toUpperCase();

    //   if (!this.did_country) {
    //     this.did_country = (DidCountryRepository.getInstance() as DidCountryRepository).getObjectByCode2(this.country);
    //   }
    // }

    // this.creation_dt = object_data.creation_dt ? parseDateTime(object_data.creation_dt) : undefined;
  }

  fetchRelatedData(): void {
    if (this.api_webcallback && typeof this.api_webcallback === 'string') {
      // convert api_webcallback to object, if it's string
      this.api_webcallback = JSON.parse(this.api_webcallback);
    }

    if (this.subscription_id) {
      this.subscription = SubscriptionRepository.getInstance().getObjectById(this.subscription_id);
      if (!this.subscription) {
        console.error('No subscription');
      }
    }

    if (this.subscription && this.subscription.id) { // TODO: See again then remove it if necessary
      this.subscription = SubscriptionRepository.getInstance().create(this.subscription) as SubscriptionEntity;
    }

    if (this.country) {
      this.country = this.country.toUpperCase();

      if (!this.did_country) {
        this.did_country = (DidCountryRepository.getInstance() as DidCountryRepository).getObjectByCode2(this.country);
      }
    }

    this.creation_dt = this.creation_dt ? parseDateTime(this.creation_dt) : undefined;
  }

  get isBusinessTypeReseller(): boolean {
    return this.business_type === BusinessType.RESELLER;
  }
}
