import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { ImageEntity } from '@wephone-core/model/entity/image';
import { DialogActionButton, Colors, regexSearch, NoWhitespaceValidator } from '@wephone-utils';
import * as _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { ImageRepository } from '@wephone-core/model/repository/image';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

enum StepChangeImage {
  SELECT_ITEM = 'select_item',
  ADD_ITEM = 'add_item',
}

@Component({
  selector: 'app-image-create-dialog',
  templateUrl: './image-create-dialog.component.html',
  styleUrls: ['./image-create-dialog.component.scss']
})
export class ImageCreateDialogComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('image.content.add');
  backButton: DialogActionButton =
    {
      label: _tk('public.navigation.back'),
      action: () => {
        this.goPrevStep();
      },
      visible: () => {
        return this.steps.indexOf(this.step) > 0;
      },
    };
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.navigation.next'),
      action: () => {
        this.goNextStep();
      },
      visible: () => {
        return this.step !== this.steps[this.steps.length - 1] &&
          this.step === StepChangeImage.SELECT_ITEM;
      },
      color: Colors.PRIMARY
    },
    {
      label: _tk('public.finish'),
      action: () => {
        this.save();
      },
      visible: () => {
        return this.step === this.steps[this.steps.length - 1] && this.localFile;
      },
      color: Colors.PRIMARY
    }
  ];

  private imageRepo = EntityManager.getRepository<ImageRepository>('ImageRepository');
  private image: ImageEntity; // current file entry
  private selectExisting = true; // Check whether having step select-step or not, default as True

  showBtnSelects: any = [];

  selectedMethod: string;

  // file uploaded
  localFile: any;
  localFileUrl: string | ArrayBuffer;

  steps: StepChangeImage[] = [
    StepChangeImage.SELECT_ITEM,
    StepChangeImage.ADD_ITEM,
  ];

  step = StepChangeImage.SELECT_ITEM;

  // binding from directive
  showInputName = true; // Check whether showing input name or not, default as True
  langSearch = '';

  newImage: ImageEntity; // new item which will be created
  searchCtrl = new FormControl();
  imageList: ImageEntity[];
  imageSelect = new FormControl();
  newImageList = [];
  fileDefaultSound: any;
  allowedUploadExtensions = [
    'jpeg',
    'jpg',
    'bmp',
    'png',
    'gif',
  ];

  form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<ImageCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: {
      image: ImageEntity;
      showInputName: boolean;
      selectExisting: boolean;
    },
    readonly cdr: ChangeDetectorRef,
    private readonly em: EntityManager,
  ) {
    super(cdr);

    this.image = data.image;

    this.form = this.fb.group({
      name: [this.image && this.image.name, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]],
    });

    this.showInputName = data.showInputName;
    this.selectExisting = data.selectExisting; // Check whether edit or create new

    if (!this.selectExisting) {
      this.step = StepChangeImage.ADD_ITEM;
      this.steps = [
        StepChangeImage.ADD_ITEM,
      ];
    }

    if (this.image && this.image.id) {
      this.dialogTitle = _tk('image.content.edit');
    }
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    await this.imageRepo.findAll();

    this.imageList = this.imageRepo.getObjectList();

    this.newImageList = this.imageList;

    // Go to step add-sound if in case of edit sound
    if (!this.selectExisting) {
      this.step = StepChangeImage.ADD_ITEM;
      this.steps = [
        StepChangeImage.ADD_ITEM,
      ];
    }

    if (this.image && this.image.id) {
      this.dialogTitle = _tk('image.content.edit');
    }

    this.setSelectSoundData();

    this.addSubscription(
      this.searchCtrl.valueChanges
        .subscribe(value => {
          const filterValue: string = (value || '').trim();
          this.filterSound(filterValue);
        })
    );
  }

  goPrevStep(): void {
    if (this.step === this.steps[0]) {
      return;
    }
    this.step = this.steps[this.steps.indexOf(this.step) - 1];
    if (this.step === StepChangeImage.SELECT_ITEM) {
      this.setSelectSoundData();
    }

    this.updateDialogTitle(this.step);
    this.markAsChanged();
  }

  private setAddSoundData(): void {
    this.form.get('name').setValue(_ti('image.content.name'));
  }

  private setSelectSoundData(): void {
    this.form.get('name').setValue(this.image && this.image.name || _ti('image.content.name'));
  }

  private updateDialogTitle(step: StepChangeImage): void {
    if (step === StepChangeImage.ADD_ITEM) {
      this.dialogTitle = _tk('image.content.add');
    } else if (step === StepChangeImage.SELECT_ITEM) {
      this.dialogTitle = _tk('image.content.edit');
    }
  }

  filterSound(name: string): void {
    this.newImageList = this.imageList.filter(sound => {
      if (!name) {
        return true;
      }

      return regexSearch(sound.name, name);
    });

    this.updateDialogLayout();
  }

  addSound(): void {
    this.updateDialogTitle(StepChangeImage.ADD_ITEM);
    this.setAddSoundData();
    this.goNextStep();
  }

  async goNextStep(): Promise<void> {
    if (this.step === this.steps[this.steps.length - 1]) {
      console.warn('End step has been reached');
      return;
    }

    if (this.step === StepChangeImage.ADD_ITEM && this.newImage) {
      await this.save();
    }

    this.step = this.steps[this.steps.indexOf(this.step) + 1];

    this.markAsChanged();
  }


  private setNewImage(image: ImageEntity): void {
    console.log('newImage', image);
    this.newImage = image;
    if (!this.newImage) {
      console.error('No file entry responsed');
      return;
    }

    this.markAsChanged();
  }

  private async uploadLocalFile(): Promise<ImageEntity> {
    if (!this.localFile) {
      console.error('No local sound to upload');
      throw new Error(_ti('sound_manager.message.no_uploaded_file'));
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      throw new Error(_ti('public.message.data_invalid'));
    }

    const name: string = this.form.get('name').value;
    this.form.get('name').setErrors(null);
    if (this.imageRepo.getObjectByName(name, this.image && this.image.id)) {
      this.form.get('name').setErrors({duplicated: true});
      this.form.get('name').markAllAsTouched();
      throw new Error(_ti('public.message.data_invalid'));
    }

    try {
      const resp: any = await this.imageRepo.upload(this.localFile, this.form.value);
      this.imageRepo.setDataAsReady();
      const image: ImageEntity = this.imageRepo.getObjectById(resp.id);
      this.setNewImage(image);

      return this.newImage;
    } catch (error) {
      throw new Error(_ti('public.message.create_failure'));
    }
  }

  fileLoadedForBrowser(localFile: File): void {
    console.log('localFile', localFile);
    this.localFile = localFile;
    this.onLocalFileChanged();
    this.markAsChanged();
  }

  deleteSound(): void {
    this.localFile = undefined;
    this.onLocalFileChanged();
  }

  onLocalFileChanged(): void {
    const localFile: File = this.localFile;
    if (!localFile) {
      this.localFileUrl = null;
      console.warn('No sound file');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(localFile);
    reader.onload = ($event) => {
      this.localFileUrl = reader.result;
      this.updateDialogLayout();
    };
  }

  async save(): Promise<void> {
    try {
      await this.uploadLocalFile();

      this.closeDialog();
    } catch (error) {
      this.showErrorMessage(error, _ti('image.error.unknown'));
      return Promise.reject();
    } finally {
      this.markAsChanged();
    }
  }

  chooseExisting(image: ImageEntity): void {
    this.dialogRef.close({ image });
  }

  closeDialog = () => {
    if (!this.newImage) {
      console.error('No new file entry created');
      this.toastService.showError(_ti('sound_manager.message.no_uploaded_file'));

      return;
    }
    console.log('new file entry created', this.newImage);
    this.dialogRef.close({ image: this.newImage });
  }

  markAsChanged(): void {
    this.updateDialogLayout();
  }
}
