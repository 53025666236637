import { DialogActionButton, IFlexDialogConfig, IDialogComponent, DialogWrapper } from '@wephone-utils';
import { FlexBaseComponent } from './flex-base-component';
import { HostBinding, Directive } from '@angular/core';
import { _tk, _ti } from '@wephone-translation';

@Directive()
export abstract class DialogComponentBase extends FlexBaseComponent implements IDialogComponent {
  SCROLLABLE_CONTENT = false;
  @HostBinding('class.flex-dialog-no-scroll') get _baseDialogCssClassNoScroll(): boolean {
    return !this.SCROLLABLE_CONTENT;
  }

  @HostBinding('class.flex-dialog-scrollable') get _baseDialogCssClassScrollable(): boolean {
    return this.SCROLLABLE_CONTENT;
  }

  dialogTitle?: string;
  dialogLeftActions?: DialogActionButton[];
  dialogRightActions?: DialogActionButton[];
  modalRef: DialogWrapper;
  _cancelButton: DialogActionButton = {label: _tk('public.button.close')};
  backButton: DialogActionButton = {label: _tk('public.button.back'), visible: () => false };

  get cancelButton(): DialogActionButton {
    return this._cancelButton;
  }

  set cancelButton(button: DialogActionButton) {
    this._cancelButton = button;
  }

  setModalComponent(dlg: DialogWrapper): void {
    this.modalRef = dlg;
  }

  updateDialogLayout(): void {
    this.modalRef.detectChange();
  }

  getDialogRightActions(): DialogActionButton[] {
    return this.dialogRightActions;
  }

  getDialogLeftActions(): DialogActionButton[] {
    return this.dialogLeftActions;
  }
}
