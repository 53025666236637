import { Injectable } from '@angular/core';
import { DialogService, ToastService } from '@wephone-utils';
import { AgentService } from '@wephone/services/agent.service';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { EnterPhoneNumberDialog } from '../modals/dialogEnterPhoneNumber/dialogEnterPhoneNumber';
import { _ti } from '@wephone-translation';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { AccessRight } from '@wephone-core/system';
import { EavesdropRequestRepository } from '@wephone-core/model/repository/eavesdrop_request';
import { EavesdropRequestEntity } from '@wephone-core/model/entity/eavesdrop_request';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { AgentReservedStatus, IAgentEntity } from '@wephone-core/model/entity/agent.i';

@Injectable()
export class EavesdropService {
  private readonly URL_REMOVE_EAVESDROP_RQ = '/callcenter/eavesdrop/eavesdrop_remove_request';

  constructor(
    private readonly dialogService: DialogService,
    private readonly agentService: AgentService,
    private readonly authService: AuthenticationService,
    private readonly toast: ToastService,
  ) { }

  removeEavesdropRequest(eavesdrop_request_id: number): Promise<any> {
    if (!this.authService.isAdmin() && !this.authService.hasAccessRight(AccessRight.Eavesdrop)) {
      this.toast.showError(_ti('error_403.description'));
      return;
    }
    const params: any = {
      eavesdrop_request_id
    };

    return HttpEngine.getInstance().apiPostV2(this.URL_REMOVE_EAVESDROP_RQ, params);
  }

  private enter_phone_number(eavesdrop_period = false, userId: number = null, onValueReturned?: (data) => any): Promise<any> {
    if (!this.authService.isAdmin() && !this.authService.hasAccessRight(AccessRight.Eavesdrop)) {
      this.toast.showError(_ti('error_403.description'));
      return;
    }
    return this.dialogService
      .openDialog2(
        EnterPhoneNumberDialog,
        {
          data: {
            eavesdrop_period,
            user_id: userId
          },
          size: 's'
        },
        onValueReturned
      )
      .afterClosed()
      .toPromise();
  }

  call_eavesdrop(session_id): Promise<any> {
    return this.enter_phone_number(false, null, async (ret) => {
      if (!ret || !ret.data) {
        console.error('No responsed data');

        return;
      }

      try {
        const resp = await this.agentService.call_eavesdrop(session_id, ret.data.phone_number);
        this.toast.showSuccess(_ti('public.done'));

        return resp;
      } catch (e) {
        const msg =
          e.error && e.error.message
            ? e.error.message
            : e.message || _ti('public.failure');
        this.toast.showError(msg);
      }
    });
  }

  call_eavesdrop_group(group_id): Promise<any> {
    return this.enter_phone_number(true, null, async (ret) => {
      if (!ret || !ret.data) {
        console.error('No responsed data');

        return;
      }

      try {
        const resp = await this.agentService.call_eavesdrop_group(
          group_id,
          ret.data.phone_number,
          ret.data.eavesdrop_duration === 1 ? ret.data.eavesdrop_until_time : null
        );

        this.toast.showSuccess(_ti('public.done'));

        return resp;
      } catch (e) {
        const msg =
          e.error && e.error.message
            ? e.error.message
            : e.message || _ti('public.failure');
        this.toast.showError(msg);
      }
    });
  }

  call_eavesdrop_queue(queue_id): Promise<any> {
    return this.enter_phone_number(true, null, async (ret) => {
      if (!ret || !ret.data) {
        console.error('No responsed data');

        return;
      }

      try {
        const resp = await this.agentService.call_eavesdrop_queue(
          queue_id,
          ret.data.phone_number,
          ret.data.eavesdrop_duration === 1 ? ret.data.eavesdrop_until_time : null
        );

        this.toast.showSuccess(_ti('public.done'));

        return resp;
      } catch (e) {
        const msg =
          e.error && e.error.message
            ? e.error.message
            : e.message || _ti('public.failure');
        this.toast.showError(msg);
      }
    });
  }

  async call_eavesdrop_agent(user_id): Promise<any> {
    const ret = await this.enter_phone_number(true, user_id, undefined);

    if (!ret || !ret.data) {
      console.error('No responsed data');
      return undefined;
    }

    try {
      const resp = await this.agentService.call_eavesdrop_agent(
        user_id,
        ret.data.phone_number,
        ret.data.eavesdrop_duration === 1 ? ret.data.eavesdrop_until_time : null
      );

      this.toast.showSuccess(_ti('public.done'));

      return resp;
    } catch (e) {
      const msg =
        e.error && e.error.message
          ? e.error.message
          : e.message || _ti('public.failure');
      this.toast.showError(msg);
    }
  }

  canEavesdropAgent(user: IUserEntity, agent: IAgentEntity): boolean {
    if (agent.reserved === AgentReservedStatus.InternalReserved) {
      return false;
    }
    if (this.canRemoveEavesdrop(user, agent)) {
      return false;
    }

    return this.authService.hasAccessRight(AccessRight.Eavesdrop) && user.canEavesdrop(agent.user);
  }

  getEavesdropAgent(user: IUserEntity, agent: IAgentEntity): EavesdropRequestEntity {
    return EavesdropRequestRepository.getInstance().getObjectList()
      .find(e => e.is_active && e.eavesdropper_user_id === user.id && e.target_id === agent.user_id);
  }

  canRemoveEavesdrop(user: IUserEntity, agent: IAgentEntity): boolean {
    return this.authService.hasAccessRight(AccessRight.Eavesdrop) && !!this.getEavesdropAgent(user, agent);
  }
}
