import * as _ from 'lodash';
import { Component, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, DialogService, IFlexDialogConfig, PhoneNumberService, StringUtils, ToastService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICallInfoDataChanged, ICallLog } from '@wephone-app-phone/pages/history/calllog.i';
import { CdrService, ICallDetailInfo, MissedCallVoicemail } from '@wephone-core/service/cdr_service';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { UserRepository } from '@wephone-core/model/repository/user';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { BlackListRepository } from '@wephone-core/model/repository/blacklist';
import { BlackListEntity } from '@wephone-core/model/entity/blacklist';
import { CreateBlackListModalComponent, ICreateBlackListModalData } from '@wephone-common/modals/create-black-list-modal/create-black-list-modal.component';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { SystemParam, UserRole } from '@wephone-core/system';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { ICallQualify, IQualifyResult, QualificationService } from '@wephone-common/qualification';
import { Clipboard } from '@angular/cdk/clipboard';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { RecordingCallAnalysisResultComponent } from '@wephone/modals/recording-call-analysis-result/recording-call-analysis-result.component';
import { RCAnalysisState, RCTranscriptionState } from '@wephone/pages/recording-call/recording-call.i';
import { CallLogService } from '@wephone-core/service/call_log_service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { Router } from '@angular/router';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DidRepository } from '@wephone-core/model/repository/did';
import { VoiceMailRepository } from '@wephone-core/model/repository/voicemail';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { AgentService } from '@wephone/services/agent.service';
import { MailboxType } from '@wephone-core/model/entity/voicemail_box';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';

@Component({
  selector: 'app-call-info-modal',
  templateUrl: './call-info-modal.component.html',
  styleUrls: ['./call-info-modal.component.scss']
})
export class CallInfoModalComponent extends DialogComponentBase {
  static modalConfig: IFlexDialogConfig = {
    size: 's',
    // width: '400px',
  };

  @ViewChild(MatSort) sort: MatSort;

  dialogTitle = _tk('call.detail');

  // private call: ICallLog; // Set from handled-call, missed-call, voice-mail, recording-call page
  private dataChanged: ICallInfoDataChanged = {};
  private callId: number;
  private callPublicId: string;

  // Missed-call
  isMissedCall = false;
  isVoicemail = false;
  clientInfo: any;
  agentTriedList: ICallLog[];
  loading = true;
  displayedColumns = [];
  dataSource: any;

  voicemailMessages: MissedCallVoicemail[] = [];

  userRole: UserRole;
  userId: number;
  filteredUserIds: number[];
  canBlockCaller: boolean;
  callInfo: ICallDetailInfo;
  transcriptionText: string;
  // voicemailInfo: IVoicemailInfo;
  queues: Record<number, CallQueueEntity> = {};
  ivrCustomMenus: Record<number, IvrCustomMenuEntity> = {};
  canDeleteVoicemail: boolean;
  canEditDid: boolean;
  canEditQueue: boolean;
  canEditIvrMenu: boolean;
  canEditCampaign: boolean;
  campaign: OutcallCampaignEntity;
  queue: CallQueueEntity;

  constructor(
    public readonly configManager: ConfigManager,
    @Inject(MAT_DIALOG_DATA) readonly data: {
      cdr_public_id?: string;
      cdr_id?: number;
    },
    private readonly dialogRef: MatDialogRef<CallInfoModalComponent>,
    private readonly phoneNumberService: PhoneNumberService,
    private readonly agentService: AgentService,
    private readonly cdrService: CdrService,
    private readonly qualService: QualificationService,
    private readonly fileEntryService: FileEntryService,
    private readonly toast: ToastService,
    private readonly clipboard: Clipboard,
    private readonly em: EntityManager,
    private readonly dialogService: DialogService,
    private readonly authService: AuthenticationService,
    private readonly calllogService: CallLogService,
    private readonly router: Router,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);

    this.callPublicId = data.cdr_public_id;
    this.callId = data.cdr_id;

    this.userId = this.authService.getUserId();
    this.canBlockCaller = this.authService.isAdmin() || this.authService.isSupervisor();
    this.canEditDid = this.authService.isAdmin();

    this.canEditQueue = this.authService.isAdmin() || this.authService.isSupervisor();
    this.canEditCampaign = this.authService.isAdmin() || this.authService.isSupervisor();
    this.canEditIvrMenu = this.authService.isAdmin() || this.authService.isSupervisor();

  }

  get isHandledCall(): boolean {
    return !this.isMissedCall && !this.isVoicemail;
  }

  protected async resolveData(): Promise<void> {
    this.userRole = this.authService.getUserRole();
    if (this.userRole !== UserRole.ADMIN) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      this.filteredUserIds = myProfile.managed_user_ids;
    }

    this.callInfo = await this.cdrService.getCallInfo(this.callId, this.callPublicId);

    this.canDeleteVoicemail = this.authService.isAdmin() || this.authService.isSupervisor() ||
      !(this.authService.isAdmin() || this.authService.isSupervisor()) && this.callInfo && this.callInfo.vm_mailbox_type !== MailboxType.QUEUE;

    if (this.callInfo && this.callInfo.transcription_state === 2) {
      this.transcriptionText = StringUtils.alignTranscriptionText(this.callInfo.transcription, '<br>');
    }

    if (this.queue_ids) {
      const queueId: number = this.queue_ids[this.queue_ids.length - 1];
      if (queueId) {
        this.queue = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(queueId);
        this.campaign = this.queue && this.queue.outcall_campaign;
      }
    }

    this.isMissedCall = this.callInfo && !!this.callInfo.is_missed;

    this.isVoicemail = this.callInfo && !!this.callInfo.is_voicemail;

    // if (this.isMissedCall) {
    this.getMissedCallHistoryInfo();
    // }

    await this.em.getRepository<BlackListRepository>('BlackListRepository').loadObjectList();
  }

  get client_number(): string {
    return this.callInfo && this.callInfo.client_number;
  }

  get agent_number(): string {
    return this.callInfo && this.callInfo.agent_number;
  }

  get start_time(): Date {
    return this.callInfo && this.callInfo.start_dt && new Date(this.callInfo.start_dt) || null;
  }

  get client_name(): string {
    return this.callInfo && this.callInfo.client_name;
  }

  get wait_duration(): number {
    return this.callInfo && this.callInfo.wait_duration;
  }

  get hangup_first(): string {
    return this.callInfo ? (this.callInfo.client_hangup_first && _ti('handled_call_info.content.client_hangup_first') || _ti('handled_call_info.content.agent_hangup_first')) : '';
  }

  get queue_ids(): number[] {
    return this.callInfo && this.callInfo.queue_ids || [];
  }

  get hangup_agent_names(): string[] {
    let hangupAgentNames: string[] = [];
    const hangupUserIds: number[] = this.callInfo && this.callInfo.handled_user_ids || [];

    if (!_.isEmpty(hangupUserIds)) {
      const users: IUserEntity[] = this.em.getRepository<UserRepository>('UserRepository').getObjectList();
      hangupAgentNames = users.filter(u => _.includes(hangupUserIds, u.id)).map(u => u.name);
    }

    return hangupAgentNames;
  }

  get is_outgoing(): boolean {
    return this.callInfo && !!this.callInfo.is_outgoing;
  }

  get duration(): number {
    return this.callInfo && this.callInfo.duration;
  }

  get qualification_displayed(): string {
    return this.callInfo && this.callInfo.qualification_displayed;
  }

  get qualification_comment(): string {
    return this.callInfo && this.callInfo.qualification_comment;
  }

  get cancelButton(): DialogActionButton {
    return _.extend(this._cancelButton, {
      action: () => {
        this.dialogRef.close(this.dataChanged);
      }
    });
  }

  // get missed_call_id(): number {
  //   return this.call && this.call.missed_call_id;
  // }

  getQueueName(queueId: number): string {
    if (this.queues[queueId]) {
      return this.queues[queueId].queue_name;
    }

    const queue: CallQueueEntity = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(queueId);
    if (queue) {
      this.queues[queueId] = queue;
      return queue.queue_name;
    }

    return '';
  }

  getIvrMenuName(id: number): string {
    if (this.ivrCustomMenus[id]) {
      return this.ivrCustomMenus[id].name;
    }

    const ivrMenu: IvrCustomMenuEntity = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getObjectById(id);
    if (ivrMenu) {
      this.ivrCustomMenus[id] = ivrMenu;
      return ivrMenu.name;
    }

    return '';
  }

  async onPlayCallHistoryMessage(item: ICallLog): Promise<void> {
    await this.onPlayVMMessage(item.voicemail_public_id, item.is_read, item.readers);

    item.is_read = 1;
    if (!item.readers) {
      item.readers = [];
    }
    item.readers.push(this.userId);
  }

  async onPlayVoicemailMessage(item: MissedCallVoicemail): Promise<void> {
    await this.onPlayVMMessage(item.public_id, item.is_read, item.readers);

    item.is_read = 1;
    if (!item.readers) {
      item.readers = [];
    }
    item.readers.push(this.userId);
  }

  async onPlayVMMessage(publicId: string, isRead: number, readers: number[]): Promise<void> {
    if (this.callInfo && publicId === this.callInfo.file_public_id) {
      this.onPlayMessage();
    }

    if (!isRead || _.isEmpty(readers) ||
      (!_.isEmpty(readers) && !_.includes(readers, this.userId))) {
      await this.updateVMListened(publicId);
    }
  }

  async onPlayMessage(): Promise<void> {
    if (!this.callInfo || !this.callInfo.file_public_id) {
      console.error('No file sound');
      return;
    }

    if (this.isVoicemail && (
      !this.callInfo.vm_message_is_read || _.isEmpty(this.callInfo.vm_message_readers) ||
      (!_.isEmpty(this.callInfo.vm_message_readers) && !_.includes(this.callInfo.vm_message_readers, this.userId))
    )) {
      await this.updateVMListened(this.callInfo.file_public_id);
    }
  }

  private async updateVMListened(filePublicId: string): Promise<void> {
    try {
      await VoiceMailRepository.getInstance<VoiceMailRepository>().markVoiceMailListened(filePublicId);

      this.callInfo.vm_message_is_read = 1;

      if (!this.callInfo.vm_message_readers) {
        this.callInfo.vm_message_readers = [];
      }

      if (!_.includes(this.callInfo.vm_message_readers, this.userId)) {
        this.callInfo.vm_message_readers.push(this.userId);
      }

      // Update dataChanged
      this.dataChanged.vm_message_is_read = this.callInfo.vm_message_is_read;
      this.dataChanged.vm_message_readers = this.callInfo.vm_message_readers;
    } catch (error) {
      console.error('Cannot mark voicemail listened', error.message);
    }
  }

  canQualify = (item: ICallDetailInfo): boolean => {
    if (this.userRole === UserRole.WATCHER) {
      return false;
    }

    if (!item.has_qualification) {
      return false;
    }

    if (this.userRole !== UserRole.ADMIN &&
      (
        this.filteredUserIds &&
        !_.intersection(this.filteredUserIds, item.handled_user_ids).length
      )
    ) {
      return false;
    }

    return item.is_outgoing && !!item.out_qualification_id || !item.is_outgoing && !!item.in_qualification_id;
  }

  gotoEditCallQueue(callQueueId: number): void {
    this.dialogRef.close();
    this.router.navigateByUrl(`queues/${callQueueId}`);
  }

  gotoEditIvrMenu(ivrMenuId: number): void {
    this.dialogRef.close();
    this.router.navigateByUrl(`ivr/ivr-menus/${ivrMenuId}`);
  }

  gotoEditCampaign(campaignId: number): void {
    this.dialogRef.close();
    this.router.navigateByUrl(`out-call-campaigns/${campaignId}`);
  }

  gotoEditDid(phoneNumber: string): void {
    const displayPhoneNumber = this.phoneNumberService.getDisplayNumber(phoneNumber);
    const did: DidEntity = this.em.getRepository<DidRepository>('DidRepository').getObjectByDisplayedNumber(displayPhoneNumber);
    if (!did) {
      console.error(`No did with phone number ${phoneNumber} found`);
      return;
    }

    this.dialogRef.close();
    const didId: number = did.id;
    this.router.navigateByUrl(`number-routing/${didId}`);
  }

  async reQualify(call: ICallDetailInfo): Promise<void> {
    try {
      const callQualify: ICallQualify = {
        id: call.id,
        qualification_comment: call.qualification_comment,
        out_qualification_id: call.out_qualification_id,
        in_qualification_id: call.in_qualification_id,
        queue_ids: call.queue_ids,
        is_outgoing: call.is_outgoing,
        qualification_ids: call.qualification,
      };

      const result: IQualifyResult = await this.qualService.reQualify(callQualify);
      if (result) {
        // To update call item from Dialog
        _.extend(call, {
          qualification: result.qualification_ids,
          qualification_comment: result.qualification_comment,
          qualification_displayed: result.qualification,
        });

        // To update call item from Page
        _.extend(this.dataChanged, {
          qualification: result.qualification_ids,
          qualification_comment: result.qualification_comment,
          qualification_displayed: result.qualification,
        });
      }

      this.updateDialogLayout();
    } catch (error) {
      console.error('Cannot qualify call', error.message);
    }
  }

  getKeyAnalysis(item: ICallDetailInfo): string[] {
    return item.analysis_result && item.analysis_result.json_result ? Object.keys(item.analysis_result.json_result) : [];
  }

  getKeyAnalysisUuid(item: ICallDetailInfo): string[] {
    return ['analysis_date', 'transcript_analysis_uuid'];
  }

  showTranscriptionResult(item: ICallDetailInfo): void {
    if (item.transcription_state !== RCTranscriptionState.TranscriptionDone &&
      item.analysis_state !== RCAnalysisState.AnalysisDone) {
      console.error('No transcription result');
      return;
    }

    this.dialogService.openDialog2(
      RecordingCallAnalysisResultComponent,
      {
        data: {
          item,
        },
        padding: false
      }
    );
  }

  async unarchiveVoicemail(item: ICallDetailInfo): Promise<any> {
    try {
      await this.em.getRepository<VoiceMailRepository>('VoiceMailRepository').unarchiveItemsByIds([item.vm_message_id]);
      this.showInfo(_ti('public.message.unarchive_success'));
      item.vm_message_is_archived = 0;
      this.updateDialogLayout();
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.unarchive_failure'));
    }
  }

  async archiveVoicemail(item: ICallDetailInfo): Promise<any> {
    try {
      await this.em.getRepository<VoiceMailRepository>('VoiceMailRepository').archiveItemsByIds([item.vm_message_id]);
      this.showInfo(_ti('public.message.archive_success'));
      item.vm_message_is_archived = 1;
      this.updateDialogLayout();
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.archive_failure'));
    }
  }

  deleteVoicemail(item: ICallDetailInfo): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        if (!item || !item.vm_message_id) {
          console.error('Voice item id not found');
          return;
        }

        try {
          await this.em.getRepository<VoiceMailRepository>('VoiceMailRepository').deleteItemsByIds([item.vm_message_id]);

          this.dataChanged.voicemail_has_deleted = true;

          this.dialogRef.close(this.dataChanged);

          this.showInfo(_ti('public.message.delete_success'));
        } catch (error) {
          this.showErrorMessage(error, _ti('public.message.delete_failure'));
        }
      }
    );
  }

  downloadFileEntry(item: ICallDetailInfo, fileType: string): void {
    const fileName = `${fileType}-${item.file_public_id}.mp3`;
    const download_url = this.fileEntryService.getFileUrl(item.file_public_id, true);
    this.fileEntryService.download(download_url, fileName);
  }

  copyRecoredCallUrl(item: ICallDetailInfo): void {
    const urlPattern: string = this.configManager.getSystemParam(SystemParam.recorded_call_url);
    const url: string = urlPattern.replace('{{public_id}}', item.file_public_id);
    this.clipboard.copy(url);
    this.toast.showSuccess(_ti('call.message.share_url_success'));
  }

  copyCallDetailUrl(cdr: ICallDetailInfo): void {
    const urlPattern: string = this.configManager.getSystemParam(SystemParam.detail_call_url);
    const url: string = urlPattern.replace('{{public_id}}', cdr.public_id);
    this.clipboard.copy(url);
    this.toast.showSuccess(_ti('call.message.share_url_success'));
  }

  private isAnonymous(): boolean {
    return this.client_number === 'anonymous';
  }

  private getBlockCallerNumber(): string {
    return this.isAnonymous() && this.callInfo && this.callInfo.calling_number_real || this.client_number;
  }

  hasBlockCallerButton(): boolean {
    if (this.isAnonymous() &&
      (!this.callInfo || !this.callInfo.calling_number_real || this.callInfo.calling_number_real === 'anonymous')) {
      return false;
    }

    return true;
  }

  private getBlockedCaller(): BlackListEntity {
    const phoneNumber: string = this.getBlockCallerNumber();
    return this.em.getRepository<BlackListRepository>('BlackListRepository').getObjectByNumber(phoneNumber);
  }

  isExistBlackList(): boolean {
    return !!this.getBlockedCaller();
  }

  blockCaller(): any {
    if (this.hasBlockCallerButton() && this.isExistBlackList()) {
      console.warn('Caller has already been blocked');
      return;
    }

    const data: ICreateBlackListModalData = {
      number: this.getBlockCallerNumber(),
      cdr_id: this.callInfo.id,
      block_reason: this.client_name,
      block_caller: true
    };

    return this.dialogService.openDialog2(CreateBlackListModalComponent, { data }, (item: BlackListEntity) => {
      if (item && item.id) {
        this.updateDialogLayout();
      }
    });
  }

  async unblockCaller(): Promise<any> {
    const blockedCaller = this.getBlockedCaller();

    if (!blockedCaller) {
      console.warn('Blocked Caller not found');
      return;
    }

    return this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('handled_call_info.content.unblock_caller_confirm'), async () => {
      try {
        await this.em.getRepository<BlackListRepository>('BlackListRepository').bulkDelete([blockedCaller]);
        this.successToast(_ti('handled_call_info.message.unblock_success'));
        this.updateDialogLayout();
      } catch (e) {
        console.error('Unblock caller error', e);
        const message = e.error && e.error.message ? e.error.message : _ti('handled_call_info.message.unblock_failure');
        this.showError(message);
      }
    });
  }

  private getMissedCallHistoryInfo(): void {
    // if (!this.missed_call_id) {
    //   console.error('Not have missed_call_id');
    //   return;
    // }

    // this.loading = true;
    // try {
    // const missedCallInfo: MissedCallInfo = await this.cdrService.get_missed_call_info(this.missed_call_id);
    // this.callInfo = missedCallInfo.callInfo;

    const callHistory = this.callInfo && this.callInfo.call_history || [];
    this.voicemailMessages = this.callInfo && this.callInfo.voicemail_messages || [];
    this.dataSource = null;

    if (!_.isEmpty(callHistory)) {
      this.agentTriedList = callHistory.map(x => {
        const item: ICallLog = this.calllogService.cdrToCallLog(x);
        return item;
      });
      this.updateDialogLayout();
      this.displayedColumns = ['agent_name', 'start_time', 'called_number', 'voicemail_public_id'];
      this.dataSource = new MatTableDataSource(this.agentTriedList);
      this.dataSource.sort = this.sort;
    }

    // } catch (error) {
    //   console.error('Error:', error);
    //   this.showError('An error occured');
    // } finally {
    //   this.updateDialogLayout();
    //   this.loading = false;
    // }

  }

  numberNormalize(phoneNumber: string): string {
    return this.phoneNumberService.getDisplayNumber(phoneNumber);
  }

  makeCall(): void {
    if (!this.callInfo) {
      console.error('Call-info not found');
      return;
    }

    this.agentService.make_recall_missed_call(this.callInfo.id);
  }
}
