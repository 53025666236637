import { Component, Input, OnInit } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { SystemParam } from '@wephone-core/system';
import { ConfigManager } from '@wephone-core/wephone-core.module';

@Component({
  selector: 'app-sip-connection-info',
  templateUrl: './sip-connection-info.component.html',
  styleUrls: ['./sip-connection-info.component.scss']
})
export class SipConnectionInfoComponent extends FlexBaseComponent implements OnInit {
  @Input() sipphone: SipPhoneEntity;

  showSipPassword: boolean;
  sipDomain: string;

  constructor(
    private readonly configManager: ConfigManager,
  ) {
    super();
  }

  ngOnInit(): void {
    this.sipDomain = this.configManager.getSystemParam(SystemParam.sip_domain);
    const sipPort = this.configManager.getSystemParam(SystemParam.sip_port);
    if (+sipPort !== 5060) {
      this.sipDomain = `${this.sipDomain}:${sipPort}`;
    }
  }

}
