import * as _ from 'lodash';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { DialogActionButton, Colors } from '@wephone-utils';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { OpeningHourCalendarEditComponent } from '@wephone/components/opening-hour-calendar-edit/opening-hour-calendar-edit.component';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';

@Component({
  selector: 'calendar-edit-modal',
  templateUrl: './calendar-edit.html',
  styleUrls: ['./calendar-edit.scss'],
})
export class CalendarEditModal extends DialogComponentBase implements OnInit {
  @ViewChild('calendarEdit') calendarEdit: OpeningHourCalendarEditComponent;

  dialogTitle = _tk('opening_hour_calendar.title.edit');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('opening_hour_calendar.content.update_calendar'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  calendar: OpeningHourCalendarEntity;

  // Constructor
  constructor(
    public dialogRef: MatDialogRef<CalendarEditModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private em: EntityManager,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    const calendarRepo = this.em.getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository');
    this.calendar = _.cloneDeep(calendarRepo.getObjectById(this.data.id));
  }

  async submit(): Promise<any> {
    return this.calendarEdit.submitForm().then(() => {
      this.dialogRef.close();
    });
  }
}
