import * as _ from 'lodash';

import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService, DialogActionButton, Colors, regexSearch } from '@wephone-utils';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { UserEntity } from '@wephone-core/model/entity/user';
import { UserRepository } from '@wephone-core/model/repository/user';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { PermissionService } from '@wephone/services/permission.service';

export class GroupAddUserInputData {
  userIds?: number[];
  excludedUserIds?: number[];
  onAddToGroup?: (users: UserEntity[]) => void;
}

@Component({
  selector: 'group-add-user',
  templateUrl: './group-add-user.component.html',
  styleUrls: ['./group-add-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupAddUserComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('call_queue.content.operators.available');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.button.add'),
      action: () => {
        this.addSelectedUsers();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    },
    // {
    //   label: _tk('call_queue.content.operators.add_all_agent'),
    //   action: () => {
    //     this.addAllDisplayedUsers();
    //   },
    //   visible: () => {
    //     return true;
    //   },
    //   color: Colors.PRIMARY
    // }
  ];

  onAddToGroup: (users: UserEntity[]) => void;
  userList: UserEntity[];

  filters: {
    search_text?: string;
  } = {};

  selectedUserIds: number[];
  selectedUsers: UserEntity[] = [];
  selectedAll: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: GroupAddUserInputData,
    private readonly dialogService: DialogService,
    private readonly permissionService: PermissionService,
    cdr: ChangeDetectorRef,
    em: EntityManager
  ) {
    super(cdr);

    this.onAddToGroup = data.onAddToGroup;
    this.selectedUserIds = data.userIds || [];

    this.userList = em.getRepository<UserRepository>('UserRepository').getAllEnabled().filter(x =>
      _.isEmpty(data.excludedUserIds) || !_.includes(data.excludedUserIds, x.id)
    );
  }

  get users(): UserEntity[] {
    return this.userList.filter((u: UserEntity) => {
      return this.filterItem(u) && this.selectedUserIds.indexOf(u.id) === -1 ? true : false;
    }); // see filter in template
  }

  filterItem = (user: UserEntity): boolean => {
    if (user.isAccountant()) {
      return false;
    }

    if (this.filters.search_text) {
      // const search = new RegExp(this.filters.search_text.toLowerCase(), 'gi');
      // if (user.name.toLowerCase().search(search) === -1) {
      //   return false;
      // }
      const filterValue: string = this.filters.search_text.trim();
      if (!regexSearch(user.name, filterValue)) {
        return false;
      }
    }

    return true;
  }

  canChangeUser(user: UserEntity): boolean {
    return this.permissionService.canChangeUserFromGroup(user);
  }

  selectAll(): void {
    const displayedUsers = this.users;
    if ((displayedUsers || []).length === 0) {
      console.warn('User filtered not found');
      return;
    }

    this.selectedAll = !this.selectedAll;

    if (this.selectedAll) {
      this.selectedUsers = this.users;
    } else {
      this.selectedUsers.splice(0);
    }
  }

  // addAllDisplayedUsers(): void {
  //   // get all displayed
  //   const all_displayed = this.users;
  //   if ((all_displayed || []).length === 0) {
  //     console.warn('User filtered not found');

  //     return;
  //   }
  //   this.dialogService.confirmDialog(
  //     _ti('dialogs.confirmation', {}),
  //     _ti('components.queue_agent_list.confirm_add_queue_agents', {
  //       nb_agent: (all_displayed || []).length
  //     }),
  //     () => {
  //       this.addAgentsToGroup(all_displayed);
  //       this.modalRef.returnValue(true);
  //     }
  //   );
  // }

  addAgentsToGroup(users: UserEntity[]): void {
    if (!users || users.length === 0) {
      console.error('No user selected');
      return;
    }

    for (const u of users) {
      this.selectedUserIds.push(u.id);
    }

    this.cdr.markForCheck();
    // this.cdr.detectChanges();
    if (this.onAddToGroup) {
      this.onAddToGroup(users);
    }
  }

  addSelectedUsers(): void {
    this.addAgentsToGroup(this.selectedUsers);
  }

  selectUser(user: UserEntity): void {
    if (!this.hasSelected(user)) {
      this.selectedUsers.push(user);
    } else {
      this.unselectUser(user);
    }
  }

  unselectUser(user: UserEntity): void {
    const findIndex = this.selectedUsers.findIndex((u: UserEntity) => u.id === user.id);
    this.selectedUsers.splice(findIndex, 1);

    if (this.selectedUsers.length === 0) {
      this.selectedAll = false;
    }
  }

  hasSelected(user: UserEntity): boolean {
    for (const selectedUser of this.selectedUsers) {
      if (selectedUser.id === user.id) {
        return true;
      }
    }
    return false;
  }
}
