import { Component, OnInit } from '@angular/core';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { RoutableTabGroup, ITabSubRoute, DialogService } from '@wephone-utils';
import { DashboardPage } from '@wephone/pages/dashboard/dashboard/dashboard';
import { ActiveOutboundCampaignsComponent } from '@wephone/pages/dashboard/active-outbound-campaigns/active-outbound-campaigns.component';
import { GraphChartComponent } from '@wephone/pages/dashboard/graph-chart/graph-chart.component';
import { LivePage } from '@wephone/pages/dashboard//live/live.component';
import { HandledCallsComponent, MissedCallsComponent } from '@wephone-common';
import { _tk, _ti } from '@wephone-translation';
import { TranslateService } from '@ngx-translate/core';
import { WallboardComponent } from '@wephone/components/wallboard/wallboard.component';
import { AccessRight, UserRole } from '@wephone-core/system';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { CallInfoModalComponent } from '@wephone-common/modals/call-info-modal/call-info-modal.component';

@Component({
  selector: 'overview-page',
  host: { class: 'flex-page-component' },
  templateUrl: 'overview.html',
  styleUrls: ['./overview.scss'],
  providers: [TranslateService], // fix bug translate on this page
})
export class OverviewPage extends RoutableTabGroup implements OnInit {
  static FullRoleMenus = ['overview', 'agent-list', 'graph', 'outbound-campaign', 'handled-calls', 'missed-calls', 'wallboard'];
  static RoleMenus = [
    {
      role: UserRole.WATCHER,
      menus: ['agent-list', 'graph', 'outbound-campaign', 'handled-calls', 'missed-calls']
    },
    {
      role: UserRole.AGENT,
      menus: ['overview', 'agent-list', 'graph', 'outbound-campaign', 'handled-calls', 'missed-calls']
    },
    {
      role: UserRole.USER,
      menus: ['overview', 'agent-list', 'graph', 'outbound-campaign', 'handled-calls', 'missed-calls']
    },
    {
      role: UserRole.ACCOUNTANT,
      menus: ['overview']
    },
    {
      role: UserRole.ADMIN,
      menus: OverviewPage.FullRoleMenus
    },
    {
      role: UserRole.SUPERVISOR,
      menus: OverviewPage.FullRoleMenus
    },
  ];

  childRoutes: ITabSubRoute[] = [];
  availableRoutes: ITabSubRoute[] = [
    { name: 'overview', label: _tk('livecall.tab.dashboard'), component: DashboardPage },
    { name: 'agent-list', label: _tk('livecall.tab.live_call'), component: LivePage },
    { name: 'graph', label: _tk('livecall.tab.graph'), component: GraphChartComponent },
    { name: 'outbound-campaign', label: _tk('livecall.tab.active_campaign'), component: ActiveOutboundCampaignsComponent },
    { name: 'handled-calls', label: _tk('livecall.tab.handled_calls'), component: HandledCallsComponent },
    { name: 'missed-calls', label: _tk('livecall.tab.missed_calls'), component: MissedCallsComponent },
    { name: 'wallboard', label: _tk('livecall.tab.wallboard'), component: WallboardComponent },
  ];

  constructor(
    private readonly settings: FlexIvrSettings,
    private readonly authService: AuthenticationService,
    private readonly route: ActivatedRoute,
    private readonly dialogService: DialogService,
  ) {
    super();

    const userRole: UserRole = this.authService.getUserRole();
    const roleMenu = OverviewPage.RoleMenus.find(x => x.role === userRole);

    for (const menuItem of roleMenu.menus) {
      const r: ITabSubRoute = this.availableRoutes.find(i => i.name === menuItem);
      // Only check live-page for role watcher
      if (userRole === UserRole.WATCHER &&
        r.name === 'agent-list' &&
        !this.authService.hasAccessRight(AccessRight.LivePage)) {
        continue;
      }
      this.childRoutes.push(r);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.addSubscription(
      this.route.params.subscribe((params: any) => {
        console.log('Params', params);
        const publicId = params['cdr_public_id'];
        if (publicId) {
          this.dialogService.openDialog2(
            CallInfoModalComponent,
            {
              data: {
                cdr_public_id: publicId
              },
              padding: false
            }
          );
        }
      })
    );
  }

  modeCallCener(): boolean {
    return this.settings.uiCallCenter();
  }

  modeEnterprise(): boolean {
    return this.settings.uiEnterprise();
  }

  modeReseller(): boolean {
    return this.settings.uiReseller();
  }
}
