import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DynamicFilterSource, IFlexTableConfig, DialogService, ToastService,
  IFlexTableSidePannelOptions, FlexCRUDPageComponent,
} from '@wephone-utils';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SipTrunkEntity } from '@wephone-core/model/entity/siptrunk';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';
import { CreateSiptrunkModalComponent } from '@wephone/modals/create-siptrunk-modal/create-siptrunk-modal.component';
import { EditSiptrunkComponent } from '@wephone/components/edit-siptrunk/edit-siptrunk.component';
import { FlexBasePage } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-sip-trunk',
  templateUrl: './sip-trunk.component.html',
  styleUrls: ['./sip-trunk.component.scss']
})
export class SipTrunkComponent extends FlexBasePage implements OnInit {

  filterValue: string;
  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sipTrunkForm: FormGroup;
  sipTrunkRepo = SipTrunkRepository.getInstance<SipTrunkRepository>();
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EditSiptrunkComponent,
  };

  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  constructor(
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly dialogService: DialogService,
    private readonly toast: ToastService,
  ) {
    super();
    const sipTrunkSource = this.sipTrunkRepo.dataSource<SipTrunkEntity>();
    this.dataSource = new DynamicFilterSource(sipTrunkSource, undefined);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('siptrunk.form.name')
        },
        {
          name: 'username',
          label: _tk('siptrunk.form.username')
        },
        {
          name: 'outgoing_enabled',
          label: _tk('siptrunk.form.outgoing_enabled'),
          searchable: false
        },
        {
          name: 'alias',
          label: _tk('siptrunk.form.alias')
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.sipTrunkForm = this.fb.group({
      filterValue: [''],
    });

    this.sipTrunkForm.valueChanges.subscribe(this.onFormValueChange);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected resolveData(): void {
    this.sipTrunkRepo.findAll();
  }

  private readonly onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  createNew(): any {
    this.dialogService.openDialog2(CreateSiptrunkModalComponent);
  }

  public async bulkDelete(items: SipTrunkEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      this.translate.instant('dialogs.confirmation'),
      this.translate.instant('user.title.delete'),
      async () => {
        if (!items) {
          return;
        }

        try {
          await this.sipTrunkRepo.bulkDelete(items);
          this.flexCrud.closeSidePanel();
          this.toast.show(_ti('public.message.delete_success'));
        } catch (error) {
          this.toast.showErrorMessage(error, _ti('public.message.delete_failure'));
        }
      }
    );
  }
}
