import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk } from '@wephone-translation';

@Component({
  selector: 'app-reusable-sound-confirm-delete',
  templateUrl: './reusable-sound-confirm-delete.component.html',
  styleUrls: ['./reusable-sound-confirm-delete.component.scss']
})
export class ReusableSoundConfirmDeleteComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('dialogs.warning');
  inUsedObjects: any = {};

  constructor(
    public dialogRef: MatDialogRef<ReusableSoundConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.inUsedObjects = this.data.inUsedObjects;
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
