import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, ToastService, DialogService, NoWhitespaceValidator } from '@wephone-utils';
import { ShortDialCodeEntity } from '@wephone-core/model/entity/short_dial_code';
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroupDirective, NgForm, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ShortDialCodeRepository } from '@wephone-core/model/repository/short_dial_code';
import { DidEntity } from '@wephone-core/model/entity/did';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IRoutingApp } from '@wephone-core/routing-app/routing-app.interface';
import { DidConfigWizardModalComponent } from '@wephone/modals/did/did-config-wizard-modal/did-config-wizard-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { _tk, _ti } from '@wephone-translation';

export class ShortDialCodeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-short-dial-code-edit',
  templateUrl: './short-dial-code-edit.component.html',
  styleUrls: ['./short-dial-code-edit.component.scss']
})
export class ShortDialCodeEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: ShortDialCodeEntity;

  private readonly shortDialCodeRepo = ShortDialCodeRepository.getInstance<ShortDialCodeRepository>();

  matcher = new ShortDialCodeErrorStateMatcher();
  form: FormGroup;
  shortDialCode: ShortDialCodeEntity;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService,
    private readonly trans: TranslateService,
    private readonly em: EntityManager,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.shortDialCode = this.editingItem;
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      dial_code: [this.shortDialCode.dial_code, [Validators.required, NoWhitespaceValidator, Validators.maxLength(15)]],
      match_prefix: [this.shortDialCode.match_prefix],
      routing_data: [this.shortDialCode.routing_data],
      comment: [this.shortDialCode.comment, [NoWhitespaceValidator, Validators.maxLength(256)]],
    });

    this.form.valueChanges.subscribe(changes => {
      this.onFormValueChange();
    });
  }

  async submitForm(): Promise<void> {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.toastService.showError(_ti('public.message.data_invalid'));
        return;
      }
      const updateData = this.getChangeSet();
      if ('match_prefix' in updateData) {
        updateData.match_prefix = updateData.match_prefix ? 1 : 0;
      }

      const updateFields = Object.keys(updateData);
      const res = await this.shortDialCodeRepo.saveAttrs(this.shortDialCode, updateFields, updateData);
      this.toastService.showSuccess(_ti('public.message.update_success'));
      if (res && res.object_id) {
        this.shortDialCode = this.shortDialCodeRepo.getObjectById(res.object_id);
      }
      this.resetForm();
    } catch (error) {
      let errorMSG = _ti('public.message.update_failure');
      if (error) {
        if (error.message) {
          errorMSG = error.message;
        } else if (error.error && error.error.message) {
          errorMSG = error.error.message;
        }
      }
      this.toastService.showError(errorMSG);
    }
  }

  private getFormResetData(): any {
    return {
      dial_code: this.shortDialCode.dial_code,
      match_prefix: this.shortDialCode.match_prefix,
      routing_data: this.shortDialCode.routing_data,
      comment: this.shortDialCode.comment,
    };
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  changeCallDestination(): void {
    const virtualDid: DidEntity = this.em.getRepository('DidRepository').create() as DidEntity;
    virtualDid.number = this.shortDialCode.dial_code;
    virtualDid.action_office_hour = this.shortDialCode.routing_data;
    virtualDid.same_routing = 1;

    const data = {
      call_destination: virtualDid.action_office_hour,
      full_option: true
    };

    this.dialogService.openDialog2(
      DidConfigWizardModalComponent, { data },
      (res: { app?: any, masterDid?: DidEntity }) => {
        const app: IRoutingApp = res && res.app;
        if (app) {
          this.updateCallDestination(app);
        }
      }
    );
  }

  private updateCallDestination(app: IRoutingApp): any {
    const appParams = app.getAppParams();
    if (!appParams) {
      return;
    }

    const routeData = app.getAsJsonObject();
    this.form.get('routing_data').markAsDirty();
    this.form.get('routing_data').setValue(routeData);
  }

  get applicationName(): string {
    const application = this.form.get('routing_data').value ? this.form.get('routing_data').value.application : '';
    return application ? this.trans.instant(`routing_application.${application}.app_name`) : '';
  }

  get callDestinationInfo(): string {
    if (!this.form.get('routing_data').value) {
      return '';
    }

    const shortDialCode = _.cloneDeep(this.shortDialCode);
    shortDialCode.routing_data = this.form.get('routing_data').value;

    return (shortDialCode.tooltip[0] || {}).param_value || '';
  }
}
