import * as _ from 'lodash';

import { Component, OnInit, Inject, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { FlexTreeInput } from '../flex-tree-input/flex-tree-input';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'flex-select-tree-dialog',
  templateUrl: './flex-select-tree-dialog.html',
  styleUrls: ['./flex-select-tree-dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexSelectTreeDialog extends DialogComponentBase implements OnInit {
  @ViewChild('flexTreeInput') flexTreeInput: FlexTreeInput;

  dialogTitle = '';
  nodes: FlexSelectTreeNode[] = [];
  multiple = false;
  showCheckAll: boolean;
  selectedControl = new FormControl();
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.ok();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  constructor(
    private readonly dialogRef: MatDialogRef<FlexSelectTreeDialog>,
    cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.initialize();
  }

  private initialize(): void {
    if (this.data.options) {
      if (this.data.options.title) {
        this.dialogTitle = this.data.options.title;
      }

      if (this.data.options.nodes) {
        this.nodes = this.data.options.nodes;
      }

      this.showCheckAll = !!this.data.options.showCheckAll;

      if (this.data.options.multiple) {
        this.multiple = this.data.options.multiple;
      }

      if (this.data.options.selected || this.data.options.selected === 0) {
        this.selectedControl = new FormControl(this.data.options.selected);
      }
    }
  }

  ok(): void {
    const dialogResult = {
      selected: this.selectedControl.value,
      selectedText: this.flexTreeInput.getSelectedText()
    };

    this.dialogRef.close(dialogResult);
  }

  cancel(result?): void {
    this.dialogRef.close(result);
  }
}
