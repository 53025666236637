import * as _ from 'lodash';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, IFlexDialogConfig, MessageService, parseDateTime, localNow } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { DateTime } from 'luxon';
import { FlexGridConfig } from '@wephone-grid';

@Component({
  selector: 'app-hotel-room-log-modal',
  templateUrl: './hotel-room-log-modal.component.html',
  styleUrls: ['./hotel-room-log-modal.component.scss']
})
export class HotelRoomLogModalComponent extends DialogComponentBase implements OnInit {

  static modalConfig: IFlexDialogConfig = {
    size: 'l'
  };

  filters: any = {};
  config: FlexGridConfig = {
    searchable: false,
    columns: {
      called_number: { cellRenderer: 'numberRenderer' },
      start_time: { cellRenderer: 'dateTimeRenderer' },
      duration: { cellRenderer: 'durationRenderer' },
      in_out: { cellRenderer: 'rendersInout' },
    }
  };

  dialogTitle = _tk('hotelroom.content.call_log');
  dialogRightActions: DialogActionButton[] = [];

  constructor(
    public readonly configManager: ConfigManager,
    @Inject(MAT_DIALOG_DATA) private readonly data: { hotel_room_id: number },
    public readonly messageService: MessageService,
    dateAdapter: DateAdapter<Date>,
  ) {
    super();
    this.dateAdapter = dateAdapter;
  }

  ngOnInit(): void {
    super.ngOnInit();

    const now = localNow();
    this.filters = {
      display_type: 'by_date',
      start_dt: now.minus({ days: 7 }).toJSDate(),
      end_dt: now.toJSDate(),
      called_number: '',
      call_direction: '',
      room_id: this.data.hotel_room_id,
    };

    this.reloadClick();
  }

  reloadClick(): void {
    const newFilters = _.cloneDeep(this.filters);
    newFilters.start_dt = parseDateTime(newFilters.start_dt).toISODate();
    newFilters.end_dt = parseDateTime(newFilters.end_dt).toISODate();
    this.messageService.broadcastDatasource('hotelroom_call_history', newFilters);
  }

  // ngAfterViewInit(): void {
  //   this.reloadClick();
  // }

}
