import { Component, OnInit, ViewChild } from '@angular/core';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DynamicFilterSource, DialogService, ToastService, IFlexTableSidePannelOptions, regexSearch, IFlexTableConfig } from '@wephone-utils';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { ENTERPRISE_CRM } from '@wephone-core/constants/crm-config';
import { EnterpriseCrmEditComponent } from '@wephone/components/enterprise-crm/enterprise-crm-edit/enterprise-crm-edit.component';
import { CreateEnterpriseCrmModalComponent } from '@wephone/modals/create-enterprise-crm-modal/create-enterprise-crm-modal.component';
import { _tk, _ti } from '@wephone-translation';
import { FlexBasePage } from '@wephone-core-ui';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enterprise-crm-list',
  host: { class: 'flex-page-component' },
  templateUrl: './enterprise-crm-list.component.html',
  styleUrls: ['./enterprise-crm-list.component.scss'],
})
export class EnterpriseCrmListComponent extends FlexBasePage implements OnInit {
  private readonly enterpriseCrmRepo: EnterpriseCrmRepository = EnterpriseCrmRepository.getInstance();
  private logos: {[key: string]: any} = {};

  isBusinessHotel: boolean;
  enterpriseCrmForm: FormGroup;
  filterValue: string;
  tableConfig: IFlexTableConfig;
  dataSource: DynamicFilterSource;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: EnterpriseCrmEditComponent
  };
  @ViewChild('flexCrud') flexCrud;
  constructor(
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly authService: AuthenticationService,
    public dialogService: DialogService,
    public toast: ToastService,
    private router: Router,
  ) {
    super();

    this.enterpriseCrmRepo.findAll(false);
    const enterpriseCrmSource = this.enterpriseCrmRepo.dataSource<EnterpriseCrmEntity>();
    this.dataSource = new DynamicFilterSource(enterpriseCrmSource, this.filterFunc);

    this.tableConfig = {
      rowClass: this.getRowClass,
      columns: [
        {
          name: 'name',
          label: _tk('enterprise_crm.content.name'),
          searchable: true,
          sortable: true,
          sort: 'asc',
        },
        {
          name: 'type',
          label: _tk('enterprise_crm.content.type'),
          searchable: true,
          sortable: true,
        },
        {
          name: 'url',
          label: _tk('enterprise_crm.content.url'),
          searchable: false,
        },
        {
          name: 'is_active',
          label: _tk('enterprise_crm.content.is_default'),
          searchable: false,
          sortable: true,
        },
        // {
        //   name: 'use_agent_id',
        //   label: _tk('enterprise_crm.content.use_agent_id'),
        // }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: () => {
              return this.createNew();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: params => {
                return this.bulkDelete(params.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.enterpriseCrmForm = this.fb.group({
      filterValue: [this.filterValue]
    });

    this.enterpriseCrmForm.valueChanges.subscribe(this.onFormValueChange);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.isBusinessHotel = this.authService.isBusinessHotel();
  }

  getRowClass = (row: EnterpriseCrmEntity): string => {
    return row.is_authenticated ? 'mat-text-primary' : 'mat-text-warn';
  }

  private onFormValueChange = formValues => {
    this.filterValue = (formValues.filterValue || '').trim();

    this.dataSource.filter = this.filterValue;
    this.dataSource.onFilterChange();
  }

  private getNewNameCRM(): string {
    const baseName = this.translate.instant('enterprise_crm.content.new_crm');
    let newName = baseName;
    if (!this.enterpriseCrmRepo.nameExists(newName)) {
      return newName;
    }

    let subfix = 1;
    while (true) {
      newName = `${baseName} ${subfix}`;
      if (!this.enterpriseCrmRepo.nameExists(newName)) {
        break;
      }
      subfix += 1;
    }

    return newName;
  }

  private filterFunc = (item: EnterpriseCrmEntity) => {
    return this.filterPredicate(item, this.filterValue);
  }

  private filterPredicate(item: EnterpriseCrmEntity, filterString: string): boolean {
    if (!filterString) {
      return true;
    }
    return regexSearch(item.name.toLowerCase(), filterString.toLowerCase()) || regexSearch(item.type.toLowerCase(), filterString.toLowerCase());
  }

  async bulkDelete(enterpriseCrms: EnterpriseCrmEntity[]): Promise<any> {
    return this.dialogService.confirmDialog(
      this.translate.instant('dialogs.confirmation'),
      this.translate.instant('user.title.delete'),
      async () => {
        if (!enterpriseCrms) {
          return;
        }

        try {
          await this.enterpriseCrmRepo.bulkDelete(enterpriseCrms);
          this.flexCrud.closeSidePanel();
          this.toast.show(_ti('public.message.delete_success'));
          this.enterpriseCrmRepo.reload();
        } catch (e) {
          console.error('Delete Enterprise CRM erorr: ' + e);
          const errMsg = e && e.error && e.error.message || e && e.message || '';
          this.toast.showError(`${this.translate.instant('public.message.delete_failure')} ${errMsg}`);
        }
      }
    );
  }

  async createNew(): Promise<any> {
    try {
      const dialogRef = this.dialogService.openDialog2(CreateEnterpriseCrmModalComponent, { size: 'l' });
      const crmSelected = await dialogRef.afterClosed().toPromise();

      if (!crmSelected) {
        return undefined;
      }

      const newEnterpriseCrmData = {
        name: this.getNewNameCRM(),
        type: crmSelected.name
      };

      const newCrm: EnterpriseCrmEntity = await this.enterpriseCrmRepo.createEnterpriseCrmAndSave(newEnterpriseCrmData);

      this.toast.showSuccess(_ti('public.message.create_success'));

      this.router.navigate(['crm', 'enterprise-crm', newCrm.id]);
    } catch (e) {
      console.error('Create Enterprise CRM erorr: ' + e);
      const errMsg = e && e.error && e.error.message || e && e.message || '';
      this.toast.showError(_ti('public.message.create_failure') + ` ${errMsg}`);
      return false;
    }
  }

  getLogo(item: EnterpriseCrmEntity): string {
    if (this.logos[item.type] === undefined) {
      this.logos[item.type] = ENTERPRISE_CRM.crm_list[item.type] && ENTERPRISE_CRM.crm_list[item.type].logo || '';
    }

    if (!this.logos[item.type]) {
      return;
    }

    return `assets/images/${this.logos[item.type]}`;
  }
}
