import { Component } from '@angular/core';
import { DialogActionButton } from '@wephone-utils';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { DialogComponentBase } from '@wephone-core-ui';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { PhoneBookService, ImportPhoneBookResult } from '@wephone/services/phonebook.service';
import { FileSettings, getFileValidation } from '@wephone-utils/utils/file-util';

export enum PhoneBookImportSteps {
  UPLOAD = 'upload',
  RESULT = 'result'
}

@Component({
  selector: 'app-phonebook-import',
  templateUrl: './phonebook-import.component.html',
  styleUrls: ['./phonebook-import.component.scss']
})
export class PhonebookImportComponent extends DialogComponentBase {
  dialogTitle = _tk('phonebook.title.import');
  backButton: DialogActionButton =
    {
      action: () => {
        this.goPrev();
      },
      visible: () => {
        return this.isUploadResult;
      }
    };
  dialogRightActions: DialogActionButton[] = [];

  private steps = PhoneBookImportSteps;
  errors: string[] = [];
  step: string;
  importFile: any;
  importResult: ImportPhoneBookResult;
  fileSettings: FileSettings;

  constructor(
    private phoneBookService: PhoneBookService,
    private fileEntryService: FileEntryService,
    private settings: FlexIvrSettings
  ) {
    super();
    this.step = this.steps.UPLOAD;
    this.fileSettings = {
      extensions: ['.csv'],
      max_size: this.settings.max_upload_size * 1024 * 1024
    };
  }

  downloadSampleFile(): void {
    // const url = this.settings.getAbsoluteApiUrlv2('phonebook/download_sample_csv');
    const url: string = this.phoneBookService.getPhonebookDownloadSampleUrl();
    this.fileEntryService.download(url, 'sample-phonebooks.csv');
  }

  /**
   * @return: Promise {result:any} | {error:{message:string},status:string} 
   */
  async importCsv($event): Promise<any> {
    this.errors = [];
    this.importFile =
      $event.target && $event.target.files && $event.target.files.length ? $event.target.files[0] : undefined;
    if (!this.importFile) {
      console.error('No selected file');
      return;
    }

    try {
      if (this.importFile) {
        const fileValidation = getFileValidation(this.importFile, this.fileSettings);
        if (!fileValidation.valid) {
          this.importFile = undefined;
          throw new Error(fileValidation.message);
        }
      }

      this.importResult = await this.phoneBookService.importCsv(this.importFile);
    } catch (e) {
      console.error('Import CSV Failure', e);
      this.errors.push(e.message);
    } finally {
      this.importFile = undefined;
      this.goNext();
    }
  }

  private goNext(): void {
    this.step = this.steps.RESULT;
    this.updateDialogLayout();
  }

  goPrev(): void {
    this.step = this.steps.UPLOAD;
    this.updateDialogLayout();
  }

  get isUpload(): boolean {
    return this.step === this.steps.UPLOAD;
  }

  get isUploadResult(): boolean {
    return this.step === this.steps.RESULT;
  }
}
