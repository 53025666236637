import { sipTrunkGeneratePassword } from '@wephone-utils';
import { AbstractControl, FormControl } from '@angular/forms';
import * as _ from 'lodash';

export class UpdateSipTrunkCommon {
  generatePassword(control: FormControl | AbstractControl, limit: number = 12): void {
    const password = sipTrunkGeneratePassword(limit);
    control.markAsDirty();
    control.setValue(password);
  }

  formDataTransform(formData: { [key: string]: any }): { [key: string]: any } {
    const attrList = Object.keys(formData);
    for (const attr of attrList) {
      switch (attr) {
        case 'outgoing_enabled':
        case 'sip_ping_enable':
          formData[attr] = formData[attr] ? 1 : 0;
          break;
        case 'backup_number_enable':
          formData[attr] = formData[attr] ? 1 : 0;

          if (attrList.find(f => f === 'backup_number') && !formData[attr]) {
            delete formData.backup_number;
          }
          break;
        default:
          break;
      }
    }
    return formData;
  }

  clearValue(control: FormControl | AbstractControl): void {
    control.markAsDirty();
    control.setValue(null);
  }
}
