<div fxLayout="row" [ngClass]="{'enable-tooltip': enableTooltip}">
  <ng-container *ngIf="did.hasLinkedUser();else BlockRoutingApp">
    <!-- <ng-container *ngIf="did.master_did">
      <strong [innerHtml]="'did.content.tracking_number_of'|translate:{did_number: ''}"></strong>&nbsp;
      <a class="flex-link" (click)="gotoEditDidPage(did.master_did)">{{ did.master_did.display_number }}</a>
    </ng-container> -->

    <ng-container *ngIf="did.hasLinkedUser()">
      <div fxLayout="row" *ngIf="enableTooltip" [matTooltip]="did.routed_user.name">
        <strong [innerHtml]="'did.content.dedicated_number_for'|translate:{user_name: ''}"></strong>&nbsp;
        <div class="destination">
          <ng-container *ngTemplateOutlet="BlockLinkedUserDetail, context: {did: did}"></ng-container>
        </div>
      </div>
      <div fxLayout="row" *ngIf="!enableTooltip">
        <strong [innerHtml]="'did.content.dedicated_number_for'|translate:{user_name: ''}"></strong>&nbsp;
        <div>
          <ng-container *ngTemplateOutlet="BlockLinkedUserDetail, context: {did: did}"></ng-container>
        </div>
      </div>
      <ng-template #BlockLinkedUserDetail let-did="did">
        <a *ngIf="did.routed_user" class="flex-link" (click)="gotoEditUserOrHotelRoomPage(did.routed_user)">
          {{ did.routed_user.name }}</a>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #BlockRoutingApp>
    <ng-container *ngIf="enableTooltip">
      <div fxLayout="row" *ngIf="routingParams && routingParams.display_name;else BlockNoRouting"
        [matTooltip]="routingParams.display_name">
        <strong [attr.data-ci]="routingParams.app">{{ routingParams.label }}</strong>&nbsp;
        <div class="destination">
          <ng-container *ngTemplateOutlet="BlockDestinationDetail, context: {routingParams: routingParams}">
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!enableTooltip">
      <div fxLayout="row" *ngIf="routingParams && routingParams.display_name;else BlockNoRouting">
        <strong [attr.data-ci]="routingParams.app">{{ routingParams.label }}</strong>&nbsp;
        <div>
          <ng-container *ngTemplateOutlet="BlockDestinationDetail, context: {routingParams: routingParams}">
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #BlockDestinationDetail let-routingParams="routingParams">
      <div *ngIf="routingParams.url_edit;else BlockDisplayName">
        <a class="flex-link" [attr.data-ci]="routingParams.app" (click)="gotoEditPage(routingParams.url_edit)">
          {{ routingParams.display_name }}
        </a>
        <span *ngIf="routingParams.object">
          &nbsp;(<a class="flex-link" [attr.data-ci]="'sipphone_owner'"
            (click)="gotoEditUserOrHotelRoomPage(routingParams.object)">{{ routingParams.object.name || routingParams.object.display_room_number }}</a>)
        </span>
      </div>
    </ng-template>

    <ng-template #BlockDisplayName>
      <span [attr.data-ci]="routingParams.app">{{ routingParams.display_name }}</span>
    </ng-template>

    <ng-template #BlockNoRouting>
      <span>{{ 'did.content.not_config'|translate }}</span>
    </ng-template>
  </ng-template>
</div>
