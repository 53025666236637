import { Injectable } from '@angular/core';
import { FlexIvrSettings } from './flexivr_settings';
import * as querystring from 'querystring';
import { HttpEngine } from './http_engine';
import { parseDateTime } from '@wephone-utils';
import { DateTime } from 'luxon';
import { RCAnalysisState, RCTranscriptionState, TranscriptAnalysisData } from '@wephone/pages/recording-call/recording-call.i';
import * as _ from 'lodash';
import { EntityManager } from './entity_manager';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { MailboxType } from '@wephone-core/model/entity/voicemail_box';
export interface CallHistoryItem {
  id: number;
  is_missed: number;
  is_voicemail: number;
  agent_name?: string;
  start_dt: string;
  hangup_dt: string;
  called_number: string;
  calling_number: string;
  connected_dt: string;
  user_direction: number;
  voicemail_public_id?: string;
  is_read: number;
  readers: number[];
}

export interface MissedCallVoicemail {
  public_id: string;
  web_status: number;
  receive_dt: string;
  cdr_id: number;
  is_read: number;
  readers: number[];
}
export interface MissedCallInfo {
  callInfo: {
    wait_duration: number;
  };
  callHistory: CallHistoryItem[];
  voicemailMessages: MissedCallVoicemail[];
}

export interface MissedCallDetail {
  start_time?: DateTime;
  missed_call_reason?: string;
  agent_list?: number[];
}

enum CallDirection {
  INBOUND = 1,
  OUTBOUND = 2,
}

enum RecallState {
  DEFAULT = 0,
  RECALL_REQUESTED = 1,
  RECALL_REFUSED = 2
}

enum MissedCallReason {
  DEFAULT = 0,
  SERVICE_CLOSED = 1,
  NO_AGENT_AVAILABLE = 2,
}

export interface CallDetail {
  id: number;
  calling_number: string;
  called_number: string;
  start_dt: string;
  is_voicemail: number;
  is_missed: number;
  public_id: string;
  user_direction: CallDirection;
}

export interface IVoicemailInfo {
  id: number;
  is_archived: number;
  is_read: number;
  file_entry_ready: number;
  readers: number[];
  file_public_id: string;
}

// export interface ICallHistoryItem {
//   is_missed: number;
//   agent_name?: string;
//   start_dt: string;
//   hangup_dt: string;
//   connected_dt: string;
//   user_direction: number;
//   called_number: string;
//   calling_number: string;
// }

// export interface IVoicemailMessage {
//   public_id: string;
//   web_status: number;
//   receive_dt: string;
// }

export interface ICallDetailInfo {
  id: number;
  public_id: string;
  vm_message_id?: number;
  vm_mailbox_type?: MailboxType;
  vm_message_is_archived?: number;
  vm_message_is_read?: number;
  vm_message_readers?: number[];
  is_missed: number;
  is_voicemail: number;
  is_outgoing: number;
  duration: number;
  start_dt: string;
  client_number: string;
  agent_number: string;
  client_name?: string;
  calling_number_real?: string;
  queue_ids: number[];
  mc_ivr_menu_id?: number;
  mc_user_id?: number;
  recording_call_id?: number;
  file_public_id?: string;
  file_entry_ready?: number;
  transcription_state?: RCTranscriptionState;
  transcription?: string;
  analysis_state?: RCAnalysisState;
  analysis_result?: TranscriptAnalysisData;
  wait_duration: number;
  client_hangup_first: 1 | 0;
  qualification: number[];
  qualification_displayed?: string;
  has_qualification: number;
  qualification_comment: string;
  out_qualification_id: number;
  in_qualification_id: number;
  handled_user_ids: number[];
  call_history?: CallHistoryItem[];
  voicemail_messages?: MissedCallVoicemail[];
}

export interface ExportDataParams {
  start_dt: string;
  end_dt: string;
  'groups[]'?: number[];
  'agents[]'?: number[];
  include_contact?: number; // For exportint calls
  include_call_id?: number; // For exportint calls
}

@Injectable()
export class CdrService {
  static URL_FIND_CALLS = 'cdr/search/{{cdr_public_id}}';
  static URL_GET_MISSED_CALL_DETAIL = 'cdr/missed-call-detail/{{cdr_id}}';
  static URL_GET_MISSED_CALL_INFO = 'cdr/missed-call-history';
  static URL_GET_HISTORY_CALL_INFO = 'cdr/call-history';
  static URL_GET_HANDLED_CALL_INFO = 'cdr/handled_call_info';
  static URL_GET_CALL_INFO = 'cdr/call_info';
  static UPDATE_CALL_QUALITY_EVALUATION = '/cdr/update_quality_note';
  // static URL_GET_DATA_CONNECTION = '/callcenter/data_export_csv_connections';
  static UPDATE_CALL_OUTBOUND_VOICEMAIL = '/cdr/mark_as_outbound_voicemail';
  static UPDATE_CALL_OUTBOUND_AM_INFO = '/callcenter/outcallcampaign_item/update_answer_machine_info';
  static URL_GET_DATA_CONNECTION_V3 = '/callcenter/data_export_csv_connections_v3';
  static URL_GET_DATA_CALL = '/callcenter/data_export_csv_calls';
  static URL_GET_IVR_STATS_TOTAL_NUMBER = 'ivr_log/ivr_get_total_calls';

  constructor(
    private readonly httpEngine: HttpEngine,
    private readonly em: EntityManager
  ) { }

  get_missed_call_info(missedCallId: number): Promise<MissedCallInfo> {
    const url = CdrService.URL_GET_MISSED_CALL_INFO;
    return this.httpEngine.apiGetV2(url, { id: missedCallId });
  }

  get_history_call_info(number: string): Promise<any> {
    const url = CdrService.URL_GET_HISTORY_CALL_INFO;
    return this.httpEngine.apiGetV2(url, { number });
  }

  async getCallInfo(cdrId?: number, cdrPublicId?: string): Promise<ICallDetailInfo> {
    const url = CdrService.URL_GET_CALL_INFO;

    const params: any = {};
    if (cdrId) {
      params.cdr_id = cdrId;
    }

    if (cdrPublicId) {
      params.cdr_public_id = cdrPublicId;
    }

    if (_.isEmpty(params)) {
      console.error('No cdr_id or cdr_public_id given');
      return;
    }

    const ret: ICallDetailInfo = await this.httpEngine.apiGetV2(url, params);

    if (ret.qualification) {
      ret.qualification_displayed = (this.em.getRepository('QualificationRepository') as QualificationRepository).getDisplayedQualification(ret.qualification);
    }

    return ret;
  }

  exportDataCalls(params: ExportDataParams): void {
    const url: string = FlexIvrSettings.getInstance().getEnterpriseUrl(CdrService.URL_GET_DATA_CALL);
    return this._exportData(url, params);
  }

  exportDataConnections(params: ExportDataParams): void {
    const url: string = FlexIvrSettings.getInstance().getEnterpriseUrl(CdrService.URL_GET_DATA_CONNECTION_V3);
    return this._exportData(url, params);
  }

  private _exportData(url: string, params: any): void {
    const queryString = `${url}?${querystring.stringify(params)}`;
    window.open(queryString, '_blank');
  }

  getTotalIvrCalls(ivrId: number, statsType: 'ivr' | 'campaign', startDt: Date, endDt: Date): Promise<any> {
    if (!ivrId || !statsType || !startDt || !endDt) {
      console.warn('Some params are empty');
      return;
    }

    if (!(startDt instanceof Date) || !(endDt instanceof Date)) {
      console.warn('Parameters startDt and endDt must be Date Object');
      return;
    }

    const start_dt = parseDateTime(startDt).toISO().substr(0, 10);
    const end_dt = parseDateTime(endDt).toISO().substr(0, 10);

    return this.httpEngine.apiGetV2(CdrService.URL_GET_IVR_STATS_TOTAL_NUMBER, { object_id: ivrId, stats_type: statsType, start_dt, end_dt });
  }

  updateCallQualityEvaluation(data: { id: number; callqueue_session_id?: number; quality_note: number }): Promise<any> {
    return this.httpEngine.apiPostV2(CdrService.UPDATE_CALL_QUALITY_EVALUATION, data);
  }

  updateCallOutboundVoicemail(callId: number): Promise<any> {
    return this.httpEngine.apiPostV2(CdrService.UPDATE_CALL_OUTBOUND_VOICEMAIL, {
      call_id: callId
    });
  }

  updateCallOutboundAMInfo(campaignItemId: number): Promise<any> {
    return this.httpEngine.apiPostV2(CdrService.UPDATE_CALL_OUTBOUND_AM_INFO, {
      campaign_item_id: campaignItemId
    });
  }

  async getMissedCallDetail(cdrId: number): Promise<any> {
    const rs = await this.httpEngine.apiGetV2(CdrService.URL_GET_MISSED_CALL_DETAIL.replace('{{cdr_id}}', `${cdrId}`));

    const ret: MissedCallDetail = {
      start_time: rs.start_dt && parseDateTime(rs.start_dt),
      missed_call_reason: rs.missed_call_reason,
      agent_list: rs.hunt_history
    };

    return ret;
  }

  async findCall(cdrPublicId: string): Promise<CallDetail[]> {
    const resp = await this.httpEngine.apiGetV2(CdrService.URL_FIND_CALLS.replace('{{cdr_public_id}}', cdrPublicId));
    return resp && resp.map(x => _.extend(x, {
      start_dt: x.start_dt && DateTime.fromJSDate(new Date(x.start_dt))
    }));
  }
}
