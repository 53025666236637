import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { _ti } from '@wephone-translation';
import { DidEntity } from '@wephone-core/model/entity/did';
import { Deferred } from 'ts-deferred';
import { DialogService } from '@wephone-utils';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';

@Injectable()
export class ConferenceService {
  // Constructor
  constructor(
    private dialogService: DialogService,
  ) { }

  async getConfirmDeleteRoutingItems(items: ConferenceEntity[]): Promise<boolean> {
    let msg: string = _ti('user.title.delete');
    const msgs: string[] = [];

    const routedDids: DidEntity[] = [];
    const routedCrmRoutings: CrmRoutingRuleEntity[] = [];
    for (const item of items) {
      for (const routedDid of item.routedDids) {
        if (!_.includes(routedDids, routedDid)) {
          routedDids.push(routedDid);
        }
      }

      for (const routedCrmRouting of item.routedCrmRoutings) {
        if (!_.includes(routedCrmRoutings, routedCrmRouting)) {
          routedCrmRoutings.push(routedCrmRouting);
        }
      }
    }

    if (routedDids.length) {
      let conferenceObjects: ConferenceEntity[] = items.filter(c => c.routedDids.length);
      conferenceObjects = _.uniqBy(conferenceObjects, q => q.id);

      msgs.push(_ti('did.message.confirm_delete_with_dependent_did', {
        dependent_numbers: routedDids.map(x => x.display_number).join(', '),
        objects: conferenceObjects.map(x => x.name).join(', '),
      }));
    }
    if (routedCrmRoutings.length) {
      let conferenceObjects: ConferenceEntity[] = items.filter(c => c.routedCrmRoutings.length);
      conferenceObjects = _.uniqBy(conferenceObjects, q => q.id);

      msgs.push(_ti('crm_routing.message.confirm_delete_with_dependent_items', {
        crm_routing_names: routedCrmRoutings.map(x => x.name).join(', '),
        objects: conferenceObjects.map(x => x.name).join(', '),
      }));
    }

    const ret = new Deferred<boolean>();
    const canDelete = !msgs.length;

    if (!canDelete) {
      msgs.push(_ti('routing_application.message.cannot_clear_routing'));
      msg = msgs.join('\n');

      await this.dialogService.showAlert(_ti('dialogs.warning'), msg);

      ret.resolve(canDelete);
      return ret.promise;
    }

    await this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      msg,
      () => {
        ret.resolve(canDelete);
      }
    );
    return ret.promise;
  }
}
