import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ToastService,
  DialogService,
  EditingComponent,
} from '@wephone-utils';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import * as _ from 'lodash';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CalendarFormInput } from '../calendar/calendar-form-input/calendar-form-input.component';
import { IOpeningCalendarEditDialogConfig } from '../calendar/calendar-types';
import { _tk, _ti } from '@wephone-translation';
import { OpeningHourSpecialDateEntity } from '@wephone-core/model/entity/openinghour_special_date';
import { OpeningHourEntity } from '@wephone-core/model/entity/openinghour';

@Component({
  selector: 'app-opening-hour-calendar-edit',
  templateUrl: './opening-hour-calendar-edit.component.html',
  styleUrls: ['./opening-hour-calendar-edit.component.scss']
})
export class OpeningHourCalendarEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: OpeningHourCalendarEntity;
  @Input() showSideSteps: boolean;
  private readonly calendarRepo = OpeningHourCalendarRepository.getInstance<OpeningHourCalendarRepository>();
  _showSideSteps = true;
  calendarForm: FormGroup;
  calendar: OpeningHourCalendarEntity;
  config: IOpeningCalendarEditDialogConfig = {
    editCustomData: false,
    showUsedObjects: true,
    editName: true,
    editGroup: true
  };
  @ViewChild('calendarEditor') calendarEditor: CalendarFormInput;

  constructor(
    public translate: TranslateService,
    public toast: ToastService,
    public dialogService: DialogService,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    if (this.showSideSteps !== undefined) {
      this._showSideSteps = this.showSideSteps;
    }
    this.calendar = _.cloneDeep(this.editingItem);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.calendarForm = this.fb.group({
      calendar: [this.calendar]
    });

    this.addSubscription(
      this.calendarForm.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  private getFormResetData(): Object {
    return {
      calendar: this.calendar
    };
  }

  // async confirmSaving(): Promise<boolean> {
  //   const df = new Deferred<boolean>();
  //   await this.dialogService.confirmDialog(
  //     this.translate.instant('dialogs.confirmation'),
  //     this.translate.instant('opening_hour_calendar.message.confirm_used_saving'),
  //     () => {
  //       df.resolve(true);
  //     }
  //   );
  //   return df.promise;
  // }

  async submitForm(): Promise<void> {
    // if (this.calendarEditor.hasUsedItem) {
    //   const ok: boolean = await this.confirmSaving();
    //   if (!ok) {
    //     return;
    //   }
    // }
    const calendarUpdateed = this.calendarForm.get('calendar').value as OpeningHourCalendarEntity;
    const isValid: boolean = await this.calendarEditor.isValidCalendar(calendarUpdateed);
    if (!isValid) {
      this.toast.showError(_ti('public.message.data_invalid'));
      return;
    }

    const extra_params = {
      openinghours: (calendarUpdateed.openinghours || []).map((i: OpeningHourEntity) => i.dumpObjectData()),
      openinghours_special_date: (calendarUpdateed.openinghours_special_date || []).map((i: OpeningHourSpecialDateEntity) => i.dumpObjectData())
    };

    // for (let i = 0; i < extra_params.openinghours.length; i++) {
    //   if (extra_params.openinghours[i].day_from === extra_params.openinghours[i].day_to) {
    //     if (extra_params.openinghours[i].start_time === extra_params.openinghours[i].end_time) {
    //       this.toast.showError(_ti('opening_hour_calendar.message.dulicate_time'));
    //       return;
    //     }
    //   }
    // }

    try {
      const returnedCalendar = await this.calendarRepo.save(calendarUpdateed, extra_params);
      this.toast.show(_ti('public.message.update_success'));
      await EntityManager.getInstance().reloadRepositories([
        'opening_hour_calendar',
        'opening_hour',
        'opening_hour_special_date'
      ]);
      this.calendar = _.cloneDeep(this.calendarRepo.getObjectById(+returnedCalendar.id));
      this.resetForm();
    } catch (error) {
      let msg = _ti('public.message.create_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.toast.showError(msg);
    }
  }

  resetForm(): void {
    this.calendarForm.reset(this.getFormResetData());
    this.calendarForm.markAsPristine();
    this.onFormValueChange();
  }

  formHasChanged(): boolean {
    return this.calendarForm.dirty;
  }
}
