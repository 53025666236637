import * as _ from 'lodash';

import { Component, Inject, OnInit, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToastService, DialogActionButton, Colors, IFlexDialogConfig } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'sipphone-create-modal',
  templateUrl: './sipphone-create-modal.component.html',
  styleUrls: ['./sipphone-create-modal.component.scss']
})
export class SipPhoneCreateModal extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('sipphone.title.create');
  dialogLeftActions: DialogActionButton[] = [
    {
      id: 'addMore',
      label: _tk('sipphone.content.add_more'),
      action: () => {
        this.addNewRow();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    },
  ];
  dialogRightActions: DialogActionButton[] = [
    {
      id: 'validate',
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  public form: FormGroup;
  rangeExtensions = [];

  constructor(
    private readonly fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<SipPhoneCreateModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public readonly toast: ToastService,
    private readonly el: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      itemRows: this.fb.array([this.initItemRows()])
    });

    this.form.valueChanges.subscribe(changes => {
      this.rangeExtensions = changes.itemRows;
    });
  }

  get itemRowsCtrl(): FormArray {
    return this.form.get('itemRows') as FormArray;
  }

  initItemRows(): any {
    return this.fb.group({
      from: ['', [Validators.min(100), Validators.required]],
      to: ['', [Validators.min(100), Validators.required]]
    });
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      'form .mat-input-element.ng-invalid'
    );

    firstInvalidControl.focus();
  }

  submit(): void {
    if (!this.form.get('itemRows').value.length) {
      this.toast.showError(_ti('form.validator.data_invalid'));
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      return;
    }

    for (const control of this.itemRowsCtrl.controls) {
      if (control.get('from').value >= control.get('to').value) {
        this.toast.showError(_ti('sipphone.validator.end_range_extensions_must_greater_than_start_range_extensions'));
        return;
      }
    }

    for (const control of this.itemRowsCtrl.controls) {
      for (const otherControl of this.itemRowsCtrl.controls) {
        if (control !== otherControl) {
          if ((control.value['from'] === otherControl.value['from'])
            || (control.value['to'] === otherControl.value['to'])) {
            this.toast.showError(_ti('sipphone.validator.range_extensions_duplicate'));
            return;
          }
        }
      }
    }

    this.closeModal(this.rangeExtensions);
  }

  public closeModal(result?): void {
    this.dialogRef.close(result);
  }

  addNewRow(): void {
    this.itemRowsCtrl.push(this.initItemRows());
  }

  deleteRow(index: number): void {
    this.itemRowsCtrl.removeAt(index);
  }
}
