import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { IvrMenuEditComponent } from '@wephone/components/ivr-menu/ivr-menu-edit/ivr-menu-edit.component';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import { Colors, DialogActionButton } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';

@Component({
  selector: 'ivr-menu-create-modal',
  templateUrl: './ivr-menu-create-modal.component.html',
  styleUrls: ['./ivr-menu-create-modal.component.scss']
})
export class IvrMenuCreateModal extends DialogComponentBase {
  @ViewChild('ivrmenuInput') ivrmenuInput: IvrMenuEditComponent;

  dialogTitle = _tk('ivrmenu.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.createIvrMenu();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];
  ivrmenu: IvrCustomMenuEntity;

  constructor(
    private readonly _em: EntityManager,
    public dialogRef: MatDialogRef<IvrMenuCreateModal>,
  ) {
    super();
    this.ivrmenu = this._em
      .getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository')
      .create() as IvrCustomMenuEntity;
  }

  async createIvrMenu(): Promise<any> {
    const retData = await this.ivrmenuInput.saveIvrMenu();
    if (!_.isEmpty(retData)) {
      this.successToast(_ti('ivrmenu.message.create_success'));
      this.dialogRef.close({ id: retData && retData.id });
    }
  }

  public cancel(result?): void {
    this.dialogRef.close(result);
  }
}
