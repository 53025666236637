import * as _ from 'lodash';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ToastService,
  EditingComponent,
  DialogService,
} from '@wephone-utils';
import { UserRepository } from '@wephone-core/model/repository/user';
import { UserEntity } from '@wephone-core/model/entity/user';
import { UserService } from '@wephone/services/user.service';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { UserApiComponent } from '@wephone/components/user/user-api/user-api.component';
import { UserGeneralComponent } from '@wephone/components/user/user-general/user-general.component';
import { UserAccessRightComponent } from '@wephone/components/user/user-access-right/user-access-right.component';
import { UserCallcenterComponent } from '@wephone/components/user/user-callcenter/user-callcenter.component';
import { _tk, _ti } from '@wephone-translation';
import { UserSipComponent } from '../user-sip/user-sip.component';
import { FormBuilder } from '@angular/forms';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { OpeningHourCalendarSelectComponent } from '@wephone/components/opening-hour-calendar-select/opening-hour-calendar-select.component';
import { AgentEntity } from '@wephone-core/model/entity/agent';
import { VoiceMailBoxEntity } from '@wephone-core/model/entity/voicemail_box';
import { UserDedicatedNumberModalComponent } from '@wephone/modals/user-dedicated-number-modal/user-dedicated-number-modal.component';
import { DidEntity } from '@wephone-core/model/entity/did';
import { Router } from '@angular/router';
import { DidRepository } from '@wephone-core/model/repository/did';
import { UserCallReceptionComponent } from '@wephone-common/user';
import { AgentService } from '@wephone/services/agent.service';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { Deferred } from 'ts-deferred';

export enum permissions {
  eavesdrop = 'EAVESDROP',
  recording = 'RECORDING',
  voicemail = 'VOICEMAIL',
  stats = 'STATS',
  livepage = 'LIVEPAGE',
}

@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent extends EditingComponent implements OnInit {
  ALLOW_SCROLL = true;
  @Input() editingItem: UserEntity;

  @ViewChild('generalUserEditor') generalUserEditor: UserGeneralComponent;
  @ViewChild('sipUserEditor') sipUserEditor: UserSipComponent;
  @ViewChild('apiUserEditor') apiUserEditor: UserApiComponent;
  @ViewChild('accessRightUserEditor') accessRightUserEditor: UserAccessRightComponent;
  @ViewChild('callCenterUserEditor') callCenterUserEditor: UserCallcenterComponent;
  @ViewChild('openingHourCalendarSelect') openingHourCalendarSelect: OpeningHourCalendarSelectComponent;
  @ViewChild('userCallReception') userCallReception: UserCallReceptionComponent;
  @ViewChild('mainArea') mainArea;

  user: UserEntity;
  tabSelectedIndex: number;
  isAuthorAdmin: boolean;
  private originUser: UserEntity;
  private originAgent: AgentEntity;
  private originMailBox: VoiceMailBoxEntity;

  stepLabel: any = {
    _1general: 'user.heading.general',
    _2call_transfer: 'account_basic_settings.content.call_transfer',
    _3work_calendar: 'opening_hour_calendar.menu.title',
    _4agent_numbers: 'user.heading.numbers',
    _5telephone: 'user.heading.telephone',
    _6api: 'manage_integration.api',
    _7access_rights: 'user.heading.access_rights',
  };

  steps: string[] = [];
  hasRemoveDedicatedNumberPermission = false;

  constructor(
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly agentService: AgentService,
    private readonly authService: AuthenticationService,
  ) {
    super();
    EntityManager.getInstance().getRepository('TagRepository').findAll(false);
    EntityManager.getInstance().getRepository('AgentRepository').findAll();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    console.log('ngOnInit UserEditComponent', this.editingItem);
    this.isAuthorAdmin = this.authService.isAdmin();
    this.hasRemoveDedicatedNumberPermission = this.authService.isAdmin();
    this.initialize(this.editingItem);
  }

  initialize(user: UserEntity): void {
    this.setOriginUserInfo(user);
    this.initFormGroup();
    this.setSteps();
  }

  setSteps(): void {
    this.steps = [
      '_1general'
    ];
    if (!this.user.isWatcher()) {
      this.addStep([
        '_2call_transfer',
        '_3work_calendar',
        '_4agent_numbers'
      ]);

      if (this.isAuthorAdmin) {
        this.addStep([
          '_5telephone',
          '_6api'
        ]);
      }
    }

    if (this.isAuthorAdmin &&
      this.user.isWatcher() || this.user.isUser() || this.user.isAgent()
    ) {
      this.addStep([
        '_7access_rights'
      ]);
    }
  }

  private addStep(steps: string[]): void {
    for (const step of steps) {
      if (!_.includes(this.steps, step)) {
        this.steps.push(step);
      }
    }
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      availability: [this.agent.availability],
      calendar: [this.agent.working_calendar],
      updateOpeningHour: [false],
    });
  }

  resetUserValue(): void {
    this.user = _.cloneDeep(this.originUser);
    this.user.mailbox = this.originMailBox && _.cloneDeep(this.originMailBox);
    this.agent = _.cloneDeep(this.originAgent);
  }

  private setOriginUserInfo(user: UserEntity): void {
    this.user = user;
    this.originUser = _.cloneDeep(user);
    this.originMailBox = user.mailbox && _.cloneDeep(user.mailbox);
    this.originAgent = user.agent && _.cloneDeep(user.agent) as any;
  }

  onChangeOpeningCalendar($event: {
    updateOpeningHour?: boolean,
    availability?: number,
    calendar?: OpeningHourCalendarEntity
  }): void {
    console.log('onChangeOpeningCalendar', $event);
    if ($event.hasOwnProperty('availability')) {
      const availability = $event.availability;
      this.form.get('availability').setValue(availability);
      this.form.get('availability').markAsDirty();
      this.form.get('availability').updateValueAndValidity();
    }

    if ($event.hasOwnProperty('calendar')) {
      this.form.get('calendar').setValue($event.calendar);
      this.form.get('calendar').markAsDirty();
      this.form.get('calendar').updateValueAndValidity();
    }

    if ($event.hasOwnProperty('updateOpeningHour')) {
      this.form.get('updateOpeningHour').setValue(true);
      this.form.get('updateOpeningHour').markAsDirty();
      this.form.get('updateOpeningHour').updateValueAndValidity();
    }

    this.onFormValueChange();
  }

  formHasChanged(): boolean {
    const editors: any[] = [
      this.apiUserEditor,
      this.generalUserEditor,
      this.sipUserEditor,
      this.accessRightUserEditor,
      this.userCallReception,
      this.callCenterUserEditor
    ];

    let otherFormChanged = false;
    for (const editor of editors) {
      if (editor && editor.formHasChanged()) {
        otherFormChanged = true;
        break;
      }
    }

    return otherFormChanged || super.formHasChanged();
  }

  resetForm(): void {
    this.resetUserValue();
    for (const editor of [
      this.apiUserEditor,
      this.generalUserEditor,
      this.sipUserEditor,
      this.accessRightUserEditor,
      this.userCallReception,
      this.callCenterUserEditor
    ]) {
      if (editor) {
        editor.resetForm();
      }
    }

    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  get agent(): AgentEntity {
    return this.user.agent as AgentEntity || new AgentEntity();
  }

  set agent(agent: AgentEntity) {
    this.user.agent = agent;
  }

  private getFormResetData(): any {
    return {
      availability: this.agent.availability,
      calendar: this.agent.working_calendar,
      updateOpeningHour: false,
    };
  }

  /**
   * Get all fields & values updated
   */
  private getUpdatedData(): any {
    const updatedData: any[] = [];
    // GENERAL + API + SIP
    for (const editor of [
      this.apiUserEditor,
      this.userCallReception,
      this.generalUserEditor,
      this.sipUserEditor]) {
      if (editor) {
        updatedData.push(editor.getChangeSet());
      }
    }

    // SELF
    updatedData.push(this.getChangeSet());
    console.log('updatedData', updatedData);

    // ACCESS-RIGHT
    const accessRightData = this.accessRightUserEditor ? this.accessRightUserEditor.getChangeSet() : {};

    if (!_.isEqual(accessRightData, {})) {
      // Save Access right
      const access_rights: string[] = this.user.access_granted || [];
      for (const right of Object.keys(accessRightData)) {
        // add right
        if (accessRightData[right] && access_rights.indexOf(right) === -1) {
          access_rights.push(right);
        }
        // remove right
        if (!accessRightData[right] && access_rights.indexOf(right) !== -1) {
          access_rights.splice(access_rights.indexOf(right), 1);
        }
      }

      updatedData.push({ access_granted: access_rights });
    }

    // CALL-CENTER
    const callCenterData: any = this.callCenterUserEditor ? this.callCenterUserEditor.getChangeSet() : {};

    if (!_.isEqual(callCenterData, {})) {
      // Save default queue
      if (callCenterData.default_queue_id && callCenterData.default_queue_id.id) {
        updatedData.push({ default_queue_id: callCenterData.default_queue_id });
      }
    }
    return updatedData;
  }

  private getUpdatedFields(): any {
    const updatedData: any = this.getUpdatedData();

    // Save General & API
    const fields: any = {
      calendar: null,
      availability: null,
      uncond_transfer_enabled: 'uncond_transfer_state',
      uncond_transfer_state: null,
      uncond_transfer_dest: null,
      busy_transfer_enabled: 'busy_transfer_state',
      busy_transfer_state: null,
      busy_transfer_dest: null,
      noanswer_transfer_enabled: 'noanswer_transfer_state',
      noanswer_transfer_state: null,
      noanswer_transfer_dest: null,
      alias: null,
      email: null,
      firstname: null,
      lastname: null,
      phone: 'phonenum',
      user_type: null,
      voicemail_enabled: 'vm_enabled',
      voicemail_welcome_file: 'voicemail_welcome_file_id',
      vm_timeout: null,
      wait_timeout_config: null,
      recording_mode: null,
      callout_enabled: null,
      calling_profile_id: null,
      calling_number_id: null,
      secretary_did_id: null,
      sipphone_ids: null,
      group_ids: null,
      tags: null,
      // voicemail_in_attachement: null,
      team_id: null,
      access_granted: null,
      default_queue_id: null,
      voicemail_by_mail_enabled: null,
      phoneMode: 'phone_mode',
      auto_pickup: null,
    };

    const obj_data_user: any = {};
    const update_user_fields: string[] = [];

    // const obj_data_call_reception: any = {};

    const obj_data_agent: any = {};
    const update_agent_fields: string[] = [];

    let updateOpeningHour = false;
    for (const data of updatedData) {
      if (data['updateOpeningHour']) {
        updateOpeningHour = true;
      }
      for (const field of Object.keys(data)) {
        // values
        let updated_value: any = null;

        switch (field) {
          case 'recording_mode':
          case 'voicemail_enabled':
          case 'voicemail_by_mail_enabled':
          // case 'voicemail_in_attachement':
          case 'callout_enabled':
          case 'uncond_transfer_enabled':
          case 'busy_transfer_enabled':
          case 'noanswer_transfer_enabled':
          case 'auto_pickup':
            updated_value = data[field] ? 1 : 0;
            break;
          case 'calendar':
          case 'default_queue_id':
          case 'team_id':
          case 'calling_profile_id':
          case 'calling_number_id':
          case 'secretary_did_id':
          case 'voicemail_welcome_file':
            updated_value = data[field] ? data[field].id : null;
            break;
          case 'group_ids':
            if (data[field].length) {
              updated_value = [];
              for (const v of data[field]) {
                updated_value.push(v.id);
              }
            }
            break;
          case 'tags':
            if (data[field].length) {
              updated_value = [];
              for (const v of data[field]) {
                updated_value.push(v.id);
              }
            }
            break;
          default:
            updated_value = data[field];
            break;
        }

        const campaign_field = fields[field] ? fields[field] : field;
        if (field in fields && ['calendar', 'availability', 'phoneMode', 'auto_pickup'].indexOf(field) === -1) {
          // update mapped fields
          obj_data_user[campaign_field] = updated_value;
          update_user_fields.push(campaign_field);
        }

        if (['calendar', 'availability', 'phoneMode', 'auto_pickup'].indexOf(field) > -1) {
          obj_data_agent[campaign_field] = updated_value;
          update_agent_fields.push(campaign_field);
        }
      }
    }
    return {
      updateOpeningHour,
      obj_data_agent,
      obj_data_user,
      update_user_fields,
      update_agent_fields
    };
  }

  onChangeCallQueues($event): void {
    // console.log('onChangeCallQueues', $event);
    this.sipUserEditor.initialize(); // In order to reset list of calling-number
  }

  // tslint:disable-next-line:cyclomatic-complexity
  async submitForm(): Promise<any> {
    try {
      let mustResetForm = false;
      const updatedFields = this.getUpdatedFields();
      const obj_data_agent = updatedFields.obj_data_agent;
      const obj_data_user = updatedFields.obj_data_user;
      // const callReception = updatedFields.obj_data_call_reception;

      const update_agent_fields = updatedFields.update_agent_fields;
      const update_user_fields = updatedFields.update_user_fields;

      const updateOpeningHour = updatedFields.updateOpeningHour;
      if (updateOpeningHour) {
        const ret = await this.openingHourCalendarSelect.updateCalendar();
        if (!ret) {
          // this.toast.showError(_ti('public.message.update_failure'));
          // return Promise.resolve();
          throw new Error(_ti('public.message.update_failure'));
        }
      }

      let returneduser: UserEntity;
      if (update_agent_fields.length > 0) {
        if (!this.openingHourCalendarSelect.isValid()) {
          throw new Error(_ti('public.message.data_invalid'));
        }
        if (update_agent_fields.includes('availability') || update_agent_fields.includes('calendar')) {
          await this.userService.updateAgentAvailability(this.user.id, obj_data_agent['availability'], obj_data_agent['calendar']);
        }

        if (update_agent_fields.includes('phone_mode')) {
          await this.agentService.change_number(this.agent.id, obj_data_agent['phone_mode'], undefined, obj_data_agent['auto_pickup']);
        }

        returneduser = this.em.getRepository('UserRepository').getObjectById(this.user.id);
        mustResetForm = true;

        // if (update_user_fields.length === 0) {
        //   this.toast.showSuccess(_ti('public.message.update_success'));
        //   return;
        // }
      }

      if (update_user_fields.length > 0) {
        // update campaign
        const user: UserEntity = UserRepository.getInstance().create() as UserEntity;

        user.id = this.user.id;
        user.setObjectData(obj_data_user, false);

        // validation
        for (const editor of [this.apiUserEditor, this.generalUserEditor, this.sipUserEditor]) {
          if (editor && !editor.isValid(update_user_fields)) {
            // this.toast.showError(_ti('public.message.update_failure'));
            // return Promise.resolve();
            throw new Error(_ti('public.message.update_failure'));
          }
        }

        // request saving
        console.log('update_user_fields', update_user_fields);
        console.log('user', user);
        const extraAttrs: any = {};
        const extraKeys = [
          'calling_number_id',
          'secretary_did_id',
          // 'voicemail_in_attachement',
          'voicemail_by_mail_enabled',
        ];
        for (const k of extraKeys) {
          if (k in obj_data_user) {
            extraAttrs[k] = obj_data_user[k];
          }
        }

        returneduser = await this.updateUserAttrs(user, update_user_fields, extraAttrs);
        if (!returneduser) {
          return;
        }

        console.log('returneduser', returneduser);
        await this.em.reloadRepositories(['user', 'servicegroup']);

        mustResetForm = true;
        if (user.group_ids) {
          returneduser.setGroupIds(user.group_ids);
        }
      }

      if (mustResetForm) {
        if (returneduser) {
          this.setOriginUserInfo(returneduser);
          this.initFormGroup();
        }
        this.resetForm();
      }

      this.initialize(returneduser);
      this.toast.showSuccess(_ti('public.message.update_success'));
    } catch (error) {
      console.error('Update user error', error);
      this.toast.showError(error.message || _ti('public.message.update_failure'));
      return Promise.resolve();
    }
  }

  private async updateUserAttrs(
    user: UserEntity,
    update_user_fields: string[],
    extra_data: any = {}
  ): Promise<UserEntity> {
    const ret = new Deferred<UserEntity>();
    const originalUser: UserEntity = UserRepository.getInstance().getObjectById(user.id);
    const oldGroupIds: number[] = originalUser.group_ids || [];

    if (_.includes(update_user_fields, 'group_ids')) { //  || extra_data && extra_data.hasOwnProperty('group_ids')
      const updatedGroupIds = user.group_ids || [];

      const removedQueues = this.userService.getRemovedQueues(user, oldGroupIds, updatedGroupIds);
      if (removedQueues.length) {
        await this.dialogService.confirmDialog(
          _ti('dialogs.confirmation'),
          _ti('user.message.group_change_cause_remove_queues_confirm', { queues: removedQueues.map(x => x.queue_name).join(', ') }),
          async () => {
            const updatedUser = await this.doUpdateUserAttrs(user, update_user_fields, extra_data);
            ret.resolve(updatedUser);
          }
        );

        return ret.promise;
      }
    }

    const updatedUser = await this.doUpdateUserAttrs(user, update_user_fields, extra_data);
    ret.resolve(updatedUser);

    return ret.promise;
  }

  private async doUpdateUserAttrs(
    user: UserEntity,
    update_user_fields: string[],
    extra_data: any = {}
  ): Promise<UserEntity> {
    try {
      const ret: { object_id: number, message: string } = await UserRepository.getInstance().saveAttrs(
        user,
        update_user_fields,
        extra_data
      );

      // const returnedUser = this.em.getRepository<UserRepository>('UserRepository').create() as UserEntity;
      // returnedUser.setObjectData(ret, true);
      const returnedUser = this.em.getRepository<UserRepository>('UserRepository').getObjectById(ret.object_id);

      // this.toast.showSuccess(_ti('public.message.update_success'));
      return returnedUser;
    } catch (e) {
      console.error('Update user attrs', e);
      // if (e.error && e.error.message) {
      //   this.toast.showError(_ti('public.message.update_failure') + ' ' + e.error.message);
      // }
      return Promise.reject(e);
    }
  }

  get stepKeys(): string[] {
    return this.steps.sort();
  }

  userCallReceptionChanged($event: any): void {
    this.user.phone = $event.phoneNumber;
    this.user.agent.phone_mode = $event.phoneMode;
    this.onFormValueChange();
  }

  updateUserDedicatedNumber(): void {
    this.dialogService.openSideDialog(UserDedicatedNumberModalComponent, {
      data: {
        user_id: this.user.id
      }
    });
  }

  gotoEditNumberPage(): void {
    const dedicatedNumber = this.getUserDidDedicatedNumber();
    this.router.navigate(['number-routing', dedicatedNumber.id], { skipLocationChange: false });
  }

  getUserDidDedicatedNumber(): DidEntity {
    const dedicatedDids = this.em.getRepository('DidRepository').getObjectList()
      .filter(d => d.hasLinkedData() && d.hasLinkedUser() && d.linked_object_id === this.user.id) || [];

    return dedicatedDids.length && dedicatedDids[0];
  }

  async removeDedicatedNumber(): Promise<void> {
    try {
      const didRepo = this.em.getRepository<DidRepository>('DidRepository');
      const dids: DidEntity[] = didRepo.getLinkedsByUser(this.user.id);
      await didRepo.clear(dids);
      this.toast.showSuccess(_ti('public.message.delete_success'));
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.delete_failure'));
    }
  }

  getChangeSet(): any {
    const updateData = super.getChangeSet();
    if ('calendar' in updateData) {
      updateData.calendar = updateData.calendar || null;
    }

    return updateData;
  }
}
