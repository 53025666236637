import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { PhoneNumberService } from '@wephone-utils';
import { Observable, BehaviorSubject } from 'rxjs';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { DidRepository } from '@wephone-core/model/repository/did';
import { SystemParam } from '@wephone-core/system';
import { DidEntity } from '@wephone-core/model/entity/did';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { StorageService } from '@wephone-core/service/storage.service';

export interface CallingNumber {
  id: number;
  number: string;
  name: string;
  sms: number;
  is_default: number;
}

export interface IStoragePhoneCallingInfo {
  calling_number?: string;
  calling_queue_id?: number;
}

@Injectable()
export class UserProfileService {
  static URL_SET_DEFAULT_CALLING_NUMBER = 'user/set_default_calling_number';
  static URL_SET_DEFAULT_QUEUE = 'user/set_default_queue';
  private readonly CALLING_NUMBERS_URL = 'user/calling_numbers';
  private defaultCallingNumber: CallingNumber;
  private defaultQueueId: number;
  private readonly RINGTONES_PATH = 'fileentry/ringtones';
  private readonly DELETE_RINGTONES_PATH = 'fileentry/ringtones/delete';

  _callingNumberList: BehaviorSubject<CallingNumber[]>;

  private readonly configManager = ConfigManager.getInstance() as ConfigManager;
  private readonly didRepo = DidRepository.getInstance<DidRepository>();
  private readonly queueRepo = CallQueueRepository.getInstance<CallQueueRepository>();

  anonymousCallingNumber: CallingNumber;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly phoneNumberService: PhoneNumberService,
    private readonly storage: StorageService,
  ) {
    this.anonymousCallingNumber = this.getAnonymousCallingNumber();
    this._callingNumberList = new BehaviorSubject<CallingNumber[]>([]);

  }

  get callingNumberList(): Observable<CallingNumber[]> {
    return this._callingNumberList.asObservable();
  }

  getAnonymousCallingNumber(): CallingNumber {
    const anonymous = this.didRepo.getAnonymousDid();
    return {
      id: anonymous.id,
      number: anonymous.number,
      name: anonymous.name,
      sms: 0,
      is_default: 0
    };
  }

  async setDefaultCallingNumber(callingNumber: CallingNumber): Promise<void> {
    try {
      if (_.isEqual(callingNumber, this.defaultCallingNumber)) {
        console.warn('No need to set default calling number because nothing changed');
        return;
      }

      await this.setPhoneCallingInfoCallingNumber(callingNumber && callingNumber.number || null);
      this.defaultCallingNumber = callingNumber;
    } catch (e) {
      throw e;
    }
  }

  initDefaultQueue(queue: CallQueueEntity): void {
    this.defaultQueueId = undefined;
    if (queue.id) {
      this.defaultQueueId = queue.id;
    }
  }

  async setDefaultQueue(queueId: number): Promise<void> {
    if (this.defaultQueueId === queueId) {
      console.warn('No need to set default queue because nothing changed');
      return;
    }
    
    // Set into local storage
    await this.setPhoneCallingInfoCallingQueue(queueId);
    this.defaultQueueId = queueId;

    // Reload calling numbers to update default calling number
    const filteredList = await this.reloadCallingNumbers();

    const preferredCallingNumbers: CallingNumber[] = [];

    const defaultQueue = this.queueRepo.getObjectById(this.defaultQueueId);
    // First preferred item is default did of default queue
    if (defaultQueue) {
      let defaultDidOfQueue: CallingNumber;
      if (defaultQueue.default_did_id) {
        defaultDidOfQueue = filteredList.find(x => x.id === defaultQueue.default_did_id);
      } else if (this.configManager.getSystemParam(SystemParam.telecom_has_anonymous)) {
        defaultDidOfQueue = filteredList.find(x => x.id === this.anonymousCallingNumber.id);
      }
      if (defaultDidOfQueue && !_.includes(preferredCallingNumbers, defaultDidOfQueue)) {
        preferredCallingNumbers.push(defaultDidOfQueue);
      }
    }

    // Next preferred item is random did from list of calling-numbers
    if (!_.isEmpty(filteredList)) {
      const listExcludeAnonymous = _.filter(filteredList, (n: CallingNumber) => n.number !== this.anonymousCallingNumber.number);
      if (!_.isEmpty(listExcludeAnonymous)) {
        const randomCallingNumber = _.sample(listExcludeAnonymous);
        if (randomCallingNumber && !_.includes(preferredCallingNumbers, randomCallingNumber)) {
          preferredCallingNumbers.push(randomCallingNumber);
        }
      }
    }

    // Last preferred item is the anonymous number
    if (_.includes(preferredCallingNumbers, this.anonymousCallingNumber)) {
      preferredCallingNumbers.push(this.anonymousCallingNumber);
    }

    // Set default calling-number from preferred list
    let defaultCallingNumber;
    for (const callingNumber of preferredCallingNumbers) {
      if (callingNumber && _.find(filteredList, (n: CallingNumber) => n.number === callingNumber.number)) {
        defaultCallingNumber = callingNumber;
        break;
      }
    }

    await this.setDefaultCallingNumber(defaultCallingNumber);
  }

  private requestCallingNumbers(userId: number = null): Promise<CallingNumber[]> {
    const params: any = userId ? { user_id: userId } : {};
    return HttpEngine.getInstance().apiGetV2(this.CALLING_NUMBERS_URL, params);
  }

  getUserCallingNumbers(userId: number = null): Promise<CallingNumber[]> {
    return this.requestCallingNumbers(userId);
  }

  getCallingNumbers(): Promise<CallingNumber[]> {
    return this.requestCallingNumbers();
  }

  async reloadCallingNumbers(): Promise<CallingNumber[]> {
    const numberList: CallingNumber[] = await this.requestCallingNumbers();
    
    const currentUser = this.authService.getUser();
    let defaultDidOfUser: DidEntity;

    // Next preferred item is default did of user
    if (currentUser) {
      defaultDidOfUser = currentUser.getDefaultCallingNumber();
    }

    let filteredList: CallingNumber[] = [];
    let defaultQueue: CallQueueEntity;
    // Should check also id because there's the case of empty call-queue
    if (this.defaultQueueId) {
      defaultQueue = this.queueRepo.getObjectById(this.defaultQueueId);
      for (const c of numberList) {
        const did: DidEntity = this.didRepo.getObjectById(c.id);
        const masterDid: DidEntity = did.master_did;
        const routedQueue: CallQueueEntity = did.routed_queue;
        const routedQueueOOH: CallQueueEntity = did.out_office_hours_routed_queue;
        const routedQueueMaster: CallQueueEntity = masterDid && masterDid.routed_queue;
        const routedQueueOOHMaster: CallQueueEntity = masterDid && masterDid.out_office_hours_routed_queue;

        if (routedQueue && routedQueue.id === this.defaultQueueId
          || routedQueueOOH && routedQueueOOH.id === this.defaultQueueId
          || routedQueueMaster && routedQueueMaster.id === this.defaultQueueId
          || routedQueueOOHMaster && routedQueueOOHMaster.id === this.defaultQueueId
          || did.isDestType(RoutingAppName.ivr_custom_menu)
          || did.isDestType(RoutingAppName.remote_application)
          || defaultDidOfUser && defaultDidOfUser.id === did.id
          || did.id === defaultQueue.default_did_id
        ) {
          filteredList.push(c);
        }
      }
    } else {
      filteredList = numberList;
    }

    if (this.configManager.getSystemParam(SystemParam.telecom_has_anonymous)) {
      filteredList.push(this.anonymousCallingNumber);
    }

    this._callingNumberList.next(filteredList);

    return filteredList;
  }

  getMyDefaultCallingDID(): CallingNumber {
    return this.defaultCallingNumber;
  }

  async getMyCallingNumberByNumber(phone_number: string): Promise<CallingNumber> {
    const callingNumbers = await this.getCallingNumbers();
    const phoneNumberNormalize = this.phoneNumberService.normalizeNumber(phone_number);
    return callingNumbers.find(callingNumber => callingNumber.number === phoneNumberNormalize);
  }

  async getDefaultCallingNumber(): Promise<CallingNumber> {
    if (!this.defaultCallingNumber) {
      const filteredList = await this.reloadCallingNumbers();

      const defaultCallingNumber: string = await this.getPhoneCallingInfoCallingNumber();
      if (defaultCallingNumber) {
        const callingNumber = filteredList.find(x => x.number === defaultCallingNumber);
        await this.setDefaultCallingNumber(callingNumber);
      }
    }

    return this.getMyDefaultCallingDID();
  }

  getDefaultQueueId(): number {
    return this.defaultQueueId;
  }

  getRingtones(): Promise<FileEntryEntity[]> {
    return HttpEngine.getInstance().apiGetV2(this.RINGTONES_PATH);
  }

  deleteRingtones(public_ids: string[]): Promise<any> {
    return HttpEngine.getInstance().apiPostV2(this.DELETE_RINGTONES_PATH, { public_ids });
  }

  private setPhoneCallingInfoCallingNumber(callingNumber: string): Promise<void> {
    return this.setPhoneCallingInfo({ calling_number: callingNumber });
  }

  private setPhoneCallingInfoCallingQueue(queueId: number): Promise<void> {
    return this.setPhoneCallingInfo({ calling_queue_id: queueId });
  }

  async getPhoneCallingInfoCallingNumber(): Promise<string> {
    const callingInfo = await this.getPhoneCallingInfo();
    return callingInfo && callingInfo.calling_number || null;
  }

  async getPhoneCallingInfoCallingQueue(): Promise<number> {
    const callingInfo = await this.getPhoneCallingInfo();
    return callingInfo && callingInfo.calling_queue_id || null;
  }

  private getPhoneCallingInfoKey(): string {
    const currentUserId: string = this.authService.getUserId() ? this.authService.getUserId().toString() : 'guest';
    return `phone_calling_info_id_${currentUserId}`;
  }

  async getPhoneCallingInfo(): Promise<IStoragePhoneCallingInfo> {
    const data = await this.storage.getDomainConfig(this.getPhoneCallingInfoKey()) || {};
    return data || {};
  }

  private async setPhoneCallingInfo(appendData: any): Promise<void> {
    const data = await this.getPhoneCallingInfo();

    const phoneCallingInfo: IStoragePhoneCallingInfo = _.assign(_.cloneDeep(data) || {}, appendData);
    return this.storage.setDomainConfig(this.getPhoneCallingInfoKey(), phoneCallingInfo);
  }

}
