import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FormControl } from '@angular/forms';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors, regexSearch, MessageService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { QualificationServiceV2, QualificationTree } from '../qualification2.service';
import { IQualificationEntity } from '@wephone-core/model/entity/qualification.i';

export interface CallCenterQualificationDialogParams {
  callQueueSessionId?: number;
  hasAnsweringMachine: boolean;
  required: boolean;
  pathQualIds: number[];
  finished: boolean;
  // root: QualificationEntity;
  root_id: number;
  comment: string;
  canQualify: boolean;
}

@Component({
  selector: 'call-center-qualification-dialog',
  templateUrl: './call-center-qualification-dialog.html',
  styleUrls: ['./call-center-qualification-dialog.scss']
})
export class CallCenterQualificationDialog extends DialogComponentBase implements OnInit, OnDestroy {
  @ViewChild('comment_input') commentInput: any;
  dialogTitle = _tk('qualification.dialog.title');
  backButton: DialogActionButton =
    {
      action: () => {
        this.backQualification();
      },
      visible: () => {
        return this.parentQual && this.parentQual.root_id !== this.parentQual.id;
      },
    };
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.save'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return !this.parentQual || this.parentQual && this.parentQual.root_id !== this.parentQual.id && this.parentQual.children.length === 0;
      },
      color: Colors.PRIMARY
    }
  ];

  hasAnsweringMachine: boolean;
  pathQualIds = [];
  qualTree: QualificationTree;
  parentQual: IQualificationEntity;
  childrenQual: IQualificationEntity[] = [];
  searchCtrl: FormControl = new FormControl();
  commentCtrl: FormControl;

  constructor(
    private readonly dialogRef: MatDialogRef<CallCenterQualificationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CallCenterQualificationDialogParams,
    // private readonly em: EntityManager,
    private readonly messageService: MessageService,
    private readonly qualificationService: QualificationServiceV2,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.messageService.broadcast('show_qualify', true);
    // console.log('CallCenterQualificationDialog data', data);

    if (this.data && this.data.callQueueSessionId) {
      console.log('CallCenterQualificationDialog data callqueue-session-id', this.data.callQueueSessionId);
      this.addSubscription(
        this.messageService.subscribeQualificationDone((event: {
          callqueue_session_id: number;
          type: 'audio'|'call'
        }) => {
          console.log('subscribe qualification done event', event);
          if (event.callqueue_session_id === this.data.callQueueSessionId && event.type === 'call') {
            // console.log('Matched callQueueSessionId, close dialog');
            this.close();
          }
        })
      );
    }
  }

  protected async resolveData(): Promise<any> {
    this.qualTree = await this.qualificationService.getQualificationTree(this.data.root_id);

    this.commentCtrl = new FormControl(this.data.comment);

    this.addSubscription(
      this.searchCtrl.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.setChildrenQualification();
        const filterValue: string = (value || '').trim();
        if (filterValue) {
          this.childrenQual = this.childrenQual.filter(q => {
            return regexSearch(q.value, filterValue);
          });
        }

        this.detectChanges();
      })
    );

    this.hasAnsweringMachine = this.data.hasAnsweringMachine;
    this.pathQualIds = this.data.pathQualIds;
    this.setQualificationsTree(this.data.root_id);
    this.detectChanges();
  }

  get cancelButton(): DialogActionButton {
    if (this.data && this.data.required) {
      return undefined;
    }

    return this._cancelButton;
  }

  isSelectedQualification(id): boolean {
    if (!this.pathQualIds) {
      return false;
    }

    return this.pathQualIds.includes(id);
  }

  // Private method
  private setQualificationsTree(rootId: number): void {
    this.parentQual = this.qualTree && this.qualTree.findQualificationById(rootId) || null;

    this.setChildrenQualification();
  }

  private setChildrenQualification(): void {
    if (this.parentQual) {
      this.childrenQual = this.qualTree && this.qualTree.findChildren(this.parentQual.id) || null;
    }
  }

  private makeQualificationIds(qualification, ids = []): number[] {
    ids.push(qualification.id);
    if (qualification.id === qualification.parent_id) {
      return ids.reverse();
    }

    const parentQualification = this.qualTree && this.qualTree.findQualificationById(qualification.parent_id) || null;
    if (parentQualification) {
      return this.makeQualificationIds(parentQualification, ids);
    }

    return ids.reverse();
  }

  backQualification(): void {
    this.searchCtrl.reset(undefined, { emitEvent: false });
    this.setQualificationsTree(this.parentQual.parent_id);
    this.detectChanges();
  }

  selectQualification(selectedQualificationId): void {
    this.searchCtrl.reset(undefined, { emitEvent: false });
    this.setQualificationsTree(selectedQualificationId);
    this.detectChanges();

    if (!this.isSelectQualication) {
      setTimeout(() => {
        this.commentInput?.nativeElement?.focus();
      }, 500);
    }
  }

  qualifCallWithAnsweringMachine(): void {
    this.dialogRef.close({ isAnsweringMachine: true });
  }

  submit(): void {
    const selectedQualificationIds = this.parentQual && this.makeQualificationIds(this.parentQual);
    const selectedQualificationDisplayed = selectedQualificationIds ? this.qualTree.getQualificationsDisplayed(selectedQualificationIds) : '';
    const qualificationComment = this.commentCtrl.value;

    this.close({ selectedQualificationIds, qualificationComment, selectedQualificationDisplayed });
  }

  private close(result?): void {
    this.dialogRef.close(result);
  }

  get isSelectQualication(): boolean {
    return this.data.canQualify &&
      (!this.parentQual ||
        this.parentQual.root_id === this.parentQual.id ||
        (this.parentQual.children || []).length > 0);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.messageService.broadcast('show_qualify', false);
  }
}
