import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActionButton, Colors, NoWhitespaceValidator, parseDateTime } from '@wephone-utils';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OutcallCampaignItemEntity } from '@wephone-core/model/entity/outcallcampaignitem';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { OutcallCampaignItemRepository } from '@wephone-core/model/repository/outcallcampaignitem';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { DateAdapter } from '@angular/material/core';
import { PhoneNumberValidated } from '@wephone/services/form-validator';
import * as _ from 'lodash';

@Component({
  selector: 'app-outcallcampaign-create-contact',
  templateUrl: './outcallcampaign-create-contact.component.html',
  styleUrls: ['./outcallcampaign-create-contact.component.scss']
})
export class OutcallcampaignCreateContactComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('campaign.campaign_item.manage.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.save();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  outcallcampaign_item: OutcallCampaignItemEntity;
  show_timepicker: number;
  form: FormGroup;

  private outcallcampaign_id: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {
      outcallcampaign_item: OutcallCampaignItemEntity;
      outcallcampaign_id: number;
    },
    private em: EntityManager,
    private fb: FormBuilder,
    dateAdapter: DateAdapter<Date>,
  ) {
    super();
    this.dateAdapter = dateAdapter;

    this.outcallcampaign_item = data.outcallcampaign_item;
    this.outcallcampaign_id = data.outcallcampaign_id;
    if (this.outcallcampaign_item.id) {
      this.dialogTitle = _tk('campaign.campaign_item.manage.update');
    }
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.initFormGroup();

    this.show_timepicker = 0;
    if (
      (this.form.controls['hour_end'].value && this.form.controls['hour_end'].value !== '00:00') ||
      (this.form.controls['hour_start'].value && this.form.controls['hour_start'].value !== '00:00')
    ) {
      this.show_timepicker = 1;
    }

    this.updateDialogLayout();
  }

  async resolveData(): Promise<void> {
    await (this.em.getRepository('OutcallCampaignItemRepository') as OutcallCampaignItemRepository).findByCampaignId(this.outcallcampaign_id);
  }

  initFormGroup(): void {
    const scheduledDt = this.outcallcampaign_item.scheduled_dt && this.outcallcampaign_item.scheduled_dt.toJSDate();
    this.form = this.fb.group({
      company: [this.outcallcampaign_item.company, [Validators.maxLength(1024)]],
      agent_alias: [this.outcallcampaign_item.agent_alias, [Validators.maxLength(128)]],
      first_name: [this.outcallcampaign_item.first_name, [Validators.maxLength(128)]],
      last_name: [this.outcallcampaign_item.last_name, [Validators.maxLength(128)]],
      scheduled_dt: [scheduledDt],
      hour_start: [this.outcallcampaign_item.hour_start],
      hour_end: [this.outcallcampaign_item.hour_end],
      custom1: [this.outcallcampaign_item.custom1, [Validators.maxLength(1024)]],
      custom2: [this.outcallcampaign_item.custom2, [Validators.maxLength(1024)]],
      custom3: [this.outcallcampaign_item.custom3, [Validators.maxLength(1024)]],
      called_number: [this.outcallcampaign_item.called_number, [Validators.required, NoWhitespaceValidator, PhoneNumberValidated(), Validators.maxLength(32)]],
      calling_number: [this.outcallcampaign_item.calling_number, [PhoneNumberValidated(), Validators.maxLength(32)]],
    });
  }

  isValid(): boolean {
    // this.form.markAllAsTouched();
    // let is_valid = true;

    let calledNumber: string = this.form.get('called_number').value;
    calledNumber = _.trim(calledNumber);

    if (calledNumber) {
      const repo: OutcallCampaignItemRepository = this.em.getRepository<OutcallCampaignItemRepository>('OutcallCampaignItemRepository');
      if (repo.getObjectByCalledNumber(calledNumber, this.outcallcampaign_id, this.outcallcampaign_item.id)) {
        this.form.get('called_number').setErrors({
          exists: true
        });

        // is_valid = false;
      }
    }

    if (this.show_timepicker) {
      const hourStartValue = this.form.get('hour_start').value;
      const hourEndValue = this.form.get('hour_end').value;

      if (!hourStartValue) {
        this.setFormControlError('hour_start', {
          required: _ti('form.validator.field_required')
        });
        // is_valid = false;
      }

      // if (!this.form.controls['hour_end'].value) {
      //   this.form.controls['hour_end'].setErrors({ required: _ti('form.validator.field_required') });
      //   this.form.get('hour_end').markAsDirty();
      //   // this.form.get('hour_end').updateValueAndValidity();
      //   is_valid = false;
      // }

      const dateStart = new Date();
      if (hourStartValue) {
        const timeStart = hourStartValue.split(':');
        dateStart.setHours(parseInt(timeStart[0]), parseInt(timeStart[1]));
      }

      const dateEnd = new Date();
      if (hourEndValue) {
        const timeEnd = hourStartValue.split(':');
        dateEnd.setHours(parseInt(timeEnd[0]), parseInt(timeEnd[1]));
      }

      if (hourEndValue && hourEndValue < hourStartValue) {
        this.setFormControlError('hour_end', {
          greater: _ti('campaign.message.hour_end_must_greater_than_hour_start')
        });
        // is_valid = false;
      }
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      // is_valid = false;
    }

    return this.form.valid;
  }

  private setFormControlError(controlName: string, error: any): void {
    this.form.get(controlName).setErrors(error);
    this.form.get(controlName).markAsDirty();
  }

  changeShowTime($event): void {
    if (!$event.checked || !this.form.get('hour_start').value) {
      this.form.get('hour_start').setValue('10:00');
    }

    if (!$event.checked || !this.form.get('hour_end').value) {
      this.form.get('hour_end').setValue('18:00');
    }

    this.form.get('hour_start').markAsDirty();
    this.form.get('hour_start').updateValueAndValidity();

    this.form.get('hour_end').markAsDirty();
    this.form.get('hour_end').updateValueAndValidity();
  }

  async save(): Promise<any> {
    if (!this.isValid()) {
      this.showError(_ti('public.message.update_failure'));
      return Promise.resolve(undefined);
    }

    const outcallcampaign_item = this.em.getRepository('OutcallCampaignItemRepository').create();
    outcallcampaign_item.setObjectData(
      {
        id: this.outcallcampaign_item.id,
        campaign_id: this.outcallcampaign_id,
        company: this.form.get('company').value,
        agent_alias: this.form.get('agent_alias').value,
        first_name: this.form.get('first_name').value,
        last_name: this.form.get('last_name').value,
        scheduled_dt: this.form.get('scheduled_dt').value && parseDateTime(this.form.get('scheduled_dt').value).toISODate(),
        hour_start: this.form.get('hour_start').value,
        hour_end: this.form.get('hour_end').value,
        custom1: this.form.get('custom1').value,
        custom2: this.form.get('custom2').value,
        custom3: this.form.get('custom3').value,
        called_number: this.form.get('called_number').value,
        calling_number: this.form.get('calling_number').value,
        // max_try_count: this.form.get('max_try_count').value
      },
      false
    );

    try {
      const repo: OutcallCampaignItemRepository = this.em.getRepository<OutcallCampaignItemRepository>('OutcallCampaignItemRepository');
      this.outcallcampaign_item = await repo.save(outcallcampaign_item);

      // await repo.findByCampaignId(this.outcallcampaign_id, true); // Refresh list
      this.successToast(_ti(this.outcallcampaign_item.id ? 'public.message.update_success' : 'public.message.create_success'));

      this.modalRef.returnValue({ outcallcampaign_item: this.outcallcampaign_item });
    } catch (e) {
      console.error('Create/Update campaign contact item error', e);
      this.showErrorMessage(e, _ti(this.outcallcampaign_item.id ? 'public.message.update_failure' : 'public.message.create_failure'));
    }
  }
}
