import * as _ from 'lodash';
import { DateTime } from 'luxon';

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, DialogActionButton, Colors, compareTimeStr } from '@wephone-utils';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { IOpeningCalendarEditDialogConfig } from '../calendar-types';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-opening-calendar-edit-dialog',
  templateUrl: './opening-calendar-edit-dialog.component.html',
  styleUrls: [ './opening-calendar-edit-dialog.component.scss' ]
})
export class OpeningCalendarEditDialogComponent extends DialogComponentBase implements OnInit {
  @ViewChild('calendarEditor', { static: false }) calendarEditor;
  dialogLeftActions: DialogActionButton[] = [
    {
      id: 'reset',
      label: _tk('public.reset'),
      action: () => {
        this.reset();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];
  dialogRightActions: DialogActionButton[] = [
    {
      id: 'validate',
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];
  // Private member var with default value
  private originCalendar;

  // Public member var with default value
  config: IOpeningCalendarEditDialogConfig = {
    editCustomData: false,
    editName: false,
    editGroup: false
  };

  // Public member var
  calendar: OpeningHourCalendarEntity;
  calendarControl: FormControl;

  constructor(
    private dialogRef: MatDialogRef<OpeningCalendarEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public translate: TranslateService,
    public toast: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.calendar = _.cloneDeep(this.data.calendar);
    this.originCalendar = _.cloneDeep(this.calendar);
    this.calendarControl = new FormControl(this.calendar);

    if (this.data.config) {
      _.extend(this.config, this.data.config);
    }

    this.dialogTitle = this.calendar.id ? _tk('opening_hour_calendar.title.edit') : _tk('opening_hour_calendar.title.add');
  }

  private checkName(): boolean {
    // return this.calendar.name !== '' && this.calendar.name.trim() !== '';
    return this.calendarControl.value.name !== '' && this.calendarControl.value.name.trim() !== '';
  }

  // private checkOpeningHour(): boolean {
  //   this.calendarControl.value.openinghours.forEach((o) => {
  //     o['duplicate'] = false;
  //   });
  //   for (const openinghour of this.calendarControl.value.openinghours) {
  //     const isDuplicate = _.filter(this.calendar.openinghours, (o) => {
  //       return (
  //         o.start_time === openinghour.start_time &&
  //         o.end_time === openinghour.end_time &&
  //         o.day_from === openinghour.day_from &&
  //         o.day_to === openinghour.day_to
  //       );
  //     });
  //     if (isDuplicate.length > 1) {
  //       isDuplicate.forEach((e) => {
  //         e['duplicate'] = true;
  //       });
  //       return false;
  //     }
  //   }

  //   return true;
  // }


  // private checkoutOpeningHourSpecialDate(): boolean {
  //   if (this.calendarControl.value.openinghours_special_date.length > 0) {
  //     this.calendarControl.value.openinghours_special_date.forEach((o) => {
  //       o['error'] = false;
  //     });
  //     for (const opening_hour_special_date of this.calendarControl.value.openinghours_special_date) {
  //       if (!opening_hour_special_date.name) {
  //         opening_hour_special_date['error'] = true;
  //         this.toast.showError(_ti('opening_hour_calendar.message.exception_name_empty'));
  //         return false;
  //       }

  //       let format = 'yyyy-MM-dd';
  //       let startDateTime = DateTime.fromSQL(opening_hour_special_date.start_dt).toFormat(format);
  //       let endDateTime = DateTime.fromSQL(opening_hour_special_date.end_dt).toFormat(format);

  //       if (opening_hour_special_date.opening_mode === 2 || opening_hour_special_date.opening_mode === 3) {
  //         startDateTime = startDateTime + ' ' + opening_hour_special_date.openinghour.start_time;
  //         endDateTime = endDateTime + ' ' + opening_hour_special_date.openinghour.end_time;
  //         format = format + 'HH:mm';
  //       }

  //       if (compareTimeStr(startDateTime, endDateTime) < 1) {
  //         opening_hour_special_date['error'] = true;
  //         this.toast.showError(_ti('opening_hour_calendar.message.exception_date_invalid'));
  //         return false;
  //       }
  //     }
  //   }

  //   return true;
  // }

  // onChangeOpeningHour(): void {
  //   if (!this.checkOpeningHour()) {
  //     this.toast.showError(_ti('opening_hour_calendar.message.weekly_schedule_duplicate_invalid'));
  //   }
  // }

  validate(): Promise<boolean> {
    if (this.config.editName) {
      if (!this.checkName()) {
        this.toast.showError(_ti('opening_hour_calendar.message.calendar_name_empty'));
        return Promise.resolve(false);
      }
    }

    // if (_.isEmpty(this.calendarControl.value.openinghours)) {
    //   this.toast.showError(_ti('opening_hour_calendar.message.weekly_schedule_empty'));
    //   return false;
    // }

    // if (!this.checkOpeningHour()) {
    //   this.toast.showError(_ti('opening_hour_calendar.message.weekly_schedule_duplicate_invalid'));
    //   return false;
    // }

    // if (!this.checkoutOpeningHourSpecialDate()) {
    //   this.toast.showError(_ti('opening_hour_calendar.message.weekly_schedule_duplicate_invalid'));
    //   return false;
    // }

    return this.calendarEditor.isValidCalendar(this.calendarControl.value);
  }

  reset(): void {
    this.calendarControl.setValue(this.originCalendar);
  }

  async submit(): Promise<void> {
    const valid: boolean = await this.validate();
    if (!valid) {
      console.error('Calendar is not valid');
      return;
    }
    // for (let i = 0; i < this.calendarControl.value.openinghours.length; i++) {
    //   if (this.calendarControl.value.openinghours[i].day_from === this.calendarControl.value.openinghours[i].day_to) {
    //     if (
    //       this.calendarControl.value.openinghours[i].start_time === this.calendarControl.value.openinghours[i].end_time
    //     ) {
    //       this.toast.showError(_ti('opening_hour_calendar.message.dulicate_time'));
    //       return;
    //     }
    //   }
    // }
    this.close(this.calendarControl.value);
  }

  close(result?): void {
    this.dialogRef.close(result);
  }
}
