import * as _ from 'lodash';

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import {
  DialogActionButton,
  Colors,
  IFlexDialogConfig,
  localNow,
  parseDateTime,
  parseStringWithFormat,
  TreeHelper,
} from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { FormControl } from '@angular/forms';
import { FlexSelectTreeNode } from '@wephone-core/core/flex-select-tree-node';
import { DateAdapter } from '@angular/material/core';
import { LiveQueueData } from '@wephone-core/service/live-stats.service';

@Component({
  selector: 'page-queues-filter',
  templateUrl: 'queues-filter.html',
  styleUrls: ['./queues-filter.scss']
})
export class QueuesFilterPage extends DialogComponentBase {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('public.filter');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('dialogs.ok'),
      action: () => {
        this.applyFilter();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  queueNodes: FlexSelectTreeNode[];
  queueCtl = new FormControl();
  date: string;
  start_time: string;
  end_time: string;

  private readonly queueRepo: CallQueueRepository;

  constructor(
    public dialogRef: MatDialogRef<QueuesFilterPage>,
    @Inject(MAT_DIALOG_DATA) public data: {
      show_date: boolean;
      show_queue: boolean;
      has_routed?: boolean;
      queues?: LiveQueueData[];
      day?: Date;
      time_start?: string;
      time_end?: string;
      filter_queue_ids?: number[];
    },
    private em: EntityManager,
    dateAdapter: DateAdapter<Date>,
  ) {
    super();
    this.dateAdapter = dateAdapter;
    this.queueRepo = this.em.getRepository<CallQueueRepository>('CallQueueRepository');
  }

  resolveData(): void {
    this.date = this.data.day && this.data.day.toISOString() || undefined;
    this.start_time = this.data.time_start || '00:00';
    this.end_time = this.data.time_end || '00:00';
    this.queueCtl = new FormControl(this.data.filter_queue_ids);

    if (this.queueRepo) {
      this.buildQueueTreeNodes();
    }
  }

  private buildQueueTreeNodes(): void {
    let queue_data: LiveQueueData[] = this.data.queues || [];
    if (this.data.has_routed) {
      const queueIdsRouted = this.queueRepo.getQueuesRouted().map(q => q.id);
      queue_data = (this.data.queues || []).filter(i => queueIdsRouted.includes(i.queue_id));
    }

    const nodeList = queue_data.map(q => {
      const queue: CallQueueEntity = this.queueRepo.getObjectById(q.queue_id);
      let noteName = queue.queue_name;

      if (queue.default_did) {
        noteName = `${noteName} (${queue.default_did.display_number})`;
      }

      return {
        id: queue.id,
        name: noteName,
        parent_id: undefined,
      };
    });

    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.queueNodes = TreeHelper.listToTree(sortedNodeList) as FlexSelectTreeNode[];
  }

  fastChangeDay(name = 'today'): void {
    const now = localNow();
    if (name === 'yesterday') {
      this.date = now.minus({ days: 1 }).toISO();
      return;
    }
    this.date = now.toISO();
  }

  fastChangePrevDay(): void {
    this.date = parseDateTime(this.date).minus({ days: 1 }).toISO();
  }

  fastChangeNextDay(): void {
    this.date = parseDateTime(this.date).plus({ days: 1 }).toISO();
  }

  applyFilter(): void {
    const start_dt = parseStringWithFormat(this.start_time, 'hh:mm');
    const end_dt = parseStringWithFormat(this.end_time, 'hh:mm');
    if (start_dt.diff(end_dt).milliseconds > 0) {
      this.showError(_ti('dashboard.chart_filters.validate_filter_time'));
      return;
    }

    this.modalRef.returnValue({
      day: this.date ? new Date(this.date) : null,
      filter_queue_ids: this.queueCtl.value,
      time_start: this.start_time,
      time_end: this.end_time
    });
  }
}
