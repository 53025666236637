<ng-template #loading>
  <loading-screen fxFlexFill></loading-screen>
</ng-template>
<div class="pa-2" fxLayout="column" *ngIf="isReady(); else loading">
  <div fxLayout="row">
    <!--Voicemail && Recording-call-->
    <ng-container *ngIf="callInfo">
      <!--Recording-call player-->
      <fileentry-player-button [disabled]="!callInfo.file_entry_ready" (onPlay)="onPlayMessage()"
        [matTooltip]="(isHandledCall ? 'call.actions.play_recorded_call' : 'call.actions.play_voice_message')|translate"
        [filePublicId]="callInfo.file_public_id">
      </fileentry-player-button>

      <!--Download-file-->
      <button mat-icon-button [disabled]="!callInfo.file_entry_ready"
        (click)="downloadFileEntry(callInfo, isHandledCall ? 'recording-call' : 'voicemail-message')"
        [matTooltip]="(isHandledCall ? 'call.actions.download_recorded_call': 'call.actions.download_voice_message')|translate">
        <mat-icon>cloud_download</mat-icon>
      </button>

      <button *ngIf="!isHandledCall; else ShareActions" mat-icon-button (click)="copyCallDetailUrl(callInfo)"
        [matTooltip]="'call.actions.share_detail_url'|translate">
        <mat-icon>share</mat-icon>
      </button>
      <ng-template #ShareActions>
        <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menuAction"
          [matTooltip]="'call.actions.share_link'|translate">
          <mat-icon>share</mat-icon>
        </button>
        <mat-menu #menuAction="matMenu">
          <!--Copy-recording-detail-url-->
          <div mat-menu-item [disabled]="callInfo && !callInfo.file_entry_ready"
            (click)="copyRecoredCallUrl(callInfo)">
            {{ 'call.actions.share_recording_url'|translate }}
          </div>
          <!--Copy-call-detail-url-->
          <div mat-menu-item (click)="copyCallDetailUrl(callInfo)">
            {{ 'call.actions.share_detail_url'|translate }}
          </div>
        </mat-menu>
      </ng-template>

      <!--Archive-->
      <button *ngIf="isMissedCall || isVoicemail" data-ci="btn-archive" mat-icon-button
        [color]="callInfo.vm_message_is_archived === 1 ? 'warn' : 'primary'"
        (click)="callInfo.vm_message_is_archived === 1 ? unarchiveVoicemail(callInfo) : archiveVoicemail(callInfo)"
        [matTooltip]="(callInfo.vm_message_is_archived === 1 ? 'call.actions.unarchive_voicemail' : 'call.actions.archive_voicemail')|translate"
        [disabled]="!callInfo.vm_message_id">
        <mat-icon>{{ callInfo.vm_message_is_archived === 1 ? 'unarchive' : 'archive' }}</mat-icon>
      </button>

      <!--Delete-->
      <button *ngIf="(isMissedCall || isVoicemail) && canDeleteVoicemail" mat-icon-button color="warn"
        (click)="deleteVoicemail(callInfo)" [matTooltip]="'call.actions.delete_voicemail'|translate"
        [disabled]="!callInfo.vm_message_id">
        <mat-icon>delete</mat-icon>
      </button>
    </ng-container>

    <!--Qualification-->
    <ng-container *ngIf="isHandledCall">

      <ng-container *ngIf="callInfo && canQualify(callInfo); else NoQualification">
        <ng-container *ngIf="(callInfo.qualification_comment || callInfo.qualification); else AddQualificationBlock">
          <button mat-icon-button (click)="reQualify(callInfo)" color="warn"
            [matTooltip]="'call.actions.change_qualification' | translate">
            <mat-icon>edit_note</mat-icon>
          </button>
        </ng-container>
        <ng-template #AddQualificationBlock>
          <button mat-icon-button color="primary" (click)="reQualify(callInfo)"
            [matTooltip]="'call.actions.add_qualification' | translate">
            <mat-icon>note_add</mat-icon>
          </button>
        </ng-template>
      </ng-container>

      <ng-template #NoQualification>
        <button mat-icon-button color="primary" disabled [matTooltip]="'call.actions.add_qualification' | translate">
          <mat-icon>note_add</mat-icon>
        </button>
      </ng-template>

    </ng-container>

    <button *ngIf="isMissedCall" class="bt-call" color="primary" mat-icon-button (click)="makeCall()"
      [disabled]="client_number === 'anonymous'" [matTooltip]="'livecall.actions.redial'|translate">
      <mat-icon>call</mat-icon>
    </button>
  </div>

  <hr class="mtb-10 full-width">

  <!--Start-time-->
  <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1">
    <div fxFlex="30" class="label">
      <!-- {{ 'dialog_missed_call_info.start_time'|translate }} -->
      {{ 'public.date'|translate }}
    </div>
    <div fxFlex class="value" *ngIf="start_time">
      <div>{{ start_time|timeFormatDateTimeShort }}</div>
    </div>
  </div>

  <!--Direction-->
  <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1">
    <div fxFlex="30" class="label">
      {{ 'livecall.call_direction.title'|translate }}
    </div>
    <div fxFlex class="value">
      {{ (is_outgoing ? 'livecall.call_direction.outbound' : 'livecall.call_direction.inbound')|translate }}
    </div>
  </div>

  <!--Client-number-->
  <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1">
    <div fxFlex="30" class="label">
      {{ 'handled_call_info.content.client_number'|translate }}
    </div>
    <div fxFlex fxLayoutAlign="start center" class="value" fxLayout="row">
      <span [ngClass]="hasBlockCallerButton() && !is_outgoing && isExistBlackList() ? 'number_blocked' : ''"
        class="mr-1">
        {{ client_number|displayPhoneNumber }}
        <ng-container *ngIf="client_name">&nbsp;({{ client_name }})</ng-container>
      </span>

      <ng-container *ngIf="canBlockCaller && hasBlockCallerButton() && !is_outgoing && client_number">
        <button mat-icon-button type="button" color="primary" *ngIf="isExistBlackList();else NotExistBlackList"
          [matTooltip]="'handled_call_info.action.unblock_caller'|translate" (click)="unblockCaller()">
          <mat-icon>check_circle_outline</mat-icon>&nbsp;
        </button>

        <ng-template #NotExistBlackList>
          <button mat-icon-button type="button" color="warn"
            [matTooltip]="'handled_call_info.action.block_caller'|translate" (click)="blockCaller()">
            <mat-icon>block</mat-icon>&nbsp;
          </button>
        </ng-template>
      </ng-container>

    </div>
  </div>

  <!--Agent-number-->
  <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1">
    <div fxFlex="30" class="label">
      {{ 'handled_call_info.content.agent_number'|translate }}
    </div>
    <div fxFlex class="value">
      <a *ngIf="canEditDid && agent_number !== 'anonymous';else DisplayDidNumber" class="flex-link mt-1"
        (click)="gotoEditDid(agent_number)">{{
        agent_number|displayPhoneNumber }}</a>
      <ng-template #DisplayDidNumber>
        {{ agent_number|displayPhoneNumber }}
      </ng-template>
    </div>
  </div>

  <!--Call-queue|Campaign-->
  <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1" *ngIf="queue_ids.length || campaign">
    <div fxFlex="30" class="label">
      {{ (campaign ? 'campaign.name' : 'handled_call_info.content.call_queue')|translate }}
    </div>
    <div fxFlex class="value" *ngIf="queue_ids.length && !campaign;else DisplayCampaign">
      <ng-container *ngFor="let id of queue_ids; index as i">
        <a *ngIf="canEditQueue;else DisplayQueueName" class="flex-link" (click)="gotoEditCallQueue(id)">{{
          getQueueName(id) }}</a>&nbsp;
        <ng-template #DisplayQueueName>
          {{ getQueueName(id) }}<span *ngIf="i < queue_ids.length-1">,&nbsp;</span>
        </ng-template>
      </ng-container>
    </div>

    <ng-template #DisplayCampaign>
      <ng-container *ngIf="campaign">
        <a *ngIf="canEditCampaign;else DisplayCampaignName" class="flex-link" (click)="gotoEditCampaign(campaign.id)">{{
          campaign.name }}</a>&nbsp;
        <ng-template #DisplayCampaignName>
          {{ campaign.name }}
        </ng-template>
      </ng-container>
    </ng-template>
  </div>

  <!--IvrMenu-->
  <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1" *ngIf="callInfo.mc_ivr_menu_id">
    <div fxFlex="30" class="label">
      {{ 'handled_call_info.content.mc_ivr_menu'|translate }}
    </div>
    <div fxFlex class="value">
      <a *ngIf="canEditIvrMenu;else DisplayIvrMenuName" class="flex-link" (click)="gotoEditIvrMenu(callInfo.mc_ivr_menu_id)">
        {{ getIvrMenuName(callInfo.mc_ivr_menu_id) }}</a>&nbsp;
      <ng-template #DisplayIvrMenuName>
        {{ getIvrMenuName(callInfo.mc_ivr_menu_id) }}
      </ng-template>
      
    </div>
  </div>

  <!--Call-duration-->
  <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1" *ngIf="duration">
    <div fxFlex="30" class="label">
      {{ 'cdr.duration'|translate }}
    </div>
    <div fxFlex class="value">
      {{ duration | seconds_as_duration }}
    </div>
  </div>

  <!--Wait-time-->
  <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1" *ngIf="wait_duration">
    <div fxFlex="30" class="label">
      {{ 'handled_call_info.content.wait_time'|translate }}
    </div>
    <div fxFlex class="value">
      {{ wait_duration | seconds_as_duration }}
    </div>
  </div>

  <ng-container *ngIf="isHandledCall">

    <!--Qualification-->
    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1">
      <div fxFlex="30" class="label">
        {{ 'qualification.dialog.title'|translate }}
      </div>

      <div fxFlex class="value">
        <p *ngIf="qualification_displayed">
          {{ qualification_displayed }}
        </p>

        <p *ngIf="qualification_comment">{{ qualification_comment }}</p>
      </div>
    </div>

    <!--Handling-agents-->
    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1">
      <div fxFlex="30" class="label">
        {{ 'handled_call_info.content.handling_agents'|translate }}
      </div>
      <div fxFlex class="value" *ngIf="hangup_agent_names.length">
        {{ hangup_agent_names.join(', ') }}
      </div>
    </div>

    <!--Hangup-first-->
    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="pb-1">
      <div fxFlex="30" class="label">
        {{ 'handled_call_info.content.hangup_first'|translate }}
      </div>
      <div fxFlex class="value">
        {{ hangup_first }}
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="callInfo && (callInfo.analysis_state === 2 || callInfo.transcription_state === 2)">
    <hr class="mtb-10 full-width">
  </ng-container>

  <ng-container *ngIf="callInfo && callInfo.analysis_state === 2">
    <strong>{{ 'call_queue.content.analysis.title'|translate }}</strong>
    <table class="flex-table-info" *ngIf="callInfo.analysis_result">
      <tr>
        <th>{{ 'public.key_label'|translate }}</th>
        <th>{{ 'public.value'|translate }}</th>
      </tr>

      <tr *ngFor="let key of getKeyAnalysis(callInfo)">
        <td>{{ key }}</td>
        <td>{{ callInfo.analysis_result.json_result[key] }}</td>
      </tr>

      <tr *ngFor="let key of getKeyAnalysisUuid(callInfo)">
        <td>{{ key }}</td>
        <td>{{ callInfo.analysis_result[key] }}</td>
      </tr>
    </table>

  </ng-container>

  <ng-container *ngIf="transcriptionText">
    <strong>{{ 'call_queue.content.transcription'|translate }}</strong>
    <p class="transcription-text" [innerHTML]="transcriptionText"></p>
  </ng-container>

  <ng-container *ngIf="voicemailMessages.length || dataSource">

    <hr class="mtb-10 full-width">

    <div fxFlex="grow" fxLayout="column" class="overview-scroll">
      <ng-container *ngIf="voicemailMessages.length">
        <h2 class="tried-calls-title">{{ 'voicemail.title' | translate }}</h2>
        <table class="flex-table-info">
          <tr>
            <th class="text-left">
              {{ 'stats.missed_call.dialog_missed_call_info.content.call_time'|translate }}
            </th>
            <th class="text-left">
              {{ 'call.listen_voicemail'|translate }}
            </th>
          </tr>

          <tr *ngFor="let vm of voicemailMessages">
            <td>{{ vm.receive_dt|timeFormatDateTimeShort }}</td>
            <td>
              <fileentry-player-button (onPlay)="onPlayVoicemailMessage(vm)" [filePublicId]="vm.public_id"
                playerId="main"></fileentry-player-button>
            </td>
          </tr>
        </table>
      </ng-container>

      <h2 class="tried-calls-title">{{ 'dialog_missed_call_info.agent_tried' | translate }}</h2>
      <table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="mat-elevation-z8 shadow-none" matSort>
        <ng-container matColumnDef="agent_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="tried-call-header-cell">
            <mat-icon>height</mat-icon>
            <!-- {{ 'stats.missed_call.dialog_missed_call_info.content.agent_name'|translate }} -->
          </th>
          <td mat-cell *matCellDef="let item">
            <div fxLayout="row">
              <ng-container *ngIf="item.user_direction === 2; then iconCallForwardBlock; else iconCallBackwardBlock">
              </ng-container>

              <ng-template #iconCallForwardBlock>
                <ng-container *ngTemplateOutlet="iconCallForward; context: {callItem: item}"></ng-container>
              </ng-template>
              <ng-template #iconCallBackwardBlock>
                <ng-container *ngTemplateOutlet="iconCallBackward; context: {callItem: item}"></ng-container>
              </ng-template>
              <span class="agent-name">{{ item.agent_name }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="start_time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="tried-call-header-cell">{{
            'stats.missed_call.dialog_missed_call_info.content.call_time'|translate }}</th>
          <td mat-cell *matCellDef="let item">{{ item.start_time|timeFormatDateTimeShort }}</td>
        </ng-container>
        <ng-container matColumnDef="called_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="tried-call-header-cell">{{
            'stats.missed_call.dialog_missed_call_info.content.phone_number'|translate }}</th>
          <td mat-cell *matCellDef="let item">{{ numberNormalize(item.called_number) }}</td>
        </ng-container>
        <ng-container matColumnDef="voicemail_public_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="tried-call-header-cell">{{
            'voicemail.title'|translate }}</th>
          <td mat-cell *matCellDef="let item">
            <fileentry-player-button *ngIf="item.voicemail_public_id" [filePublicId]="item.voicemail_public_id"
              (onPlay)="onPlayCallHistoryMessage(item)" playerId="main"></fileentry-player-button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="tried-call-header"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <p *ngIf="!agentTriedList || agentTriedList.length == 0">{{
        'stats.missed_call.dialog_missed_call_info.content.no_data'|translate }}
      </p>
      <ng-template #MissedCall let-callItem="callItem">
        <mat-icon *ngIf="callItem.is_voicemail else MissedCallIcon" class="is-missed">voicemail</mat-icon>
        <ng-template #MissedCallIcon>
          <mat-icon class="is-missed">call_missed</mat-icon>
        </ng-template>
        &nbsp;
      </ng-template>

      <ng-template #iconCallBackward let-callItem="callItem">
        <ng-container *ngIf="callItem.is_missed == 1 then missedInbound;else noMissedInbound"></ng-container>

        <ng-template #missedInbound>
          <ng-container *ngTemplateOutlet="MissedCall; context: {callItem: callItem}"></ng-container>
        </ng-template>

        <ng-template #noMissedInbound>
          <mat-icon class="call_in">south</mat-icon>
        </ng-template>
      </ng-template>

      <ng-template #iconCallForward let-callItem="callItem">
        <ng-container *ngIf="callItem.is_missed == 1 then missedOutbound;else noMissedOutbound"></ng-container>

        <ng-template #missedOutbound>
          <ng-container *ngTemplateOutlet="MissedCall; context: {callItem: callItem}"></ng-container>
        </ng-template>

        <ng-template #noMissedOutbound>
          <mat-icon class="mat-text-muted">north</mat-icon>
        </ng-template>
      </ng-template>
    </div>
  </ng-container>
</div>
