import { Injectable } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as HighCharts from 'highcharts';
import { SingletonBase } from '@wephone-utils';
import { _ti } from '@wephone-translation';
import { ChartPeriod } from '@wephone/pages/dashboard/graph-chart/graph-chart-type';
import { FilterHour, LiveStatsService, IChartFilter, IChartFilterTime } from './live-stats.service';
import { SMSesTodaySA } from 'projects/superadmin/src/app/service/dashboard-sa.service';

export interface ChartStatsDataInterface {
  [key: string]: number[];
}

@Injectable()
export class HighChartsHelperService extends SingletonBase {
  public default_label_config: any;
  public default_queue_graph_config: any;

  private colors = {
    missed_call: '#d1555e',
    // handled_call: '#65c479',
    inbound_handled_call: '#65c479',
    sms_received: '#24a6e7',
    sms_sent: '#65c479',
    outbound_call: '#3399ff',
    evolution_invoice_value: '#0ed7a5',
    evolution_user_creation_count: '#3399ff',
  };

  constructor(private liveStatsService: LiveStatsService) {
    super();

    this.default_label_config = {
      rotation: -45,
      autoRotation: [-45],
      style: {
        color: HighCharts.getOptions().colors[1],
        fontWeight: 'bold'
      }
    };
  }

  private getDefaultQueueGraphConfig(yAxisLabel: string = _ti('highchart.total_call_number')): any {
    return {
      options: {
        title: {
          text: '',
          floating: true,
          x: 0,
          y: 10,
          style: {
            align: 'center',
            color: 'blue',
            fontSize: '10px'
          }
        },
        xAxis: {
          tickInterval: 1,
          labels: this.default_label_config
        },
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: '{value}',
              style: {
                color: HighCharts.getOptions().colors[1]
              }
            },
            title: {
              text: yAxisLabel,
              style: {
                color: HighCharts.getOptions().colors[1]
              }
            },
            // title: values['highchart.total_call_number'],
            min: 0
          },
          // {
          //   // Secondary yAxis
          //   alignTicks: false,
          //   gridLineWidth: 0,
          //   title: {
          //     text: '',
          //     style: {
          //       color: HighCharts.getOptions().colors[1]
          //     }
          //   },
          //   labels: {
          //     format: '{value}',
          //     style: {
          //       color: HighCharts.getOptions().colors[1]
          //     }
          //   },
          //   min: 0,
          //   max: 100,
          //   opposite: true
          // }
        ],
        plotOptions: {
          column: {
            // stacking: 'normal',
            enableMouseTracking: true,
            pointPadding: 0,
            borderWidth: 0,
          },
          // line: {
          //   enableMouseTracking: true,
          //   dataLabels: {
          //     enabled: true,
          //     format: '{y}'
          //   },
          //   tooltip: {
          //     pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
          //   }
          // }
        },
        tooltip: {
          annimation: true,
          enabled: true,
          headerFormat: ''
        },
        legend: {
          enabled: true
        }
      },
      credits: {
        enabled: false
      },
      size: {
        height: 200
      }
    };
  }

  private _getXLabelHours(hour: number, filter_hours): string {
    // Force range of day to 00:00 - 24:00 if input is 00:00 - 00:00 because there's no input for 24h
    const filterHourStart: number = filter_hours.start_hour;
    let filterHourEnd: number = filter_hours.end_hour;

    const filterMinStart: number = filter_hours.start_min;
    let filterMinEnd: number = filter_hours.end_min;

    if (filterHourStart === 0 && filterHourEnd === 0 &&
      filterMinStart === 0 && filterMinEnd === 0
    ) {
      filterHourEnd = 24;
      filterMinEnd = 0;
    }

    let xlabel: string = hour <= filterHourEnd ? `${hour}h00-${hour + 1}h00` : `${hour}h00-${hour}h00`;

    if (hour === filterHourStart && filterMinStart > 0) {
      const x_start_min = `${filterMinStart}`.length < 2 && `0${filterMinStart}` || `${filterMinStart}`;
      xlabel = `${hour}h${x_start_min}-${hour + 1}h00`;
      return xlabel;
    }

    if (hour === filterHourEnd && filterMinEnd > 0) {
      const x_end_min = `${filterMinEnd}`.length < 2 && `0${filterMinEnd}` || `${filterMinEnd}`;
      xlabel = `${hour}h00-${hour}h${x_end_min}`;
      return xlabel;
    }

    return xlabel;
  }

  get_charts_line_data(period: ChartPeriod, stats_data: ChartStatsDataInterface, filter_time?: IChartFilter): any {
    let config_obj = {};
    const defaultQueueGraphConfig = this.getDefaultQueueGraphConfig();

    config_obj = _.extend(config_obj, defaultQueueGraphConfig);
    // config_obj['options']['plotOptions']['column']['tooltip'] = {
    //   pointFormat:
    //     '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} ' +
    //     _ti('highchart.call'] +
    //     '</b><br/>'
    // };

    const call_handled = [];
    const call_inbound_handled = [];
    const call_missed = [];
    const call_outbound = [];
    // const call_handled_rate = [];
    const x_axis = [];

    /* tslint:disable-next-line: no-for-in */
    for (const i in stats_data) { // Have to use for in to prevent from sorting array of keys
      let x_label = i;

      // Limit data if period is today and yesterday
      if (_.includes([ChartPeriod.today, ChartPeriod.yesterday], period)) {
        const hourStart = parseInt(i, 10);

        // Limit data from 9h to 20h with period is today and yesterday if limit_hours is empty
        const filter_hours: FilterHour = {
          start_hour: 9,
          end_hour: 21,
          start_min: 0,
          end_min: 0,
        };
        if (!_.isEmpty(filter_time && filter_time.time_start && filter_time.time_end)) {
          filter_hours.start_hour = +(filter_time.time_start.substr(0, 2));
          filter_hours.end_hour = +(filter_time.time_end.substr(0, 2));
          filter_hours.start_min = +(filter_time.time_start.substr(3, 2));
          filter_hours.end_min = +(filter_time.time_end.substr(3, 2));
        }

        x_label = this._getXLabelHours(hourStart, filter_hours);

        if (!this.liveStatsService.isHourInRange(hourStart, filter_hours)) {
          continue;
        }
      }

      const data = stats_data[i];

      // const call_count = data[0] && +data[0] || 0;
      // const call_handled_count = data[1] && +data[1] || 0;

      // Get working-hour call count instead of all
      const call_count = data[7] && +data[7] || 0;
      const call_missed_count = data[8] && +data[8] || 0;
      // const call_handled_count = call_count - call_missed_count;
      const call_inbound_count = data[2] && +data[2] || 0;
      const call_outbound_count = data[9] && +data[9] || 0;
      const call_inbound_handled_count = call_inbound_count - call_missed_count;

      // call_handled.push([_ti('highchart.handled'), call_handled_count]);
      call_inbound_handled.push([_ti('highchart.inbound_handled'), call_inbound_handled_count]);
      call_missed.push([_ti('highchart.missed'), call_missed_count]);
      call_outbound.push([_ti('highchart.outbound'), call_outbound_count]);
      // call_handled_rate.push([
      //   _ti('highchart.rate'],
      //   call_count > 0 ? Math.round((call_handled_count * 100) / call_count) : 0
      // ]);

      x_axis.push(x_label);
    }

    config_obj['series'] = [
      {
        name: _ti('highchart.missed_calls'),
        type: 'column',
        yAxis: 0,
        data: call_missed,
        color: this.colors.missed_call
        // color: HighCharts.getOptions().colors[0]
      },
      // {
      //   name: _ti('highchart.handled_calls'),
      //   type: 'column',
      //   yAxis: 0,
      //   data: call_handled,
      //   color: this.colors.handled_call
      // },
      {
        name: _ti('highchart.answered_calls'),
        type: 'column',
        yAxis: 0,
        data: call_inbound_handled,
        color: this.colors.inbound_handled_call
      },
      {
        name: _ti('highchart.outbound_calls'),
        type: 'column',
        yAxis: 0,
        data: call_outbound,
        color: this.colors.outbound_call
      },
      // {
      //   name: _ti('highchart.rate_handled_calls'],
      //   type: 'line',
      //   yAxis: 1,
      //   data: call_handled_rate,
      //   color: HighCharts.getOptions().colors[3]
      // }
    ];
    config_obj['xAxis'] = { categories: x_axis, labels: this.default_label_config };

    return config_obj;
  }

  getChartsLineSMS(statsData: { [key: number]: SMSesTodaySA }, filter_time?: IChartFilterTime): any {
    let config_obj = {};
    const defaultQueueGraphConfig = this.getDefaultQueueGraphConfig(_ti('highchart.total_sms'));

    config_obj = _.extend(config_obj, defaultQueueGraphConfig);

    const smsTotalCount = [];
    const smsPendingCount = [];
    const smsErrorCount = [];
    const smsSuccessCount = [];
    const x_axis = [];

    let x_label = '';

    // Limit data from 9h to 20h with period is today and yesterday if limit_hours is empty
    const filter_hours: FilterHour = {
      start_hour: 9,
      end_hour: 21,
      start_min: 0,
      end_min: 0,
    };
    if (!_.isEmpty(filter_time && filter_time.time_start && filter_time.time_end)) {
      filter_hours.start_hour = +(filter_time.time_start.substr(0, 2));
      filter_hours.end_hour = +(filter_time.time_end.substr(0, 2));
      filter_hours.start_min = +(filter_time.time_start.substr(3, 2));
      filter_hours.end_min = +(filter_time.time_end.substr(3, 2));
    }

    // Foreach hour of data [0-23]
    for (const i of Object.keys(statsData)) {
      const hourStart = +i;
      if (!this.liveStatsService.isHourInRange(hourStart, filter_hours)) {
        continue;
      }

      const data = statsData[i];

      smsTotalCount.push([_ti('sms.sms_total'), data.count_total]);
      smsPendingCount.push([_ti('sms.sms_pending'), data.count_pending]);
      smsSuccessCount.push([_ti('sms.sms_success'), data.count_success]);
      smsErrorCount.push([_ti('sms.sms_error'), data.count_error]);

      const _getXLabel = (hour: number): string => {
        // Force range of day to 00:00 - 24:00 if input is 00:00 - 00:00 because there's no input for 24h
        const filterHourStart: number = filter_hours.start_hour;
        let filterHourEnd: number = filter_hours.end_hour;

        const filterMinStart: number = filter_hours.start_min;
        let filterMinEnd: number = filter_hours.end_min;

        if (filterHourStart === 0 && filterHourEnd === 0 &&
          filterMinStart === 0 && filterMinEnd === 0
        ) {
          filterHourEnd = 24;
          filterMinEnd = 0;
        }

        let xlabel: string = hour <= filterHourEnd ? `${hour}h00-${hour + 1}h00` : `${hour}h00-${hour}h00`;

        if (hour === filterHourStart && filterMinStart > 0) {
          const x_start_min = `${filterMinStart}`.length < 2 && `0${filterMinStart}` || `${filterMinStart}`;
          xlabel = `${hour}h${x_start_min}-${hour + 1}h00`;
          return xlabel;
        }

        if (hour === filterHourEnd && filterMinEnd > 0) {
          const x_end_min = `${filterMinEnd}`.length < 2 && `0${filterMinEnd}` || `${filterMinEnd}`;
          xlabel = `${hour}h00-${hour}h${x_end_min}`;
          return xlabel;
        }

        return xlabel;
      };

      x_label = _getXLabel(hourStart);

      x_axis.push(x_label);
    }

    config_obj['series'] = [
      {
        name: _ti('sms.sms_total'),
        type: 'line',
        yAxis: 0,
        data: smsTotalCount,
        // color: this.colors.sms_received
      },
      {
        name: _ti('sms.sms_pending'),
        type: 'line',
        yAxis: 0,
        data: smsPendingCount,
        // color: this.colors.sms_sent
      },
      {
        name: _ti('sms.sms_success'),
        type: 'line',
        yAxis: 0,
        data: smsSuccessCount
      },
      {
        name: _ti('sms.sms_error'),
        type: 'line',
        yAxis: 0,
        data: smsErrorCount,
        // color: this.colors.sms_sent
      }
    ];
    config_obj['xAxis'] = { categories: x_axis, labels: this.default_label_config };

    return config_obj;
  }

  getChartsLineUserCreationCount(statsData: { [key: string]: number }): any {
    let config_obj = {};
    const defaultQueueGraphConfig = this.getDefaultQueueGraphConfig(_ti('highchart.total_user_created'));

    config_obj = _.extend(config_obj, defaultQueueGraphConfig);

    const invoiceValue = [];
    const xAxis = [];

    // Foreach month of data
    for (const m of Object.keys(statsData)) {
      const value = statsData[m];
      invoiceValue.push([_ti('enterprise.content.evolution_total_user_created'), value]);
      xAxis.push(m);
    }

    config_obj['series'] = [
      {
        name: _ti('enterprise.content.evolution_total_user_created'),
        type: 'column',
        yAxis: 0,
        data: invoiceValue,
        color: this.colors.evolution_user_creation_count
      }
    ];
    config_obj['xAxis'] = { categories: xAxis, labels: this.default_label_config };

    return config_obj;
  }

  getChartsLineTotalInvoiceValue(statsData: { [key: string]: number }): any {
    let config_obj = {};
    const defaultQueueGraphConfig = this.getDefaultQueueGraphConfig(_ti('highchart.total_invoice_value'));

    config_obj = _.extend(config_obj, defaultQueueGraphConfig);

    const invoiceValue = [];
    const xAxis = [];

    // Foreach month of data
    for (const m of Object.keys(statsData)) {
      const value = statsData[m];
      invoiceValue.push([_ti('enterprise.content.evolution_invoice_value'), value]);
      xAxis.push(m);
    }

    config_obj['series'] = [
      {
        name: _ti('enterprise.content.evolution_invoice_value'),
        type: 'column',
        yAxis: 0,
        data: invoiceValue,
        color: this.colors.evolution_invoice_value
      }
    ];
    config_obj['xAxis'] = { categories: xAxis, labels: this.default_label_config };

    return config_obj;
  }
}
