<div fxFill fxLayout="column">
  <div class="wrapper">
    <div class="col-left">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of steps | keyvalue; let i=index;">
            <a (click)="mainArea.scrollToElement(step.key)" class="step {{ step.key }}"
              [ngClass]="{'active': mainArea.activeAnchor === step.key}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ step.value | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <ng-container *ngIf="form">
        <form [formGroup]="form">
          <mat-card class="shadow-none " id="_1general" anchorPart>
            <mat-card-header>
              <div mat-card-avatar>
                <h2><i class="material-icons">settings_input_component</i></h2>
              </div>
              <mat-card-title>
                <h2>{{ 'sipphone.content.tab_general' | translate }}</h2>
              </mat-card-title>
              <span fxFlex="auto"></span>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                <section>
                  <mat-slide-toggle formControlName="enabled" color="primary">
                    {{ 'sipphone.content.enabled' | translate }}
                  </mat-slide-toggle>
                </section>
              </div>
            </mat-card-header>
            <mat-card-content class="form-group">
              <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <div fxFlex>
                  <mat-form-field class="full-width">
                    <mat-label>{{'sipphone.content.extension_number'|translate}}</mat-label>
                    <input matInput formControlName="extension"
                      placeholder="{{'sipphone.content.extension_number'|translate}}">
                    <mat-error *ngIf="extensionCtrl.invalid">{{getExtensionErrorMessage()}}</mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <mat-form-field class="full-width">
                    <mat-label>{{'sipphone.content.password'|translate}}</mat-label>
                    <input matInput formControlName="password" placeholder="{{'sipphone.content.password'|translate}}"
                      [type]="hidePassword ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                      {{hidePassword ? 'visibility' : 'visibility_off'}}
                    </mat-icon>

                    <mat-error [flexFormControlError]="form.get('password')"></mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <div fxFlex>
                  <mat-form-field class="full-width">
                    <mat-label>{{'user.content.calling_profile'|translate}}</mat-label>
                    <calling-profile-select-form-input formControlName="calling_profile"
                      placeholder="{{'user.content.calling_profile'|translate }}">
                    </calling-profile-select-form-input>
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <mat-form-field class="full-width">
                    <mat-label>{{'user.content.calling_number'|translate}}</mat-label>
                    <did-select-form-input formControlName="calling_number"
                      placeholder="{{'user.content.calling_number'|translate}}">
                    </did-select-form-input>
                    <button mat-icon-button matSuffix type="button" (click)="removeControlValue('calling_number')"
                      [disabled]="!hasControlValue('calling_number')">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="is_hotel">
                <mat-button-toggle-group fxLayout="row" formControlName="is_hotelroom"
                  class="button-multiple-is_hotelchoice">
                  <mat-button-toggle fxFlex="50" [value]="false" class="button-multiple-choice">
                    {{'sipphone.content.user_name'|translate}}
                  </mat-button-toggle>
                  <mat-button-toggle fxFlex="50" [value]="true" class="button-multiple-choice">
                    {{'sipphone.content.hotel_room'|translate}}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div>
                <mat-form-field class="full-width" *ngIf="!hasControlValue('is_hotelroom')">
                  <mat-label>{{'user_type_names.user'|translate}}</mat-label>
                  <user-select-form-input [hideDisableUsers]='true' formControlName="user"
                    placeholder="{{'user_type_names.user'| translate }}">
                  </user-select-form-input>
                  <button mat-icon-button matSuffix type="button" (click)="removeControlValue('user')"
                    [disabled]="!hasControlValue('user')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="is_hotel" [hidden]="!hasControlValue('is_hotelroom')">
                  <mat-label>{{'sipphone.content.hotel_room'|translate}}</mat-label>
                  <flex-select-hotel-room-form-input formControlName="hotelroom"
                    [disabledOptionFunc]="disabledHotelRoomOptionFunc"
                    placeholder="{{'sipphone.content.hotel_room'|translate }}">
                  </flex-select-hotel-room-form-input>
                  <button mat-icon-button matSuffix type="button" (click)="removeControlValue('hotelroom')"
                    [disabled]="!hasControlValue('hotelroom')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field class="full-width">
                  <mat-label>{{'sipphone.content.description'|translate}}</mat-label>
                  <textarea matInput formControlName="description"
                    placeholder="{{'sipphone.content.description'|translate }}"></textarea>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </form>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>

        <mat-card class="shadow-none" id="_2sip_info" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <h2><i class="material-icons">sip</i></h2>
            </div>
            <mat-card-title>
              <h2>{{ 'sipphone.content.sip_connection_info' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group" fxLayout="column">
            <app-sip-connection-info [sipphone]="sipphone"></app-sip-connection-info>

            <mat-form-field class="full-width">
              <mat-label>{{'sipphone.content.phone_model'|translate}}</mat-label>
              <mat-select [formControl]="form.get('phone_model')">
                <mat-option *ngFor="let phoneModel of phoneModels" [value]="phoneModel.value">
                  {{ phoneModel.label | translate }}
                </mat-option>
              </mat-select>
              <button matSuffix mat-icon-button type="button" (click)="removeControlValue('phone_model', $event)"
                [disabled]="!hasControlValue('phone_model')">
                <mat-icon>clear</mat-icon>
              </button>
              
              <mat-error [flexFormControlError]="form.get('phone_model')"></mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>{{'sipphone.content.mac_address'|translate}}</mat-label>
              <input matInput [formControl]="form.get('mac_addr')" data-ci="mac_addr"
                [placeholder]="'sipphone.content.mac_address'|translate">
              <button matSuffix mat-icon-button type="button" (click)="removeControlValue('mac_addr', $event)"
                [disabled]="!hasControlValue('mac_addr')">
                <mat-icon>clear</mat-icon>
              </button>
              
              <mat-error [flexFormControlError]="form.get('mac_addr')"></mat-error>
            </mat-form-field>
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>

        <mat-card class="shadow-none" id="_3key_config" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <h2><i class="material-icons">settings</i></h2>
            </div>
            <mat-card-title>
              <h2>{{ 'sipphone.content.key_config' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content class="form-group">
            <app-sipphone-key-config #sipphoneKeyConfig *ngIf="phoneCustomSettings" [settings]="phoneCustomSettings"
              [sipphone]="sipphone" (onChange)="onChangeKeyConfig($event)">
            </app-sipphone-key-config>
            <!-- <span class="text-warning" *ngIf="form.get('key_config').invalid">
              {{ 'public.message.data_invalid'|translate }}
            </span> -->
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>

        <mat-card class="shadow-none" id="_4custom_config" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <h2><i class="material-icons">settings</i></h2>
            </div>
            <mat-card-title>
              <h2>{{ 'sipphone.content.custom_config' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>


          <mat-card-content class="form-group">
            <app-sipphone-custom-config #sipphoneCustomConfig *ngIf="phoneCustomSettings"
              [settings]="phoneCustomSettings" [sipphone]="sipphone"
              (onChange)="onChangeCustomConfig($event)"></app-sipphone-custom-config>
          </mat-card-content>

        </mat-card>
        <mat-divider [inset]="true" class="section-divider"></mat-divider>

        <mat-card class="shadow-none" id="_5location" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <h2><i class="material-icons">phone</i></h2>
            </div>
            <mat-card-title>
              <h2>{{ 'sipphone.content.tab_location' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <app-registered-sip-phones [sipPhoneExtension]="sipphone.extension"
              [enableRestartSipPhone]="currentUser.isAdmin() || sipphone.user_id == currentUser.id" class="mb-1">
            </app-registered-sip-phones>

            <app-sipphone-connection-log [sipphone]="sipphone"></app-sipphone-connection-log>
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>

        <mat-card class="shadow-none" id="_6logs" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <h2><i class="material-icons">history</i></h2>
            </div>
            <mat-card-title>
              <h2>{{ 'sipphone.content.tab_logs' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <div fxLayout="row">
              <div fxFlex="33.3"></div>
              <div fxFlex="33.3">
                <button mat-raised-button class="full-width" (click)="openSipPhoneLogs()" color="accent">
                  {{ 'public.open' | translate }}
                </button>
              </div>
              <div fxFlex="33.3"></div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #tplDataInvalid>
  {{ 'public.message.data_invalid'|translate }}
</ng-template>
