import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ElementRef, Optional } from '@angular/core';
import { FlexMatInputBase } from '@wephone-utils';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'object-condition-select',
  templateUrl: './object-condition-select.html',
  styleUrls: ['./object-condition-select.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: ObjectConditionSelect }]
})
export class ObjectConditionSelect extends FlexMatInputBase implements OnInit, MatFormFieldControl<any> {

  objectConditionFrom: FormGroup;

  constructor(
    elRef: ElementRef,
    @Optional()
    ngControl: NgControl,
    private fb: FormBuilder,
  ) {
    super(ngControl, elRef);

    this.objectConditionFrom = this.fb.group({
      field: [],
      op: [],
      operand: [],
    });
  }

  ngOnInit(): void {
    this.objectConditionFrom.valueChanges.pipe(
      takeUntil(this._onDestroy)
    )
    .subscribe(values => {
      const objectCondition = this.value;
      objectCondition.field = values['field'];
      objectCondition.op = values['op'];
      objectCondition.operand = values['operand'];
      this.value = objectCondition;
    });
  }

  setFormData(val): void {
    this.fieldControl.setValue(val.field, { emitEvent: false });
    this.opControl.setValue(val.op, { emitEvent: false });
    this.operandControl.setValue(val.operand, { emitEvent: false });
  }

  get controlType(): string {
    return 'object-condition-select';
  }

  get fieldControl() {
    return this.objectConditionFrom.get('field');
  }

  get opControl() {
    return this.objectConditionFrom.get('op');
  }

  get operandControl() {
    return this.objectConditionFrom.get('operand');
  }

  writeValue(val: any): void {
    const dt = _.cloneDeep(val);
    this.setFormData(dt);

    super.writeValue(dt);
  }
}
