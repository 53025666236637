import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ICallQueueEntity } from '@wephone-core/model/entity/callqueue.i';
import { QueueTransferNumberEntity } from '@wephone-core/model/entity/queue_transfernumber';
import { FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { ToastService, EditingComponent } from '@wephone-utils';
import { QueueEditAdvanceComponent } from '@wephone/components/call-queue/queue-edit-advance/queue-edit-advance.component';
import { QueueEditGeneralComponent } from '@wephone/components/call-queue/queue-edit-general/queue-edit-general.component';
import * as _ from 'lodash';
import { StorageService } from '@wephone-core/service/storage.service';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { QueueTransferNumberRepository } from '@wephone-core/model/repository/queue_transfernumber';
import { TranslateService } from '@ngx-translate/core';
import { _tk, _ti } from '@wephone-translation';
import { VoiceMailBoxEntity } from '@wephone-core/model/entity/voicemail_box';
import { VoiceMailBoxRepository } from '@wephone-core/model/repository/voicemail_box';

interface QueueDataToUpdate {
  queue: CallQueueEntity;
  extra: object;
}

@Component({
  selector: 'call-queue-multi-edit',
  templateUrl: './call-queue-multi-edit.component.html',
  styleUrls: ['./call-queue-multi-edit.component.scss']
})
export class CallQueueMultiEditComponent extends EditingComponent implements OnInit {
  static TabSelected: string[] = ['tab_queue', 'current_tab'];

  ALLOW_SCROLL = false;
  MULTI_EDITOR = true;

  @Input() editingList: CallQueueEntity[];

  @ViewChild('advanceQueueEditor') advanceQueueEditor: QueueEditAdvanceComponent;
  @ViewChild('generalQueueEditor') generalQueueEditor: QueueEditGeneralComponent;

  // Public member
  dummyQueue: ICallQueueEntity;
  // dummyVmBox: VoiceMailBoxEntity;
  tabSelectedIndex = 0;
  readonly isCallCenter: boolean;

  queueRepo = CallQueueRepository.getInstance<CallQueueRepository>();
  vmBoxRepo = VoiceMailBoxRepository.getInstance<VoiceMailBoxRepository>();

  constructor(
    public toast: ToastService,
    public storage: StorageService,
    flexIvrSettings: FlexIvrSettings
  ) {
    super();
    this.isCallCenter = flexIvrSettings.uiCallCenter();
  }

  setEditingData(data: any): void {
    super.setEditingData(data);
    this.resetDummyQueueValue();
    this.resetForm();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.resetDummyQueueValue();
    this.updateTabSelectedIndex(this.tabSelectedIndex);
  }

  private resetDummyQueueValue(): void {
    this.dummyQueue = this.queueRepo.create() as CallQueueEntity;
    const dummyVmBox = this.vmBoxRepo.create() as VoiceMailBoxEntity;
    this.dummyQueue.mailbox = dummyVmBox;

    const fieldsToEdit = this.getUpdateFields();
    for (const k of Object.keys(fieldsToEdit)) {
      if (!fieldsToEdit.hasOwnProperty(k)) {
        continue;
      }

      let isVoicemailBoxField = false;
      if (_.includes([
        'voicemail_by_mail_enabled',
        'voicemail_in_attachement',
        'voicemail_by_mail_addrs'
      ], k)) {
        isVoicemailBoxField = true;
      }

      const keyName = fieldsToEdit[k] || k;
      let sameValue = true;

      const firstItem: CallQueueEntity | VoiceMailBoxEntity = !isVoicemailBoxField ? this.editingList[0] : this.editingList[0].mailbox;

      for (let i = 1; i < this.editingList.length; i++) {
        const comparedItem: CallQueueEntity | VoiceMailBoxEntity = !isVoicemailBoxField ? this.editingList[i] : this.editingList[i].mailbox;
        if (!comparedItem || !firstItem) {
          sameValue = false;
          break;
        }

        sameValue = _.isEqual(comparedItem[keyName], firstItem[keyName]);

        if (!sameValue) {
          break;
        }
      }

      if (sameValue) {
        if (isVoicemailBoxField) {
          this.dummyQueue.mailbox[keyName] = firstItem[keyName];
        } else {
          this.dummyQueue[keyName] = firstItem[keyName];
        }
      }
    }
  }

  updateTabSelectedIndex(tabIndex: number): void {
    this.tabSelectedIndex = tabIndex || 0;

    const value = {};
    value[CallQueueMultiEditComponent.TabSelected[1]] = this.tabSelectedIndex;
  }

  formHasChanged(): boolean {
    const editors = [
      this.generalQueueEditor,
      this.advanceQueueEditor
    ];
    for (const editor of editors) {
      if (editor && editor.formHasChanged()) {
        return true;
      }
    }

    return false;
  }

  resetForm(): void {
    if (this.generalQueueEditor && this.advanceQueueEditor) {
      this.generalQueueEditor.resetForm();
      this.advanceQueueEditor.resetForm();
      this.onFormValueChange();
    }
  }

  get noQueueId(): boolean {
    return !this.dummyQueue || !this.dummyQueue.id;
  }

  private getUpdateFields(): object {
    return {
      // General
      queue_name: undefined,
      group: 'group_id',
      opening_calendar: 'opening_hour_calendar_id',
      agenda_closed_config: undefined,
      max_inqueue: 'max_inqueue_time',
      voicemail_enabled: undefined,
      voicemail_by_mail_enabled: undefined,
      voicemail_in_attachement: undefined,
      voicemail_by_mail_addrs: undefined,
      recording_mode: undefined,
      queue_priority: undefined,
      silent: undefined,
      languages: undefined,
      opening_hour_file_id: undefined,
      no_agent_file_id: undefined,
      moh_file_id: undefined,
      greeting_file_id: undefined,
      waiting_music_file_id: undefined,
      play_entire_welcome: undefined,
      eoc_ivr_menu: 'eoc_survey_id',
      aftercall_pause_mode: undefined,
      after_call_time: undefined,
      has_qualification: undefined,
      qualification_required: undefined,
      in_qualification: 'in_qualification_id',
      out_qualification: 'out_qualification_id',
      bo_type: undefined,
      bo_url: undefined,
      transfer_numbers: undefined,

      // Advance
      alias: undefined,
      xapi_agent_search: undefined,
      agent_display_number_type: undefined,
      agent_display_number: 'agent_display_number_id',
      is_exclusive: undefined
    };
  }

  private getQueueToUpdate(updatedData: object[]): QueueDataToUpdate {
    const updatedQueue = this.queueRepo.create() as CallQueueEntity;
    const fields = this.getUpdateFields();

    updatedQueue.id = this.dummyQueue.getId();

    const extraFields: string[] = [
      'voicemail_by_mail_enabled',
      'voicemail_in_attachement',
      'voicemail_by_mail_addrs'
    ];

    const ret: QueueDataToUpdate = {
      queue: updatedQueue,
      extra: {},
    };

    for (const data of updatedData) {
      for (const field of Object.keys(data)) {
        if (!data.hasOwnProperty(field) && !(field in fields)) {
          console.warn('Field not in updated', field);
          continue;
        }

        let value: any = data[field];
        let queueField: string = field;
        switch (field) {
          case 'has_qualification':
          case 'voicemail_by_mail_enabled':
          case 'voicemail_in_attachement':
          case 'recording_mode':
          case 'qualification_required':
          case 'voicemail_enabled':
          case 'play_entire_welcome':
            value = data[field] ? 1 : 0;
            // updatedQueue[field] = value;
            break;
          case 'transfer_numbers':
            const transfer_numbers = [];
            if (data[field].length) {
              for (const i of data[field]) {
                const transfer_number = QueueTransferNumberRepository.getInstance<
                  QueueTransferNumberRepository
                >().create() as QueueTransferNumberEntity;
                transfer_number.setObjectData(i);
                transfer_number.id = i.id || undefined;
                transfer_numbers.push(transfer_number);
              }
            }
            // updatedQueue[field] = transfer_numbers;
            value = transfer_numbers;
            break;
          case 'voicemail_by_mail_addrs':
            const mail_addrs = [];
            if (data[field].length) {
              for (const i of data[field]) {
                mail_addrs.push(i.mail_addr);
              }
            }
            // updatedQueue[field] = mail_addrs;
            value = mail_addrs;
            break;
          case 'max_inqueue':
            // updatedQueue[fields[field]] = data[field] || 0;
            queueField = fields[field];
            value = data[field] || 0;
            break;

          // case 'languages':
          //   updatedQueue[field] = data[field];
          //   break;
          case 'defaultDid':
            // updatedQueue[fields[field]] = data[field] || 0;
            value = data[field] || 0;
            break;

          default:
            if (data[field] === data['defaultDid']) {
              // updatedQueue['default_did'] = data[field];
              queueField = 'default_did';
            } else {
              // updatedQueue[field] = data[field];
            }
            value = data[field];
            break;
        }

        if (_.includes(extraFields, field)) {
          ret.extra[field] = value;
        } else {
          ret.queue[queueField] = value;
        }
      }
    }

    return ret;
  }

  async saveQueue(editorList: any[]): Promise<any> {
    const updatedData = [];
    for (const editor of editorList) {
      if (editor) {
        updatedData.push(editor.getChangeSet());
      }
    }

    const update_queue_fields = [] as string[];
    const fields = this.getUpdateFields() as Object;

    for (const data of updatedData) {
      for (const field of Object.keys(data)) {
        if (fields.hasOwnProperty(field)) {
          update_queue_fields.push(fields[field] ? fields[field] : field);
        }
      }
    }

    const updatedQueueData: QueueDataToUpdate = this.getQueueToUpdate(updatedData);
    const updatedQueue: CallQueueEntity = updatedQueueData.queue;
    const extraData: object = updatedQueueData.extra;

    if (!update_queue_fields.length) {
      console.warn('Update data not found');

      return;
    }

    // validation data prevent submit
    for (const editor of editorList) {
      // Only check valid the submitted key
      if (editor && !editor.isValid(update_queue_fields, Object.keys(update_queue_fields))) {
        // this.toast.showError(_ti('public.message.update_failure'));
        throw new Error(_ti('public.message.update_failure'));
      }
    }

    console.log('editingList', this.editingList);
    const ids: number[] = this.editingList.map(i => i.id);

    const response = await this.queueRepo.saveAttrsBulk(ids, updatedQueue, update_queue_fields, extraData);
    console.log('saveAttrsBulk', response);
    for (const id of response.object_ids) {
      const queue: CallQueueEntity = this.queueRepo.getObjectById(id);
      const editedItem = this.editingList.find(x => x.id === id);
      _.extend(editedItem, queue);
    }
    this.resetDummyQueueValue();

    for (const editor of editorList) {
      editor.clearEditState();
      // console.log('has changed', editor.formHasChanged());
    }
    return response;
  }

  async submitForm(): Promise<CallQueueEntity> {
    const editorList = [
      this.generalQueueEditor,
      this.advanceQueueEditor
    ];

    try {
      const returnedData = await this.saveQueue(editorList);
      this.toast.showSuccess(_ti('call_queue.message.update_success'));

      const queueId = returnedData['object_ids'][0];
      const queueUpdated = this.queueRepo.getObjectById(queueId);

      for (const editor of editorList) {
        if (editor) {
          editor.queue = queueUpdated;
        }
      }
      this.resetForm();

      return Promise.resolve(returnedData);
    } catch (e) {
      console.error('Update multiple queue failure', e);
      const msg: string = e && e.message || e && e.error && e.error.message;
      if (msg) {
        this.toast.showError(msg);
      }
    }
  }
}
