import { Component, ChangeDetectorRef, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActionButton, DialogService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { AddCreditCardComponent } from '../add-credit-card/add-credit-card.component';
import { AddSepaDebitComponent } from '../add-sepa-debit/add-sepa-debit.component';

@Component({
  selector: 'update-payment-method',
  templateUrl: './update-payment-method.component.html',
  styleUrls: ['./update-payment-method.component.scss']
})
export class UpdatePaymentMethodModal extends DialogComponentBase {
  dialogTitle = _tk('enterprise_info.update-payment-method.title');
  dialogRightActions: Array<DialogActionButton> = undefined;

  constructor(
    private dialogRef: MatDialogRef<UpdatePaymentMethodModal>,
    @Inject(MAT_DIALOG_DATA) public data: {
      extend_subscription_pack: string
    },
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  updatePayment(type: string): void {
    let ref: MatDialogRef<any>;

    if (type === 'card') {
      ref = this.dialogService.openDialog2(AddCreditCardComponent, { hasBackdrop: true, size: 's', data: this.data });
    } else if (type === 'sepa') {
      ref = this.dialogService.openDialog2(AddSepaDebitComponent, { hasBackdrop: true, size: 's', data: this.data });
    }

    ref.afterClosed().subscribe((data: any) => {
      this.dialogRef.close(data);
    });
  }
}
