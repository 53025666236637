import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { LocalManager } from '@wephone-core/service/local_manager';
import { FlexBasePage } from '@wephone-core-ui';
import { DialogService, parseDateTime } from '@wephone-utils';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { PaymentService } from '@wephone/services/payment.service';
import { PaymentSourceEntity } from '@wephone-core/model/entity/payment_source';
import { UpdatePaymentMethodModal } from '@wephone/modals/update-payment-method/update-payment-method.component';
import { TranslateService } from '@ngx-translate/core';
import { _ti } from '@wephone-translation';

@Component({
  selector: 'enterprise-payment-info',
  templateUrl: 'enterprise-payment-info.html',
  styleUrls: ['enterprise-payment-info.scss']
})
export class EnterprisePaymentInfo extends FlexBasePage implements OnInit {
  private enterpriseRepo = EntityManager.getRepository<EnterpriseRepository>('EnterpriseRepository') as EnterpriseRepository;
  myEnterprise: EnterpriseEntity;
  myPaymentSource: PaymentSourceEntity;

  constructor(
    public readonly localManager: LocalManager,
    private readonly dialogService: DialogService,
    private readonly paymentService: PaymentService,
    private readonly translate: TranslateService,
  ) {
    super();
    this.myEnterprise = this.enterpriseRepo.getMyEnterprise();
    console.log('My Enterprise: ', this.myEnterprise);
    this.init();
  }

  private async init(): Promise<void> {
    this.myPaymentSource = await this.paymentService.mySource();
    console.log('My Payment Source: ', this.myPaymentSource);
  }

  updatePaymentInfo(): void {
    const dialogRef = this.dialogService.openDialog2(UpdatePaymentMethodModal, { hasBackdrop: true, size: 's' });
    dialogRef.afterClosed().subscribe(async () => {
      await this.init();
    });
  }

  deletePaymentInfo(): Promise<any> {
    return this.dialogService.confirmDialog(
      this.translate.instant('dialogs.confirmation'),
      this.translate.instant('payment_wizard.message.confirm_delete_card'),
      async () => {
        try {
          const deleteResp: any = await this.paymentService.deleteSource();
          await this.init();
          console.log('Delete payment response', deleteResp);
          this.showInfo('Remove credit card successfully');
        } catch (e) {
          console.error('makePaymentInvoice', e);
          this.showError(e.message || 'Remove credit card failure');
        }
      }
    );
  }
}
