import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FlexBaseComponent } from '@wephone-core-ui';
import { DidEntity } from '@wephone-core/model/entity/did';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DidRepository } from '@wephone-core/model/repository/did';
import * as _ from 'lodash';
import { IRouteToData } from '../did-route-to-data.i';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { _ti } from '@wephone-translation';
import { ConferenceSelectFormInput, IvrMenuSelectFormInput, QueueSelectFormInput } from '@wephone-common';
import { DialogService, NoWhitespaceValidator } from '@wephone-utils';
import { DidConfigWizardModalComponent } from '../../../modals/did/did-config-wizard-modal/did-config-wizard-modal.component';
import { IRoutingApp, IRoutingAppDestinationData } from '@wephone-core/routing-app/routing-app.interface';
import { FormControl, Validators } from '@angular/forms';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { ICallQueueEntity } from '@wephone-core/model/entity/callqueue.i';
import { Router } from '@angular/router';
import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ResponsedItem } from '@wephone-core/model/model.interface';

@Component({
  selector: 'did-setup-wizard',
  templateUrl: './did-setup-wizard.component.html',
  styleUrls: ['./did-setup-wizard.component.scss']
})
export class DidSetupWizardComponent extends FlexBaseComponent implements OnInit, OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input('did') inputDid: DidEntity;
  @Output() readonly onFinish: EventEmitter<any> = new EventEmitter();

  stepActive = 1;

  did: DidEntity;
  didRepo: DidRepository;
  moreRouteOption = false;
  dedicatedDidIds: number[] = [];

  routeTo: IRouteToData;
  message: string;

  didNameControl: FormControl;

  @ViewChild('queueSelectInput', { static: false }) queueSelectInput: QueueSelectFormInput;
  @ViewChild('conferenceSelectInput', { static: false }) conferenceSelectInput: ConferenceSelectFormInput;
  @ViewChild('ivrmenuSelectInput', { static: false }) ivrmenuSelectInput: IvrMenuSelectFormInput;

  mapDestinationParamName = {
    call_phone_number: 'phone_number',
    call_phone: 'sipphone',
    call_user: 'user',
    callqueue: 'queue',
    ivr_custom_menu: 'ivrmenu',
    conference: 'conference',
    runvxmlscript: 'ivrscript',
    remote_application: 'remote_application',
    play_then_hangup: 'file_entry',
    webservice: 'url',
    crm_route: 'crm_routing_rule'
  };

  ivrmenuRepo: IvrCustomMenuRepository;

  constructor(
    private em: EntityManager,
    private dialogService: DialogService,
    cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    super(cdr);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('didConfigWizard onChange changes', changes);

    if (changes.inputDid) {
      // reset did name after clear did on the edit page
      if (this.did) {
        this.did.name = changes.inputDid.currentValue.name;
      }
      this.stepActive = 1;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.ivrmenuRepo = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository');
    this.didRepo = this.em.getRepository<DidRepository>('DidRepository');
    this.did = _.cloneDeep(this.inputDid);

    this.dedicatedDidIds = (this.em.getRepository<DidRepository>('DidRepository') as DidRepository).getDidListForDedicated().map(x => x.id);
    if (this.did.id && _.includes(this.dedicatedDidIds, this.did.id)) {
      this.dedicatedDidIds.splice(this.dedicatedDidIds.indexOf(this.did.id), 1);
    }

    this.didNameControl = new FormControl(this.did.name, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]);

    if (this.did.name) {
      this.stepActive = 2;
    }

    this._initRouteData();
  }

  private _initRouteData(): void {
    this.routeTo = {
      destination: undefined
    };
    if (this.did.hasRoutingData() && !_.isEmpty(this.did.action_office_hour) && !_.isEmpty(this.did.action_office_hour.application)) {
      switch (this.did.action_office_hour.application) {
        case 'call_phone_number':
          this.routeTo.destination = 'call_phone_number';
          this.routeTo.phone_number = this.did.routed_phone_number;
          break;
        case 'callqueue':
          this.routeTo.destination = 'callqueue';
          this.routeTo.queue = this.did.routed_queue;
          break;
        case 'ivr_custom_menu':
          this.routeTo.destination = 'ivr_custom_menu';
          this.routeTo.ivrmenu = this.did.routed_menu;
          break;
        case 'call_phone':
          this.routeTo.destination = 'call_phone';
          this.routeTo.sipphone = this.did.routed_sipphone as SipPhoneEntity;
          break;
        case 'conference':
          this.routeTo.destination = 'conference';
          this.routeTo.conference = this.did.routed_conference;
          break;
        default:
          break;
      }
    }
  }

  setRouteApplication(appName: string): void {
    this.routeTo.destination = appName;

    if (this.routeTo.destination === 'ivr_custom_menu' && this.ivrmenuRepo.getMenuList().length === 0) {
      this.createIvrMenu();
      return;
    }

    if (this.routeTo.destination === 'callqueue' && (this.em.getRepository<CallQueueRepository>('CallQueueRepository') as CallQueueRepository)
      .getInboundQueues().length === 0) {
      this.createQueue();
      return;
    }

    this.nextStep();
  }

  setOtherDestination(): void {
    const data = {
      call_destination: this.did.action_office_hour,
      full_option: false
    };
    this.dialogService.openSideDialog(DidConfigWizardModalComponent, { data }, (res: { app?: IRoutingApp, masterDid?: DidEntity }) => {
      if (!res) {
        console.warn('No response');
        return;
      }
      const app: IRoutingApp = res && res.app;

      if (res.masterDid) {
        this.did.master_did = res.masterDid;
        this.did.action_not_office_hour = null;
        this.did.action_office_hour = null;
      } else if (app) {
        this.did.master_did = null;
        this.setDidRoutingData(app);
      } else {
        console.warn('Application not found');
        return;
      }

      this.saveDid();
    });
  }

  nextStep(): void {
    this.stepActive += 1;
  }

  prevStep(): void {
    if (this.stepActive > 1) {
      this.stepActive -= 1;
    }
  }

  async saveDidName(): Promise<void> {
    try {
      const existName: DidEntity = this.didRepo.getDidByName(this.didNameControl.value, this.did && this.did.id);
      if (existName) {
        this.didNameControl.setErrors({ name_exist: true });
      }

      if (this.didNameControl.invalid) {
        this.didNameControl.markAsTouched();
        throw new Error(_ti('public.message.data_invalid'));
      }

      this.did.name = _.trim(this.didNameControl.value);

      const ret = await this.didRepo.saveAttrs(this.did, ['name']);
      if (ret) {
        this.onFinish.emit({ name: this.did.name });
        this.nextStep();
        this._clearMessage();
        this.cdr.markForCheck();
      }
    } catch (e) {
      console.error('Save did error', e);
      // const msg = e && e.error && e.error.message || e && e.message || _ti('public.message.update_failure');
      this.showErrorMessage(e, _ti('public.message.update_failure'));
    }
  }

  private _validData(): boolean {
    this._clearMessage();
    if (!this.routeTo.destination) {
      return false;
    }

    switch (this.routeTo.destination) {
      case 'callqueue':
        if (this.routeTo.destination === 'callqueue' && !this.routeTo.queue) {
          this.message = _ti('dialogs.did_setup_wizard.validator.queue_required');
        }
        break;
      case 'conference':
        if (!this.routeTo.conference) {
          this.message = _ti('dialogs.did_setup_wizard.validator.conference_required');
        }
        break;

      case 'ivr_custom_menu':
        if (!this.routeTo.ivrmenu) {
          this.message = _ti('dialogs.did_setup_wizard.validator.ivrmenu_required');
        }
        break;

      case 'call_phone':
        if (!this.routeTo.sipphone) {
          this.message = _ti('dialogs.did_setup_wizard.validator.sipphone_required');
        }
        break;

      case 'call_phone_number':
        if (!this.routeTo.phone_number) {
          this.message = _ti('dialogs.did_setup_wizard.validator.phone_number_required');
        }
        break;

      case 'alias':
        if (!this.did.master_did) {
          this.message = _ti('dialogs.did_setup_wizard.validator.master_did_required');
          break;
        }
        break;

      default:
        this.message = _ti('dialogs.did_setup_wizard.validator.unknown');
        break;
    }

    if (this.message) {
      return false;
    }

    return true;
  }

  setDidRoutingData(app?: IRoutingApp): Promise<void> {
    this.did.action_not_office_hour = new IRoutingAppDestinationData();
    this.did.action_office_hour = new IRoutingAppDestinationData();

    if (!_.isEmpty(app)) {
      this.did.action_office_hour = {
        application: app.getAppName(),
        params: app.getAppParams()
      };
    } else {
      // if (this.routeTo.destination !== 'ivr_custom_menu') {
      //   return;
      // }

      // this.did.routeToMenu(this.routeTo.ivrmenu);

      switch (this.routeTo.destination) {
        // {"application":"callqueue","params":{"queue":224}}
        case 'callqueue':
          this.did.routeToQueue(this.routeTo.queue);
          break;
        case 'call_phone':
          this.did.routeToSipPhone(this.routeTo.sipphone);
          break;

        // {"application":"conference","params":{"conference":2}}
        // case 'conference':
        //   this.did.routeToConference(this.routeTo.conference);
        //   break;

        // {"application":"ivr_custom_menu","params":{"id":41}}
        case 'ivr_custom_menu':
          this.did.routeToMenu(this.routeTo.ivrmenu);
          break;

        // {"application":"call_phone_number","params":{"number":"123456"}}
        // case 'call_phone_number':
        //   this.did.routeToPhoneNumber(this.routeTo.phone_number);
        //   break;

        // case 'alias':
        //   break;

        default:
          console.warn('No destination for routing');
          return;
      }
    }
  }

  updateDidConfig(): Promise<void> {
    if (!this._validData()) {
      return;
    }

    this.setDidRoutingData();

    return this.saveDid();
  }

  async saveDid(): Promise<void> {
    try {
      // await this.didRepo.save(this.did);
      const updateFields = [
        'action_office_hour', 'action_not_office_hour',
        'linked_object_id', 'linked_object_type',
        'master_did_id', 'same_routing',
      ];
      await this.didRepo.saveAttrs(this.did, updateFields);
      this.successToast(_ti('public.message.update_success'));
      this.onFinish.emit({ id: this.did.id });
    } catch (e) {
      console.error('Cannot update DID: ', e);
      // const msg = e && e.error && e.error.message || e && e.message || _ti('public.message.update_failure');
      this.showErrorMessage(e, _ti('public.message.update_failure'));
    }
  }

  async createQueue(): Promise<any> {
    const queueRepo = this.em.getRepository<CallQueueRepository>('CallQueueRepository');
    const newQueueObject = {
      queue_name: this.did.name,
      default_did_id: this.did.id,
      queue_priority: 2,
      max_inqueue_time: 0,
      after_call_time: 0,
      bo_type: 0,
    };

    // let queue: ICallQueueEntity = queueRepo.create() as ICallQueueEntity;
    // queue.queue_priority = 2;
    // queue.max_inqueue_time = 0;
    // queue.after_call_time = 0;
    // queue.default_did_id = this.did.id;
    // queue.bo_type = 0;
    // queue.queue_name = this.did.name;
    // queue.eoc_survey_min_duration = undefined; // Not request this field

    try {
      const response: ResponsedItem = await queueRepo.createAndSave(newQueueObject);
      // const response: { object_id: number } = await queueRepo.save(queue);
      if (response) {
        // this.queueSelectInput.setQueueList();
        // queue = queueRepo.getObjectById<CallQueueEntity>(response.object_id);
        this.routeTo.queue = queueRepo.getObjectById<CallQueueEntity>(response.object_id);

        await this.updateDidConfig();

        this.gotoEditDidPage();
      }
    } catch (e) {
      console.error('Cannot create Queue: ', e);
      // const msg = e && e.error && e.error.message || e && e.message || _ti('public.message.create_failure');
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  // async createConference(): Promise<any> {
  //   const conferenceRepo = this.em.getRepository<ConferenceRepository>('ConferenceRepository');
  //   const conference: ConferenceEntity = conferenceRepo.create() as ConferenceEntity;
  //   conference.name = this.did.name;

  //   try {
  //     const resp = await conferenceRepo.save(conference);
  //     if (resp && resp.item) {
  //       this.conferenceSelectInput.setConferenceList();
  //       this.routeTo.conference = conferenceRepo.getObjectById(resp.item.id);

  //       return Promise.resolve(this.routeTo.conference);
  //     }
  //   } catch (e) {
  //     console.error('Cannot create conference: ', e);
  //     if (e && e.error && e.error.message) {
  //       this.showError(e.error.message);
  //     }
  //   }
  // }

  async updateConfigRoute($event): Promise<any> {
    console.log('didSelectChanged', $event);
    if (!$event) {
      console.error('No item selected');
      return;
    }
    await this.updateDidConfig();
    this.gotoEditDidPage();
  }

  async createIvrMenu(): Promise<any> {
    const ivrmenuObject: any = {
      name: this.getNewIvrMenuName(),
      ivrscript_type: IvrCustomMenuEntity.TYPE_IVRMENU
    };

    try {
      const ret = await this.ivrmenuRepo.createAndSave(ivrmenuObject);
      if (!_.isEmpty(ret)) {
        // this.ivrmenuSelectInput.setIvrMenuList();
        const returnedIvrmenu: IvrCustomMenuEntity = this.ivrmenuRepo.getObjectById(ret.id);
        this.routeTo.ivrmenu = returnedIvrmenu;

        await this.updateDidConfig();

        this.gotoEditDidPage();
        // this.showInfo(_ti('public.message.create_success'));
      }
    } catch (e) {
      console.error('Cannot create ivr-menu: ', e);
      // let msg = e && e.message || _ti('public.message.create_failure');
      // if (e && e.error && e.error.error && e.error.error.message) {
      //   msg = e.error.error.message;
      // }
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }

  private getNewIvrMenuName(): string {
    let newName = this.did.name;
    const nameList = this.ivrmenuRepo.getObjectList().map(ivr => ivr.name);
    let count = 1;

    while (nameList.includes(newName)) {
      newName = `${this.did.name} ${count}`;
      count++;
    }

    return newName;
  }

  private _clearMessage(): void {
    this.message = undefined;
  }

  // async routeToQueue(): Promise<any> {
  //   try {
  //     return this.routeToExistQueue();

  //     // const ret = await this.didService.routeToQueue(this.did.id);
  //     // if (!_.isEmpty(ret)) {
  //     //   this.did = _.cloneDeep(this.didRepo.getObjectById(this.did.id));
  //     //   this.successToast(_ti('public.message.update_success'));
  //     //   this.onFinish.emit({ id: this.did.id });
  //     // }
  //   } catch (e) {
  //     const msg = e && e.error && e.error.message || e && e.message || _ti('public.message.update_failure');
  //     this.showError(msg);
  //   }
  // }

  // routeToExistQueue(): void {
  //   this.routeTo.destination = 'callqueue';

  //   this.nextStep();
  // }

  gotoEditDidPage(): void {
    this.router.navigateByUrl(`number-routing/${this.did.id}`);
  }
}
