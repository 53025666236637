
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogActionButton, Colors, IFlexDialogConfig, joiValidator, NoWhitespaceValidator } from '@wephone-utils';
import * as Joi from 'joi-browser';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';
import { EntityManager } from '@wephone-core/wephone-core.module';
import * as _ from 'lodash';

@Component({
  selector: 'app-create-hotelroom-modal',
  templateUrl: './create-hotelroom-modal.component.html',
  styleUrls: ['./create-hotelroom-modal.component.scss']
})
export class CreateHotelroomModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'xs'
  };

  dialogTitle = _tk('hotelroom.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  public form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<CreateHotelroomModalComponent>,
    private readonly em: EntityManager,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      room_number: [undefined, [joiValidator(Joi.string().min(1).max(10).required()), NoWhitespaceValidator]],
      has_sipphone: [true]
    });
  }

  async submitForm(): Promise<void> {
    try {
      if (!this.form.get('room_number').invalid && !!this.em.getRepository<HotelRoomRepository>('HotelRoomRepository').getExistRoomNumber(this.form.get('room_number').value, null)) {
        this.form.get('room_number').setErrors({ exists: true });
      }

      if (!this.form.valid) {
        this.form.markAllAsTouched();
        throw new Error(_ti('public.message.data_invalid'));
      }

      const postData = { room_number: _.trim(this.form.value.room_number) };
      const extendData = { has_sipphone: this.form.value.has_sipphone ? 1 : 0 };
      const res = await this.em.getRepository<HotelRoomRepository>('HotelRoomRepository').createAndSave(postData, extendData);
      this.successToast(_ti('public.message.create_success'));
      this.dialogRef.close(res);
    } catch (e) {
      console.error('Ceate room numbers errors', e);
      this.showErrorMessage(e, _ti('public.message.create_failure'));
    }
  }
}
