import { Component, isDevMode, OnDestroy, ViewChild } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { DidEntity } from '@wephone-core/model/entity/did';
import { FormControl, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { DidRepository } from '@wephone-core/model/repository/did';
import { takeUntil } from 'rxjs/operators';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import * as _ from 'lodash';
import { MessageService, DialogService, ToastService, IFlexTableConfig, localNow, parseDateTime, PhoneNumberService, FlexTableComponent } from '@wephone-utils';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { RecordingService } from '@wephone-core/service/recording_service';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { FlexBasePage } from '@wephone-core-ui';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { _tk, _ti } from '@wephone-translation';
import { DateAdapter } from '@angular/material/core';
import { SystemParam } from '@wephone-core/system';
@Component({
  selector: 'app-recording-call-agent',
  templateUrl: './recording-call-agent.component.html',
  styleUrls: ['./recording-call-agent.component.scss']
})
export class RecordingCallAgentComponent extends FlexBasePage implements OnDestroy {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  private didRepo = DidRepository.getInstance() as DidRepository;
  private quanlifiRepo = QualificationRepository.getInstance() as QualificationRepository;
  private _onDestroy = new Subject<void>();

  gridOptions: GridOptions;
  didList: DidEntity[] = [];
  quanlifi = [];
  isInDevMode;

  filteredDidsMulti: ReplaySubject<DidEntity[]> = new ReplaySubject<DidEntity[]>(1);

  filteredQuanlifisMulti: ReplaySubject<QualificationEntity[]> = new ReplaySubject<QualificationEntity[]>(1);
  didMultiFilterCtrl: FormControl = new FormControl();
  qualifiMultiFilterCtrl: FormControl = new FormControl();

  tableConfig: IFlexTableConfig;

  dataSource: FlexDataSource;
  filterForm: FormGroup;
  filtersReadonly: boolean;
  hasAnonymous: boolean;

  constructor(
    public messageService: MessageService,
    private translate: TranslateService,
    public dialogService: DialogService,
    public toast: ToastService,
    private datasourceService: DataSourceService,
    private phoneNumberService: PhoneNumberService,
    private fileEntryService: FileEntryService,
    private recordingService: RecordingService,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    dateAdapter: DateAdapter<Date>,
  ) {
    super();
    this.dateAdapter = dateAdapter;

    this.filterForm = this.fb.group({
      start_dt: localNow().toJSDate(),
      end_dt: localNow().toJSDate(),
      start_time: '00:00',
      end_time: '00:00',
      start_min: 0,
      end_min: 60,
      qualifications: '',
      filter_duration: false,
      local_numbers: [[]],
      audio_state: ''
    });
    this.dataSource = this.datasourceService.createDataSource('pbx_recording_user');
    this.dataSource.setOrder('recorded_dt', 'desc');

    this.isInDevMode = isDevMode();
    const dids = this.didRepo.getObjectList();
    // this.didList = [this.didRepo.getAnonymousDid()];
    this.didList = [];
    for (const item of dids) {
      if (!item.hasLinkedData()) {
        this.didList.push(item);
      }
    }

    this.quanlifi = this.quanlifiRepo.getObjectList();

    const canTakeAction: boolean = this.authService.isAdmin() || this.authService.isSupervisor();
    this.tableConfig = {
      multiSelect: canTakeAction,
      enableExportCsv: canTakeAction,
      columns: [
        { name: 'recorded_dt', label: _tk('recording_call.recorded_date') },
        { name: 'local_number', visible: true, label: _tk('call_queue.content.didroute.did_number') },
        { name: 'duration', label: _tk('recording_call.length') },
        { name: 'remote_number', label: _tk('recording_call.remote_number'), renderCell: this.renderClientNumber, enableTooltip: true, lineClamp: 2 },
        { name: 'direction', label: _tk('recording_call.direction') },
        { name: 'qualification', label: _tk('recording_call.table_filter.qualification'), enableTooltip: true, lineClamp: 2 },
        { name: 'user_ids', label: _tk('voicemail.label_operator') },
        { name: 'action', label: _tk('public.action') }
      ]
    };

    if (canTakeAction) {
      this.tableConfig.listActions = {
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      };
    }
  }

  resolveData(): void {
    this.hasAnonymous = !!ConfigManager.getInstance().getSystemParam(SystemParam.telecom_has_anonymous);

    this.filteredDidsMulti.next(this.didList.slice());
    this.filteredQuanlifisMulti.next(this.quanlifi.slice());

    this.addSubscription(
      this.didMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterDidMulti();
      })
    );

    this.addSubscription(
      this.qualifiMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterQuanlifiMulti();
      })
    );

    this.reloadClick();
  }

  async reloadClick(): Promise<void> {
    const newFilters = this.getFilters();

    if (this.flexTable) {
      // this.dataSource.setFilter(newFilters);
      this.flexTable.reloadDataSource(newFilters);
    } else {
      const rs = await this.dataSource.load(newFilters);
      // if (!rs) {
      //   this.showError(_ti('public.message.error_occurred'));
      //   return;
      // }
    }
    this.filtersReadonly = true;
  }

  private filterDidMulti(): void {
    if (!this.didList) {
      return;
    }

    let search = this.didMultiFilterCtrl.value;
    if (!search) {
      this.filteredDidsMulti.next(this.didList.slice());

      return;
    }

    search = search.toLowerCase();

    this.filteredDidsMulti.next(this.didList.filter(did => did.number_with_name.toLowerCase().indexOf(search) > -1));
  }

  private filterQuanlifiMulti(): void {
    if (!this.quanlifi) {
      return;
    }

    let search = this.qualifiMultiFilterCtrl.value;
    if (!search) {
      this.filteredQuanlifisMulti.next(this.quanlifi.slice());

      return;
    }

    search = search.toLowerCase();

    this.filteredQuanlifisMulti.next(this.quanlifi.filter(quan => quan.value.toLowerCase().indexOf(search) > -1));
  }

  getFilters = (): any => {
    const formData = this.filterForm.value;
    const newFilters = _.cloneDeep(formData);
    newFilters.start_dt = parseDateTime(formData.start_dt).toISODate();
    newFilters.end_dt = parseDateTime(formData.end_dt).toISODate();

    // format local_numbers before apply filters
    newFilters.local_numbers = this.didRepo.getObjectListByIds(formData.local_numbers).map(d => d.display_number);

    if (_.includes(formData.local_numbers, 0)) {
      const anonymousDid = this.didRepo.getAnonymousDid();
      newFilters.local_numbers.unshift(anonymousDid.display_number.toLocaleLowerCase());
    }

    // if (this.dataSource.sort) {
    //   newFilters.order = [{
    //     column: this.dataSource.sort.active,
    //     dir: this.dataSource.sort.direction
    //   }];
    // }

    return newFilters;
  }

  displayPhoneNumber(phoneNumber: string): string {
    return this.phoneNumberService.getDisplayNumber(phoneNumber);
  }

  downloadRecoredCall(item): void {
    const fileName = `recording_call-${item.file_public_id}.mp3`;
    const download_url = this.fileEntryService.getFileUrl(item.file_public_id, true);
    this.fileEntryService.download(download_url, fileName);
  }

  deleteRecoredCall(item: any): void {
    this.dialogService.confirmDialog(
      this.translate.instant('dialogs.confirmation'),
      this.translate.instant('user.title.delete'),
      () => {
        this.bulkDelete([item]);
      }
    );
  }

  bulkDelete(items: any[]): Promise<any> {
    return this.dialogService.confirmDialog(
      this.translate.instant('dialogs.confirmation'),
      this.translate.instant('user.title.delete'),
      async () => {
        const itemIds: number[] = items.map(i => i.id);

        try {
          await this.recordingService.deleteRecordCalls(itemIds);

          for (const item of items) {
            this.dataSource.deleteRow(item);
          }
          this.toast.showSuccess(_ti('public.message.delete_success'));
        } catch (errData) {
          console.error('Delete Voicemail Error: ', errData);
        }
      }
    );
  }

  resetLocalNumberFilters(): void {
    this.filterForm.value.local_numbers = [];
  }

  changeFilter(): Boolean {
    return this.filtersReadonly = false;
  }

  rendererNumber(ids: number[]): string[] {
    const displayNumbers: string[] = [];
    for (const id of ids) {
      let d: DidEntity = this.didRepo.getAnonymousDid();

      if (id) {
        d = this.didRepo.getObjectById(id);
      }

      displayNumbers.push(d.display_number);
    }
    return displayNumbers;
    // return this.didRepo.getObjectListByIds(ids).map(d => d.display_number);
  }

  rendererQueue(queueIds: any[]): any[] {
    const queueName = [];
    const queueList = EntityManager.getRepository('CallQueueRepository').getObjectList();
    for (let i = 0; i < queueList.length; i++) {
      for (let q = 0; q < queueIds.length; q++) {
        if (queueList[i].id === queueIds[q]) {
          queueName.push(queueList[i].queue_name);
        }
      }
    }

    return queueName;
  }

  renderClientNumber = (row: any, colName?: string): string => {
    let value: string = this.displayPhoneNumber(row[colName]);
    if (row.client_name) {
      value = `${value} (${row.client_name})`;
    }
    return value;
  }

  rendererQualifi(qualifiIds: number[]): string[] {
    const qualifiList = EntityManager.getRepository('QualificationRepository').getObjectList();
    return qualifiList.filter(i => qualifiIds.includes(i.id)).map(q => q.value);
  }

  clearValue(event: MouseEvent, controlName: string, multiple: boolean = false): void {
    event.stopPropagation();
    this.filterForm.get(controlName).setValue(multiple ? [] : null);
  }
}
