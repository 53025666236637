<div fxfill class="flex-wrapper" *ngIf="form">
    <div class="col-left" fxHide.xs="true">
      <img src="assets/images/onboarding/bg-did-config-wizard-monkey.png" alt="Monkey" title="Monkey" class="monkey"/>
    </div>
    <div class="col-right">
      <div fxLayout="column">
        <div *ngIf="isLoading; else importUsers" fxFlexFill fxLayout="row" fxLayoutAlign="center center">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template #importUsers>
          <div fxFlexFill fxLayout="column">
            <h3 class="mr-3 ml-3 mt-3 fontfamily-bold">{{ 'enterprise_crm.message.select_users_to_import_from_crm' | translate }}</h3>

            <div fxLayout="row" fxLayoutAlign="center center" class="mt-2">
              <div fxFlex>
                <mat-checkbox (change)="selectAll($event)"><strong>{{ 'public.select_item'|translate }}</strong></mat-checkbox>
              </div>
              <div fxFlex>
                <mat-label><strong>{{ 'account_basic_settings.content.user_role' | translate }}</strong></mat-label>
              </div>
              <div fxFlex="20px"></div>
              <div fxFlex>
                <mat-label><strong>{{ 'crm.contact.email' | translate }}</strong></mat-label>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let user of crmUsers; let i = index">
              <div fxFlex>
                <mat-checkbox [(ngModel)]="user.checked">{{ user.name }}</mat-checkbox>
              </div>
              <div fxFlex>
                <mat-form-field class="form-item-container" *ngIf="usersControl.at(i)">
                  <!-- <mat-label>{{ 'account_basic_settings.content.user_role' | translate }}</mat-label> -->
                  <mat-select [formControl]="usersControl.at(i).get('user_type')">
                    <mat-option *ngFor="let userType of userTypes" [value]="userType.user_type">
                      {{ ('user_type_names.'+ userType.user_type) | translate }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="usersControl.at(i) && usersControl.at(i).get('user_type') && usersControl.at(i).get('user_type').invalid">
                    {{ 'public.message.data_invalid'|translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="20px"></div>
              <div fxFlex>
                <mat-form-field class="full-width input-email" *ngIf="usersControl.at(i)">
                  <!-- <mat-label>{{ 'crm.contact.email' | translate }}</mat-label> -->
                  <input matInput [formControl]="usersControl.at(i).get('email')" />
                  <mat-error *ngIf="usersControl.at(i) && usersControl.at(i).get('email') && usersControl.at(i).get('email').invalid">
                    {{ 'public.message.data_invalid'|translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

          </div>
        </ng-template>
      </div>
    </div>
  </div>
