import { SipPhoneEntity } from '@wephone-core/model/entity/sipphone';
import { Entity, datafield, mapped_datafield } from '@wephone-core/model/model';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { DidEntity } from '@wephone-core/model/entity/did';
import * as _ from 'lodash';
import { RoutingAppManager } from '@wephone-core/routing-app/routing-app-manager';
import { AccessRight, UserPhoneMode, UserRole, UserType } from '@wephone-core/system';
import { IRoutingApp, IRoutingAppDestinationData, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { IGroupEntity } from '@wephone-core/model/entity/group.interface';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { SipPhoneRepository } from '@wephone-core/model/repository/sipphone';
import { VoiceMailBoxEntity } from './voicemail_box';
import { parseDateTime } from '@wephone-utils';
import { TagEntity } from './tag';
import { IAgentEntity } from './agent.i';
import { IvrCustomMenuEntity } from './ivr_custom_menu';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { DateTime } from 'luxon';

export class UserEntity extends Entity implements IUserEntity {
  static object_type_id = 'user';

  id: number;
  @datafield firstname: string;
  @datafield lastname: string;
  @datafield email: string;
  @datafield enabled: boolean;
  @datafield enterprise_id: number;
  @mapped_datafield('vm_id') mailbox_id: number;
  @mapped_datafield('vm_enabled') voicemail_enabled: number;
  @mapped_datafield('vm_timeout') voicemail_timeout: number;
  @datafield recording_mode: number;
  @mapped_datafield('phonenum') phone: string;
  @datafield roles: UserRole[];
  @mapped_datafield('calling_number') calling_number_id: number;
  @datafield calling_profile_id: number;
  @datafield secretary_did_id: number;
  @datafield avatar_id: number;
  @datafield system_user: number;
  @datafield is_online: number;
  @datafield last_online_dt;
  @datafield group_ids: number[]; // Include all groups (direct group and their parent group)
  @datafield tags: number[];
  @datafield google_login: number;
  @datafield alias: string;
  @datafield team_id: number;
  @datafield user_type: UserType;
  @datafield access_granted: string[] = [];
  @datafield uncond_transfer_state = 0;
  @datafield uncond_transfer_dest: any;
  @datafield busy_transfer_state = 0;
  @datafield busy_transfer_dest: any;
  @datafield noanswer_transfer_state = 0;
  @datafield noanswer_wait_time = 0;
  @datafield noanswer_transfer_dest: any;
  @datafield phone_mode: UserPhoneMode;
  @datafield callout_enabled: number;
  @datafield auto_pickup: number;
  @datafield wait_timeout_config: IRoutingAppDestinationData;
  // voicemail_by_mail_enabled: number;
  // voicemail_in_attachement: number;
  last_login: DateTime;

  team: IGroupEntity;
  is_owner_calling_number: number; // 0|1, retrieved from did table, not directed user entity
  name: string;
  password: string;
  confirm_password: string;
  agent: IAgentEntity; // all agent info
  private _sipphone: SipPhoneEntity;
  secretary_did: DidEntity;
  // Transfer destinations
  private uncond_transfer_app: IRoutingApp;
  private busy_transfer_app: IRoutingApp;
  private noanswer_transfer_app: IRoutingApp;

  groups: UserGroupEntity[];

  get is_system_user(): boolean {
    return this.system_user === 1;
  }
  get sipphone_is_online(): number {
    if (this._sipphone) {
      return this._sipphone.is_online; // 0 or 1
    }

    return -1; // not having sip phone
  }

  get sipphone(): SipPhoneEntity {
    return this._sipphone;
  }

  set sipphone(sp: SipPhoneEntity) {
    this._sipphone = sp;
    if (sp) {
      sp.user_id = this.id;
    }
  }

  get sipphone_ids(): number[] {
    const sipphone_list =
      EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository').getObjectListByUserId(this.id) || [];

    return sipphone_list.map(i => i.id) || [];
  }

  get voicemail_welcome_file(): FileEntryEntity {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.greeting_file : undefined;
  }

  set voicemail_welcome_file(fileentry: FileEntryEntity) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for user');
      return;
    }
    this.mailbox.greeting_file = fileentry;
  }

  get voicemail_by_mail_enabled(): number {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.voicemail_by_mail_enabled : undefined;
  }

  set voicemail_by_mail_enabled(value: number) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for user');
      return;
    }
    this.mailbox.voicemail_by_mail_enabled = value;
  }

  get voicemail_in_attachement(): number {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.voicemail_in_attachement : undefined;
  }

  set voicemail_in_attachement(value: number) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for user');
      return;
    }
    this.mailbox.voicemail_in_attachement = value;
  }

  get mailbox(): VoiceMailBoxEntity {
    return this.mailbox_id ? EntityManager.getRepository('VoiceMailBoxRepository').getObjectById(this.mailbox_id) : undefined;
  }

  set mailbox(mailbox: VoiceMailBoxEntity) {
    this.mailbox_id = mailbox && mailbox.id;
    this.voicemail_welcome_file = mailbox && mailbox.greeting_file;
  }

  get is_online_cc(): boolean {
    return this.agent ? this.agent.is_online_cc : false;
  }

  fetchRelatedData(): void {
    this.team = this.team_id ? EntityManager.getRepository('TeamGroupRepository').getObjectById(this.team_id) : undefined;
    this.sipphone = EntityManager.getRepository<SipPhoneRepository>('SipPhoneRepository').getFirstObjectByUserId(
      this.id
    );
    this.agent = EntityManager.getRepository<any>('AgentRepository').getObjectByUserId(this.id);
    this.groups = [];
    if (this.group_ids && this.group_ids.length) {
      for (const group_id of this.group_ids) {
        this.groups.push(EntityManager.getRepository<any>('UserGroupRepository').getObjectById(group_id));
      }
    }
  }

  hasPhone(): boolean {
    return !!(this.phone && this.phone.trim());
  }

  canSendSms(): boolean {
    const dfCallingNumber: DidEntity = this.getDefaultCallingNumber();
    return dfCallingNumber && dfCallingNumber.can_send_sms;
  }

  hasDefaultCallingNumber(): boolean {
    return !!this.calling_number_id;
  }

  getDefaultCallingNumber(): DidEntity {
    if (this.calling_number_id) {
      return EntityManager.getRepository('DidRepository').getObjectById(this.calling_number_id);
    }
    if (this.agent && this.agent.default_queue) {
      return this.agent.default_queue.default_did;
    }
  }

  private _setFullName(): void {
    this.name = [
      (this.firstname || '').trim(),
      (this.lastname || '').trim()
    ].join(' ').trim();
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    super.setObjectData(object_data, fetch_related_data);
    this._setFullName();

    this.access_granted = this.access_granted || []; // fix bug cannot set access_granted value in case access_granted is null
    this.last_online_dt = this.last_online_dt && parseDateTime(this.last_online_dt); // convert to DateTime
    this.last_login = this.last_login && parseDateTime(this.last_login); // convert to DateTime
    //            this.role = this.getRole();
    delete this.password; // not need password from remote

    this.agent = EntityManager.getRepository<any>('AgentRepository').getObjectByUserId(object_data.id);
    this.secretary_did = object_data.secretary_did_id ?
      EntityManager.getRepository<any>('DidRepository').getObjectById(object_data.secretary_did_id) :
      undefined;

    this.uncond_transfer_app = undefined;
    if (this.uncond_transfer_dest) {
      let app_data;
      try {
        app_data = this.uncond_transfer_dest;
      } catch (e) {
        console.error('Cannot parse json', this.uncond_transfer_dest);
      }
      if (app_data && app_data.application) {
        this.uncond_transfer_app = RoutingAppManager.getInstance().createAppInstance(app_data.application);
        this.uncond_transfer_app.setAppParams(app_data.params);
      }
    }

    this.busy_transfer_app = undefined;
    if (this.busy_transfer_dest) {
      let app_data;
      try {
        app_data = this.busy_transfer_dest;
      } catch (e) {
        console.error('Cannot parse json', this.busy_transfer_dest);
      }
      if (app_data && app_data.application) {
        this.busy_transfer_app = RoutingAppManager.getInstance().createAppInstance(app_data.application);
        this.busy_transfer_app.setAppParams(app_data.params);
      }
    }

    this.noanswer_transfer_app = undefined;
    if (this.noanswer_transfer_dest) {
      let app_data;
      try {
        app_data = this.noanswer_transfer_dest;
      } catch (e) {
        console.error('Cannot parse json', this.noanswer_transfer_dest);
      }
      if (app_data && app_data.application) {
        this.noanswer_transfer_app = RoutingAppManager.getInstance().createAppInstance(app_data.application);
        this.noanswer_transfer_app.setAppParams(app_data.params);
      }
    }

    if (typeof object_data.wait_timeout_config === 'string') {
      this.wait_timeout_config = JSON.parse(object_data.wait_timeout_config || '{}');
    }

    // if (object_data.vm_id) {
    //   this.voicemail_by_mail_enabled = this.mailbox.voicemail_by_mail_enabled;
    //   this.voicemail_in_attachement = this.mailbox.voicemail_in_attachement;
    // }
  }

  dumpObjectData(field_list: string[], use_mapped_name: boolean = false): any {
    const data = super.dumpObjectData(field_list, use_mapped_name);
    data['enabled'] = this.enabled !== false ? 1 : 0;
    return data;
  }

  getUncondTransferApp(): IRoutingApp {
    return this.uncond_transfer_app;
  }

  setUncondTransferApp(transfer_app: IRoutingApp): void {
    this.uncond_transfer_app = transfer_app;
    this.uncond_transfer_dest = transfer_app.getAsJsonObject();
  }

  getBusyTransferApp(): IRoutingApp {
    return this.busy_transfer_app;
  }

  setBusyTransferApp(transfer_app: IRoutingApp): void {
    this.busy_transfer_app = transfer_app;
    this.busy_transfer_dest = transfer_app.getAsJsonObject();
  }

  getNoanswerTransferApp(): IRoutingApp {
    return this.noanswer_transfer_app;
  }

  setNoanswerTransferApp(transfer_app: IRoutingApp): void {
    this.noanswer_transfer_app = transfer_app;
    this.noanswer_transfer_dest = transfer_app.getAsJsonObject();
  }

  setUserGroup(user_group): UserEntity {
    if (this.group_ids.indexOf(user_group.getId()) !== -1) {
      return this;
    }
    this.group_ids.push(user_group.getId());
    return this;
  }

  setGroupIds(group_ids: number[]): void {
    this.group_ids = group_ids;
    this.groups = [];
    for (const group_id of group_ids) {
      this.groups.push(EntityManager.getRepository('UserGroupRepository').getObjectById(group_id));
    }
  }

  get role(): UserRole {
    if (this.user_type && this.user_type === UserType.USER) {
      return UserRole.USER;
    }
    return this.user_type ? `ROLE_${this.user_type.toUpperCase()}` as UserRole : undefined;
  }

  set role(role: UserRole) {
    if (!role) {
      console.error('Role must be not empty');
      return;
    }
    const r = role.toUpperCase() as UserRole;
    if (!this.roles) {
      this.roles = [];
    }
    if (this.roles.indexOf(r) === -1) {
      this.roles.push(r);
    }
  }

  isWatcher(): boolean {
    return this.user_type === UserType.WATCHER;
  }

  isAdmin(): boolean {
    return this.user_type === UserType.ADMIN;
  }

  isSupervisor(): boolean {
    return this.user_type === UserType.SUPERVISOR;
  }

  isAgent(): boolean {
    return this.user_type === UserType.AGENT;
  }

  isUser(): boolean {
    return this.user_type === UserType.USER;
  }

  isAccountant(): boolean {
    return this.user_type === UserType.ACCOUNTANT;
  }

  get can_use_callcenter(): boolean {
    return [UserType.ADMIN, UserType.SUPERVISOR, UserType.AGENT, UserType.USER].indexOf(this.user_type) !== -1;
  }

  addUserGroup(user_group): UserEntity {
    return this.setUserGroup(user_group);
  }

  removeUserGroup(user_group): UserEntity {
    const idx = this.group_ids.indexOf(user_group.getId());
    if (idx !== -1) {
      this.group_ids.splice(idx, 1);
    }
    return this;
  }

  isGrantedAccess(access_name: string): boolean {
    if (!this.access_granted) {
      return false;
    }
    return this.access_granted.indexOf(access_name) >= 0;
  }

  revokeAccess(access_name: string): void {
    if (!this.access_granted) {
      this.access_granted = [];
    }
    const i = this.access_granted.indexOf(access_name);
    if (i >= 0) {
      this.access_granted.splice(i, 1);
    }
  }

  grantAccess(access_name: string): void {
    if (!this.access_granted) {
      this.access_granted = [];
    }
    if (!this.isGrantedAccess(access_name)) {
      this.access_granted.push(access_name);
    }
  }

  // see storeproc get_managed_user_ids for reference
  // only for ROLE_ADMIN && ROLE_SUPERVISOR
  canEavesdrop(user: IUserEntity): boolean {
    if (this.isAdmin()) {
      // Admin can manage anyone
      return true;
    }
    // if (user.isAdmin()) {
    // Cannot manage an admin if you are not an admin yourself
    // return false;
    // }
    if (!this.isSupervisor() && !this.isGrantedAccess(AccessRight.Eavesdrop)) {
      return false;
    }
    // if (!this.team) {
    //   // If you are a supervisor and you are not in a team, you manage everyone except admins
    //   return true;
    // }
    // if (!user.team || user.team && (this.team_id === user.team_id || user.team.isSubgroupOf(this.team_id))) {
    //   // If you are in a team and the agent is in the same team or in a subteam
    //   return true;
    // }
    // if (_.isEmpty(this.group_ids)) {
    //   // If you are not in any group
    //   return true;
    // }
    // if (_.isEmpty(user.group_ids) || user.group_ids && _.intersection(this.group_ids, user.group_ids).length) {
    //   // If you are in a same group with agent
    //   return true;
    // }
    // return false;
    return true;
  }

  get tagList(): TagEntity[] {
    if (this.tags) {
      return EntityManager.getRepository<any>('TagRepository').getObjectListByIds(this.tags);
    }

    return [];
  }

  get has_recording(): boolean {
    return this.recording_mode === 1;
  }

  get routedIvrMenus(): IvrCustomMenuEntity[] {
    return RoutingAppService.getInstance().getRoutedIvrMenusToEntity(this.id, RoutingAppName.call_user);
  }

  get avatarUrl(): string {
    if (!this.avatar_id) {
      return 'assets/images/avatar.png';
    }

    const url = `/resource/user/avatar/${this.id}?avatar_id=${this.avatar_id}`;
    return FlexIvrSettings.getInstance().getEnterpriseUrl(url);
  }
}
