import { Component, OnInit, Input } from '@angular/core';
import { EditingComponent, joiValidator, NoWhitespaceValidator, ToastService } from '@wephone-utils';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { _tk, _ti } from '@wephone-translation';
import { IvrEvaluationRepository } from '@wephone-core/model/repository/ivr_evaluation';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { IIvrEvaluationQuestionDataType } from '@wephone-core/model/entity/ivr_evaluation.i';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { Router } from '@angular/router';
import { IvrEvaluationService } from '@wephone-core/service/ivr_evaluation.service';

@Component({
  selector: 'edit-ivr-evaluation',
  templateUrl: './edit-ivr-evaluation.component.html',
  styleUrls: ['./edit-ivr-evaluation.component.scss']
})
export class IvrEvaluationEditComponent extends EditingComponent implements OnInit {
  @Input() editingItem: IvrEvaluationEntity;

  ivrEvaluation: IvrEvaluationEntity;
  usedQueues: CallQueueEntity[] = [];

  readonly steps = {
    general: 'ivr_evaluation.content.general',
    inused: 'ivr_evaluation.content.using',
  };

  constructor(
    private readonly fb: FormBuilder,
    public readonly toast: ToastService,
    private readonly em: EntityManager,
    private readonly router: Router,
    private readonly ivrEvaluationService: IvrEvaluationService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.ivrEvaluation = this.editingItem;
    this.usedQueues = this.ivrEvaluationService.getUsedQueuesOfIvrEvaluations([this.ivrEvaluation.id]);
    this.initFormGroup();
  }

  get stepKeys(): string[] {
    return Object.keys(this.steps);
  }

  // private getQuestionAnswerKeys(): string[] {
  //   if (_.isEmpty(this.questionsControl.value)) {
  //     return [];
  //   }

  //   let keys: string[] = [];
  //   for (const q of this.questionsControl.value) {
  //     if (!q || _.isEmpty(q.accepted_answer)) {
  //       continue;
  //     }
  //     keys = keys.concat(q.accepted_answer);
  //   }

  //   return keys;
  // }

  getChangeSet(): any {
    const formData: any = super.getChangeSet();
    if ('name' in formData) {
      formData.name = _.trim(formData.name);
    }
    if ('welcome_msg' in formData) {
      formData.welcome_msg_id = formData.welcome_msg ? formData.welcome_msg.id : null;
      delete formData.welcome_msg;
    }
    if ('thankyou_msg' in formData) {
      formData.thankyou_msg_id = formData.thankyou_msg ? formData.thankyou_msg.id : null;
      delete formData.thankyou_msg;
    }
    if (!_.isEmpty(formData.questions)) {
      formData.question_data = formData.questions.map(o => {
        const obj: IIvrEvaluationQuestionDataType = {
          msg_id: o.msg ? o.msg.id : undefined,
          accepted_answer: o.accepted_answer
        };
        return obj;
      });
      delete formData.questions;
    }
    return formData;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.ivrEvaluation.name, [Validators.required, Validators.maxLength(256), NoWhitespaceValidator]],
      welcome_msg: [this.ivrEvaluation.welcome_msg],
      thankyou_msg: [this.ivrEvaluation.thankyou_msg, [Validators.required]],
      questions: this.fb.array(this.getQuestionControls()),
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  private getQuestionControls(): FormControl[] {
    const questionsValue = this.getQuestionsControllValue();
    const questionControls: FormControl[] = [];
    for (const q of questionsValue) {
      questionControls.push(this.createQuestionControl(q));
    }

    return questionControls;
  }

  get questionsControl(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  removeQuestion(index: number): void {
    this.questionsControl.markAsDirty();
    this.questionsControl.removeAt(index);
  }

  addQuestion(): void {
    this.questionsControl.markAsDirty();
    this.questionsControl.push(this.createQuestionControl());
  }

  private createQuestionControl(value?: { msg: FileEntryEntity, accepted_answer: string[] }): FormControl {
    return new FormControl(value, joiValidator(
      Joi.object({
        msg: Joi.object().required(),
        accepted_answer: Joi.array().items(Joi.string().max(50)).required(),
      }).required()
    ));
  }

  private getQuestionsControllValue(): any[] {
    const questions = [];
    if (!_.isEmpty(this.ivrEvaluation.question_data)) {
      for (const qData of this.ivrEvaluation.question_data) {
        const fileEntry: FileEntryEntity = qData.msg_id && this.em.getRepository<FileEntryRepository>('FileEntryRepository').getObjectById(qData.msg_id);
        const q: any = {
          msg: fileEntry,
          accepted_answer: qData.accepted_answer || []
        };

        questions.push(q);
      }
    }
    return questions;
  }

  private updateValidatorQuestionData(): void {
    // const questionAnswerKeys = this.getQuestionAnswerKeys();

    for (let index = 0; index < this.questionsControl.controls.length; index++) {
      const questionControl = this.questionsControl.controls[index];
      questionControl.markAsTouched();

      const errors: any = questionControl.errors || {};

      if (questionControl.getError('accepted_answer_required')) {
        delete errors.accepted_answer_required;
        questionControl.setErrors(!_.isEmpty(errors) ? errors : undefined);
      }

      const q = questionControl.value;
      if (!q || _.isEmpty(q.accepted_answer)) {
        errors.accepted_answer_required = true;
        questionControl.setErrors(errors);
        continue;
      }

      // if (questionControl.getError('duplicated')) {
      //   delete questionControl.errors.duplicated;
      //   questionControl.setErrors(!_.isEmpty(errors) ? errors : undefined);
      // }

      // const duplicatedKeys = [];
      // for (const k of q.accepted_answer) {
      //   if (questionAnswerKeys.filter(key => key === k).length > 1) {
      //     duplicatedKeys.push(k);
      //   }
      // }

      // if (duplicatedKeys.length) {
      //   errors.duplicated = { keys: duplicatedKeys };
      //   questionControl.setErrors(errors);
      // }
    }
  }

  updateSoundB4WelcomeMsgFile(soundData: { new_file_entry: FileEntryEntity }): void {
    const file_entry = soundData && soundData.new_file_entry;
    this.form.get('welcome_msg').markAsDirty();
    this.form.get('welcome_msg').setValue(file_entry);
  }

  updateSoundB4ThankyouMsgFile(soundData: { new_file_entry: FileEntryEntity }): void {
    const file_entry = soundData.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivr-evaluation');
    }
    this.form.get('thankyou_msg').markAsDirty();
    this.form.get('thankyou_msg').setValue(file_entry);
  }

  updateSoundB4QuestionMsgFile(soundData: { new_file_entry: FileEntryEntity }, index: number): void {
    const file_entry = soundData.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivr-evaluation');
    }

    const value: any = this.questionsControl.controls[index].value || {};
    value.msg = file_entry;

    this.questionsControl.controls[index].markAsDirty();
    this.questionsControl.controls[index].setValue(value);
  }

  phonePadPickerChanged(data: { keys: string[], error?: string }, index: number): void {
    const questionControl: FormControl = this.questionsControl.controls[index] as FormControl;
    const value: any = questionControl.value || {};
    value.accepted_answer = data.keys;

    questionControl.setValue(value);
    questionControl.markAsDirty();

    this.updateValidatorQuestionData();

    this.onFormValueChange();
  }

  async submitForm(): Promise<any> {
    this.updateValidatorQuestionData();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      // this.toast.showError(_ti('public.message.data_invalid'));
      return undefined;
    }

    try {
      const updateData: any = this.getChangeSet();
      const updateFields = Object.keys(updateData);

      const ivrEvaluationRepo: IvrEvaluationRepository = EntityManager.getRepository('IvrEvaluationRepository');

      const updatedIvrEvaluation = ivrEvaluationRepo.create(updateData) as IvrEvaluationEntity;
      updatedIvrEvaluation.id = this.ivrEvaluation.id;

      const returnedIvrEvaluation = await ivrEvaluationRepo.saveAttrs(updatedIvrEvaluation, updateFields);
      await ivrEvaluationRepo.wait_for_object_id(returnedIvrEvaluation.id);

      this.ivrEvaluation = ivrEvaluationRepo.getObjectById(returnedIvrEvaluation.id);
      this.resetForm();

      this.toast.showSuccess(_ti('public.message.update_success'));
    } catch (error) {
      this.toast.showError(error.message || _ti('public.message.update_failure'));
    }
  }

  private getFormResetData(): Object {
    return {
      name: this.ivrEvaluation.name,
      welcome_msg: this.ivrEvaluation.welcome_msg,
      thankyou_msg: this.ivrEvaluation.thankyou_msg,
      // questions: this.getQuestionControls()
    };
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    this.resetQuestionsControl();

    const formData = this.getFormResetData();
    this.form.reset(formData, options);

    this.form.setControl('questions', this.fb.array(this.getQuestionControls()));

    this.form.markAsPristine();
    this.formValueChanges.next(false);
  }

  private resetQuestionsControl(): void {
    while (this.questionsControl && this.questionsControl.length > 0) {
      this.questionsControl.removeAt(0);
    }

    const questionValue = this.getQuestionsControllValue();
    for (const value of questionValue) {
      this.questionsControl.controls.push(this.createQuestionControl(value));
    }
  }

  gotoPageQueue(queue: CallQueueEntity): void {
    this.router.navigateByUrl(`/queues/${queue.id}`);
  }
}
