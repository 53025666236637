import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  ToastService, DialogActionButton, Colors, IFlexDialogConfig, FormService,
} from '@wephone-utils';
import * as _ from 'lodash';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { SipTrunkRepository } from '@wephone-core/model/repository/siptrunk';
import { UpdateSipTrunkCommon } from '@wephone/components/edit-siptrunk/update-siptrunk-common';
import { RequiredByEnableStatusValidator } from '@wephone/services/form-validator';

@Component({
  selector: 'app-create-siptrunk-modal',
  templateUrl: './create-siptrunk-modal.component.html',
  styleUrls: ['./create-siptrunk-modal.component.scss']
})
export class CreateSiptrunkModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '600px',
    minHeight: '90%',
  };

  dialogTitle = _tk('siptrunk.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  private readonly sipTrunkRepo = SipTrunkRepository.getInstance<SipTrunkRepository>();
  private readonly updateSipTrunkCommon: UpdateSipTrunkCommon = new UpdateSipTrunkCommon();
  form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly toast: ToastService,
    private readonly formService: FormService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      username: ['', [Validators.required, Validators.maxLength(64)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
      proxy_host: ['', [Validators.maxLength(255)]],
      proxy_port: ['', [Validators.min(0), Validators.max(65535)]],
      secondary_proxy_host: ['', [Validators.maxLength(255)]],
      secondary_proxy_port: ['', [Validators.min(0), Validators.max(65535)]],
      secondary_proxy_mode: [0],
      outgoing_enabled: [],
      alias: ['', [Validators.maxLength(255)]],
      backup_number: ['', [Validators.maxLength(32)]],
      backup_number_enable: [],
      sip_ping_enable: [],
      channel_max: [undefined, [Validators.min(1)]],
    }, {
      validators: RequiredByEnableStatusValidator('backup_number', 'backup_number_enable')
    });
  }

  generatePassword(): void {
    this.updateSipTrunkCommon.generatePassword(this.form.get('password'), 12);
  }

  private getChangeSet(): any {
    const data: any = this.formService.getChangeSet(this.form);
    return this.updateSipTrunkCommon.formDataTransform(data);
  }

  private isFormValid(): boolean {
    return this.form && this.form.valid;
  }

  async submit(): Promise<void> {
    this.form.markAllAsTouched();

    if (!this.isFormValid()) {
      this.toast.showError(_ti('form.validator.data_invalid'));
      return;
    }

    try {
      const formDataUpdated = this.getChangeSet();
      const createdEntity = await this.sipTrunkRepo.createAndSave(formDataUpdated);
      this.toast.showSuccess(_ti('public.message.create_success'));
      this.modalRef.returnValue(createdEntity);
    } catch (error) {
      this.toast.showErrorMessage(error, _ti('public.message.create_failure'));
    }
  }

  clearValue(event: MouseEvent, controlName: string): void {
    event.stopPropagation();
    this.updateSipTrunkCommon.clearValue(this.form.get(controlName));
  }
}
