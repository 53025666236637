<div fxFill fxLayout="column" *ngIf="hotelRoom && form">
  <div class="wrapper">
    <div class="col-left" [fxHide.xs]="true">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of stepKeys; let i=index;">
            <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
              [ngClass]="{'active': mainArea.activeAnchor === step}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ steps[step] | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <form [formGroup]="form" (ngSubmit)="submitForm()" fxLayout="column" class="form-change-highlight" *ngIf="form">
        <mat-card class="shadow-none ma-0 pb-0" id="_0room_information" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon class="h2">settings</mat-icon>
            </div>
            <mat-card-title>
              <h2>{{ 'hotelroom.content.room_information' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{ 'hotelroom.content.room_number'|translate }}</mat-label>
                  <input matInput type="text" formControlName="room_number"
                    placeholder="{{ 'hotelroom.content.room_number'|translate }}">
                  <mat-error *ngIf="form.get('room_number').hasError('exists')">
                    {{'hotelroom.message.room_number_exist'|translate}}
                  </mat-error>
                  <mat-error
                    *ngIf="form.get('room_number').hasError('any.empty') || form.get('room_number').hasError('any.required')">
                    {{'form.validator.field_required'|translate}}
                  </mat-error>
                  <mat-error *ngIf="form.get('room_number').hasError('whitespace')">
                    {{'form.validator.whitespace'|translate}}
                  </mat-error>
                  <mat-error *ngIf="form.get('room_number').hasError('number.base')">
                    {{ 'form.validator.data_invalid'|translate }}
                  </mat-error>
                  <mat-error *ngIf="form.get('room_number').hasError('string.min')">
                    {{ 'form.validator.min_value'|translate:{min: 1} }}
                  </mat-error>
                  <mat-error *ngIf="form.get('room_number').hasError('string.max')">
                    {{ 'form.validator.max_value'|translate:{max: 10} }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{ 'hotelroom.content.sipphone'|translate }}</mat-label>
                  <flex-select [placeholder]="'hotelroom.content.select_sipphone'| translate"
                    formControlName="sip_phone" [attr.data-ci]="'sip_phone'" [itemList]="hotelRoomSipPhones"
                    [itemTemplate]="itemTemplate" [selectTriggerTemplate]="selectTriggerTemplate"
                    [options]="filterSipPhoneSelectOptions" [multiple]="false">
                    <ng-template #selectTriggerTemplate let-selectedValue="selectedValue">
                      <div *ngIf="selectedValue">
                        {{ selectedValue.extension_with_owner }}
                      </div>
                    </ng-template>
                    <ng-template #itemTemplate let-item="item">
                      {{ item.extension_with_owner }}
                    </ng-template>
                  </flex-select>
                  <button matSuffix mat-icon-button type="button" (click)="clearValue($event, 'sip_phone')"
                    [disabled]="!form.get('sip_phone').value">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>

        <mat-card class="shadow-none ma-0 py-0" id="_1client_information" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon class="h2">info</mat-icon>
            </div>
            <mat-card-title>
              <h2>{{ 'hotelroom.content.client_information' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <div>
              <mat-form-field class="full-width">
                <mat-label>{{ 'hotelroom.content.client_name'|translate }}</mat-label>
                <input matInput placeholder="{{ 'hotelroom.content.client_name'|translate }}"
                  formControlName="client_name" [readonly]="true">
                <mat-error *ngIf="form.get('client_name').invalid">
                  <span *ngIf="form.get('client_name').hasError('any.required'); else tplClientNameMax">
                    {{'form.validator.field_required'|translate}}
                  </span>
                  <ng-template #tplClientNameMax>
                    <span *ngIf="form.get('client_name').hasError('string.max'); else tplDataInvalid">
                      {{ 'form.validator.max_length'|translate:{max:128} }}
                    </span>
                  </ng-template>
                  <ng-template #tplDataInvalid>
                    {{ 'form.validator.data_invalid'|translate }}
                  </ng-template>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <div [ngClass]="{'mat-text-mute': !hotelRoom.is_occupied}" class="mt-1 mb-2">
              <mat-slide-toggle formControlName="is_checkout" [attr.data-ci]="'is_checkout'"
                [disabled]="!hotelRoom.is_occupied">
                <span [ngClass]="{'font-weight-bold': hotelRoom.is_occupied}">
                  {{'hotelroom.content.checkout'|translate}}</span>
              </mat-slide-toggle>
            </div> -->
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>

        <mat-card class="shadow-none ma-0 py-0" id="_2wakeup_call" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon class="h2">alarm</mat-icon>
            </div>
            <mat-card-title>
              <h2>{{ 'hotelroom.content.wakeup_call' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <div>
              <mat-slide-toggle [attr.data-ci]="'wakeup_time_enable'" formControlName="wakeup_time_enable"
                (change)="changeWakeupCall()">
                <span>{{'hotelroom.content.setup_wakeup_time'|translate}}</span>
              </mat-slide-toggle>
            </div>
            <div [hidden]="!form.get('wakeup_time_enable').value" fxLayout="column">
              <div class="wakeup-time" fxLayout="row wrap">
                <div fxLayout="column">
                  <flex-time-picker fxFlex="initial" [attr.data-ci]="'wakeup_time'" formControlName="wakeup_time"
                    placeholder="{{ 'hotelroom.content.wakeup_time'|translate }}" (timeSet)="markWakeupTimeAsDirty()">
                  </flex-time-picker>
                  <small fxFlex="100" class="mat-error"
                    *ngIf="form.get('wakeup_time').invalid && form.get('wakeup_time').touched">
                    {{ 'form.validator.field_required'|translate }}
                  </small>
                </div>

                <div fxFlex="10px"></div>

                <div fxLayout="column">
                  <mat-form-field>
                    <mat-label>{{ 'public.date'|translate }}</mat-label>
                    <input matInput formControlName="wakeup_date" [matDatepicker]="picker" (ngModelChange)="onChangeWakeupDate($event)"
                      [min]="roomRangeDate && roomRangeDate[0]" [max]="roomRangeDate && roomRangeDate[1]">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

                  <small fxFlex="100" class="mat-error"
                    *ngIf="form.get('wakeup_date').invalid && form.get('wakeup_date').touched">
                    {{ 'public.message.data_invalid'|translate }}
                  </small>
                </div>
              </div>
            </div>
            <div class="last-wakeup-result" *ngIf="lastWakeup">
              {{ 'hotelroom.content.wakeup_call_status'|translate: {
              wakeup_time:lastWakeup.wakeup_time|timeFormatDateTimeShort,
              state: lastWakeup.state} }}
            </div>
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider" *ngIf="hotelRoom.sip_phone"></mat-divider>
        <mat-card class="shadow-none mx-0 mt-0 mb-2 pt-0" id="_3sip_info" anchorPart *ngIf="hotelRoom.sip_phone">
          <mat-card-header>
            <div mat-card-avatar>
              <h2><i class="material-icons">sip</i></h2>
            </div>
            <mat-card-title>
              <h2>{{ 'sipphone.content.sip_connection_info' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <app-sip-connection-info [sipphone]="hotelRoom.sip_phone"></app-sip-connection-info>
          </mat-card-content>
        </mat-card>

        <mat-divider [inset]="true" class="section-divider"></mat-divider>

        <mat-card class="shadow-none mx-0 mt-0 mb-2 pt-0" id="_4call_log" anchorPart>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon class="h2">history</mat-icon>
            </div>
            <mat-card-title>
              <h2>{{ 'hotelroom.content.call_log' | translate }}</h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="form-group">
            <div fxLayoutAlign="center center">
              <button mat-raised-button color="accent" (click)="openCallLog()" type="button" data-ci="open_call_log"
                fxFlex="33%">
                {{ 'public.open'|translate }}
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </form>
    </div>
  </div>
</div>
