import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { _ti } from '@wephone-translation';
import * as _ from 'lodash';

/**
 * @params handleControl: Form Control
 * @params customMessages: Special error message input from the outside
 * 
 * Eg: 
 * <mat-form-field>
 *   <input matInput [formControl]="handleControl">
 *   <mat-error [flexFormControlError]="handleControl" [customMessages]="{
 *       exist: 'public.validator.name_exist'|translate
 *     }">
 *   </mat-error>
 * </mat-form-field>
 */
@Component({
  selector: '[flexFormControlError]',
  templateUrl: './flex-form-control-error.component.html',
  styleUrls: ['./flex-form-control-error.component.scss']
})
export class FlexFormControlErrorComponent implements OnInit {
  @Input() flexFormControlError: AbstractControl | FormControl;
  @Input() customMessages: { [keys: string]: string };

  errorMessages = {
    required: 'form.validator.field_required',
    'any.empty': 'form.validator.field_required',
    'number.base': 'form.validator.invalid_numeric',
    whitespace: 'form.validator.whitespace',
    notContainSpace: 'form.validator.not_contain_space',
    number: 'client.validator.value_must_be_number',
    min: 'form.validator.min_value',
    max: 'form.validator.max_value',
    'number.max': 'form.validator.max_value',
    'number.min': 'form.validator.min_value',
    'number.integer': 'public.message.data_invalid',
    maxlength: 'form.validator.max_length',
    minlength: 'form.validator.min_length',
    validEmail: 'public.message.email_invalid',
    wrongEmail: 'public.message.email_invalid',
    pattern: 'public.message.data_invalid',
    invalidPhoneNumber: 'public.message.phone_number_invalid',
    wrongPhoneNumber: 'public.message.phone_number_invalid',
    isValidUrl: 'public.message.url_invalid',
    invalid_mac: 'enterprise_sip_gateway.validator.mac_address_pattern',
    isPhoneNumber: 'send_sms.message.phone_number_not_accepted',
    invalidSipPhoneExtension: 'public.message.data_invalid',
    passwordConstraint: 'user.validators.password_constraint',
    mustMatch: 'public.message.data_invalid',
    invalid_ip: 'public.message.invalid_ip',
    ascii: 'send_sms.message.ascii_only_accepted',
    smsMax: 'form.validator.max_length'
  };

  ngOnInit(): void {
    console.log('flexFormControlError', this.flexFormControlError);
  }

  private get errorKeys(): string[] {
    return Object.keys(this.flexFormControlError.errors || {});
  }

  private get firstError(): any {
    return this.firstErrorKey && this.flexFormControlError.errors && this.flexFormControlError.errors[this.firstErrorKey] || undefined;
  }

  private get firstErrorKey(): string {
    return this.errorKeys.length && this.errorKeys[0] || undefined;
  }

  private get errorMessageParams(): any {
    if (!this.firstErrorKey) {
      return undefined;
    }

    const error = this.firstError;

    switch (this.firstErrorKey) {
      case 'maxlength': // {maxlength: {requiredLength: 5, actualLength: 7}}
        return { max: error.requiredLength };
        break;

      case 'minlength': // {minlength: {requiredLength: 3, actualLength: 2}}
        return { min: error.requiredLength };
        break;

      case 'smsMax': // {smsMax: {requiredLength: 3}}
        return { max: error.requiredLength };
        break;

      case 'number.max':
        return { max: error.limit }; // {key: undefined, label: "value", limit: 1000000, value: 9999999}
        break;
      case 'number.min':
        return { min: error.limit }; // {key: undefined, label: "value", limit: 1000000, value: 9999999}
        break;

      case 'max': // {max: {max: 15, actual: 16}}
      case 'min': // {min: {min: 3, actual: 2}}
        default:
        return error;
    }
  }

  get errorMessage(): string {
    if (!this.firstErrorKey) {
      return _ti('public.message.data_invalid');
    }

    if (this.customMessages && this.customMessages[this.firstErrorKey]) {
      return this.customMessages[this.firstErrorKey];
    }

    if (this.errorMessages[this.firstErrorKey]) {
      return _ti(this.errorMessages[this.firstErrorKey], this.errorMessageParams || {});
    }

    return _ti('public.message.data_invalid');
  }
}
