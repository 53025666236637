import { Component, OnInit, Input } from '@angular/core';
import { BlackListEntity, DURATION_TYPES, PhoneNumberBlockActionType } from '@wephone-core/model/entity/blacklist';
import {
  ToastService,
  EditingComponent,
  localNow
} from '@wephone-utils';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BlackListRepository } from '@wephone-core/model/repository/blacklist';
import * as _ from 'lodash';
import { ValidatorService } from '@wephone-utils/services/validator.service';
import { _tk, _ti } from '@wephone-translation';
import { DateTime } from 'luxon';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { PhoneNumberValidated } from '@wephone/services/form-validator';

@Component({
  selector: 'app-edit-black-list',
  templateUrl: './edit-black-list.component.html',
  styleUrls: ['./edit-black-list.component.scss']
})
export class EditBlackListComponent extends EditingComponent implements OnInit {
  @Input() editingItem: BlackListEntity;

  blackListItem: BlackListEntity;
  blockDurationList: any[];
  form: FormGroup;
  phoneNumberBlockAction = { block: PhoneNumberBlockActionType.BLOCK, low_priority: PhoneNumberBlockActionType.LOW_PRIORITY };
  selectedBlock: string;

  constructor(
    public toast: ToastService,
    private fb: FormBuilder,
    private em: EntityManager
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.blackListItem = _.cloneDeep(this.editingItem);

    this.blockDurationList = [
      {
        value: DURATION_TYPES.forever,
        label: 'blacklist.content.block_duration.forever',
        color: 'warn'
      },
      {
        value: DURATION_TYPES.one_year,
        label: 'blacklist.content.block_duration.year',
        label_params: { value: 1 },
        color: 'primary'
      },
      {
        value: DURATION_TYPES.six_months,
        label: 'blacklist.content.block_duration.months',
        label_params: { value: 6 }
      },
      {
        value: DURATION_TYPES.three_months,
        label: 'blacklist.content.block_duration.months',
        label_params: { value: 3 }
      },
      {
        value: DURATION_TYPES.one_week,
        label: 'blacklist.content.block_duration.week',
        label_params: { value: 1 }
      },
      {
        value: DURATION_TYPES.one_day,
        label: 'blacklist.content.block_duration.day',
        label_params: { value: 1 }
      }
    ];
    this.initFormGroup();
  }

  private getBlockUntilDate(duration: string): DateTime {
    let block_until_dt: DateTime;
    const now = localNow();
    switch (duration) {
      case DURATION_TYPES.one_year:
        block_until_dt = now.plus({ years: 1 });
        break;
      case DURATION_TYPES.six_months:
        block_until_dt = now.plus({ month: 6 });
        break;
      case DURATION_TYPES.three_months:
        block_until_dt = now.plus({ month: 3 });
        break;
      case DURATION_TYPES.one_week:
        block_until_dt = now.plus({ days: 7 });
        break;
      case DURATION_TYPES.one_day:
        block_until_dt = now.plus({ days: 1 });
        break;
      default:
        block_until_dt = null;
        break;
    }

    return block_until_dt;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      number: [this.blackListItem.number, [Validators.required, Validators.maxLength(20), PhoneNumberValidated()]],
      blockReason: [this.blackListItem.block_reason, [Validators.required, Validators.maxLength(512)]],
      blockAction: [this.blackListItem.block_action || 0],
      blockExpire: [this.blackListItem.block_until]
    });

    this.initSelectedBlock();

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  private getFormResetData(): any {
    return {
      number: this.blackListItem.number,
      blockReason: this.blackListItem.block_reason,
      blockAction: this.blackListItem.block_action || 0,
      blockExpire: this.blackListItem.block_until
    };
  }

  async submitForm(): Promise<any> {
    this.form.markAllAsTouched();
    if (this.formIsValid()) {
      try {
        const updateData: {
          number?: string,
          blockReason?: string
          blockAction?: number
          blockExpire?: any
        } = this.getChangeSet();
        const field_list: string[] = [];

        for (const f of Object.keys(updateData)) {
          switch (f) {
            case 'number':
              field_list.push('number');
              this.blackListItem.number = updateData.number.replace(/\D/g, '');
              break;
            case 'blockReason':
              field_list.push('block_reason');
              this.blackListItem.block_reason = updateData.blockReason;
              break;
            case 'blockAction':
              field_list.push('block_action');
              this.blackListItem.block_action = updateData.blockAction;
              break;
            case 'blockExpire':
              field_list.push('block_until');
              this.blackListItem.block_until = updateData.blockExpire;
              break;
            default: break;
          }
        }

        const blackListRepo = this.em.getRepository<BlackListRepository>('BlackListRepository');
        const returnedData = await blackListRepo.saveAttrs(this.blackListItem, field_list);

        await blackListRepo.wait_for_object_id(returnedData.id);
        this.blackListItem = blackListRepo.getObjectById(returnedData.id);
        this.resetForm();

        this.toast.show(_ti('public.message.update_success'));
      } catch (e) {
        console.error('Update blacklist error:', e);
        const message = e && e.message ? e.message : _ti('public.message.update_failure');
        this.toast.showError(message);
      }
    }
  }

  private initSelectedBlock(): void {
    this.selectedBlock = _.isEmpty(this.form.get('blockExpire').value) || _.isNull(this.form.get('blockExpire').value) ? DURATION_TYPES.forever : undefined;
  }

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.initSelectedBlock();
    this.onFormValueChange();
  }

  changeExpire(duration: string): void {
    this.selectedBlock = duration;
    this.form.get('blockExpire').markAsDirty();
    this.form.get('blockExpire').setValue(this.getBlockUntilDate(duration));
  }
}
