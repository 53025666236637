import { Injectable } from '@angular/core';
import { MyCallCenterStatus } from '@wephone-core/model/entity/my_callcenter_status';
import { MyCallCenterStatusRepository } from '@wephone-core/model/repository/my_callcenter_status';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { WsRpcService } from '@wephone-core/service/wsrpc_service';
import { PeriodPerformance } from './user.service';
import * as _ from 'lodash';

export interface IDailyStats {
  answser_count: number;
  call_count: number;
  qos: number;
}

@Injectable()
export class AgentService {
  private static _instance: AgentService;

  private readonly URL_PAUSE_AGENT = '/callcenter/agent/pause';
  private readonly URL_MAKE_RECALL_MISSED_CALL = '/callcenter/agent/recall_missed_call';
  private readonly URL_CALLCENTER_ACTION_EAVESDROP_CALL = '/callcenter/eavesdrop/eavesdrop_call';
  private readonly URL_CALLCENTER_ACTION_EAVESDROP_GROUP = '/callcenter/eavesdrop/eavesdrop_group';
  private readonly URL_CALLCENTER_ACTION_EAVESDROP_QUEUE = '/callcenter/eavesdrop/eavesdrop_queue';
  private readonly URL_CHANGE_INOUT_STATE = '/callcenter/agent/change_inout_state';
  private readonly URL_HANGUP_CALL = '/callcenter/queue/dropitem';
  private readonly URL_CHANGE_NUMBER = '/callcenter/agent/change_number';
  private readonly URL_QUEUE_USER_DAILY_STATS_URL = '/user/daily-stats';
  private readonly URL_DISCONNECT_CALLCENTER = '/callcenter/agent/disconnect';
  private readonly URL_UNPAUSE_AGENT = '/callcenter/agent/unpause';
  private readonly MAKE_OUTCALL_PATH = '/callcenter/agent/make_outcall';
  private readonly URL_CALLCENTER_ACTION_EAVESDROP_AGENT = '/callcenter/eavesdrop/eavesdrop_agent';

  constructor(
    public wsRpcService: WsRpcService,
  ) {
    if (AgentService._instance) {
      throw new Error('Error: Instantiation failed: Use AgentService.getInstance() instead of new.');
    }
    AgentService._instance = this;
  }

  static reset(): void {
    AgentService._instance = undefined;
  }

  public static getInstance(): AgentService {
    return AgentService._instance;
  }

  private _send_session_command(command, session_id, params?): Promise<string> {
    const data = { command, session_id, params };

    return this.wsRpcService.call_remote('send_session_command', data);
  }

  pause(agentId: number, pauseReasonId: number): Promise<any> {
    return HttpEngine.getInstance().apiPostV2(this.URL_PAUSE_AGENT, {
      operator_id: agentId,
      pause_reason_id: pauseReasonId
    });
  }

  agentChangePauseReason(agentId: number, pauseReasonId: number): Promise<any> {
    // return HttpEngine.getInstance().apiPostV2(this.URL_AGENT_CHANGE_PAUSE_REASON, {
    //   operator_id: agentId,
    //   pause_reason_id: pauseReasonId
    // });
    return this.pause(agentId, pauseReasonId);
  }

  make_recall_missed_call(missed_call_id: number): Promise<any> {
    return HttpEngine.getInstance().apiPostV2(this.URL_MAKE_RECALL_MISSED_CALL, { missed_call_id }).then(
      ret => {
        console.log('make_recall_missed_call response success', ret);

        return ret;
      },
      err => {
        console.error('make_recall_missed_call response error', err);

        return err;
      }
    );
  }

  call_eavesdrop(session_id, phone_number): Promise<any> {
    return HttpEngine.getInstance().apiGetV2(this.URL_CALLCENTER_ACTION_EAVESDROP_CALL, {
      session_id,
      phone_number
    });
  }

  call_eavesdrop_group(group_id, phone_number, eavesdrop_until_time?): Promise<any> {
    const params: any = {
      group_id,
      phone_number
    };
    if (eavesdrop_until_time) {
      params.eavesdrop_until_time = eavesdrop_until_time;
    }

    return HttpEngine.getInstance().apiGetV2(this.URL_CALLCENTER_ACTION_EAVESDROP_GROUP, params);
  }

  call_eavesdrop_queue(queue_id, phone_number, eavesdrop_until_time?): Promise<any> {
    const params: any = {
      queue_id,
      phone_number
    };
    if (eavesdrop_until_time) {
      params.eavesdrop_until_time = eavesdrop_until_time;
    }

    return HttpEngine.getInstance().apiGetV2(this.URL_CALLCENTER_ACTION_EAVESDROP_QUEUE, params);
  }

  set_inout_state(agent_id, inout_state): Promise<any> {
    console.log('set_inout_state', agent_id, inout_state);

    return HttpEngine.getInstance().apiGetV2(this.URL_CHANGE_INOUT_STATE, { operator_id: agent_id, inout_state }).then(
      (response: any) => {
        console.log('response', response);
      },
      (err: any) => {
        console.error('response', err);
      }
    );
  }

  hangupCall(queue_item_id: number): Promise<any> {
    return HttpEngine.getInstance().apiPostV2(this.URL_HANGUP_CALL, { id: queue_item_id });
  }

  change_number(agent_id: number, phone_mode: number, new_number: string, auto_pickup: number = undefined): Promise<any> {
    const params: any = {
      operator_id: agent_id,
      phone_mode,
    };

    if (new_number) {
      params.phone_number = new_number;
    }

    if (_.isInteger(auto_pickup)) {
      params.auto_pickup = auto_pickup;
    }

    console.log('change_number params', params);

    return HttpEngine.getInstance().apiPostV2(this.URL_CHANGE_NUMBER, params);
  }

  async queueUserDailyStats(period: PeriodPerformance = PeriodPerformance.Today): Promise<IDailyStats> {
    const ret: IDailyStats = await HttpEngine.getInstance().apiGetV2(this.URL_QUEUE_USER_DAILY_STATS_URL, {
      period
    });

    return ret;
  }

  disconnect_callcenter(operator_id): Promise<any> {
    console.log('disconnect_callcenter', operator_id);

    return HttpEngine.getInstance().apiGetV2(this.URL_DISCONNECT_CALLCENTER, { operator_id });
  }

  unpause(agentId: number): Promise<any> {
    console.log('unpause', agentId);

    return HttpEngine.getInstance().apiPostV2(this.URL_UNPAUSE_AGENT, {
      operator_id: agentId
    });
  }

  hold(session_id): Promise<string> {
    console.log('hold', session_id);

    return this._send_session_command('put_on_hold', session_id, {});
  }

  resume(session_id): Promise<string> {
    console.log('resume', session_id);

    return this._send_session_command('resume', session_id, {});
  }

  make_outcall(phone_number: string, queue_id: number, calling_number: string, autoAnswer?: boolean): Promise<any> {
    console.log('make_outcall', phone_number, queue_id, calling_number);

    return HttpEngine.getInstance()
      .apiPostV2(this.MAKE_OUTCALL_PATH, {
        called_number: phone_number,
        queue_id,
        calling_number,
        auto_answer: autoAnswer ? 1 : 0
      });
  }

  getMyCallcenterStatus(): MyCallCenterStatus {
    return MyCallCenterStatusRepository.getInstance<MyCallCenterStatusRepository>().getMyStatus();
  }

  call_eavesdrop_agent(user_id, phone_number, eavesdrop_until_time?): Promise<any> {
    const params: any = {
      user_id,
      phone_number
    };
    if (eavesdrop_until_time) {
      params.eavesdrop_until_time = eavesdrop_until_time;
    }

    return HttpEngine.getInstance().apiGetV2(this.URL_CALLCENTER_ACTION_EAVESDROP_AGENT, params);
  }
}
