import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, ElementRef, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IFlexTableConfig, FlexTableSelectionChangeEvent } from '../flex-table/flex-table.i';
import { FlexTableComponent } from '../flex-table/flex-table.component';
import { IFlexTableSidePannelOptions, FlexTableSidePannelComponent } from '../flex-table-side-pannel/flex-table-side-pannel.component';
import { UIStateService } from '../../services/ui-state.service';
import { DynamicFilterSource } from '../flex-table/datasource/dynamic-filter-source';
import { IEntity } from '@wephone-core/model/model.interface';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionManagedComponent } from '../base';
import { DialogActionButton } from '../flex-dialog';
import { TimerService } from '../../services';
import * as _ from 'lodash';

@Component({
  selector: 'flex-crud-page',
  templateUrl: './flex-crud-page.component.html',
  styleUrls: ['./flex-crud-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexCRUDPageComponent extends SubscriptionManagedComponent implements OnInit {
  @Input() dataSource: DynamicFilterSource;
  @Input() tableConfig: IFlexTableConfig;
  @Input() columnTemplates: Map<string, TemplateRef<any>>;
  @Input() filterTemplate: TemplateRef<any>;
  @Input() sidePannelOptions: IFlexTableSidePannelOptions;
  @Input() listWidth = 25;
  @Input() pageTitle: string;
  @Input() actions?: DialogActionButton[];
  @Input() sidePanelMaximized: boolean;
  @Input() exportable?: boolean;
  @Input() exportFileName?: string;

  editedId: number;
  selectedRowOffsetY: number;

  @Input() get focusedRow(): any {
    return this.objectListTable.focusedRow;
  }

  set focusedRow(val: any) {
    this.objectListTable.focusedRow = val;
  }

  @Output() readonly onSelectionChange = new EventEmitter<FlexTableSelectionChangeEvent>();

  @ViewChild('tplFilterDefault', { static: true }) defaultFilterTemplate: TemplateRef<any>;
  @ViewChild('objectListTable', { static: true }) objectListTable: FlexTableComponent;
  @ViewChild('listWrapper', { static: true }) listWrapper: ElementRef;
  @ViewChild('sidePannel', { static: true }) sidePannel: FlexTableSidePannelComponent;

  private _filterString: string;

  set filterString(val: string) {
    this._filterString = val;
    this.dataSource.filter = this._filterString;
  }

  get filterString(): string {
    return this._filterString;
  }

  constructor(
    public screenSize: UIStateService,
    private activeRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private timer: TimerService) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(
      this.activeRoute.queryParams.subscribe(
        params => {
          // console.log('params', params);
          this.editedId = params['id'] ? parseInt(params['id']) : 0;
  
          if (this.editedId) {
            const entity: IEntity = this.dataSource.getSelectedEntity(this.editedId);
            if (entity) {
              this.focusedRow = entity;
            }
          }
        }
      )
    );

    this.addTimerSubscription(
      this.timer.subscribe1000(
        () => {
          this.selectedRowOffsetY = this.getSelectedRowOffsetY();
          this.cdr.markForCheck();
        }
      )
    );

    if (this.sidePanelMaximized) {
      this.sidePannel.setMaximize(true);
    }

    this.addSubscription(
      this.dataSource.dataChanges.subscribe(() => {
        const id = this.activeRoute.snapshot.paramMap.get('id') && +this.activeRoute.snapshot.paramMap.get('id') || 0;
        // console.log(' dataChanges id', id );
        if (id) {
          const entity: IEntity = this.dataSource.getSelectedEntity(id);
          if (entity) {
            this.focusedRow = entity;
          }
        }
      })
    );

    if (this.exportable === true) {
      if (!this.tableConfig.listActions) {
        this.tableConfig.listActions = {};
      }

      if (!this.tableConfig.listActions.defaultActions) {
        this.tableConfig.listActions.defaultActions = [];
      }

      this.tableConfig.listActions.defaultActions.unshift(
        {
          id: 'btnExport',
          icon: 'download',
          hint: 'public.export',
          callback: () => {
            return this.exportCsv();
          }
        });
    }

    if (this.tableConfig.columns) {
      const sortColumn = this.tableConfig.columns.find(c => c.sortable === true && (c.sort === 'asc' || c.sort === 'desc'));
      if (sortColumn) {
        this.dataSource.setOrder(sortColumn.name, sortColumn.sort, sortColumn.customSortFn);
      }
    }
  }

  getSelectedRowOffsetY(): number {
    return this.objectListTable && this.objectListTable.focusRowOffsetY();
  }

  get listFlexValue(): string {
    if (this.sidePannel.hasEditor()) {
      return `0 0 ${this.listWidth}%`;
    }
    return '1 1 auto';
  }

  sidePanelHasEditor(): boolean {
    if (this.sidePannel && this.sidePannel.hasEditor()) {
      return true;
    }
    return false;
  }

  detectChanges(): void {
    return this.objectListTable.detectChanges();
  }

  closeSidePanel(): void {
    this.sidePannel.closeEditor();
  }

  selectionChange($event): void {
    this.onSelectionChange.emit($event);
  }

  get actionsMenu(): DialogActionButton[] {
    if (this.actions && this.actions.length) {
      return this.actions.filter(x => !x.visible || x.visible && x.visible() === true);
    }
    return [];
  }

  exportCsv(): void {
    if (this.exportable && this.objectListTable) {
      this.objectListTable.onExportCsv(this.exportFileName);
    }
  }
}
