import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { joiValidator, DialogActionButton, Colors, IFlexDialogConfig, NoWhitespaceValidator } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { _tk, _ti } from '@wephone-translation';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';

@Component({
  selector: 'app-create-ivr-remote-app-modal',
  templateUrl: './create-ivr-remote-app-modal.component.html',
  styleUrls: ['./create-ivr-remote-app-modal.component.scss']
})
export class CreateIvrRemoteAppModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '500px',
    minHeight: '90%',
  };

  ivrRemoteApp: IvrRemoteAppEntity;
  form: FormGroup;

  dialogTitle = _tk('shortdialcode.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<CreateIvrRemoteAppModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.ivrRemoteApp = _.cloneDeep(this.data.ivrRemoteApp);
    this.initFormGroup();
  }

  cancel(result?): void {
    this.dialogRef.close(result);
  }

  validateForm(): boolean {
    const name: string = this.form.get('name').value;
    const existName: IvrRemoteAppEntity = IvrRemoteAppRepository.getInstance<IvrRemoteAppRepository>().getObjectList().find(x => x.name === name);
    if (existName) {
      this.form.get('name').setErrors({name_exist: true});
    }

    return this.form.valid;
  }

  async submit(): Promise<void> {
    // Validate
    if (!this.validateForm()) {
      this.form.markAllAsTouched();
      this.showError(_ti('form.validator.data_invalid'));
      return;
    }

    try {
      const updatedData = this.ivrRemoteApp;

      await IvrRemoteAppRepository.getInstance<IvrRemoteAppRepository>().createAndSave(updatedData);
      this.showInfo(_ti('public.message.create_success'));

      this.dialogRef.close(updatedData);
    } catch (error) {
      this.showError(_ti('public.message.create_failure'));
    }
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.ivrRemoteApp.name, [Validators.required, NoWhitespaceValidator, Validators.maxLength(128)]]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.ivrRemoteApp.name = changes.name;
      })
    );
  }
}
