import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService, DialogActionButton, Colors } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { CallQueueAgentLinkEntity } from '@wephone-core/model/entity/callqueue_agent_link';

@Component({
  selector: 'app-confirm-agent-eligible',
  templateUrl: './confirm-agent-eligible.component.html',
  styleUrls: ['./confirm-agent-eligible.component.scss']
})
export class ConfirmAgentEligibleComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('call_queue.content.operators.available');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('dialogs.ok'),
      action: () => {
        this.ok();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  selected_agent_links: {[keys: string]: boolean} = {};

  constructor(
    public dialogRef: MatDialogRef<ConfirmAgentEligibleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      description: string,
      agent_links: CallQueueAgentLinkEntity[]
    },
    public dialogService: DialogService
  ) {
    super();
  }

  ok(): void {
    const queue_agent_links = [];

    for (const pair_ids of Object.keys(this.selected_agent_links)) {
      if (!this.selected_agent_links[pair_ids]) {
        continue;
      }

      const agent_id = pair_ids.split('-')[0];

      const queue_id = pair_ids.split('-')[1];

      const found: CallQueueAgentLinkEntity[] = this.data.agent_links.filter(item => {
        return item.agent_id == parseInt(agent_id) && item.queue_id == parseInt(queue_id);
      });
      if (found.length) {
        queue_agent_links.push(found[0]);
      }
    }
    this.dialogRef.close({ agent_links: queue_agent_links });
  }

  no(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {

  }
}
