import * as _ from 'lodash';

import { Component, Inject, OnInit } from '@angular/core';
import { UserRepository } from '@wephone-core/model/repository/user';
import { Colors, DialogActionButton, EmailValidator, NoWhitespaceValidator } from '@wephone-utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserEntity } from '@wephone-core/model/entity/user';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { UserType } from '@wephone-core/system';

@Component({
  selector: 'user-create-modal',
  templateUrl: './user-create-modal.component.html',
  styleUrls: ['./user-create-modal.component.scss']
})
export class UserCreateModal extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('user.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  private readonly userRepo = UserRepository.getInstance<UserRepository>();
  form: FormGroup;
  user_type_list: {
    user_type: UserType;
    user_type_name: string;
  }[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      enterprise: EnterpriseEntity
    },
    public dialogRef: MatDialogRef<UserCreateModal>,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (this.data && this.data.enterprise) {
      this.user_type_list = await ConfigManager.getInstance().getUserTypesByBusinessType(this.data.enterprise.business_type);
    } else {
      this.user_type_list = await ConfigManager.getInstance().getUserTypes();
    }

    this.form = this.fb.group({
      firstname: [''],
      lastname: ['', [Validators.required, NoWhitespaceValidator]],
      email: ['', [Validators.required, EmailValidator]],
      user_type: [this.user_type_list && this.user_type_list[0] && this.user_type_list[0].user_type, [Validators.required]],
    });

    this.updateDialogLayout();
  }

  async submitForm(): Promise<void> {
    const existName: UserEntity = this.userRepo.getUserByEmail(_.toLower(this.form.get('email').value));
    if (existName) {
      this.form.get('email').setErrors({ email_exist: true });
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastService.showError(_ti('public.message.data_invalid'));
      return;
    }
    const value = this.form.value;
    const newUser = this.userRepo.create();
    newUser.setObjectData({
      firstname: value.firstname,
      lastname: value.lastname,
      user_type: value.user_type,
      email: _.toLower(value.email),
    });

    try {
      let result;
      const attrList = ['user_type', 'firstname', 'lastname', 'email'];
      if (this.data && this.data.enterprise) {
        const extraData: any = {
          enterprise_id: this.data.enterprise.id
        };
        attrList.push('enterprise_id');
        result = await this.userRepo.createUserAndSaveBySA(newUser, attrList, extraData);
      } else {
        result = await this.userRepo.createUserAndSave(newUser, attrList);
      }

      const createdUser = this.userRepo.getObjectById(result.object_id);
      this.toastService.show(_ti('public.message.create_success'));
      this.dialogRef.close(createdUser);
    } catch (error) {
      let msg = _ti('public.message.create_failure');
      if (error) {
        if (error.message) {
          msg = error.message;
        } else if (error.error && error.error.message) {
          msg = error.error.message;
        }
      }
      this.showError(msg);
    }
  }

  public dismiss(): void {
    this.dialogRef.close();
  }
}
