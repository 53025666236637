import * as _ from 'lodash';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors, EmailValidator } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { UserRepository } from '@wephone-core/model/repository/user';
import { UserType } from '@wephone-core/system';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'import-users-from-crm-modal',
  templateUrl: './import-users-from-crm-modal.component.html',
  styleUrls: ['./import-users-from-crm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportUsersFromCrmModal extends DialogComponentBase implements OnInit {
  userRepo: UserRepository;
  userTypes = [];
  crmUsers: {
    name: string;
    email: string;
    checked?: boolean;
    user_type?: UserType;
  }[] = [];

  GET_IMPORTABLE_USER_LIST_PATH = 'crm/get_importable_user_list';

  dialogTitle = _tk('enterprise_crm.title.import_users_from_crm');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.navigation.ignore_step'),
      action: () => {
        this.ignore();
      },
      visible: () => {
        return !!this.data.showIgnoreStep;
      },
      color: Colors.WARN
    },
    {
      label: _tk('public.navigation.next'),
      action: () => {
        this.createUser();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  isLoading = true;
  types = [];
  form: FormGroup;

  constructor(
    public configManager: ConfigManager,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly fb: FormBuilder,
    cdr?: ChangeDetectorRef
  ) {
    super(cdr);
    this.userRepo = UserRepository.getInstance() as UserRepository;
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.userTypes = await this.configManager.getUserTypes();
    try {
      const crmId = this.data.crmId;
      const crmUsersUrl = `${this.GET_IMPORTABLE_USER_LIST_PATH}/${crmId}`;
      const crmUsers = await HttpEngine.getInstance().apiGetV2(crmUsersUrl);

      this.crmUsers = [];
      for (const u of crmUsers) {
        this.crmUsers.push(_.extend(u, {
          user_type: UserType.AGENT
        }));
      }

      this.isLoading = false;
      // this.detectChanges();

      this.updateForm();
    } catch (error) {
      console.error('Import error', error);
      this.isLoading = false;
      // this.detectChanges();
      this.toastService.showError(error.message);
    } finally {
      this.detectChanges();
    }
  }

  updateForm(): void {
    this.form = this.fb.group({
      users: this.fb.array(
        this._getUsersFormControls() || []
      )
    });
  }

  get usersControl(): FormArray {
    return (this.form.get('users') || []) as FormArray;
  }

  private _getUsersFormControls(): FormGroup[] {
    const ret: FormGroup[] = [];
    const users = this.crmUsers || []; //  && this.crmUsers.filter(x => !!x.checked)
    for (const user of users) {
      const formGrp = this.fb.group({
        user_type: [user.user_type, [Validators.required]],
        email: [user.email, [Validators.required, EmailValidator]],
        name: [user.name],
      }) as FormGroup;

      ret.push(formGrp);
    }

    return ret;
  }

  selectAll($event: MatCheckboxChange): void {
    let checked = true;
    if (!$event.checked) {
      checked = false;
    }

    this.crmUsers.forEach(crmUser => crmUser.checked = checked);
  }

  async createUser(): Promise<any> {
    // const createdUsers = [];
    // const errorUsers = [];

    if (_.isEmpty(this.crmUsers) || _.isEmpty(this.crmUsers.filter(x => !!x.checked))) {
      this.toastService.showError(_ti('public.message.no_item_selected'));
      return;
    }

    let i = 0;
    let error = false;
    const importedUsers = [];
    for (const crmUser of this.crmUsers) {
      if (crmUser.checked) {
        if (this.usersControl.at(i).invalid) {
          this.usersControl.at(i).markAllAsTouched();
          error = true;
        } else {
          importedUsers.push({
            user_type: this.usersControl.at(i).get('user_type').value,
            email: this.usersControl.at(i).get('email').value,
            name: this.usersControl.at(i).get('name').value,
          });
        }
      }
      i ++;
    }

    try {
      if (error) {
        this.detectChanges();
        throw new Error(_ti('public.message.data_invalid'));
      }

      await this.userRepo.importCrmUser(importedUsers);
      this.toastService.showSuccess(_ti('public.message.import_success'));
      this.modalRef.returnValue(true);
    } catch (err) {
      this.toastService.showErrorMessage(err, _ti('public.message.import_failure'));
    }

    // for (const crmUser of this.crmUsers) {
    //   if (crmUser.checked) {
    //     const newUser = this.userRepo.create() as UserEntity;
    //     newUser.firstname = crmUser.name;
    //     newUser.email = crmUser.email;
    //     newUser.user_type = crmUser.user_type;

    //     try {
    //       await this.userRepo.createUserAndSave(newUser, ['firstname', 'email', 'user_type']);
    //       createdUsers.push(newUser.email);
    //     } catch (e) {
    //       errorUsers.push(newUser.email);
    //     }
    //   }
    // }

    // if (!_.isEmpty(createdUsers)) {
    //   this.toastService.showSuccess(`${_ti('public.message.import_success')} ${createdUsers.join(', ')}`);
    // }

    // if (!_.isEmpty(errorUsers)) {
    //   setTimeout(() => {
    //     this.toastService.showError(`${_ti('public.message.import_failure')} ${errorUsers.join(', ')}`);
    //   }, 2000);
    // }
  }

  ignore(): void {
    this.modalRef.returnValue(undefined);
  }
}
