<table class="flex-table-info">
  <thead>
    <tr>
      <th colspan="2" class="fontfamily-bold">
        {{ 'dialogs.setup_sipphone.label.connection_info'|translate}}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="label">{{ 'dialogs.setup_sipphone.label.domain'|translate }}</td>
      <td>{{ sipDomain }}</td>
    </tr>
    <tr>
      <td class="label">{{ 'login.user'|translate }}</td>
      <td><span *ngIf="sipphone" data-ci="sipphone-username">{{ sipphone.extension }}</span></td>
    </tr>
    <tr>
      <td class="label">{{ 'login.pass'|translate }}</td>
      <td class="label" valign="middle">
        <span class="vertical-align" [ngClass]="{'fontfamily-opensans': !showSipPassword}" data-ci="sipphone-password">
          {{ showSipPassword ? sipphone.password : '&bull;&bull;&bull;&bull;&bull;&bull;&bull;' }}</span>
        <button type="button" *ngIf="showSipPassword" mat-icon-button color="primary" class="float-right"
          title="{{ 'call_qualification.content.hide'|translate }}" (click)="showSipPassword=!showSipPassword">
          <mat-icon>visibility_off</mat-icon>
        </button>
        <button type="button" *ngIf="!showSipPassword" [attr.data-ci]="'show-sipphone-pw'" mat-icon-button
          color="primary" class="float-right" title="{{ 'call_qualification.content.show'|translate }}"
          (click)="showSipPassword=!showSipPassword">
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </tr>
  </tbody>
</table>
