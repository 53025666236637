import { Component, OnInit, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DidRepository } from '@wephone-core/model/repository/did';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'update-number-description-modal',
  templateUrl: './update-number-description-modal.component.html',
  styleUrls: ['./update-number-description-modal.component.scss']
})
export class UpdateNumberDescriptionModalComponent extends DialogComponentBase implements OnInit {

  dialogTitle = _tk('did.title.update_number_description');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.cancel'),
      action: () => {
        this.cancel();
      },
      visible: () => {
        return this.formHasChanged();
      }
    },
    {
      label: _tk('public.save'),
      action: () => {
        this.save();
      },
      visible: () => {
        return this.formHasChanged();
      },
      color: Colors.PRIMARY
    },
  ];

  private readonly didRepo: DidRepository;

  did: DidEntity;
  form: FormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<UpdateNumberDescriptionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      id: number;
    },
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
  ) {
    super();
    this.didRepo = this.em.getRepository<DidRepository>('DidRepository');
  }

  ngOnInit(): void {
    this.setDid();
    this.form = this.fb.group({
      name: [this.did.name, Validators.compose([Validators.required, Validators.maxLength(100)])],
      welcome_message: [this.did.welcome_message, [Validators.maxLength(512)]]
    });
  }

  formHasChanged(): boolean {
    return this.form && this.form.dirty;
  }

  setDid(): void {
    this.did = this.didRepo.getObjectById(this.data.id);
  }

  resetForm(): void {
    this.form.reset({
      name: this.did.name,
      welcome_message: this.did.welcome_message,
    });

    this.form.markAsPristine();
  }

  cancel(): void {
    this.resetForm();
  }

  async save(): Promise<any> {
    try {
      const existName: DidEntity = this.didRepo.getDidByName(this.form.get('name').value, this.did && this.did.id);
      if (existName) {
        this.form.get('name').setErrors({ name_exist: true });
      }

      if (this.form.invalid) {
        this.form.markAllAsTouched();
        throw new Error(_ti('public.message.data_invalid'));
      }

      const did: DidEntity = _.cloneDeep(this.did);
      _.extend(did, this.form.value);

      const res = await this.didRepo.saveAttrs(did, ['name', 'welcome_message']);
      if (res) {
        this.dialogRef.close(this.form.value);
        this.successToast(_ti('public.message.update_success'));
      }
    } catch (resError) {
      let msg = resError && resError.message || _ti('public.message.update_failure');
      if (resError && resError.error && resError.error.error && resError.error.error.message) {
        msg = resError.error.error.message;
      }
      this.showError(msg);
    }
  }
}
