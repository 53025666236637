<div fxLayout="column" fxFill>
  <ng-template #listFilterTpl>
    <form [formGroup]="sipTrunkForm" class="filter-form" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center"
      fxLayoutAlign.xs="start" fxLayoutGap="20px" fxLayoutGap.xs="0">
      <div>
        <flex-search-input placeholder="{{ 'public.search' | translate }}" formControlName="filterValue"
          [hintText]="'sip_trunk.search.hint'|translate" ngClass.xs="search-full-width"></flex-search-input>
      </div>
    </form>
  </ng-template>
  <ng-template #outgoingTpl let-outgoing="row">
    <mat-icon *ngIf="outgoing.outgoing_enabled">done</mat-icon>
    <mat-icon *ngIf="!outgoing.outgoing_enabled">clear</mat-icon>
  </ng-template>
  <flex-crud-page #flexCrud pageTitle="{{ 'menu.manage_siptrunk'| translate }}" [filterTemplate]="listFilterTpl"
    [tableConfig]="tableConfig" [dataSource]="dataSource" [columnTemplates]="{'outgoing_enabled': outgoingTpl}"
    [sidePannelOptions]="sidePannelOptions" fxLayout="row" fxFlex="grow">
  </flex-crud-page>
</div>
