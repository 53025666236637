import { Component, OnInit } from '@angular/core';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { joiValidator, DialogActionButton, Colors, IFlexDialogConfig, FormService, NoWhitespaceValidator } from '@wephone-utils';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { IvrEvaluationRepository } from '@wephone-core/model/repository/ivr_evaluation';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { IIvrEvaluationQuestionDataType } from '@wephone-core/model/entity/ivr_evaluation.i';

@Component({
  selector: 'create-ivr-evaluation-modal',
  templateUrl: './create-ivr-evaluation-modal.component.html',
  styleUrls: ['./create-ivr-evaluation-modal.component.scss']
})
export class CreateIvrEvaluationModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('ivr_evaluation.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  ivrEvaluation: IvrEvaluationEntity;
  form: FormGroup;

  private readonly ivrEvaluationRepo: IvrEvaluationRepository;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<CreateIvrEvaluationModalComponent>,
    private readonly formService: FormService,
    readonly em: EntityManager,
    // @Inject(MAT_DIALOG_DATA) public data,
  ) {
    super();
    this.ivrEvaluationRepo = em.getRepository<IvrEvaluationRepository>('IvrEvaluationRepository');
  }

  ngOnInit(): void {
    this.ivrEvaluation = new IvrEvaluationEntity();
    this.initFormGroup();
  }

  // private getQuestionAnswerKeys(): string[] {
  //   if (_.isEmpty(this.questionsControl.value)) {
  //     return [];
  //   }

  //   let keys: string[] = [];
  //   for (const q of this.questionsControl.value) {
  //     if (!q || _.isEmpty(q.accepted_answer)) {
  //       continue;
  //     }
  //     keys = keys.concat(q.accepted_answer);
  //   }

  //   return keys;
  // }

  getChangeSet(): any {
    const formData: any = this.formService.getChangeSet(this.form);
    if ('name' in formData) {
      formData.name = _.trim(formData.name);
    }
    if ('welcome_msg' in formData) {
      formData.welcome_msg_id = formData.welcome_msg ? formData.welcome_msg.id : null;
      delete formData.welcome_msg;
    }
    if ('thankyou_msg' in formData) {
      formData.thankyou_msg_id = formData.thankyou_msg ? formData.thankyou_msg.id : null;
      delete formData.thankyou_msg;
    }
    if (!_.isEmpty(formData.questions)) {
      formData.question_data = formData.questions.map(o => {
        const obj: IIvrEvaluationQuestionDataType = {
          msg_id: o.msg ? o.msg.id : undefined,
          accepted_answer: o.accepted_answer
        };
        return obj;
      });
      delete formData.questions;
    }
    return formData;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.ivrEvaluation.name, [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]],
      welcome_msg: [this.ivrEvaluation.welcome_msg],
      thankyou_msg: [this.ivrEvaluation.welcome_msg, [Validators.required]],
      questions: this.fb.array([this.getQuestionControl()]),
    });
  }

  get questionsControl(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  removeQuestion(index: number): void {
    this.questionsControl.markAsDirty();
    this.questionsControl.removeAt(index);
  }

  addQuestion(): void {
    this.questionsControl.markAsDirty();
    this.questionsControl.push(this.getQuestionControl());
  }

  private getQuestionControl(value?: { msg: FileEntryEntity, accepted_answer: string[] }): FormControl {
    return new FormControl(value, joiValidator(
      Joi.object({
        msg: Joi.object().required(),
        accepted_answer: Joi.array().items(Joi.string()).required(),
      }).required()
    ));
  }

  // private getQuestionsControllValue(): any[] {
  //   const questions = [];
  //   if (!_.isEmpty(this.ivrEvaluation.question_data)) {
  //     for (const qData of this.ivrEvaluation.question_data) {
  //       const fileEntry: FileEntryEntity = qData.msg_id && this.em.getRepository<FileEntryRepository>('FileEntryRepository').getObjectById(qData.msg_id);
  //       const q: any = {
  //         msg: fileEntry,
  //         accepted_answer: qData.accepted_answer || []
  //       };

  //       questions.push(q);
  //     }
  //   }
  //   return questions;
  // }

  private updateValidatorQuestionData(): void {
    // const questionAnswerKeys = this.getQuestionAnswerKeys();

    for (let index = 0; index < this.questionsControl.controls.length; index++) {
      const questionControl = this.questionsControl.controls[index];
      questionControl.markAsTouched();

      const errors: any = questionControl.errors || {};

      if (questionControl.getError('accepted_answer_required')) {
        delete errors.accepted_answer_required;
        questionControl.setErrors(!_.isEmpty(errors) ? errors : undefined);
      }

      const q = questionControl.value;
      if (!q || _.isEmpty(q.accepted_answer)) {
        errors.accepted_answer_required = true;
        questionControl.setErrors(errors);
        continue;
      }

      // if (questionControl.getError('duplicated')) {
      //   delete questionControl.errors.duplicated;
      //   questionControl.setErrors(!_.isEmpty(errors) ? errors : undefined);
      // }

      // const duplicatedKeys = [];
      // for (const k of q.accepted_answer) {
      //   if (questionAnswerKeys.filter(key => key === k).length > 1) {
      //     duplicatedKeys.push(k);
      //   }
      // }

      // if (duplicatedKeys.length) {
      //   const errors: any = questionControl.errors || {};
      //   errors.duplicated = { keys: duplicatedKeys };
      //   questionControl.setErrors(errors);
      // }
    }
  }

  updateSoundB4WelcomeMsgFile(soundData: { new_file_entry: FileEntryEntity }): void {
    const file_entry = soundData && soundData.new_file_entry;
    this.form.get('welcome_msg').markAsDirty();
    this.form.get('welcome_msg').setValue(file_entry);
  }

  updateSoundB4ThankyouMsgFile(soundData: { new_file_entry: FileEntryEntity }): void {
    const file_entry = soundData.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivr-evaluation');
    }
    this.form.get('thankyou_msg').markAsDirty();
    this.form.get('thankyou_msg').setValue(file_entry);
  }

  updateSoundB4QuestionMsgFile(soundData: { new_file_entry: FileEntryEntity }, index: number): void {
    const file_entry = soundData.new_file_entry;
    if (!file_entry) {
      console.warn('File entry no longer exist, remove it from ivr-evaluation');
    }

    const value: any = this.questionsControl.controls[index].value || {};
    value.msg = file_entry;

    this.questionsControl.controls[index].markAsDirty();
    this.questionsControl.controls[index].setValue(value);
  }

  phonePadPickerChanged(data: { keys: string[], error?: string }, index: number): void {
    const questionControl: FormControl = this.questionsControl.controls[index] as FormControl;
    const value: any = questionControl.value || {};
    value.accepted_answer = data.keys;

    questionControl.markAsDirty();
    questionControl.setValue(value);

    this.updateValidatorQuestionData();
  }

  async submitForm(): Promise<any> {
    this.updateValidatorQuestionData();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      // this.showError(_ti('public.message.data_invalid'));
      return undefined;
    }

    try {
      const updateData = this.getChangeSet();
      const retData = await this.ivrEvaluationRepo.createAndSave(updateData);

      if (!retData || !retData.id) {
        this.showError(_ti('public.message.error_occurred'));
        return;
      }

      await this.ivrEvaluationRepo.wait_for_object_id(retData.id);
      const retIvrEvaluation = this.ivrEvaluationRepo.getObjectById(retData.id);

      this.successToast(_ti('public.message.create_success'));
      this.dialogRef.close(retIvrEvaluation);
    } catch (e) {
      this.showError(e.message || _ti('public.message.create_failure'));
    }
  }
}
