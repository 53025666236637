import * as _ from 'lodash';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { TranslateService } from '@ngx-translate/core';
// import { ToastService, DialogActionButton, Colors, FormService } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';

@Component({
  selector: 'app-outcallcampaign-contacts',
  templateUrl: './outcallcampaign-contacts.component.html',
  styleUrls: ['./outcallcampaign-contacts.component.scss']
})
export class OutcallcampaignContactsComponent extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('campaign.tab_campaign_contacts');
  // dialogLeftActions: Array<DialogActionButton> = [];
  // dialogRightActions: Array<DialogActionButton> = [];
  campaign: OutcallCampaignEntity;

  constructor(
    // private dialogRef: MatDialogRef<OutcallcampaignContactsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    super();
    this.campaign = data.campaign;
  }

  // ngOnInit(): void {
  //   super.ngOnInit();
  // }

  // close(result?): void {
  //   this.dialogRef.close(result);
  // }
}
