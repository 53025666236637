import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, ToastService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SipPhoneProvisioningTokenEntity } from '@wephone-core/model/entity/sip_phone_provisioning_token';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { EnterpriseSipGatewayService } from '@wephone-common/service';

interface SipGateWayProvisioningLinkDataType {
  sip_gateway: SipPhoneProvisioningTokenEntity;
}

@Component({
  selector: 'app-show-gateway-provisioning-link-modal',
  templateUrl: './show-gateway-provisioning-link-modal.component.html',
  styleUrls: ['./show-gateway-provisioning-link-modal.component.scss']
})
export class ShowGatewayProvisioninkModalComponent extends DialogComponentBase implements OnInit {

  constructor(
    private readonly dialogRef: MatDialogRef<ShowGatewayProvisioninkModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: SipGateWayProvisioningLinkDataType,
    private readonly clipboard: Clipboard,
    private readonly toast: ToastService,
    private readonly sipGatewayService: EnterpriseSipGatewayService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  dialogTitle = _tk('enterprise_sip_gateway.content.provision_link');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.cancel'),
      action: () => {
        this.cancel();
      },
      visible: () => {
        return true;
      },
    }
  ];

  provisionLink = '';

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (this.data.sip_gateway && this.data.sip_gateway.id) {
      const token: string = await this.sipGatewayService.getEnterpriseGatewayProvisioningToken(this.data.sip_gateway.id);
      if (token) {
        this.provisionLink = `${environment.apiUrl}/ws-v2/noauth/sipgateway-config/${token}`;
        this.detectChanges();
      }
    }
  }

  copy(): void {
    if (!this.provisionLink) {
      return;
    }
    this.clipboard.copy(this.provisionLink);
    this.toast.showSuccess(_ti('clipboard.copied'));
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
