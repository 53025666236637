import * as _ from 'lodash';
import { EntityManager } from '@wephone-core/service/entity_manager';
import { Entity, mapped_datafield, datafield } from '@wephone-core/model/model';
import { DateTime } from 'luxon';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { SkillRequirementEntity } from '@wephone-core/model/entity/skill_requirement';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { IvrScriptEntity } from '@wephone-core/model/entity/ivrscript';
import { IvrRemoteAppEntity } from '@wephone-core/model/entity/ivr_remote_app';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { PhoneNumberService, parseDateTime } from '@wephone-utils';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { IDidEntity } from './did.i';
import { UserEntity } from './user';
import { _tk, _ti } from '@wephone-translation';
import { OpeningHourCalendarRepository } from '../repository/openinghour_calendar';
import { SkillAgentRequirementRepository } from '../repository/skill_requirement';
import { DidRepository } from '../repository/did';
import {
  IRoutingAppDestinationData, RoutingAppName, RoutingParamsToDid,
  RoutingParamsWebservice,
  RoutingParamsCrmRoute, RoutingParamsSipphone, RoutingParamsIvrMenu, RoutingParamsConference, RoutingParamsPlayFileAndHangup,
  RoutingParamsPhoneNumber, RoutingParamsRunVxml, RoutingParamsRemoteApp, RoutingParamsQueue,
  getRoutingAppIdByName, RoutingAppId
} from '@wephone-core/routing-app/routing-app.interface';
import { RoutingAppManager } from '@wephone-core/routing-app/routing-app-manager';
import { VoiceMailBoxEntity } from './voicemail_box';
import { ISipphoneEntity } from './sipphone.i';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { EnterpriseCrmEntity } from './enterprise_crm';
import { EnterpriseCrmRepository } from '../repository/enterprise_crm';
import { RoutingServiceEntity } from './routing_service';
import { GroupEntity } from './group';
import { UserGroupEntity } from './usergroup';

export enum DidTelecomProvider {
  voxbone = 1,
  twilio = 2,
}

export enum DidType {
  national = 1,
  geographic = 2,
  mobile = 3,
  toll_free = 4,
  shared_cost = 5,
  inum = 6,
  special = 7
}

export enum DidPortingStatusType {
  NoPorting = 0,
  PortingIn = 1,
  PortingOut = 2,
}

export enum DidRoutePriority {
  VeryHigh = 0,
  High = 1,
  Normal = 2,
  Low = 3,
  VeryLow = 4,
}

export class DidEntity extends Entity implements IDidEntity {
  static object_type_id = 'did';

  private static USE_ENTERPRISE_CALENDAR_DEFAULT = 0;

  static LINK_TYPE_USER = 1;
  static LINK_TYPE_PHONE = 2;
  static LINK_TYPE_QUEUE = 3;
  static LINK_TYPE_IVR = 4;
  // static LINK_TYPE_EXTERNAL_NUMBER = 5;

  id: number;
  @datafield action_office_hour: IRoutingAppDestinationData;
  @datafield action_not_office_hour: IRoutingAppDestinationData;
  @datafield name: string;
  @datafield welcome_message: string;
  @datafield route_priority: DidRoutePriority;
  @datafield enterprise_id: number;
  @datafield telecom_provider_id: DidTelecomProvider;
  @datafield number: string;
  @datafield same_routing: number;
  @datafield calendar_id: number;
  @datafield trial_eligible: number;
  @datafield lang: string;
  @datafield attribution_dt: any;
  @mapped_datafield('gateway_id') gateway;
  @mapped_datafield('is_default') default: number;
  @datafield username: string;
  @datafield password: string;
  @datafield linked_object_id: number;
  @datafield did_group_id: number;
  @datafield linked_object_type: number; // 1: User, 2: Sip Phone, 3: Queue, 4: Ivr, 5:  External Number
  @datafield ivrapp_name: string;
  @datafield comment: string;
  @datafield country_code: string;
  @datafield expire_date;
  @datafield is_paid = 1;
  @datafield order_id: number;
  @datafield use_enterprise_calendar: number;
  @datafield master_did_id: number;
  @datafield vm_enabled: number;
  @datafield vm_id: number;
  @datafield is_hidden: number;
  @datafield opening_hour_file_id: number;
  @datafield routing_status: number;
  @datafield routing_status_message: string;
  @datafield crm_id: number;
  @datafield routing_service_id: number;
  @datafield inbound_sms_emails: string;
  @datafield did_type: DidType;
  @datafield group_id: number;

  porting_status: DidPortingStatusType;

  // voicemail_by_mail_enabled: number;
  // voicemail_in_attachement: number;
  // voicemail_by_mail_addrs: string[];

  display_number: string;
  _destination_type: RoutingAppId;
  _destination_object: any;
  _opening_hour_file: FileEntryEntity;
  private _group: UserGroupEntity | GroupEntity;

  // update data from fetch
  dependent_calendars: OpeningHourCalendarEntity[] = [];
  skill_requirements: SkillRequirementEntity[] = [];

  get group(): UserGroupEntity | GroupEntity {
    if (this.group_id) {
      if (!this._group) {
        this._group = EntityManager.getRepository('UserGroupRepository').getObjectById(this.group_id) as UserGroupEntity;
      }
      return this._group;
    }
  }

  set group(group: UserGroupEntity | GroupEntity) {
    this.group_id = group ? group.getId() : null;
    this._group = group;
  }

  get mailbox(): VoiceMailBoxEntity {
    return this.vm_id ? EntityManager.getRepository('VoiceMailBoxRepository').getObjectById(this.vm_id) : undefined;
  }

  set mailbox(mailbox: VoiceMailBoxEntity) {
    this.vm_id = mailbox && mailbox.id;
  }

  get voicemail_by_mail_enabled(): number {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.voicemail_by_mail_enabled : undefined;
  }

  set voicemail_by_mail_enabled(value: number) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for did');
      return;
    }
    this.mailbox.voicemail_by_mail_enabled = value;
  }

  get voicemail_in_attachement(): number {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.voicemail_in_attachement : undefined;
  }

  set voicemail_in_attachement(value: number) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for did');
      return;
    }
    this.mailbox.voicemail_in_attachement = value;
  }

  get voicemail_by_mail_addrs(): string[] {
    const mailbox = this.mailbox;
    return mailbox ? mailbox.voicemail_by_mail_addrs : undefined;
  }

  set voicemail_by_mail_addrs(value: string[]) {
    if (!this.mailbox) {
      console.warn('Mailbox not found for did');
      return;
    }
    this.mailbox.voicemail_by_mail_addrs = value;
  }

  get destination_type(): RoutingAppId {
    return this._destination_type;
  }

  get is_new_assignment(): boolean {
    if (this.configured) {
      return false;
    }
    const now = DateTime.utc();
    const attributionDate = parseDateTime(this.attribution_dt);
    return now.minus({ days: 1 }) < attributionDate;
  }

  get can_send_sms(): boolean {
    return this.is_provider_voxbone || this.is_provider_twilio;
  }

  get is_provider_voxbone(): boolean {
    return this.telecom_provider_id === DidTelecomProvider.voxbone;
  }

  get is_provider_twilio(): boolean {
    return this.telecom_provider_id === DidTelecomProvider.twilio;
  }

  get number_with_name(): string {
    let ret = this.display_number;
    if (this.name) {
      ret += ` (${this.name})`;
    }

    return ret;
  }

  get routing_error(): string {
    if (this.is_new_assignment || this.routing_status || !this.action_office_hour && !this.action_not_office_hour) {
      return;
    }

    return this.routing_status_message;
  }

  get opening_calendar(): OpeningHourCalendarEntity {
    if (this.calendar_id) {
      return EntityManager.getRepository('OpeningHourCalendarRepository').getObjectById(this.calendar_id) as OpeningHourCalendarEntity;
    }
  }

  set opening_calendar(calendar: OpeningHourCalendarEntity) {
    if (calendar) {
      this.calendar_id = calendar.id;
    }
  }

  get language(): string {
    if (!this.lang) {
      return '';
    }

    return this.lang;
  }

  set language(language) {
    if (!language) {
      this.lang = undefined;

      return;
    }

    this.lang = language;
  }

  get master_did(): DidEntity {
    if (this.master_did_id) {
      return EntityManager.getInstance().getRepository<DidRepository>('DidRepository').getObjectById(this.master_did_id);
    }
  }

  set master_did(did: DidEntity) {
    this.master_did_id = did ? did.id : undefined;
  }

  get enterprise_crm(): EnterpriseCrmEntity {
    if (this.crm_id) {
      return EntityManager.getInstance().getRepository<EnterpriseCrmRepository>('EnterpriseCrmRepository').getObjectById(this.crm_id);
    }
  }

  set enterprise_crm(crm: EnterpriseCrmEntity) {
    this.crm_id = crm ? crm.id : undefined;
  }

  get routing_service(): RoutingServiceEntity {
    if (this.routing_service_id) {
      return EntityManager.getInstance().getRepository<EnterpriseCrmRepository>('RoutingServiceRepository').getObjectById(this.routing_service_id);
    }
  }

  set routing_service(routingService: RoutingServiceEntity) {
    this.routing_service_id = routingService ? routingService.id : undefined;
  }

  // opening_hour_file
  set opening_hour_file(opening_hour_file: FileEntryEntity) {
    this.opening_hour_file_id = opening_hour_file ? opening_hour_file.getId() : null;
  }
  get opening_hour_file(): FileEntryEntity {
    if (this.opening_hour_file_id) {
      if (!this._opening_hour_file || this._opening_hour_file.id !== this.opening_hour_file_id) {
        this._opening_hour_file = EntityManager.getRepository('FileEntryRepository').getObjectById(this.opening_hour_file_id);
      }

      return this._opening_hour_file;
    }
  }

  private resetObjectCached(object_data: any): void {
    const _cachedObjects = {
      group_id: '_group',
      opening_hour_file_id: '_opening_hour_file'
    };

    for (const field of Object.keys(_cachedObjects)) {
      if (this[field] !== object_data[field]) {
        this[_cachedObjects[field]] = undefined;
      }
    }
  }

  setObjectData(object_data: any, fetch_related_data: boolean): void {
    this.resetObjectCached(object_data);
    super.setObjectData(object_data, fetch_related_data);

    this.expire_date = this.expire_date && parseDateTime(this.expire_date);
    this.display_number = PhoneNumberService.getInstance().getDisplayNumber(this.number);

    if (typeof object_data.action_office_hour === 'string') {
      this.action_office_hour = JSON.parse(object_data.action_office_hour || '{}');
    }

    if (typeof object_data.action_not_office_hour === 'string') {
      this.action_not_office_hour = JSON.parse(object_data.action_not_office_hour || '{}');
    }

    this.attribution_dt = object_data.attribution_dt && parseDateTime(object_data.attribution_dt);

    this.setDestType();

    if ('porting_status' in object_data) {
      this.porting_status = object_data.porting_status;
    }

    // if (this.vm_id) {
    //   this.voicemail_by_mail_enabled = this.mailbox.voicemail_by_mail_enabled;
    //   this.voicemail_in_attachement = this.mailbox.voicemail_in_attachement;
    //   this.voicemail_by_mail_addrs = this.mailbox.voicemail_by_mail_addrs;
    // }
  }

  private setDestType(): void {
    this._destination_type = undefined;

    if (this.action_office_hour && this.action_office_hour.application) {
      this._destination_type = getRoutingAppIdByName(this.action_office_hour.application);
    }
  }

  clearRoutingData(): void {
    this.clearLinkedObject();
    this.clearAction();
    this.lang = undefined;
    this.master_did_id = undefined;
    this._destination_object = undefined;
    this._destination_type = undefined;
    this.routing_service_id = undefined;
    this.crm_id = undefined;
    this.group = undefined;
    this.opening_hour_file = undefined;
  }

  clearLinkedObject(): void {
    this.linked_object_id = undefined;
    this.linked_object_type = undefined;
  }

  clearAction(): void {
    this.action_office_hour = undefined;
    this.action_not_office_hour = undefined;
    // this.name = undefined;
    this.welcome_message = undefined;
    this.same_routing = undefined;
    this.use_enterprise_calendar = DidEntity.USE_ENTERPRISE_CALENDAR_DEFAULT;
    this.setDestType();
  }

  hasRoutingData(outOfOfficeHours: boolean = false): boolean {
    return (this.hasMasterDid()
      || this.hasLinkedData()
      || (outOfOfficeHours ? !_.isEmpty(this.action_not_office_hour) : !_.isEmpty(this.action_office_hour)));
  }

  hasLinkedData(): boolean {
    return !!this.linked_object_id && !!this.linked_object_type;
  }

  hasLinkedUser(): boolean {
    return !!this.linked_object_id && this.linked_object_type === DidEntity.LINK_TYPE_USER;
  }

  hasLinkedSipPhone(): boolean {
    return !!this.linked_object_id && this.linked_object_type === DidEntity.LINK_TYPE_PHONE;
  }

  hasMasterDid(): boolean {
    return !!this.master_did_id;
  }

  get destination_name(): string {
    if (this.master_did) {
      return this.master_did.display_number;
    }
    if (this.routed_user) {
      return this.routed_user.name;
    }
    if (this.routed_sipphone) {
      return `Phone extension (${this.routed_sipphone.extension})`;
    }
    if (this.routed_queue) {
      return this.routed_queue.queue_name;
    }
    if (this.routed_menu) {
      return this.routed_menu.name;
    }
    if (this.routed_application) {
      return this.routed_application.application_name;
    }
    if (this.routed_remote_application) {
      return this.routed_remote_application.name;
    }

    return '';
  }

  /**
   * Check number has routed to any queue or agent that related to user (belongs queue or is agent)
   * @param user: UserEntity
   */
  hasRouteRelatedUser(user: UserEntity): boolean {
    return (
      (this.routed_user && this.routed_user.id === user.id) ||
      (this.routed_queue && user.agent && this.routed_queue.contain_agent_id(user.agent.id))
    );
  }

  get alias_dids(): DidEntity[] {
    return EntityManager.getRepository('DidRepository').getObjectList().filter(x => x.master_did_id === this.id);
  }

  get routed_user(): UserEntity {
    if (this.hasLinkedUser()) {
      return EntityManager.getRepository('UserRepository').getObjectById(this.linked_object_id);
    }
  }

  get routed_sipphone(): ISipphoneEntity {
    return this.routedSipPhone(false);
  }

  get out_office_hours_routed_sipphone(): ISipphoneEntity {
    return this.routedSipPhone(true);
  }

  private routedSipPhone(outOfOfficeHours: boolean = false): ISipphoneEntity {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.call_phone && action.params && (action.params as RoutingParamsSipphone).sipphone_id) {
      return EntityManager.getRepository('SipPhoneRepository').getObjectById(action.params.sipphone_id);
    }
  }

  get routed_queue(): CallQueueEntity {
    return this.routedQueue(false);
  }

  get out_office_hours_routed_queue(): CallQueueEntity {
    return this.routedQueue(true);
  }

  isDestType(dest_type: RoutingAppName): boolean {
    return RoutingAppId[dest_type] === this._destination_type;
  }

  getDestinationObject(): any {
    if (!this._destination_object && this.action_office_hour && this.action_office_hour.application) {
      const app = RoutingAppManager.getInstance().createAppInstanceFromData(this.action_office_hour);
      if (app) {
        this._destination_object = app.getRelatedObject();
      }
    }
    return this._destination_object;
  }

  private routedQueue(outOfOfficeHours: boolean = false): CallQueueEntity {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.callqueue && action.params && (action.params as RoutingParamsQueue).queue) {
      return EntityManager.getRepository('CallQueueRepository').getObjectById(action.params.queue);
    }
  }

  get routed_menu(): IvrCustomMenuEntity {
    return this.routedMenu(false);
  }

  get out_office_hours_routed_menu(): IvrCustomMenuEntity {
    return this.routedMenu(true);
  }

  private routedMenu(outOfOfficeHours: boolean = false): IvrCustomMenuEntity {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.ivr_custom_menu && action.params && (action.params as RoutingParamsIvrMenu).id) {
      return EntityManager.getRepository('IvrCustomMenuRepository').getObjectById(action.params.id);
    }
  }

  get routed_application(): IvrScriptEntity {
    return this.routedApplication(false);
  }

  get out_office_hours_routed_application(): IvrScriptEntity {
    return this.routedApplication(true);
  }

  private routedApplication(outOfOfficeHours: boolean = false): IvrScriptEntity {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.runvxmlscript && action.params && (action.params as RoutingParamsRunVxml).ivrscript_id) {
      return EntityManager.getRepository('IvrScriptRepository').getObjectById(action.params.ivrscript_id);
    }
  }

  get routed_remote_application(): IvrRemoteAppEntity {
    return this.routedRemoteApplication(false);
  }

  get out_office_hours_routed_remote_application(): IvrRemoteAppEntity {
    return this.routedRemoteApplication(true);
  }

  private routedRemoteApplication(outOfOfficeHours: boolean = false): IvrRemoteAppEntity {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.remote_application && action.params && (action.params as RoutingParamsRemoteApp).id) {
      return EntityManager.getRepository('IvrRemoteAppRepository').getObjectById(action.params.id);
    }
  }

  get routed_conference(): ConferenceEntity {
    return this.routedConference(false);
  }

  get out_office_hours_routed_conference(): ConferenceEntity {
    return this.routedConference(true);
  }

  private routedConference(outOfOfficeHours: boolean = false): ConferenceEntity {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.conference && action.params && (action.params as RoutingParamsConference).conference) {
      return EntityManager.getRepository('ConferenceRepository').getObjectById(action.params.conference);
    }
  }

  get routed_phone_number(): string {
    return this.routedPhoneNumber(false);
  }

  get out_office_hours_routed_phone_number(): string {
    return this.routedPhoneNumber(true);
  }

  private routedPhoneNumber(outOfOfficeHours: boolean = false): string {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.call_phone_number && action.params && (action.params as RoutingParamsPhoneNumber).number) {
      return action.params.number;
    }
  }

  get routed_fileentry(): FileEntryEntity {
    return this.routedFileEntry(false);
  }

  get out_office_hours_routed_fileentry(): FileEntryEntity {
    return this.routedFileEntry(true);
  }

  private routedFileEntry(outOfOfficeHours: boolean = false): FileEntryEntity {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.play_then_hangup && action.params && (action.params as RoutingParamsPlayFileAndHangup).sound_id) {
      return EntityManager.getRepository('FileEntryRepository').getObjectById(
        action.params.sound_id
      );
    }
  }

  get routed_webservice(): string {
    return this.routedWebService(false);
  }

  get out_office_hours_routed_webservice(): string {
    return this.routedWebService(true);
  }

  private routedWebService(outOfOfficeHours: boolean = false): string {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.webservice && action.params && (action.params as RoutingParamsWebservice).url) {
      return action.params.url;
    }
  }

  get application_name(): string {
    return this.get_application_name(false);
  }

  get out_office_hours_application_name(): string {
    return this.get_application_name(true);
  }

  private get_application_name(outOfficeHours: boolean = false): string {
    let application;
    if (outOfficeHours) {
      if (!this.action_not_office_hour) {
        return;
      }
      application = this.action_not_office_hour.application;
    } else {
      if (!this.action_office_hour && !this.hasLinkedSipPhone()) {
        return;
      }
      application = this.hasLinkedSipPhone() ? 'call_phone' : this.action_office_hour.application;
    }

    return `routing_application.${application}.app_name`;
  }

  get configured(): boolean {
    return this.name ||
      this.hasRoutingData() ? true : false;
  }

  get tooltip(): any[] {
    return this.getTooltip(false);
  }

  get out_office_hours_tooltip(): any[] {
    return this.getTooltip(true);
  }

  private getTooltip(outOfficeHours: boolean = false): any[] {
    const action_data = outOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (!action_data && !this.hasLinkedData()) {
      return;
    }

    const tooltip = [];

    if (this.hasLinkedUser()) {
      tooltip.push({
        param_name: _tk('did.content.user_dedicated_number'),
        param_value: this.routed_user && this.routed_user.name
      });

      return tooltip;
    }

    if (this.hasLinkedSipPhone()) {
      const sipphone = outOfficeHours ? this.out_office_hours_routed_sipphone : this.routed_sipphone;
      if (!sipphone) {
        return;
      }
      let paramValue = '';
      if (sipphone.extension) {
        paramValue = sipphone.extension_with_owner;
      }

      // if (sipphone.user) {
      //   paramValue += ` (${sipphone.user.name})`;
      // }

      tooltip.push({
        param_name: _tk('routing_application.params.call_phone'),
        param_value: paramValue
      });

      return tooltip;
    }

    if (!action_data || !action_data.params) {
      return tooltip;
    }

    for (const key of Object.keys(action_data.params)) {
      const param = action_data.params[key];
      const _get_tooltip = (value, alias) => {
        return {
          param_name: `routing_application.params.${alias}`,
          param_value: value
        };
      };
      const _getObjectParam = value => {
        let obj;
        switch (action_data.application) {
          case RoutingAppName.remote_application:
            // required loaded data
            obj = EntityManager.getRepository('IvrRemoteAppRepository').getObjectById(value);
            break;
          case RoutingAppName.runvxmlscript:
            obj = EntityManager.getRepository('IvrScriptRepository').getObjectById(value);
            break;
          case RoutingAppName.callqueue:
            obj = EntityManager.getRepository('CallQueueRepository').getObjectById(value);
            break;
          case RoutingAppName.conference:
            obj = EntityManager.getRepository('ConferenceRepository').getObjectById(value);
            break;
          case RoutingAppName.ivr_custom_menu:
            obj = EntityManager.getRepository('IvrCustomMenuRepository').getObjectById(value);
            break;
          case RoutingAppName.call_phone:
            obj = EntityManager.getRepository('SipPhoneRepository').getObjectById(value);
            break;
          case RoutingAppName.call_user:
            obj = EntityManager.getRepository('UserRepository').getObjectById(value);
            break;
          case RoutingAppName.crm_route:
            // required loaded data
            obj = EntityManager.getRepository('CrmRoutingRuleRepository').getObjectById(value);
            break;
          default:
            break;
        }

        return obj;
      };

      const param_obj = _getObjectParam(param);
      let tooltip_item;
      switch (key) {
        case 'ivrscript_id':
        case 'conference':
        case 'id':
          tooltip_item = _get_tooltip(param_obj ? param_obj.name : '', key);
          break;
        case 'queue':
          tooltip_item = _get_tooltip(param_obj ? param_obj.queue_name : '', key);
          break;
        case 'sipphone_id':
          tooltip_item = _get_tooltip(param_obj ? param_obj.extension_with_owner : '', key);
          break;
        case 'number':
        case 'extension':
        case 'default_ext':
        case 'url':
          tooltip_item = _get_tooltip(param, key);
          break;
        default:
          tooltip_item = undefined;
          break;
      }
      if (tooltip_item) {
        tooltip.push(tooltip_item);
      }
    }

    return tooltip;
  }

  // set user dedicated number
  routeToUser(user: IUserEntity): void {
    // if (outOfOfficeHours) {
    //   this.action_not_office_hour = { application: RoutingAppName.call_user, params: { id: user.id } };
    //   this.same_routing = 0;
    // } else {
    //   this.action_office_hour = { application: RoutingAppName.call_user, params: { id: user.id } };
    //   this.same_routing = 1;
    // }
    this.clearRoutingData();
    this.linked_object_id = user.id;
    this.linked_object_type = DidEntity.LINK_TYPE_USER;
    this.setDestType();
  }

  routeToQueue(queue: CallQueueEntity): void {
    this.clearRoutingData();
    this.action_office_hour = { application: RoutingAppName.callqueue, params: { queue: queue.id } };
    this.same_routing = 1;
    // this.clearLinkedObject();
    this.setDestType();

    this.linked_object_type = DidEntity.LINK_TYPE_QUEUE;
    this.linked_object_id = queue.id;
  }

  routeToConference(conference: ConferenceEntity): void {
    this.clearRoutingData();
    this.action_office_hour = { application: RoutingAppName.conference, params: { conference: conference.id } };
    this.same_routing = 1;
    // this.clearLinkedObject();
    this.setDestType();
  }

  routeToSipPhone(sipphone: ISipphoneEntity): void {
    this.clearRoutingData();
    this.action_office_hour = { application: RoutingAppName.call_phone, params: { sipphone_id: sipphone.id } };
    this.same_routing = 1;
    this.linked_object_id = sipphone.id;
    this.linked_object_type = DidEntity.LINK_TYPE_PHONE;
    this.setDestType();
  }

  routeToApplication(ivrscript: IvrScriptEntity): void {
    this.clearRoutingData();
    this.action_office_hour = {
      application: RoutingAppName.runvxmlscript,
      params: {
        ivrscript_id: ivrscript.id
      }
    };
    this.same_routing = 1;
    // this.clearLinkedObject();
    this.setDestType();

    // this.linked_object_type = DidEntity.LINK_TYPE_IVR;
  }

  routeToRemoteApplication(remoteApplication: IvrRemoteAppEntity): void {
    this.clearRoutingData();
    this.action_office_hour = {
      application: RoutingAppName.remote_application,
      params: { id: remoteApplication.id }
    };
    this.same_routing = 1;
    // this.clearLinkedObject();
    this.setDestType();

    // this.linked_object_type = DidEntity.LINK_TYPE_IVR;
  }

  routeToMenu(ivrCustomMenu: IvrCustomMenuEntity): void {
    this.clearRoutingData();
    this.action_office_hour = { application: RoutingAppName.ivr_custom_menu, params: { id: ivrCustomMenu.id } };
    this.same_routing = 1;
    // this.clearLinkedObject();
    this.setDestType();

    this.linked_object_type = DidEntity.LINK_TYPE_IVR;
    this.linked_object_id = ivrCustomMenu.id;
  }

  routeToPhoneNumber(phoneNumber: string, outOfOfficeHours: boolean = false): void {
    this.clearRoutingData();
    if (outOfOfficeHours) {
      this.action_not_office_hour = { application: RoutingAppName.call_phone_number, params: { number: phoneNumber } };
      this.same_routing = 0;
    } else {
      this.action_office_hour = { application: RoutingAppName.call_phone_number, params: { number: phoneNumber } };
      this.same_routing = 1;
    }
    // this.clearLinkedObject();
    this.setDestType();

    // if (!outOfOfficeHours) {
    //   this.linked_object_type = DidEntity.LINK_TYPE_EXTERNAL_NUMBER;
    // }
  }

  routeToFileEntry(fileEntry: FileEntryEntity | null): void {
    this.clearRoutingData();
    this.action_office_hour = { application: RoutingAppName.play_then_hangup, params: { sound_id: fileEntry && fileEntry.id || null } };
    this.same_routing = 1;
    // this.clearLinkedObject();
    this.setDestType();
  }

  routeToWebService(url: string): void {
    this.clearRoutingData();
    this.action_office_hour = { application: RoutingAppName.webservice, params: { url } };
    this.same_routing = 1;
    // this.clearLinkedObject();
    this.setDestType();
  }

  routeToCrmRoute(crmRoutingRule: CrmRoutingRuleEntity): void {
    this.clearRoutingData();
    this.action_office_hour = { application: RoutingAppName.crm_route, params: { id: crmRoutingRule.id } };
    this.same_routing = 1;
    // this.clearLinkedObject();
    this.setDestType();
  }

  get routed_crm_routing_rule(): CrmRoutingRuleEntity {
    return this.routedCrmRoutingRule(false);
  }

  get out_office_hours_routed_crm_routing_rule(): CrmRoutingRuleEntity {
    return this.routedCrmRoutingRule(true);
  }

  get out_office_hours_routed_did(): CrmRoutingRuleEntity {
    return this.routedDid(true);
  }

  routedDid(outOfOfficeHours: boolean = false): CrmRoutingRuleEntity {
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.to_did && action.params && (action.params as RoutingParamsToDid).did_id) {
      return EntityManager.getRepository('DidRepository').getObjectById(
        action.params.did_id
      );
    }
  }

  routedCrmRoutingRule(outOfOfficeHours: boolean = false): CrmRoutingRuleEntity {
    const crmRoutingRuleRepo = EntityManager.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository');
    const action: IRoutingAppDestinationData = outOfOfficeHours ? this.action_not_office_hour : this.action_office_hour;

    if (action && action.application === RoutingAppName.crm_route && action.params && (action.params as RoutingParamsCrmRoute).id) {
      return crmRoutingRuleRepo.getObjectById(
        action.params.id
      );
    }
  }

  setActionHourJson(): void {
    if (typeof this.action_office_hour === 'string') {
      this.action_office_hour = JSON.parse(this.action_office_hour);
    }
    if (typeof this.action_not_office_hour === 'string') {
      this.action_not_office_hour = JSON.parse(this.action_not_office_hour);
    }
  }

  fetchRelatedData(): void {
    const repo = EntityManager.getRepository<SkillAgentRequirementRepository>(
      'SkillAgentRequirementRepository'
    );
    if (repo) {
      const requirements = repo.getRequirementByDidId(this.id);
      this.updateList(
        this.skill_requirements,
        requirements
      );
    }

    const calendarRepo = EntityManager.getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository');
    if (calendarRepo) {
      const calendars = calendarRepo.getCalendarsLinkToDidByDidId(this.id);
      this.updateList(
        this.dependent_calendars,
        calendars
      );
    }
  }

  setNumberWithDisplay(number: string): void {
    this.number = number;
    this.display_number = number;
  }

  get routedDids(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      // No case in office-hour routed did
      if (did.out_office_hours_routed_did && did.out_office_hours_routed_did.id === this.id) {
        ret.push(did);
      }
    }
    return ret;
  }

  get routedCrmRoutings(): CrmRoutingRuleEntity[] {
    const ret: CrmRoutingRuleEntity[] = [];
    for (const item of EntityManager.getRepository('CrmRoutingRuleRepository').getObjectList() as CrmRoutingRuleEntity[]) {
      // No case routed_did
      if (!_.isEmpty(item.routed_dids_conditional) && _.includes(item.routed_dids_conditional.map(x => x.id), this.id)) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routedIvrMenus(): IvrCustomMenuEntity[] {
    return RoutingAppService.getInstance().getRoutedIvrMenusToEntity(this.id, RoutingAppName.to_did);
  }
}
