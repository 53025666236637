import { Component, OnInit, Inject } from '@angular/core';
import { ConferenceEntity } from '@wephone-core/model/entity/conference';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService, joiValidator, DialogActionButton, Colors, IFlexDialogConfig } from '@wephone-utils';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-create-conferences-modal',
  templateUrl: './create-conferences-modal.component.html',
  styleUrls: ['./create-conferences-modal.component.scss']
})
export class CreateConferencesModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 's'
  };

  dialogTitle = _tk('conference.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  public conference: ConferenceEntity;
  public form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<CreateConferencesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public toast: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.conference = _.cloneDeep(this.data.conference);
    this.initFormGroup();
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.conference.name, [Validators.required, Validators.maxLength(255)]],
      password: [this.conference.password, joiValidator(Joi.string().required())]
    });

    this.form.valueChanges.subscribe(changes => {
      this.conference.name = changes.name;
      this.conference.password = changes.password;
    });
  }

  submit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.showError(_ti('public.message.data_invalid'));
      return;
    }
    this.cancel({ name: this.conference.name, password: this.conference.password });
  }

  public cancel(result?): void {
    this.dialogRef.close(result);
  }
}
