import * as _ from 'lodash';
import { datafield, Entity } from '../model';
import { FileEntryEntity } from './fileentry';
import { FileEntryRepository } from '../../model/repository/fileentry';
import { EntityManager } from '../../service/entity_manager';
import { DidEntity } from './did';
import { CrmRoutingRuleEntity } from './crm_routing_rule';
import { IvrCustomMenuRepository } from '../repository/ivr_custom_menu';
import { IRoutingAppDestinationData, RoutingAppName, RoutingParamsIvrMenu, RoutingParamsIvrMenuData } from '../../routing-app/routing-app.interface';
import { CallQueueEntity } from './callqueue';
import { RoutingAppService } from '@wephone-core/routing-app/routing-app-service';
import { IvrScriptEntity } from './ivrscript';
import { IvrRemoteAppEntity } from './ivr_remote_app';
import { ConferenceEntity } from './conference';
import { IUserEntity } from './user.i';
import { SipPhoneEntity } from './sipphone';
import { SmsTemplateEntity } from './sms_template';

export class IvrCustomMenuEntity extends Entity {
  static object_type_id = 'ivr_custom_menu';
  static TYPE_IVRMENU = 0;
  static APP_IVRMENU = 'ivr_custom_menu';

  id: number;
  @datafield parent_id: number;
  @datafield name: string;
  @datafield ivrscript_type: number;
  @datafield ivrscript_data: RoutingParamsIvrMenuData;
  @datafield missed_on_hangup: number;
  @datafield direct_extension: number;
  @datafield routing_status: number;
  @datafield routing_status_message: string;
  @datafield alias: string;

  sound_message: FileEntryEntity; // if ivrscript_data.sound_id not null

  get routing_error(): string {
    if (this.routing_status) {
      return;
    }

    return this.routing_status_message;
  }

  private getKeyMapRoutings(): IRoutingAppDestinationData[] {
    const ret: IRoutingAppDestinationData[] = [];
    if (this.ivrscript_data) {
      for (const key of Object.keys(this.ivrscript_data.keymap)) {
        const routing: IRoutingAppDestinationData = this.ivrscript_data.keymap[key];
        ret.push(routing);

        if (routing.application === RoutingAppName.ivr_custom_menu) {
          const routingParams: RoutingParamsIvrMenu = routing.params as RoutingParamsIvrMenu;
          if (routingParams && routingParams.hasOwnProperty('ivrscript_data') && routingParams.ivrscript_data) {
            for (const subKey of Object.keys(routingParams.ivrscript_data.keymap)) {
              const subRouting: IRoutingAppDestinationData = routingParams.ivrscript_data.keymap[subKey];
              ret.push(subRouting);
            }
          }
        }
      }
    }

    return ret;
  }

  private getRoutedObjectList(app: RoutingAppName): CallQueueEntity[]
    | IvrCustomMenuEntity[]
    | IvrScriptEntity[]
    | IvrRemoteAppEntity[]
    | ConferenceEntity[]
    | FileEntryEntity[]
    | IUserEntity[]
    | SipPhoneEntity[]
    | DidEntity[]
    | SmsTemplateEntity[] {
    if (!this.ivrscript_data || !this.ivrscript_data.keymap) {
      return [];
    }
    const ret = [];

    const routings: IRoutingAppDestinationData[] = this.getKeyMapRoutings();
    let item;
    for (const routing of routings) {
      switch (app) {
        case RoutingAppName.callqueue:
          item = RoutingAppService.getInstance().getRoutedCallQueue(routing);
          break;
        case RoutingAppName.ivr_custom_menu:
          item = RoutingAppService.getInstance().getRoutedIvrMenu(routing);
          break;
        case RoutingAppName.runvxmlscript:
          item = RoutingAppService.getInstance().getRoutedRunVxml(routing);
          break;
        case RoutingAppName.remote_application:
          item = RoutingAppService.getInstance().getRoutedRemoteApp(routing);
          break;
        case RoutingAppName.play_then_hangup:
          item = RoutingAppService.getInstance().getRoutedFileEntryHangup(routing);
          break;
        case RoutingAppName.call_user:
          item = RoutingAppService.getInstance().getRoutedUser(routing);
          break;
        case RoutingAppName.call_phone:
          item = RoutingAppService.getInstance().getRoutedSipPhone(routing);
          break;
        case RoutingAppName.to_did:
          item = RoutingAppService.getInstance().getRoutedDid(routing);
          break;
        case RoutingAppName.send_sms:
          item = RoutingAppService.getInstance().getRoutedSmsTemplate(routing);
          break;
        default:
          break;
      }

      if (item) {
        ret.push(item);
      }
    }

    return ret;
  }

  get parent(): IvrCustomMenuEntity {
    if (!this.parent_id) {
      return;
    }

    return this.getRepository().getObjectById(this.parent_id) as IvrCustomMenuEntity;
  }

  get routed_queues(): CallQueueEntity[] {
    return this.getRoutedObjectList(RoutingAppName.callqueue) as CallQueueEntity[];
  }

  get routed_ivrmenus(): IvrCustomMenuEntity[] {
    return this.getRoutedObjectList(RoutingAppName.ivr_custom_menu) as IvrCustomMenuEntity[];
  }

  get routed_ivrscripts(): IvrScriptEntity[] {
    return this.getRoutedObjectList(RoutingAppName.runvxmlscript) as IvrScriptEntity[];
  }

  get routed_remote_apps(): IvrRemoteAppEntity[] {
    return this.getRoutedObjectList(RoutingAppName.remote_application) as IvrRemoteAppEntity[];
  }

  get routed_conferences(): ConferenceEntity[] {
    return this.getRoutedObjectList(RoutingAppName.conference) as ConferenceEntity[];
  }

  get routed_fileentries(): FileEntryEntity[] {
    return this.getRoutedObjectList(RoutingAppName.play_then_hangup) as FileEntryEntity[];
  }

  get routed_users(): IUserEntity[] {
    return this.getRoutedObjectList(RoutingAppName.call_user) as IUserEntity[];
  }

  get routed_sipphones(): SipPhoneEntity[] {
    return this.getRoutedObjectList(RoutingAppName.call_phone) as SipPhoneEntity[];
  }

  get routed_dids(): DidEntity[] {
    return this.getRoutedObjectList(RoutingAppName.to_did) as DidEntity[];
  }

  isRootIvrMenu(): boolean {
    return this.ivrscript_type === IvrCustomMenuEntity.TYPE_IVRMENU && !this.parent_id;
  }

  setObjectData(object_data: any, fetch_related_data: boolean = true): void {
    super.setObjectData(object_data, fetch_related_data);

    if (typeof this.ivrscript_data === 'string') {
      this.ivrscript_data = this.ivrscript_data ? JSON.parse(this.ivrscript_data) : {};
    }
  }

  fetchRelatedData(): void {
    this.sound_message = null;

    if (this.ivrscript_data && this.ivrscript_data.sound_id) {
      this.sound_message = FileEntryRepository.getInstance().getObjectById(this.ivrscript_data.sound_id);
    }

    // Update config when fetching related data
    if (!_.isEmpty(this.ivrscript_data && this.ivrscript_data.keymap)) {
      const objects = (this.getRepository() as IvrCustomMenuRepository).getObjectList();
      for (const key of Object.keys(this.ivrscript_data.keymap)) {
        const data = this.ivrscript_data.keymap[key];
        if (data && data.application === IvrCustomMenuEntity.APP_IVRMENU && data.params && data.params.sub_menu !== 0) {
          const item = objects.find(o => +o.id === +data.params.id);
          (data.params as RoutingParamsIvrMenu).ivrscript_data = item && item.ivrscript_data || undefined;
        }
      }
    }
  }

  getChildrenIvrMenuIds(): any {
    const ret = [];
    if (!this.ivrscript_data || !this.ivrscript_data.keymap) {
      return ret;
    }

    for (const key of Object.keys(this.ivrscript_data.keymap)) {
      if (this.ivrscript_data.keymap.hasOwnProperty(key)) {
        const data = this.ivrscript_data.keymap[key];
        if (data.application === IvrCustomMenuEntity.APP_IVRMENU) {
          ret.push(data.params.id);
        }
      }
    }

    return ret;
  }

  get isConfigured(): boolean {
    if (!_.isEmpty(this.ivrscript_data) && this.ivrscript_data.sound_id && !_.isEmpty(this.ivrscript_data.keymap)) {
      return true;
    }
    return false;
  }

  get did(): DidEntity {
    return EntityManager.getRepository('DidRepository')
      .getObjectList()
      .find(d => d.linked_object_type === DidEntity.LINK_TYPE_IVR && d.linked_object_id === this.id);
  }

  get routedDids(): DidEntity[] {
    const ret: DidEntity[] = [];
    for (const did of EntityManager.getRepository('DidRepository').getObjectList()) {
      if (did.routed_menu && did.routed_menu.id === this.id ||
        did.out_office_hours_routed_menu && did.out_office_hours_routed_menu.id === this.id ||
        (did.linked_object_type === DidEntity.LINK_TYPE_IVR && did.linked_object_id === this.id)) {
        ret.push(did);
      }
    }
    return ret;
  }

  get routedCrmRoutings(): CrmRoutingRuleEntity[] {
    const ret: CrmRoutingRuleEntity[] = [];
    for (const item of EntityManager.getRepository('CrmRoutingRuleRepository').getObjectList() as CrmRoutingRuleEntity[]) {
      if (item.routed_menu && item.routed_menu.id === this.id) {
        ret.push(item);
        continue;
      }
      if (!_.isEmpty(item.routed_menus_conditional) && _.includes(item.routed_menus_conditional.map(x => x.id), this.id)) {
        ret.push(item);
      }
    }
    return ret;
  }

  get routed_sms_templates(): SmsTemplateEntity[] {
    return this.getRoutedObjectList(RoutingAppName.send_sms) as SmsTemplateEntity[];
  }
}
