import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { EditingComponent, ToastService, joiValidator, DialogService } from '@wephone-utils';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { DidEntity } from '@wephone-core/model/entity/did';
import { DidConfigWizardModalComponent } from '@wephone/modals/did/did-config-wizard-modal/did-config-wizard-modal.component';
import { Router } from '@angular/router';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { _tk, _ti } from '@wephone-translation';
import { CallDestinationComponent } from '../call-destination/call-destination.component';

@Component({
  selector: 'app-edit-crm-routing',
  templateUrl: './edit-crm-routing.component.html',
  styleUrls: ['./edit-crm-routing.component.scss']
})
export class EditCrmRoutingComponent extends EditingComponent implements OnInit {
  @Input() editingItem: CrmRoutingRuleEntity;

  @Output() readonly change = new EventEmitter<any>();
  @ViewChildren('callDestinationComps') callDestinationComps: QueryList<CallDestinationComponent>;
  crmRouting: CrmRoutingRuleEntity;
  // crmRoutingDisplayed: CrmRoutingRuleEntity;
  form: FormGroup;
  crmList;
  virtualDid: DidEntity;
  dids: { [key: number]: DidEntity; } = {};
  isUpdatedLayout = false;

  constructor(
    private fb: FormBuilder,
    public toast: ToastService,
    private entity_manager: EntityManager,
    public dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    super();
    this.crmList = EntityManager.getRepository('EnterpriseCrmRepository').getObjectList();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.crmRouting = _.cloneDeep(this.editingItem);
    this.setCrmRoutingDisplayed(this.crmRouting);
    this.initFormGroup();
  }

  setCrmRoutingDisplayed(crmRouting: CrmRoutingRuleEntity): void {
    if (!crmRouting) {
      return;
    }
    // this.crmRoutingDisplayed = _.cloneDeep(crmRouting);
    this.virtualDid = this.dids[crmRouting.id] || new DidEntity();
    this.dids[crmRouting.id] = this.virtualDid;
    this.virtualDid.action_office_hour = crmRouting.dest_default;
    this.isUpdatedLayout = !this.isUpdatedLayout;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      name: [this.crmRouting.name, joiValidator(Joi.string().max(128).required())],
      crm: [this.crmRouting.crm_id],
      dest_default: [this.crmRouting.dest_default],
      routing_data: this.fb.array(this.getRoutingData(_.cloneDeep(this.crmRouting.routing_data)))
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(() => {
        this.onFormValueChange();
      })
    );
  }

  private getFormResetData(): Object {
    return {
      name: this.crmRouting.name,
      crm: this.crmRouting.crm_id,
      routing_data: this.crmRouting.routing_data
    };
  }

  resetForm(): void {
    this.resetRoutingdataControls();
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.setCrmRoutingDisplayed(this.editingItem);
    this.onFormValueChange();
  }

  createItem(routing_data: any): FormGroup {
    const cond = {
      field: 'contact_type',
      op: 'equals_to',
      operand: 'lead'
    };

    const dest = {
      application: 'callqueue',
      params: { queue: 52 }
    };

    return this.fb.group({
      cond: [routing_data ? routing_data.cond : cond],
      dest: [routing_data ? routing_data.dest : dest]
    });
  }

  get routing_data(): FormArray {
    return this.form.get('routing_data') as FormArray;
  }

  addNewRow(): void {
    this.routing_data.markAsDirty();
    this.routing_data.push(this.createItem(undefined));
    this.routing_data.markAsTouched();
  }

  private getRoutingData(routingData: Array<any>): Array<any> {
    const ret: Array<FormGroup> = [];
    for (const item of routingData || []) {
      ret.push(this.createItem(item));
    }

    return ret;
  }

  removeRoutingdata(index: number): void {
    this.routing_data.markAsDirty();
    this.routing_data.removeAt(index);
  }

  private resetRoutingdataControls(): void {
    while (this.routing_data.length > 0) {
      this.routing_data.removeAt(0);
    }

    for (const routing_data of _.cloneDeep(this.crmRouting.routing_data || [])) {
      this.routing_data.push(this.createItem(routing_data));
    }
  }
  async changeCallDestination(): Promise<any> {
    try {
      const virtual_did: DidEntity = this.entity_manager.getRepository('DidRepository').create() as DidEntity;
      virtual_did.number = this.crmRouting.name;
      virtual_did.action_office_hour = this.crmRouting.dest_default;
      const data = {
        call_destination: virtual_did.action_office_hour,
        is_out_of_office_hours: false,
        save_did: false,
        routing_for_crm: true,
        full_option: true
      };

      const dialogRef = this.dialogService.openDialog2(DidConfigWizardModalComponent, { data });
      const updatedConference: {app?: any, masterDid?: DidEntity} = await dialogRef.afterClosed().toPromise();
      const dest_default = {
        application: updatedConference.app.APP_NAME,
        params: updatedConference.app.appParams
      };
      this.crmRouting.dest_default = dest_default;
      this.form.get('dest_default').markAsDirty();
      this.form.get('dest_default').setValue(dest_default);
      this.setCrmRoutingDisplayed(this.crmRouting);

    } catch (e) {
      return false;
    }
  }

  gotoPage(item: any, state: string): void {
    this.router.navigate([state, item.id], {skipLocationChange: false});
  }

  // changeDidRoutedData = () => {
  //   if (this.crmRouting.routed_queue) {
  //     this.gotoPage(this.crmRouting.routed_queue, 'queues');
  //   }

  //   if (this.crmRouting.routed_conference) {
  //     this.gotoPage(this.crmRouting.routed_conference, 'conferences');
  //   }

  //   if (this.crmRouting.routed_sipphone) {
  //     this.gotoPage(this.crmRouting.routed_sipphone, 'sip-phones');
  //   }

  //   if (this.crmRouting.routed_menu) {
  //     this.gotoPage(this.crmRouting.routed_menu, 'ivr/ivr-menus');
  //   }

  //   if (this.crmRouting.routed_remote_application) {
  //     this.gotoPage(this.crmRouting.routed_remote_application, 'settings/ivr-remote-apps');
  //   }
  // }

  updateCallDestination(i: number, item: any): void {
    this.routing_data.markAsDirty();
    this.routing_data.controls[i].setValue(item.value);
  }

  async submitForm(): Promise<any> {
    const updatedCrmRouting = EntityManager.getRepository('CrmRoutingRuleRepository').create() as CrmRoutingRuleEntity;
    updatedCrmRouting.setObjectData({
      id: this.crmRouting.id,
      name: this.form.get('name').value,
      crm_id: this.form.get('crm').value,
      dest_default: this.crmRouting.dest_default,
      routing_data: this.form.get('routing_data').value
    });

    try {
      if (!this.form.get('name').valid) {
        console.error('Form field name is invalid');
        return;
      }

      for (const callDestinationComp of this.callDestinationComps.toArray()) {
        if (!callDestinationComp.validParam()) {
          this.cdr.markForCheck();
          console.error('Form field routing_data is invalid');
          this.toast.showError(_ti('form.validator.data_invalid'));
          return;
        }
      }

      const attrList = ['name', 'crm_id', 'dest_default', 'routing_data'];
      const extra_data = {
        routing_data: this.form.get('routing_data').value
      };

      const returned = await EntityManager.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository')
        .saveAttrs(updatedCrmRouting, attrList, extra_data);

      this.crmRouting = EntityManager.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository').getObjectById(returned.id);
      this.resetForm();

      this.toast.show(_ti('public.message.update_success'));
    } catch (error) {
      console.log('Error: ', error);
      this.toast.showError(_ti('public.message.update_failure'));
    }
  }
}
