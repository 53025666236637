import { _tk, _ti } from '@wephone-translation';

import { Component, OnInit } from '@angular/core';
import { EnterpriseCrmRepository } from '@wephone-core/model/repository/enterprise_crm';
import { DialogService, ToastService } from '@wephone-utils';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { FlexBasePage } from '@wephone-core-ui';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'crm-create-from-market-place',
  templateUrl: './create-from-market-place.component.html',
  styleUrls: ['./create-from-market-place.component.scss'],
})
export class CreateCrmFromMarketPlace extends FlexBasePage implements OnInit {
  private enterpriseCrmRepo = EnterpriseCrmRepository.getInstance() as EnterpriseCrmRepository;

  code: string;
  type: string;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    public dialogService: DialogService,
    public toast: ToastService,
    private router: Router,
  ) {
    super();
  }

  async ngOnInit(): Promise<any> {
    this.code = this.route.snapshot.queryParams['code'];
    this.type = this.route.snapshot.queryParams['type'];

    const newEnterpriseCrmData = {
      name: this.translate.instant('enterprise_crm.content.new_crm') + ' From Market Place',
      type: this.type,
      code: this.code
    };

    try {
      const newCrm = await this.enterpriseCrmRepo
        .addFromMarket(newEnterpriseCrmData) as EnterpriseCrmEntity;

      this.toast.show(_ti('public.message.create_success'));
      this.router.navigate(['/dashboard'], { queryParams: { import_users_from_crm: newCrm.id } });
    } catch (err) {
      this.toast.showError(err.message);
      return false;
    }
  }
}
