import * as _ from 'lodash';

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { UserRepository } from '@wephone-core/model/repository/user';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { TreeHelper, DialogActionButton, Colors, TreeNodeItem } from '@wephone-utils';
import { DialogComponentBase } from '@wephone-core-ui';
import { _tk, _ti } from '@wephone-translation';
import { FlexTreeInput } from '@wephone-common';

export interface TreeGroupItem {
  name: string;
  checked: boolean;
  checkbox: boolean;
  show: boolean;
  value: number;
  level: number;
  children: TreeGroupItem[];
}

@Component({
  selector: 'group-tree-modal',
  templateUrl: 'group-tree-modal.html'
})
export class GroupTreeModal extends DialogComponentBase {
  @ViewChild('flexTreeInput') flexTreeInput: FlexTreeInput;
  dialogTitle = _tk('livecall.actions.filter_by_group');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('dialogs.ok'),
      action: () => {
        this.applyFilter();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  selected_group_ids: number[];
  tree_group_list: TreeGroupItem[];
  private show_not_grouped_agents: boolean;

  checked_all: boolean;
  usergroup_ids: number[];

  private groupRepo = UserGroupRepository.getInstance() as UserGroupRepository;
  groups: UserGroupEntity[];
  selectedGroupIds = new FormControl();
  groupNodes: TreeNodeItem[];

  constructor(
    public dialogRef: MatDialogRef<GroupTreeModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService
  ) {
    super();
    this.groups = this.groupRepo.getObjectList() as UserGroupEntity[];
    const nodeList = this.groups.map(group => {
      return {
        id: group.id,
        name: group.name,
        parent_id: group.parent_id,
        checkbox: true
      };
    });
    const sortedNodeList = _.sortBy(nodeList, [n => n.name]);
    this.groupNodes = TreeHelper.listToTree(sortedNodeList);
  }

  resolveData(): void {
    this.usergroup_ids = this.getUserGroupIds();
    this.selected_group_ids = _.clone(this.data.group_ids);
    this.checked_all = this.data.checked_all;
    this.show_not_grouped_agents = this.data.show_ungrouped_agents || false;
    this.tree_group_list = [];

    const group_tree = GroupRepository.getInstance<GroupRepository>().getUserGroupTree();
    this.tree_group_list = this.get_tree_group_from(group_tree);

    if (this.checked_all) {
      setTimeout(() => {
        this.flexTreeInput.selectedAll(true);
      });
    } else {
      this.selectedGroupIds.setValue(this.data.group_ids || []);
    }
  }

  checkUncheckAll(checked: boolean): void {
    this.checked_all = checked;
    // Todo: make readonly for selectbox
    let group_ids = this.data.group_ids || [];
    if (checked) {
      group_ids = [];
      this.flexTreeInput.selectedAll(true);
    }
    this.selectedGroupIds.setValue(group_ids);
  }

  private getUserGroupIds = () => {
    if (!this.authService.isAdmin()) {
      const current_user = this.authService.getUser();
      return current_user.group_ids || [];
    }

    return [];
  }

  changeTreeSelected(selected_items: any): void {
    this.selected_group_ids = [];
    for (const i of selected_items) {
      if (this.selected_group_ids.indexOf(i.value) === -1) {
        this.selected_group_ids.push(i.value);
      }
    }
  }

  applyFilter(): void {
    this.dialogRef.close({
      selected_group_ids: !this.checked_all ? this.selectedGroupIds.value : [],
      show_not_grouped_agents: this.show_not_grouped_agents,
      checked_all_group: this.checked_all
    });
  }

  get_tree_group_from = (group_list: any[]): TreeGroupItem[] => {
    const tree_group_list = [];
    let n: any;
    for (const g of group_list) {
      if (this.authService.isAdmin() || (!this.authService.isAdmin() && this.usergroup_ids.indexOf(g.id) >= 0)) {
        n = {
          name: g.name,
          checked: this.selected_group_ids.indexOf(parseInt(g.id)) !== -1 ? true : false,
          checkbox: true,
          show: true,
          value: g.id,
          level: g.level,
          children: []
        };
        if (g.children) {
          n.children = this.get_tree_group_from(g.children);
        }
        tree_group_list.push(n);
      }
    }
    return tree_group_list;
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
