import * as _ from 'lodash';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, IFlexDialogConfig } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'app-create-enterprise-crm-modal',
  templateUrl: './create-enterprise-crm-modal.component.html',
  styleUrls: ['./create-enterprise-crm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEnterpriseCrmModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    width: '500px',
    minHeight: '60%',
  };

  dialogTitle = _tk('enterprise_crm.title.create');
  dialogRightActions: DialogActionButton[] = [];

  isLoading = true;
  types = [];

  constructor(
    public configManager: ConfigManager,
    cdr?: ChangeDetectorRef
  ) {
    super(cdr);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    try {
      const data = await this.configManager.getCrmTypeList();
      for (const key of Object.keys(data)) {
        this.types.push(data[key]);
      }

      this.isLoading = false;
      this.detectChanges();
    } catch (e) {
      this.isLoading = true;
      this.detectChanges();
    }
  }

  onCrmSelect(crm): void {
    this.modalRef.returnValue(crm);
  }
}
