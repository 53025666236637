import { Component, Inject, OnInit } from '@angular/core';
// import { ToastService } from '@wephone-utils';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponentBase } from '@wephone-core-ui';
import { DialogActionButton, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
// import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
// import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';

export class IvrMenuCustomAttributeType {
  name: string;
  value: string;
}

@Component({
  selector: 'ivr-menu-custom-attributes-modal',
  templateUrl: './ivr-menu-custom-attributes-modal.component.html',
  styleUrls: ['./ivr-menu-custom-attributes-modal.component.scss']
})
export class IvrMenuCustomAttributesModal extends DialogComponentBase implements OnInit {
  dialogTitle = _tk('ivrmenu.content.custom_attr.title');
  dialogRightActions: Array<DialogActionButton> = [
    {
      label: _tk('public.publish'),
      action: () => {
        this.save();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  custom_attrs: Array<IvrMenuCustomAttributeType> = [];

  errors: object = {};
  index = 0;
  constructor(
    public dialogRef: MatDialogRef<IvrMenuCustomAttributesModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit(): void {
    let empty_obj = true;

    if (this.data && this.data.call_attributes) {
      for (const attr_name of Object.keys(this.data.call_attributes)) {
        empty_obj = false;
        this.custom_attrs.push(this._createCustomAttrs(attr_name, this.data.call_attributes[attr_name]));
      }
    }

    if (empty_obj) {
      this.custom_attrs.push(this._createCustomAttrs('', ''));
    }
  }

  private _createCustomAttrs(name: string, value: string): IvrMenuCustomAttributeType {
    const newAttr = new IvrMenuCustomAttributeType();
    newAttr.name = name;
    newAttr.value = value;
    return newAttr;
  }

  public save(): void {
    this.errors = {};
    let error = false;
    let index = 0;
    for (const custom_attr of this.custom_attrs) {
      if (!custom_attr.name || !custom_attr.name.replace(/ /g, '')) {
        this.errors[index] = { name: true };
        error = true;
      }
      index++;
    }
    if (!error) {
      this.dialogRef.close({ custom_attrs: this.custom_attrs });
    }
  }

  public addCustomAttr(): void {
    const custom_attr = this._createCustomAttrs('', '');
    this.custom_attrs.push(custom_attr);
  }

  public removeCustomAttr(item: IvrMenuCustomAttributeType): void {
    const index = this.custom_attrs.indexOf(item);
    this.custom_attrs.splice(index, 1);
  }

  // private formValid() {
  //   if (!this.ivrmenu.name) {
  //     this.toastService.showError(_ti('ivrmenu.validators.name_cannot_blank'));
  //     return false;
  //   }

  //   return true;
  // }

  // createIvrMenu() {
  //   if (this.formValid()) {
  //     IvrCustomMenuRepository.getInstance<any>().save(this.ivrmenu, {ivrscript_data: {}}).then(
  //       (response) => {
  //         if (response && response.status == "error" && response.error) {
  //           this.toastService.showError(response.error.message);
  //         }
  //         else {
  //           this.toastService.show(_ti('public.message.create_success'));
  //           let user = UserRepository.getInstance().getObjectById(response.object_id);
  //           //this.viewCtrl.dismiss({user: user});
  //           this.dialogRef.close({user: user});
  //         }
  //       },
  //       (response) => {
  //         let error_data = response && response.error ? response.error : null;
  //         console.error("Create User error", error_data.error);
  //         let msg = error_data && error_data.error && error_data.error.message ? error_data.error.message : 'public.message.create_failure';
  //         this.toastService.showError(msg);
  //       }
  //     );
  //   }
  // }

  public close(): void {
    this.dialogRef.close();
  }
}
