import { Component, OnInit, ComponentFactoryResolver, ViewChild, Inject, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FlexAnchorDirective } from '../../directives/flex-anchor/flex-anchor.directive';
import { IDialogComponent, DialogActionButton, IDialogWrapper, IFlexDialogConfig } from './flex-dialog.i';
import { SubscriptionManagedComponent } from '../base';

@Component({
  selector: 'flex-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWrapper extends SubscriptionManagedComponent implements OnInit, IDialogWrapper {
  @ViewChild(FlexAnchorDirective, { static: true }) anchor: FlexAnchorDirective;

  dialogComponent: IDialogComponent;
  fullScreenMediaQuery: string;
  paddingContent: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogWrapper>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.paddingContent = this.data.paddingContent;
    this.createSubComponent();
  }

  detectChange(): void {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  createSubComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.viewComponent);

    const viewContainerRef = this.anchor.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.dialogComponent = componentRef.instance as IDialogComponent;
    this.dialogComponent.setModalComponent(this);

    if (this.data) {
      for (const key of Object.keys(this.data)) {
        if (!this.data.hasOwnProperty(key) || key === 'component') {
          continue;
        }
        componentRef.instance[key] = this.data[key];
      }
    }
  }

  returnValue(value?: any): void {
    if (this.dialogComponent.cancelButton && this.dialogComponent.cancelButton.action) {
      return this.dialogComponent.cancelButton.action();
    }

    this.dialogRef.close(value);
  }

  goBack(): void {
    if (this.dialogComponent.backButton) {
      this.dialogComponent.backButton.action();
    }
  }

  hasCustomButtons(): boolean {
    return !!((this.dialogComponent.dialogLeftActions || []).filter(x => !x.visible || x.visible && x.visible()).length ||
      (this.dialogComponent.dialogRightActions || []).filter(x => !x.visible || x.visible && x.visible()).length);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' && !event.metaKey) {
      this.dialogRef.close();
    }
  }
}
