<form [formGroup]="form">
  <mat-accordion displayMode="flat">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{ 'components.queue_update_general.heading_general'|translate }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row">
          <div fxFlex="30">
            <call-queue-logo [queue]="queue" width="100px" height="100px" [uploadAvatar]="true"></call-queue-logo>
          </div>

          <div fxLayout="column" fxFlex="70">
            <div *ngIf="!multipleEdit">
              <mat-form-field class="full-width">
                <mat-label>{{ 'call_queue.content.name'|translate }}</mat-label>
                <input matInput placeholder="{{ 'call_queue.content.name'|translate }}" formControlName="queue_name">

                <mat-error [flexFormControlError]="form.get('queue_name')"></mat-error>
              </mat-form-field>
            </div>

            <div *ngIf="hasFeature('SERVICE_GROUP')">
              <mat-form-field class="full-width">
                <mat-label>{{ 'call_queue.content.group'|translate }}</mat-label>
                <usergroup-select-form-input formControlName="group"
                  [placeholder]="'call_queue.content.group'|translate">
                </usergroup-select-form-input>
                <button mat-icon-button matSuffix type="button" (click)="removeControlValue('group')"
                  [disabled]="!hasControlValue('group')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

          </div>
        </div>

        <div>
          <calendar-select-form-input formControlName="opening_calendar"
            [create]="false"
            placeholder="{{ 'call_queue_edit.section.opening_time' | translate }}"></calendar-select-form-input>
        </div>

        <ng-container *ngIf="queue && form.get('opening_calendar').value && form.get('opening_calendar').value.id">
          <call-destination class="full-width -mt-2" data-id="agenda_closed_config"
            #agendaClosedConfigDestination [destinations]="agendaClosedConfigDestinationList"
            placeholder="call_queue.content.agenda_closed_config"
            [excludeQueueId]="queue.id" [isSubMenu]="false"
            [routeAppLabelObject]="{voicemail: 'call_queue_edit.content.voicemail'|translate}"
            [voicemail]="{ voicemail_enabled: true, mailbox_id: queue.voicemail_id }"
            [value]="queue.agenda_closed_config" [required]="true"
            (valueChanged)="updateAgendaClosedConfig($event)">
          </call-destination>
        </ng-container>

        <div>
          <mat-form-field class="full-width">
            <mat-label>{{ 'call_queue.content.max_inqueue_time'|translate }}</mat-label>
            <flex-duration-input formControlName="max_inqueue" [predefined_values]="{
              '0': 'call_queue.content.max_inqueue.never'|translate
            }">
            </flex-duration-input>
            
            <mat-error [flexFormControlError]="form.get('max_inqueue')"></mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-divider [inset]="true"></mat-divider>
        </div>

        <div>
          <mat-checkbox formControlName="voicemail_enabled">
            {{ 'voicemail.enable_voicemail_queue'|translate }}
          </mat-checkbox>
        </div>

        <div>
          <mat-checkbox formControlName="voicemail_by_mail_enabled">
            {{ 'call_queue.content.email_addr.voicemail_by_mail_addr'|translate }}
          </mat-checkbox>
        </div>

        <div *ngIf="form.get('voicemail_by_mail_enabled').value">
          <div fxFlexOffset="40px" fxFlexOffset.xs="0px" fxLayout="column">

            <div class="mb-1">
              <mat-checkbox formControlName="voicemail_in_attachement">
                {{ 'call_queue.content.email_addr.enable_mp3_attachment'|translate }}
              </mat-checkbox>
            </div>

            <div formArrayName="voicemail_by_mail_addrs" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px"
              fxLayoutGap.xs="0">

              <div class="mb-1" fxFlex.gt-xs="calc(50%-10px)"
                *ngFor="let mailAddrControl of voicemailByMailAddrsControl.controls; let mail_addr_index = index;">
                <mat-form-field [formGroupName]="mail_addr_index">
                  <mat-label>{{ 'call_queue.content.email_addr.email'|translate }}</mat-label>

                  <input matInput type="email" formControlName="mail_addr"
                    placeholder="{{'call_queue.content.email_addr.email'|translate}}" [required]="true" [email]="true">
                    
                  <button mat-icon-button matSuffix type="button" (click)="removeVoicemailByMailAddr(mail_addr_index)"
                    title="{{ 'public.remove'|translate }}" class="mat-text-warn">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                    
                  <mat-hint align="start" *ngIf="!form.get('voicemail_by_mail_addrs').value[mail_addr_index].mail_addr">
                    <strong>{{ 'public.message.email_invalid' | translate }}</strong>
                  </mat-hint>

                  <mat-error [flexFormControlError]="mailAddrControl.controls.mail_addr"
                    [customMessages]="{
                      'email': 'public.message.email_invalid'|translate
                    }"></mat-error>
                </mat-form-field>
              </div>
              <div fxFlex.gt-xs="100">
                <button mat-button mat-stroked-button (click)="addVoicemailByMailAddr()" type="button" color="accent">
                  <mat-icon class="icon-add">add</mat-icon> {{'call_queue.content.email_addr.add_email'|translate}}
                </button>
              </div>
            </div>

          </div>
        </div>

        <div>
          <mat-divider [inset]="true"></mat-divider>
        </div>

        <div>
          <mat-checkbox formControlName="recording_mode">{{ 'user.content.enable_recording_call_queue'|translate }}
          </mat-checkbox>
        </div>

        <div>
          <mat-divider [inset]="true"></mat-divider>
        </div>

        <mat-form-field fxLayout="column">
          <mat-label>{{ 'call_queue.content.priority'|translate }}</mat-label>
          <mat-select formControlName="queue_priority" placeholder="{{ 'call_queue.content.priority'|translate }}">
            <mat-option *ngFor="let priority of priorityList" [value]="priority.value">{{ priority.label|translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('queue_priority').hasError('required')">
            {{ 'form.validator.field_required'|translate }}
          </mat-error>
        </mat-form-field>

      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{'call_queue_edit.section.sounds'|translate}}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <mat-form-field>
          <mat-label>{{ 'call_queue.content.silent'|translate }}</mat-label>
          <mat-select formControlName="silent" placeholder="{{ 'call_queue.content.silent'|translate }}">
            <mat-option *ngFor="let item of queueSoundSilentList" [value]="item.value">{{ item.name|translate }}</mat-option>
          </mat-select>
          <button mat-icon-button matSuffix type="button" (click)="removeControlValue('silent')"
            [disabled]="!hasControlValue('silent')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <div [hidden]="!queue.greeting_file" class="mb-1">
          <mat-checkbox formControlName="play_entire_welcome">
            {{ 'call_queue.content.play_entirely_before_entering_queue'|translate }}
          </mat-checkbox>
        </div>

        <sound-upload class="wrapper-sound-upload" [fileEntry]="form.get('greeting_file').value"
          title="{{'call_queue.content.greeting_file'|translate}}" (onChange)="updateSoundFile('greeting_file', $event)"
          [hidden]="!!form.get('silent').value"></sound-upload>

        <sound-upload class="wrapper-sound-upload" [fileEntry]="form.get('waiting_music_file').value"
          title="{{'call_queue.content.waiting_music_file'|translate}}"
          (onChange)="updateSoundFile('waiting_music_file', $event)" [hidden]="!!form.get('silent').value">
        </sound-upload>

        <sound-upload class="wrapper-sound-upload" [fileEntry]="form.get('moh_file').value"
          title="{{'call_queue.content.holding_file'|translate}}" (onChange)="updateSoundFile('moh_file', $event)">
        </sound-upload>

        <sound-upload class="wrapper-sound-upload" [fileEntry]="form.get('opening_hour_file').value"
          title="{{'call_queue.content.out_of_hours_message'|translate}}"
          (onChange)="updateSoundFile('opening_hour_file', $event)"></sound-upload>

        <sound-upload class="wrapper-sound-upload" [fileEntry]="form.get('no_agent_file').value"
          title="{{'call_queue.content.no_agent_file'|translate}}"
          (onChange)="updateSoundFile('no_agent_file', $event)"></sound-upload>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [hidden]="!isCallCenter">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{ 'call_queue_edit.section.on_call_end'|translate }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column" fxLayoutGap.xs="0px">
        <div>
          <mat-form-field class="full-width">
            <mat-label>{{ 'call_queue.content.ivr_evaluation'|translate }}</mat-label>
            <ivr-evaluation-select-form-input formControlName="eoc_ivr_menu"
              [placeholder]="'call_queue.content.ivr_evaluation'|translate">
            </ivr-evaluation-select-form-input>
            <button mat-icon-button matSuffix type="button" (click)="removeControlValue('eoc_ivr_menu')"
              [disabled]="!hasControlValue('eoc_ivr_menu')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0px">
          <div fxFlex>
            <mat-form-field class="full-width">
              <mat-label>{{ 'call_queue.content.aftercall_pause_mode'|translate }}</mat-label>
              <mat-select formControlName="aftercall_pause_mode"
                placeholder="{{ 'call_queue.content.aftercall_pause_mode'|translate }}">
                <mat-option *ngFor="let item of afterCallPauseModeList" [value]="item.value">{{ item.name|translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex *ngIf="form.get('aftercall_pause_mode').value">
            <mat-form-field class="full-width">
              <mat-label>{{ 'call_queue.content.after_call_time'|translate }}</mat-label>
              <flex-duration-input formControlName="after_call_time" [predefined_values]="{
                  '15': ('call_queue.content.wait_after_call_time.n_seconds'|translate:{ n:15 }),
                  '30': ('call_queue.content.wait_after_call_time.n_seconds'|translate:{ n: 30 }),
                  '60': ('call_queue.content.max_inqueue.n_minutes'|translate:{ n: 1 }),
                  '120': ('call_queue.content.max_inqueue.n_minutes'|translate:{ n: 2 }),
                  '0': 'call_queue.content.wait_after_call_time.until_unpause'|translate
                }"
                placeholder="{{ 'call_queue.content.after_call_time'|translate }}">
              </flex-duration-input>
            </mat-form-field>
          </div>
          <!-- <mat-form-field fxFlex="calc(50%-10px)" fxFlex.xs="100" [hidden]="form.get('aftercall_pause_mode').value === 0">
            <mat-select formControlName="after_call_time" placeholder="{{ 'call_queue.content.after_call_time'|translate }}">
              <mat-option *ngFor="let item of afterCallTimeList" [value]="item.value">{{ item.name }}</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [hidden]="!isCallCenter">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{'call_queue.content.call_tagging'|translate}}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="0px">
        <div fxFlex="calc(50-10px)" fxFlex.xs="100">
          <mat-checkbox formControlName="has_qualification">
            {{ 'call_queue.content.active_evaluation'|translate }}
          </mat-checkbox>
        </div>

        <div fxFlex="calc(50-10px)" fxFlex.xs="100">
          <mat-checkbox formControlName="qualification_required">
            {{ 'call_queue.content.qualification_required'|translate }}
          </mat-checkbox>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutGap.xs="0px">
        <div fxFlex="calc(50-10px)" fxFlex.xs="100">
          <mat-form-field fxFlex="calc(50-10px)" fxFlex.xs="100" [hidden]="!hasQualificationControl.value">
            <mat-label>{{ 'call_queue.content.in_qualification'|translate }}</mat-label>
            <qualification-select-form-input formControlName="in_qualification"
              placeholder="{{ 'call_queue.content.in_qualification'|translate }}"></qualification-select-form-input>
          </mat-form-field>
        </div>
        <div fxFlex="calc(50-10px)" fxFlex.xs="100">
          <mat-form-field fxFlex="calc(50-10px)" fxFlex.xs="100" [hidden]="!hasQualificationControl.value">
            <mat-label>{{ 'call_queue.content.out_qualification'|translate }}</mat-label>
            <qualification-select-form-input formControlName="out_qualification"
              placeholder="{{ 'call_queue.content.out_qualification'|translate }}"></qualification-select-form-input>
          </mat-form-field>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [hidden]="!isCallCenter">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{'call_queue_edit.content.action_backoffice'|translate}}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">
        <div fxFlex class="pb-1">
          <mat-radio-group formControlName="bo_type" fxLayout="column">
            <mat-radio-button [value]="backOfficeType.NULL" class="pb-1">
              {{'call_queue.content.open_none'|translate}}
            </mat-radio-button>
            <mat-radio-button [value]="backOfficeType.URL">
              {{'call_queue.content.open_url'|translate}}
            </mat-radio-button>
          </mat-radio-group>
          <small *ngIf="form.get('bo_type').hasError('required')" class="mat-text-warn pt-1">{{
            'form.validator.field_required'
            | translate }}</small>
        </div>

        <mat-form-field fxFlex *ngIf="boTypeControl.value === backOfficeType.URL">
          <mat-label>{{ 'call_queue.content.bo_url'|translate }}</mat-label>
          <input type="text" matInput formControlName="bo_url"
            [placeholder]="'call_queue.content.bo_url'|translate">
          <mat-error *ngIf="form.get('bo_url').hasError('required')">
            {{ 'form.validator.field_required'|translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [hidden]="!isCallCenter">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{'call_queue_edit.content.transfer_numbers'|translate}}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column" formArrayName="transfer_numbers">
        <div class="mb-1" *ngFor="let transferNumberControl of transferNumbersControl.controls; let i=index;">
          <div [formGroupName]="i" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxLayoutGap.xs="5px">

            <mat-form-field fxFlex fxLayout="column" class="mb-1">
              <mat-label>{{ 'call_queue.content.transfer_number.label'|translate }}</mat-label>
              <input type="text" matInput formControlName="label"
                placeholder="{{ 'call_queue.content.transfer_number.label'|translate }}" [required]="true">
              <mat-error *ngIf="transferNumberControl.controls.label.hasError('required')">{{
                'form.validator.field_required' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex fxLayout="column" class="mb-1">
              <mat-label>{{ 'call_queue.content.transfer_number.number'|translate }}</mat-label>
              <input type="text" matInput formControlName="phone_number"
                placeholder="{{ 'call_queue.content.transfer_number.number'|translate }}" [required]="true">

              <mat-error *ngIf="transferNumberControl.controls.phone_number.invalid"
                [flexFormControlError]="transferNumberControl.controls.phone_number"></mat-error>
            </mat-form-field>

            <div fxFlex="50px">
              <button type="button" mat-icon-button (click)="removeTransferNumber(i)"
                title="{{ 'public.remove'|translate}}" class="mat-text-warn">
                <!-- <fa-icon [icon]="FAIcons.faMinusCircle"></fa-icon> -->
                <mat-icon>remove_circle</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div fxFlex>
          <button mat-button mat-stroked-button color="accent" (click)="addTransferNumber()" type="button">
            <mat-icon class="icon-add">add</mat-icon> {{ 'call_queue.content.transfer_number.add'|translate }}
          </button>
        </div>
      </div>

    </mat-expansion-panel>
  </mat-accordion>

</form>
