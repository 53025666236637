import { BaseRepository } from '@wephone-core/model/repository/base_repository';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { AuthenInfo, AuthenticationService } from '@wephone-core/service/authentication';
import * as _ from 'lodash';
import { BusinessType, UserRole } from '@wephone-core/system';

export interface EnterpriseAttributeResponse {
  invoice: any[];
  user: any[];
}

export class EnterpriseRepository extends BaseRepository {
  ENTITY_CLASS = EnterpriseEntity;
  protected USE_PUSH_API = true;
  protected URL_PREFIX = 'enterprise';
  protected METHOD_PATH_ENABLE = 'enable';
  protected METHOD_PATH_DISABLE = 'disable';

  protected getBaseUrl(): string {
    return FlexIvrSettings.getInstance().getAbsoluteApiUrlv2();
  }

  async getEnterpriseAttributes(): Promise<EnterpriseAttributeResponse> {
    const response: EnterpriseAttributeResponse = await HttpEngine.getInstance().get(
      FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/enterprise/attributes')
    );

    return response;
  }

  protected getURLAction(action: string): string {
    switch (action) {
      case 'ENABLE':
        return this.getUrl(this.METHOD_PATH_ENABLE);
      case 'DISABLE':
        return this.getUrl(this.METHOD_PATH_DISABLE);
      default:
        return super.getURLAction(action);
    }
  }

  getMyEnterprise(): EnterpriseEntity {
    const authInfo: AuthenInfo = AuthenticationService.getInstance().authInfo;
    // If user is not superadmin, enterprise has no ID and there is only one enterprise in this.object_list
    if (!authInfo.user_role) {
      return undefined;
    }

    if (authInfo.business_type === BusinessType.RESELLER) {
      return this.object_list && this.object_list.find(x => !x.reseller_id);
    }

    if (!_.includes([ UserRole.SUPER_ACCOUNTANT, UserRole.SUPER_ADMIN ], authInfo.user_role) && this.object_list && this.object_list.length === 1) {
      return this.object_list[0];
    }
  }

  protected getRemoteData(use_cache = true, filters: any = null): Promise<any> {
    if (this.data_loaded && use_cache) {
      return Promise.resolve(this.object_list);
    }
    return HttpEngine.getInstance()
      .get(FlexIvrSettings.getInstance().getAbsoluteWSv2URL('api/entity/list/enterprise'))
      .then(
        (response: any) => {
          this.setDataAsReady();
          return this.object_list;
        },
        (response: any) => {
          console.error('Get enterprise list error', response);

          return [];
        }
      );
  }

  getSystemParams(): Promise<any> {
    return HttpEngine.getInstance().get(this.getUrl('get_system_params'), null);
  }

  updateSystemParams(params): Promise<any> {
    return HttpEngine.getInstance().postJson(this.getUrl('vt_update_system_params'), params);
  }

  updateEnterprise(post_data: { [id: string]: any } = null): Promise<any> {
    const url = this.getURLAction('UPDATE_ATTRS');
    return HttpEngine.getInstance().postJson(url, post_data);
  }

  protected changeState(item: EnterpriseEntity, state: number): Promise<any> {
    return this.bulkChangeState([item], state);
  }

  async bulkChangeState(items: EnterpriseEntity[], state: number): Promise<any> {
    const action: string = state === 1 ? 'ENABLE' : 'DISABLE';
    const url = this.getURLAction(action);
    const ids = [];
    for (let i = 0; i < items.length; i++) {
      ids.push(items[i].getId());
    }
    return HttpEngine.getInstance().postJson(url, { ids }).then((ret) => {
      for (let i = 0; i < items.length; i++) {
        items[i].enabled = state;
      }
    });
  }
}
