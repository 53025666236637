import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActionButton, Colors, IFlexDialogConfig, localNow } from '@wephone-utils';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogComponentBase } from '@wephone-core-ui';
import * as _ from 'lodash';
import { _tk, _ti } from '@wephone-translation';
import { DURATION_TYPES, PhoneNumberBlockActionType } from '@wephone-core/model/entity/blacklist';
import { DateTime } from 'luxon';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { BlackListRepository } from '@wephone-core/model/repository/blacklist';
import { PhoneNumberValidated } from '@wephone/services/form-validator';
import { IBlockCall, ICreateBlackList } from '@wephone-core/model/repository/blacklist.i';

export interface ICreateBlackListModalData {
  cdr_id?: number; // Block call
  number?: string; // Create black-list by phone-number
  block_reason?: string;
  block_caller?: boolean; // Determine block a caller or a phone-number, if true, cdr_id is given, otherwise number is given
}

@Component({
  selector: 'app-create-black-list-modal',
  templateUrl: './create-black-list-modal.component.html',
  styleUrls: ['./create-black-list-modal.component.scss']
})
export class CreateBlackListModalComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    size: 'l',
    width: '800px',
    minHeight: '90%',
  };

  dialogTitle = _tk('blacklist.title.create');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.apply'),
      action: () => {
        this.submitForm();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    },
  ];

  form: FormGroup;
  phoneNumberBlockAction = { block: PhoneNumberBlockActionType.BLOCK, low_priority: PhoneNumberBlockActionType.LOW_PRIORITY };
  blockDurationList: any[];
  selectedBlock: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly em: EntityManager,
    @Inject(MAT_DIALOG_DATA) private data: ICreateBlackListModalData,
  ) {
    super();
    if (data.block_caller) {
      this.dialogTitle = _tk('handled_call_info.action.block_caller');

      if (!data.cdr_id) {
        console.error('data.cdr_id is missing');
      }
    } else if (!data.number) {
      console.error('data.number is missing');
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.blockDurationList = [
      {
        value: DURATION_TYPES.forever,
        label: _ti('blacklist.content.block_duration.forever'),
        color: 'warn'
      },
      {
        value: DURATION_TYPES.one_year,
        label: _ti('blacklist.content.block_duration.year', { value: 1 }),
        color: 'primary'
      },
      {
        value: DURATION_TYPES.six_months,
        label: _ti('blacklist.content.block_duration.months', { value: 6 })
      },
      {
        value: DURATION_TYPES.three_months,
        label: _ti('blacklist.content.block_duration.months', { value: 3 })
      },
      {
        value: DURATION_TYPES.one_week,
        label: _ti('blacklist.content.block_duration.week', { value: 1 })
      },
      {
        value: DURATION_TYPES.one_day,
        label: _ti('blacklist.content.block_duration.day', { value: 1 })
      }
    ];

    const formGroup: any = {
      // number: [this.data && this.data.number, [Validators.required, Validators.maxLength(20), PhoneNumberValidated()]],
      blockReason: [this.data && this.data.block_reason, [Validators.required]],
      blockAction: 0,
      blockExpire: undefined
    };

    if (!this.isBlockCaller) {
      formGroup.number = [this.data && this.data.number, [Validators.required, Validators.maxLength(20), PhoneNumberValidated()]];
    }

    this.form = this.fb.group(formGroup);

    this.selectedBlock = DURATION_TYPES.forever;
  }

  get isBlockCaller(): boolean {
    return this.data && !!this.data.block_caller;
  }

  async submitForm(): Promise<any> {
    const blackListRepo = this.em.getRepository<BlackListRepository>('BlackListRepository');
    this.form.markAllAsTouched();
    if (!this.form.invalid) {
      try {
        const updateObject: ICreateBlackList | IBlockCall = {
          block_reason: this.form.get('blockReason').value,
          block_action: this.form.get('blockAction').value,
          block_until: this.form.get('blockExpire').value,
        } as any;

        let numberBlockListId: number;
        let returnData;

        if (this.isBlockCaller) {
          (updateObject as IBlockCall).cdr_id = this.data.cdr_id;

          returnData = await blackListRepo.blockCall(updateObject as IBlockCall);
        } else {
          (updateObject as ICreateBlackList).number = this.form.get('number').value.replace(/\D/g, '');

          returnData = await blackListRepo.createAndSave(updateObject as ICreateBlackList);
        }

        if (!returnData || !returnData.id) {
          throw Error(_ti('public.message.error_occurred'));
        }

        numberBlockListId = returnData.id;

        await blackListRepo.wait_for_object_id(numberBlockListId);
        const blackListItem = blackListRepo.getObjectById(numberBlockListId);

        const successMsg = this.isBlockCaller ? _ti('handled_call_info.message.block_caller_success') : _ti('public.message.create_success');
        this.successToast(successMsg);
        this.modalRef.returnValue(blackListItem);

      } catch (e) {
        console.error('Create blacklist error:', e);
        const defaultMsg = this.isBlockCaller ? _ti('handled_call_info.message.block_caller_failure') : _ti('public.message.create_success');
        const message = e && e.message || defaultMsg;
        this.showError(message);
      }
    }
  }

  changeExpire(duration: string): void {
    this.selectedBlock = duration;
    this.form.get('blockExpire').markAsTouched();
    this.form.get('blockExpire').markAsDirty();
    this.form.get('blockExpire').setValue(this.getBlockUntilDate(duration));
  }

  private getBlockUntilDate(duration: string): DateTime {
    let block_until_dt: DateTime;
    const now = localNow();
    switch (duration) {
      case DURATION_TYPES.one_year:
        block_until_dt = now.plus({ years: 1 });
        break;
      case DURATION_TYPES.six_months:
        block_until_dt = now.plus({ month: 6 });
        break;
      case DURATION_TYPES.three_months:
        block_until_dt = now.plus({ month: 3 });
        break;
      case DURATION_TYPES.one_week:
        block_until_dt = now.plus({ days: 7 });
        break;
      case DURATION_TYPES.one_day:
        block_until_dt = now.plus({ days: 1 });
        break;
      default:
        block_until_dt = null;
        break;
    }

    return block_until_dt;
  }
}
