import { Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { DidRepository } from '@wephone-core/model/repository/did';
import { ConfigManager } from '@wephone-core/service/config_manager';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { DidOrderEntity } from '@wephone-core/model/entity/did_order';
import { DidEntity } from '@wephone-core/model/entity/did';
import {
  DialogService,
  ToastService,
  IFlexTableSidePannelOptions,
  DynamicFilterSource,
  IFlexTableConfig,
  regexSearch,
  FlexCRUDPageComponent,
  PhoneNumberService
} from '@wephone-utils';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NumberRoutingEditComponent } from '@wephone/pages/number-routing/number-routing-edit/number-routing-edit';
import { DidOrderRepository } from '@wephone-core/model/repository/did_order';

import { FlexBasePage } from '@wephone-core-ui';
import { IvrRemoteAppRepository } from '@wephone-core/model/repository/ivr_remote_app';
import { NumberRoutingMultiEditComponent } from '../number-routing-multi-edit/number-routing-multi-edit.component';
import { _tk, _ti } from '@wephone-translation';
import { DidOrderGlobalComponent } from '@did-order/did-order-global/did-order-global.component';
import { PaymentService } from '@wephone/services/payment.service';
import { PaymentSourceEntity } from '@wephone-core/model/entity/payment_source';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EnterpriseParamDict, SystemParam } from '@wephone-core/system';
import * as _ from 'lodash';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { DidService } from '@wephone/services/did.service';
import { RoutingServiceRepository } from '@wephone-core/model/repository/routing_service';
import { HotelRoomRepository } from '@wephone-core/model/repository/hotelroom';
import { ValidatorService } from '@wephone-utils/services/validator.service';

export interface DidFilterObjectType {
  value: number;
  label: string;
  icon?: string;
}

@Component({
  selector: 'app-number-routing-list',
  templateUrl: './number-routing-list.html',
  styleUrls: ['./number-routing-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberRoutingListComponent extends FlexBasePage implements OnInit {
  // Private member var with default value
  private entityManager = EntityManager.getInstance() as EntityManager;
  private configManager = ConfigManager.getInstance() as ConfigManager;
  private didRepo = DidRepository.getInstance() as DidRepository;
  private didOrderRepo = DidOrderRepository.getInstance() as DidOrderRepository;

  // Public member var
  defaultDid: FormControl;
  didOrder: DidOrderEntity = undefined;
  enterprise: EnterpriseEntity;
  enterpriseParams: EnterpriseParamDict;

  filterForm: FormGroup;
  filterCountryCode: string;
  filterValue: string;
  objectType: DidFilterObjectType;

  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: NumberRoutingEditComponent,
    multiItemEditor: NumberRoutingMultiEditComponent
  };

  countryCodes = [];
  objectTypeList: DidFilterObjectType[] = [
    { value: 0, label: 'did.content.not_config', icon: 'settings' },
    { value: 1, label: 'did.content.object_type.dedicated_number', icon: 'person' },
    { value: 2, label: 'did.content.object_type.sip_number', icon: 'dialer_sip' },
    { value: 3, label: 'did.content.object_type.a_group_of_agents', icon: 'people_outline' },
    { value: 4, label: 'did.content.object_type.ivr', icon: 'dialpad' },
    { value: 5, label: 'did.content.object_type.external_number', icon: 'launch' },
  ];

  isUpdatedLayout = false;

  private readonly is_hotel: boolean = FlexIvrSettings.getInstance().uiHotel();

  @ViewChild('flexCrud', { static: false }) flexCrud: FlexCRUDPageComponent;
  constructor(
    private readonly didService: DidService,
    public readonly dialogService: DialogService,
    private readonly paymentService: PaymentService,
    private readonly authService: AuthenticationService,
    private readonly fb: FormBuilder,
    public readonly toast: ToastService,
    private readonly em: EntityManager,
    private readonly validatorService: ValidatorService,
    private readonly phoneNumberService: PhoneNumberService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.enterpriseParams = this.configManager.getEnterpriseParams();
    console.log('enterpriseParams', this.enterpriseParams);

    this.loadDidOrder();
    this.loadDS();
    this.didRepo.getObjectList().map(did => {
      if (did.country_code) {
        const country_code = did.country_code.toLowerCase();
        if (!this.countryCodes.includes(country_code)) {
          this.countryCodes.push(country_code);
        }
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.enterprise = this.entityManager.getRepository<EnterpriseRepository>('EnterpriseRepository').getMyEnterprise();
    this.filterForm = this.fb.group({
      countryCode: undefined,
      filterValue: [this.filterValue],
      objectType: [this.objectType],
    });
    this.filterForm.valueChanges.subscribe(this.onFormValueChange);

    // this.loadDefaultDid();
  }

  protected async resolveData(): Promise<any> {
    // required load data before use
    await this.configManager.get_config('did_country');
    await this.em.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository').loadObjectList();
    await this.em.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository').loadObjectList();
    await this.em.getRepository<RoutingServiceRepository>('RoutingServiceRepository').loadObjectList();

    if (this.is_hotel) {
      await this.em.getRepository<HotelRoomRepository>('HotelRoomRepository').loadObjectList();
    }

    this.updateLayout();
  }

  private loadDS(): void {
    const didSource = this.didRepo.dataSource<DidEntity>();
    this.dataSource = new DynamicFilterSource(didSource, this.filterFn);

    this.tableConfig = {
      columns: [
        {
          name: 'display_number',
          label: _tk('did.content.number'),
          sortable: true,
          visibleInCompactMode: true,
          exportedValue: d => {
            return d.number;
          }
        },
        {
          name: 'welcome_message',
          label: _tk('did.content.welcome_message'),
          sortable: true,
          enableTooltip: true,
          lineClamp: 2,
          exportable: false
        },
        {
          name: 'linked_object_type',
          label: _tk('did.content.call_destination'),
          sortable: true,
          exportable: false
        },
        {
          name: 'country_code',
          label: _tk('country.content.code2'),
          sortable: false,
          searchable: false,
          visible: false
        },
        {
          name: 'configured',
          label: _tk('did.content.call_destination'),
          sortable: false,
          searchable: false,
          visible: false,
          exportedValue: d => {
            return d.configured ? 1 : 0;
          }
        },
        {
          name: 'destination_type',
          label: _tk('did.content.call_destination'),
          sortable: false,
          searchable: false,
          visible: false,
          exportedValue: d => {
            return d.destination_type || '';
          }
        },
        {
          name: 'destination_name',
          label: _tk('did.content.call_destination'),
          sortable: false,
          searchable: false,
          visible: false,
          exportedValue: d => {
            return d.destination_name || '';
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'btnAdd',
            icon: 'add',
            visible: !!this.enterpriseParams.buy_number_from_provider,
            callback: () => {
              return this.buyNumbers();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'btnRemove',
              icon: 'delete',
              callback: data => {
                return this.returnItems(data.selectedItems);
              }
            }
          ],
          secondary: [
            {
              id: 'btnClear',
              icon: 'cleaning_services',
              hint: _tk('did.content.clear_did'),
              callback: data => {
                return this.clearItems(data.selectedItems);
              }
            }
          ]
        }
      }
    };
  }

  // private async loadReferenceData(): Promise<any> {
  //   // required load data before use
  //   const promises: any[] = [
  //     await this.configManager.get_config('did_country'),
  //     await this.em.getRepository<IvrRemoteAppRepository>('IvrRemoteAppRepository').loadObjectList(),
  //     await this.em.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository').loadObjectList(),
  //     await this.em.getRepository<RoutingServiceRepository>('RoutingServiceRepository').loadObjectList(),
  //   ];

  //   if (this.is_hotel) {
  //     promises.push(await this.em.getRepository<HotelRoomRepository>('HotelRoomRepository').loadObjectList());
  //   }

  //   return Promise.all(promises);
  // }

  private loadDidOrder(): void {
    this.entityManager.reloadRepositories(['did_order']).then(() => {
      this.didOrder = this.didOrderRepo.getActiveByUserId(this.authService.getUserId());
    });
  }

  private onFormValueChange = (formValues: { countryCode: string; filterValue: string; objectType: DidFilterObjectType }) => {
    this.filterCountryCode = formValues.countryCode;
    this.objectType = formValues.objectType;
    this.filterValue = (formValues.filterValue || '').trim();
    this.dataSource.filter = this.filterValue;

    this.dataSource.onFilterChange();
  }

  filterFn = (did: DidEntity) => {
    return this.filterByCountry(did) && this.filterPredicate(did, this.filterValue) && this.filterObjectType(did);
  }

  filterObjectType(did: DidEntity): boolean {
    if (_.isEmpty(this.objectType)) {
      return true;
    }

    if (this.objectType.value === 0) {
      return !did.hasRoutingData();
    }

    if (this.objectType.value === 1) {
      return did.hasLinkedUser();
    }

    if (this.objectType.value > 1) {
      return did.linked_object_type === this.objectType.value;
    }

    return true;
  }

  filterPredicate = (item: DidEntity, filterString: string): boolean => {
    if (!filterString) {
      return true;
    }

    let normalizeFilterString;
    if (this.validatorService.isPhoneNumber(filterString)) {
      normalizeFilterString = this.phoneNumberService.normalizeNumber(filterString);

      if (normalizeFilterString && (regexSearch(item.number, normalizeFilterString) || regexSearch(item.display_number, normalizeFilterString))) {
        return true;
      }
    }

    return regexSearch(item.number, filterString) ||
      regexSearch(item.display_number, filterString) ||
      (item.name && regexSearch(item.name, filterString)) ||
      (item.welcome_message && regexSearch(item.welcome_message, filterString));
  }

  filterByCountry = (did: DidEntity): boolean => {
    if (!this.filterCountryCode) {
      return true;
    }
    if (did.country_code) {
      return did.country_code.toLowerCase() === this.filterCountryCode;
    }

    return false;
  }

  // private loadDefaultDid(): void {
  //   this.defaultDid = new FormControl();
  //   if (!this.didRepo.getDefaultDid() && this.configManager.getSystemParam(SystemParam.telecom_has_anonymous)) {
  //     this.defaultDid.setValue(this.didRepo.getAnonymousDid());
  //   } else {
  //     this.defaultDid.setValue(this.didRepo.getDefaultDid());
  //   }

  //   this.defaultDid.valueChanges.subscribe((did: DidEntity) => {
  //     this.didRepo.setDefaultDid(did).then(() => {
  //       this.toast.show(_ti('did.message.update_success'));
  //     });
  //   });
  // }

  private async clearItems(items: DidEntity[]): Promise<any> {
    const confirmed: boolean = await this.didService.getConfirmClearRoutingDids(items, _ti('dialogs.routing.reset_confirm_msg'));
    if (confirmed) {
      try {
        const returedClear = await this.didRepo.clear(items);
        if (returedClear) {
          this.updateLayout();
          this.toast.show(_ti('did.message.reset_success'));
        }
      } catch (error) {
        this.showError(error.message || _ti('did.message.reset_failure'));
      }
    }
  }

  private async returnItems(items: DidEntity[]): Promise<any> {
    const confirmed: boolean = await this.didService.getConfirmClearRoutingDids(items, _ti('dialogs.routing.repaid_confirm_msg'));
    if (confirmed) {
      try {
        const returnedRepaid = await this.didRepo.return(items);
        if (returnedRepaid) {
          this.flexCrud.closeSidePanel();
          this.toast.show(_ti('did.message.delete_success'));
        }
      } catch (error) {
        this.showError(_ti('did.message.delete_failure'));
      }
    }
  }

  // Actions page
  // openDidOrder(): void {
  //   const dialogRef = this.dialogService.openDialog2(DidOrderDialogComponent, {});
  //   dialogRef.afterClosed().subscribe(() => {
  //     this.loadDidOrder();
  //   });
  // }

  async buyNumbers(): Promise<any> {
    // Check if has already add credit card or not
    const myPaymentSource: PaymentSourceEntity = await this.paymentService.mySource();
    console.log('myPaymentSource', myPaymentSource);
    if (!myPaymentSource) {
      console.error('No payment souce');
      this.showWarning(_ti('enterprise_info.update_payment_method.message.not_add_card'));
      return;
    }

    const canBuyNumber: boolean = await this.enterprise.canBuyNumber();
    if (!canBuyNumber) {
      this.showWarning(_ti('enterprise_info.update_payment_method.message.is_trial'));
      return;
    }

    if (!myPaymentSource.can_make_payment) {
      console.error('Payment souce already expired');
      this.showWarning(_ti('enterprise_info.update_payment_method.message.card_expired'));
      return;
    }

    const data = {};
    return this.dialogService
      .openDialog2(DidOrderGlobalComponent, { data }, () => {
        this.loadDidOrder();
        this.detectChanges();
      })
      .afterClosed()
      .toPromise();

    /* Temporary disable
    const dialogDef = this.dialogService.openModal(SetupNewDidsDialogComponent, {});
    dialogDef.afterClosed().subscribe(() => {
      this.loadDidOrder();
    });
    */
  }

  private updateLayout(): void {
    // Manual update layout of number-routing-dest elements
    this.isUpdatedLayout = !this.isUpdatedLayout;
  }
}
