import { datafield, Entity } from '@wephone-core/model/model';

export class EnterpriseParametersEntity extends Entity {
  static object_type_id = 'enterprise_parameters';

  @datafield
  id: number;
  @datafield
  enterprise_id: number;
  @datafield
  cc_allow_call_direction_selection: number;
  @datafield
  disable_phone_in_iframe: number;
  @datafield
  agent_auto_disconnect: number;
  @datafield
  my_own_recorded_calls: number;
  @datafield
  any_calling_number: number;
  @datafield
  dialer_queue_selector: number;
  @datafield
  speech2text: number;
  @datafield
  group_management: number;
  @datafield
  sms_enable: number;
  @datafield
  sipphone_provisioning: number;
  @datafield
  call_quality_enable: number;
  @datafield
  call_analysis_enable: number;
  @datafield
  buy_number_from_provider: number;
  @datafield
  anti_spam: number;
}
