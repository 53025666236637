<div class="pa-1">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'crm_routing.content.name'| translate}}</mat-label>
        <input matInput placeholder="{{ 'crm_routing.content.name'|translate }}" formControlName="name">

        <mat-error [flexFormControlError]="form.get('name')"
          [customMessages]="{
            'any.empty': 'form.validator.field_required',
            'nameExist': 'public.message.name_exist'
          }"></mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width">
        <mat-label>{{'crm_routing.content.crm'| translate}}</mat-label>
        <mat-select placeholder="{{ 'crm_routing.content.crm' | translate }}" formControlName="crm">
          <mat-option *ngFor="let crm of crmList" [value]="crm.id">
            {{crm.name}}
          </mat-option>
        </mat-select>

        <mat-error [flexFormControlError]="form.get('crm')"
          [customMessages]="{
            'any.empty': 'form.validator.field_required'
          }"></mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
