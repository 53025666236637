import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { PauseReasonEntity } from '@wephone-core/model/entity/pause_reason';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { PauseReasonRepository } from '@wephone-core/model/repository/pause_reason';
import { IFlexDialogConfig, DialogActionButton, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'dialog-pause-agent',
  templateUrl: 'dialog-pause-agent.html',
  styleUrls: ['dialog-pause-agent.scss']
})
export class PauseAgentDialog extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    hasBackdrop: true,
    size: 'xs',
  };

  dialogTitle = _tk('dialogs.pause_reason.dialog_title');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.ok'),
      action: () => {
        this.modalRef.returnValue({ pause_reason_id: this.pause_reason_id });
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  pause_reasons: PauseReasonEntity[];
  searchText: string;
  pause_reason_id: number;

  constructor(
    private readonly em: EntityManager,
    @Inject(MAT_DIALOG_DATA) public data: {
      pauseReason?: PauseReasonEntity
    },
  ) {
    super();

    const pause_reasons: PauseReasonEntity[] = this.em.getRepository<PauseReasonRepository>('PauseReasonRepository').getActiveObjectList();
    this.pause_reasons = pause_reasons.sort((a: PauseReasonEntity, b: PauseReasonEntity) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    if (!this.pause_reason_id && this.pause_reasons.length) {
      this.pause_reason_id = this.pause_reasons[0].id;
    }
  }

  ngOnInit(): void {
    if (this.data.pauseReason) {
      this.pause_reason_id = this.data.pauseReason.id;
    }
  }
}
