import { FormGroup, AbstractControl, ValidatorFn, ValidationErrors, FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { parseDateTime } from '@wephone-utils';
import * as _ from 'lodash';

export const RegPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]*$/g;
const SipPhoneExtension = /^[0-9]{3,}$/;

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    let ret = null;
    if (control.value !== matchingControl.value) {
      ret = { mustMatch: true };
    }
    matchingControl.setErrors(ret);
  };
}

export function PasswordValidated(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    // const maxLength = 20;
    // const minLength = 6;

    // const valueLength = value && value.length || 0;
    // if (value && valueLength > maxLength) {
    //   return { maxlength: { requiredLength: maxLength, actualLength: valueLength } };
    // }

    // if (value && valueLength < minLength) {
    //   return { minlength: { requiredLength: minLength, actualLength: valueLength } };
    // }
    const valid = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([\x00-\x7F]+){6,20}$/.test(value);
    return !valid ? { passwordConstraint: { value } } : null;
  };
}

// export function EmailValidated(): ValidatorFn {
//   return (emailControl: AbstractControl): ValidationErrors | null => {
//     const email = emailControl.value;
//     if (!_.isEmpty(email)) {
//       const reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
//       if (reg.test(email) === false) {
//         return {
//           validateEmail: true
//         };
//       }
//     }
//     return null;
//   };
// }

export function PhoneNumberOnlyDigitValidated(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value && control.value.trim() || '';
    if (value) {
      const reg = /^[+]*[0-9]+$/g;
      if (reg.test(value) === false) {
        return {
          invalidPhoneNumber: true
        };
      }
    }
    return null;
  };
}

export function SipPhoneExtensionValidated(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value && _.trim(control.value) || '';
    if (!_.isEmpty(value)) {
      if (SipPhoneExtension.test(value) === false) {
        console.error('Invalid sip phone extension', value);
        return {
          invalidSipPhoneExtension: true
        };
      }
    }
    return null;
  };
}

export function PhoneNumberValidated(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value && _.trim(control.value) || '';
    if (!_.isEmpty(value)) {
      const reg = _.cloneDeep(RegPhoneNumber);
      if (reg.test(value) === false) {
        console.log('Invalid phone number', value, reg);
        return {
          invalidPhoneNumber: true
        };
      }
    }
    return null;
  };
}

export function NotPhoneNumberValidated(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!_.isEmpty(value)) {
      const reg = _.cloneDeep(RegPhoneNumber);
      if (reg.test(value) === true) {
        return {
          isPhoneNumber: true
        };
      }
    }
    return null;
  };
}

export function OneRequired(controlNames: string[]): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const formControls: any[] = controlNames.map(controlName => formGroup.controls[controlName] as any);
    const touched = !!formControls.filter(i => i.touched).length;
    const invalid = formControls.filter(fc => !_.isEmpty(fc.value)).length ? false : true;
    const ret = invalid ? { oneRequired: true } : null;

    for (const formControl of formControls) {
      if (invalid) {
        formControl.setErrors(ret);
        if (touched) {
          formControl.markAsDirty();
        }
      } else {

        const errors = formControl.errors && _.cloneDeep(formControl.errors);
        if (!_.isEmpty(errors) && Object.keys(errors).includes('oneRequired')) {
          delete errors.oneRequired;
        }

        if (_.isEmpty(errors)) {
          formControl.setErrors(null);
        } else {
          formControl.setErrors(errors);
          if (touched) {
            formControl.markAsDirty();
          }
        }
      }
    }

    return ret;
  };
}

/******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
export function FlexDatepickerMin(controlName: string, minControlName: string): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    const minControl = formGroup.controls[minControlName];
    const errors = control.errors || {};

    if (!_.isEmpty(errors) && 'flexDatepickerMin' in errors) {
      delete errors.flexDatepickerMin;
    }

    if (!_.isEmpty(errors)) {
      // return if another validator has already found an error on the control
      return;
    }

    const controlValue = control.value || undefined;
    const minControlValue = minControl.value || undefined;

    if (!controlValue && !minControlValue) {
      if (!_.isNull(minControl.errors) && minControl.errors.flexDatepickerMin) {
        const minControlErrors = minControl.errors;
        delete minControlErrors.flexDatepickerMin;
        minControl.setErrors(!_.isEmpty(minControlErrors) ? errors : null);
        return;
      }

      control.setErrors(!_.isEmpty(errors) ? errors : null);
      return;
    }

    if (!controlValue || !minControlValue) {
      errors.flexDatepickerMin = true;
      if (!minControlValue) {
        minControl.setErrors(errors);
        return;
      }

      control.setErrors(errors);
      return;
    }

    const controlDate = parseDateTime(controlValue).startOf('day');
    const minControlDate = parseDateTime(minControlValue).startOf('day');

    // set error on control if validation fails
    if (minControlDate.diff(controlDate).as('days') > 0) {
      errors.flexDatepickerMin = true;
    }
    control.setErrors(!_.isEmpty(errors) ? errors : null);
  };
}

export function RequiredByEnableStatusValidator(controlName: string, controlEnableStatusName: string): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    const errors = control.errors || {};

    if (!_.isEmpty(errors) && 'required' in errors) {
      delete errors.required;
    }

    if (!_.isEmpty(errors)) {
      // return if another validator has already found an error on the control
      return errors;
    }

    const enableStatus = formGroup.get(controlEnableStatusName).value;
    const controlValue = _.trim(control.value);

    if (enableStatus) {
      if (!controlValue) {
        control.setErrors({ required: true });
      }
      return;
    }

    control.setErrors(null);
  };
}


export class FormInputErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;

    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export function NotContainSpace(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && (control.value as string).indexOf(' ') >= 0) {
      return { notContainSpace: true };
    }

    return null;
  };
}
